/* eslint-disable unicorn/prefer-set-has */
/* eslint-disable unicorn/prefer-switch */
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

import { currentUserOrganizationAtom } from 'atoms/modals';

import useGetAllLeads from 'hooks/useGetLeads';
import useGetUsersLeads from 'hooks/useGetUsersLeads';
import useSFSolutions from 'hooks/useSFSolutions';

export const useLeadManagementDashboard = () => {
  const currentUserOrganization = useAtomValue(currentUserOrganizationAtom);

  const [selectedSolution, setSelectedSolution] = useState({
    pieChartOneSolution: 'all',
    allSolution: 'all',
  });

  const [selectedOrganization, setSelectedOrganization] = useState('all');

  const { sfSolutions } = useSFSolutions();

  const {
    leads,
    hasNextPage: hasNextLeadsPage,
    isFetchingNextPage: isFetchingLeadsNextPage,
    fetchNextPage: fetchNextLeadsPage,
  } = useGetAllLeads();

  const { currentUsersAccessibleLeads } = useGetUsersLeads({ allLeads: leads });

  useEffect(() => {
    if (hasNextLeadsPage && !isFetchingLeadsNextPage) {
      fetchNextLeadsPage();
    }
  }, [fetchNextLeadsPage, hasNextLeadsPage, isFetchingLeadsNextPage]);

  const { pieChartOne: pieChartDataOne, solutionDropdownList: solutionList } =
    useMemo(() => {
      const pieChartOne: any[] = [];
      const solutionDropdownList: any[] = [];
      const allLeadSolutions: any[] = [];

      currentUsersAccessibleLeads.forEach((lead: any) => {
        let leadSolution: any;
        if (lead.solution) {
          try {
            leadSolution = JSON.parse(lead.solution);
            leadSolution = leadSolution.filter((s: any) => !!s);

            leadSolution.forEach((sol: any) => {
              const index = allLeadSolutions.findIndex(
                (solution) => solution.id === sol.id,
              );
              if (index < 0) {
                allLeadSolutions.push(sol);
              }

              // pie chart 1
              const i = pieChartOne.findIndex((d) => d.id === sol.id);
              if (i < 0) {
                pieChartOne.push({
                  name: sol.name,
                  id: sol.id,
                  value: 1,
                });
              } else {
                pieChartOne[i].value += 1;
              }
            });
          } catch {
            leadSolution = sfSolutions.find((s: any) => s.id === lead.solution);
            if (leadSolution) {
              const index = allLeadSolutions.findIndex(
                (solution) => solution.id === leadSolution.id,
              );
              if (index < 0) {
                allLeadSolutions.push(leadSolution);
              }

              // pie chart 1
              const i = pieChartOne.findIndex((d) => d.id === leadSolution.id);
              if (i < 0) {
                pieChartOne.push({
                  name: leadSolution.name,
                  id: leadSolution.id,
                  value: 1,
                });
              } else {
                pieChartOne[i].value += 1;
              }
            }
          }
        }
      });

      allLeadSolutions.forEach((sol: any) => {
        const solutionName = sol.name;
        solutionDropdownList.push({
          key: solutionName,
          text: solutionName,
          id: solutionName,
          title: solutionName,
        });
      });

      // Chart 1 bar

      return {
        pieChartOne,
        solutionDropdownList: [...solutionDropdownList].sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }

          if (a.text > b.text) {
            return 1;
          }

          return 0;
        }),
      };
    }, [currentUsersAccessibleLeads, sfSolutions]);

  // pie chart
  const pieChartData: any[] =
    selectedSolution.allSolution === 'all'
      ? pieChartDataOne
      : pieChartDataOne.map((obj) => {
          if (obj.name !== selectedSolution.allSolution) {
            return {
              ...obj,
              value: 0,
            };
          }
          return obj;
        });

  return {
    pieChartDataOne: pieChartData,

    currentUsersAccessibleLeads,

    selectedSolution,

    setSelectedSolution,

    selectedOrganization,

    setSelectedOrganization,

    solutionList,

    currentUserOrganization,
  };
};

export default useLeadManagementDashboard;
