import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { StatusType } from 'API';

import {
  currentUserAccessDetailsAtom,
  allUserAccessiblePublishedChaptersAtom,
  allUserAccessiblePublishedDocsAtom,
  allUserAccessiblePublishedProductDocsAtom,
  allUserAccessiblePublishedRootChaptersAtom,
  isTenantAdminAtom,
} from 'atoms/modals';

import falainaTenantId from 'consts/tenant';

import useGetCurrentAuthenticatedUser from 'hooks/useGetCurrentAuthenticatedUser';
import useGetCurrentUserAccessDetails from 'hooks/useGetCurrentUserAccessDetails';
import { useInfiniteProductDocs } from 'hooks/useProductDocs';
import useUser from 'hooks/useUser';
import useUserDisabled from 'hooks/useUserDisabled';

import useSessionTimeout from './useSessionTimeout';
import useSetGroupsByOrganization from './useSetGroupsByOrganization';
import {
  useSetAllSalesForceOrganizations,
  useSetUserSalesForceOrganizations,
  useSetAllSalesForceProducts,
} from './useSetSalesForce';
import useSetSuperAdmin from './useSetSuperAdmin';
import useSetUserDetails from './useSetUserDetails';
import useToggleProfileCard from './useToggleProfileCard';
import useToggleSidebar from './useToggleSidebar';
import { useInfiniteChapters } from 'hooks/useChapters';
import { useInfiniteSearchableDocs } from 'hooks/useSearchableDocs';

const useAppLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.pathname;
  const state: unknown = location.state;

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname, state]);

  useSessionTimeout({ navigate, location });

  const [isSidebarOpen, setSidebarOpen] = useToggleSidebar(true);

  const [isShowProfileCard, setIsShowProfileCard] = useToggleProfileCard(false);

  const currentUserAccessDetails = useAtomValue(currentUserAccessDetailsAtom);

  const isTenantAdmin = useAtomValue(isTenantAdminAtom);

  const setAllUserAccessiblePublishedProductDocs = useSetAtom(
    allUserAccessiblePublishedProductDocsAtom,
  );

  const setAllUserAccessiblePublishedRootChapters = useSetAtom(
    allUserAccessiblePublishedRootChaptersAtom,
  );

  const setAllUserAccessiblePublishedChapters = useSetAtom(
    allUserAccessiblePublishedChaptersAtom,
  );

  const setAllUserAccessiblePublishedDocs = useSetAtom(
    allUserAccessiblePublishedDocsAtom,
  );

  const currentAuthenticatedUser = useGetCurrentAuthenticatedUser();

  useSetSuperAdmin({ currentAuthenticatedUser });

  const { userSource: userDetails } = useUser({
    id: currentAuthenticatedUser?.sub ?? '',
    enabled: !!currentAuthenticatedUser?.sub,
    staleTime: 0,
  });

  useSetUserDetails({ userDetails });

  const [isUserDisabled] = useUserDisabled({ userDetails });

  const hasSomeDocumentPortalAccess =
    !!currentUserAccessDetails?.hasSomeDocumentPortalAccess;
  const productDocsAccessMap =
    currentUserAccessDetails?.productDocsAccessMap ?? null;
  const hasSomeReaderAccess = !!currentUserAccessDetails?.hasSomeReaderAccess;
  const hasSomeCreatorAccess = !!currentUserAccessDetails?.hasSomeCreatorAccess;
  const hasSomeApproverAccess =
    !!currentUserAccessDetails?.hasSomeApproverAccess;
  const hasSomeSupportPortalAccess =
    !!currentUserAccessDetails?.hasSomeSupportPortalAccess;
  const hasSomeLeadManagementPortalAccess =
    !!currentUserAccessDetails?.hasSomeLeadManagementPortalAccess;

  const isDocPortalQueriesEnabled =
    (isTenantAdmin || !!hasSomeDocumentPortalAccess) &&
    (pathname.startsWith('/products') ||
      pathname.startsWith('/doc-portal-search'));

  const { productDocsSource } = useInfiniteProductDocs({
    filter: {
      status: { eq: StatusType.PUBLISHED },
    },
    enabled: isDocPortalQueriesEnabled,
    staleTime: 0,
  });

  const { chaptersSource } = useInfiniteChapters({
    enabled: isDocPortalQueriesEnabled,
    filter: {
      status: { eq: StatusType.PUBLISHED },
      tenantId: { eq: falainaTenantId },
    },
  });

  const { docsSource } = useInfiniteSearchableDocs({
    enabled: isDocPortalQueriesEnabled,
    filter: {
      status: { eq: StatusType.PUBLISHED },
      tenantId: { eq: falainaTenantId },
    },
  });

  const userAccessiblePublishedProductDocs = useMemo(() => {
    if (isTenantAdmin) {
      return productDocsSource;
    }

    if (
      (hasSomeReaderAccess || hasSomeCreatorAccess || hasSomeApproverAccess) &&
      productDocsAccessMap
    ) {
      if (hasSomeCreatorAccess && hasSomeApproverAccess) {
        const validProductDocIds = new Set(
          Object.keys(productDocsAccessMap).filter((item) => {
            const access = productDocsAccessMap[item] ?? [];

            if (access.includes('creator') || access.includes('approver')) {
              return true;
            }
            return false;
          }),
        );

        return productDocsSource.filter(
          (item) => item.id && validProductDocIds.has(item.id),
        );
      }

      if (hasSomeReaderAccess && hasSomeApproverAccess) {
        const validProductDocIds = new Set(
          Object.keys(productDocsAccessMap).filter((item) => {
            const access = productDocsAccessMap[item] ?? [];

            if (access.includes('reader') || access.includes('approver')) {
              return true;
            }
            return false;
          }),
        );

        return productDocsSource.filter(
          (item) => item.id && validProductDocIds.has(item.id),
        );
      }

      if (hasSomeCreatorAccess && hasSomeReaderAccess) {
        const validProductDocIds = new Set(
          Object.keys(productDocsAccessMap).filter((item) => {
            const access = productDocsAccessMap[item] ?? [];

            if (access.includes('creator') || access.includes('reader')) {
              return true;
            }

            return false;
          }),
        );

        return productDocsSource.filter(
          (item) => item.id && validProductDocIds.has(item.id),
        );
      }

      if (hasSomeCreatorAccess) {
        const validProductDocIds = new Set(
          Object.keys(productDocsAccessMap).filter((item) => {
            const access = productDocsAccessMap[item] ?? [];

            if (access.includes('creator')) {
              return true;
            }

            return false;
          }),
        );

        return productDocsSource.filter(
          (item) => item.id && validProductDocIds.has(item.id),
        );
      }

      if (hasSomeApproverAccess) {
        const validProductDocIds = new Set(
          Object.keys(productDocsAccessMap).filter((item) => {
            const access = productDocsAccessMap[item] ?? [];

            if (access.includes('approver')) {
              return true;
            }

            return false;
          }),
        );

        return productDocsSource.filter(
          (item) => item.id && validProductDocIds.has(item.id),
        );
      }

      if (hasSomeReaderAccess) {
        const validProductDocIds = new Set(
          Object.keys(productDocsAccessMap).filter((item) => {
            const access = productDocsAccessMap[item] ?? [];

            if (access.includes('reader')) {
              return true;
            }

            return false;
          }),
        );

        return productDocsSource.filter(
          (item) => item.id && validProductDocIds.has(item.id),
        );
      }

      return [];
    }

    return [];
  }, [
    hasSomeApproverAccess,
    hasSomeCreatorAccess,
    hasSomeReaderAccess,
    isTenantAdmin,
    productDocsAccessMap,
    productDocsSource,
  ]);

  const userAccessiblePublishedRootChapters = useMemo(
    () =>
      userAccessiblePublishedProductDocs
        .flatMap((x) => x.chapters?.items ?? [])
        .filter((x) => x?.status === StatusType.PUBLISHED),
    [userAccessiblePublishedProductDocs],
  );

  const userAccessiblePublishedChapters = useMemo(
    () =>
      chaptersSource.filter((x) =>
        userAccessiblePublishedRootChapters.some(
          (y) => y?.id === x.rootChapterId,
        ),
      ),
    [chaptersSource, userAccessiblePublishedRootChapters],
  );

  const userAccessiblePublishedDocs = useMemo(
    () =>
      docsSource.filter((x) =>
        userAccessiblePublishedRootChapters.some(
          (y) => y?.id === x?.rootChapterId,
        ),
      ),
    [docsSource, userAccessiblePublishedRootChapters],
  );

  useEffect(() => {
    setAllUserAccessiblePublishedProductDocs(
      userAccessiblePublishedProductDocs,
    );
  }, [
    userAccessiblePublishedProductDocs,
    setAllUserAccessiblePublishedProductDocs,
  ]);

  useEffect(() => {
    setAllUserAccessiblePublishedRootChapters(
      userAccessiblePublishedRootChapters,
    );
  }, [
    setAllUserAccessiblePublishedRootChapters,
    userAccessiblePublishedRootChapters,
  ]);

  useEffect(() => {
    setAllUserAccessiblePublishedChapters(userAccessiblePublishedChapters);
  }, [setAllUserAccessiblePublishedChapters, userAccessiblePublishedChapters]);

  useEffect(() => {
    setAllUserAccessiblePublishedDocs(userAccessiblePublishedDocs);
  }, [setAllUserAccessiblePublishedDocs, userAccessiblePublishedDocs]);

  useSetGroupsByOrganization({
    customQueryKeys: ['AppLayout'],
    organizationId: userDetails?.organizationId ?? '',
    isQueryEnabled: !!userDetails?.organizationId,
  });

  useSetAllSalesForceOrganizations({
    customQueryKeys: ['AppLayout'],
    isQueryEnabled:
      hasSomeSupportPortalAccess ||
      hasSomeLeadManagementPortalAccess ||
      isTenantAdmin,
  });

  useSetUserSalesForceOrganizations({
    customQueryKeys: ['AppLayout'],
    organizationId: userDetails?.organizationId,
    isQueryEnabled:
      !!userDetails?.organizationId &&
      (hasSomeSupportPortalAccess || isTenantAdmin),
  });

  useSetAllSalesForceProducts({
    customQueryKeys: ['AppLayout'],
    isQueryEnabled: hasSomeSupportPortalAccess || isTenantAdmin,
  });

  useGetCurrentUserAccessDetails();

  return {
    userDetails,
    isUserDisabled,
    setSidebarOpen,
    isSidebarOpen,
    setIsShowProfileCard,
    isShowProfileCard,
    currentUserAccessDetails,
    pathname,
    hasSomeSupportPortalAccess,
  };
};

export default useAppLayout;
