import type { GraphQLQuery } from '@aws-amplify/api';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';

import type {
  CustomListSFProductsQuery,
  ModelSFProductFilterInput,
  SFProduct,
} from 'API';

import { tenantAtom } from 'atoms/modals';

import { customListSFProducts } from 'graphql/custom-queries';

import getNonNullableList from 'helpers/utils/getNonNullableList';
import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseSFProductsType =
  | ({
      filter?: ModelSFProductFilterInput | null;
    } & UseInfiniteQueryOptions)
  | null
  | void;

export const useSFProductsSource = (props: IUseSFProductsType = {}) => {
  const filter = props?.filter ?? null;

  const queryKey = props?.queryKey ?? [];

  const enabled = props?.enabled ?? true;

  return useInfiniteQuery({
    queryKey: ['sfProducts', filter, ...queryKey],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<GraphQLQuery<CustomListSFProductsQuery>>({
        query: customListSFProducts,
        variables: filter
          ? { filter, limit: 1_000_000, nextToken: pageParam }
          : { limit: 1_000_000, nextToken: pageParam },
      });
    },
    staleTime: toMilliseconds({ hours: 1, minutes: 0, seconds: 0 }), // 1hr
    enabled,
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.listSFProducts?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
  });
};

const useSFProducts = (props: IUseSFProductsType = {}) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const filter = props?.filter ?? null;

  const enabled = (props?.enabled ?? true) && !!tenantId;

  const defaultFilter: ModelSFProductFilterInput | null = tenantId
    ? {
        tenantId: {
          eq: tenantId,
        },
      }
    : null;

  const sourceFilter: ModelSFProductFilterInput | null = tenantId
    ? filter
      ? { ...filter, ...defaultFilter }
      : { ...defaultFilter }
    : null;

  const result = useSFProductsSource({
    ...props,
    filter: sourceFilter,
    enabled,
  });

  const sfProductsSource = useMemo(() => {
    const pages = result.data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.listSFProducts?.items),
    );

    return tempItems as Partial<SFProduct>[];
  }, [result.data?.pages]);

  const sfProducts = useMemo(
    () =>
      sfProductsSource.flatMap((sfProduct) =>
        sfProduct.id
          ? [
              {
                id: sfProduct.id,
                name: sfProduct.productName,
                code: sfProduct.productCode,
              },
            ]
          : [],
      ),
    [sfProductsSource],
  );

  return {
    sfProductsSource,
    sfProducts,
    ...result,
  };
};

export const useInfiniteSFProducts = (props: IUseSFProductsType = {}) => {
  const result = useSFProducts(props);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = result;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      try {
        void fetchNextPage();
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return result;
};

export default useSFProducts;
