import { ToastContainer } from 'react-toastify';

import type { ChildrenProps } from 'types';

import AuthSetting from './AuthSetting';
import ReactQuerySetting from './ReactQuerySetting';
import RouterSetting from './RouterSetting';

import 'react-toastify/dist/ReactToastify.css';

const AppSetting = ({ children }: ChildrenProps) => {
  return (
    <AuthSetting>
      <ReactQuerySetting>
        <ToastContainer position="bottom-center" />
        <RouterSetting>{children}</RouterSetting>
      </ReactQuerySetting>
    </AuthSetting>
  );
};

export default AppSetting;
