/* eslint-disable react/forbid-elements */
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { useRef, useState, useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { DocItemParams } from 'types/Pages/Products';

type ImageCropperProps = {
  src: any;
  setValue: any;
  setshowCrop: any;
  fileType: any;
};

const ImageCropper = ({
  src,
  setValue,
  setshowCrop,
  fileType,
}: ImageCropperProps) => {
  const [upImg, setUpImg] = useState<any>(null);
  const imgRef = useRef<any>(null);
  const previewCanvasRef = useRef<any>(null);
  const [crop, setCrop] = useState<any>({
    unit: '%',
    width: 30,
    height: 30,
  });
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const [showCropped, setShowCropped] = useState<boolean>(false);

  useEffect(() => {
    if (src?.length) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(src[0]);
    }
    setShowCropped(false);
  }, [src]);

  const onLoad = useCallback((img: any) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const imagecrop: any = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = imagecrop.width * pixelRatio;
    canvas.height = imagecrop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      imagecrop.x * scaleX,
      imagecrop.y * scaleY,
      imagecrop.width * scaleX,
      imagecrop.height * scaleY,
      0,
      0,
      imagecrop.width,
      imagecrop.height,
    );
  }, [completedCrop]);

  const generateDownload = async (canvas: any, cropped: any) => {
    if (
      crop.height >= imgRef.current.height &&
      crop.width >= imgRef.current.width
    ) {
      setValue('file', src);
      return;
    }
    if (!cropped || !canvas) {
      return;
    }
    const croppedImageBlob: any = await new Promise((resolve) =>
      canvas.toBlob((blob: any) => {
        const file = new File([blob], `file.${fileType}`, {
          type: `image/${fileType}`,
        });
        resolve(file);
      }, `image/${fileType}`),
    );
    const list = new DataTransfer();
    list.items.add(croppedImageBlob);
    setValue('file', list.files);
  };

  return (
    <div className="flex flex-col w-auto">
      {src?.length > 0 && (
        <>
          {!showCropped && (
            <ReactCrop
              imageStyle={{
                width: '100%',
              }}
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
          )}
          {!showCropped && (
            <div className="mt-6">
              <PrimaryButton
                onClick={() => {
                  setShowCropped(true);
                  generateDownload(previewCanvasRef.current, completedCrop);
                  setshowCrop(false);
                }}
                text="Crop"
                className="bg-blue-db-20"
              />
              <DefaultButton
                className=" ml-2"
                text="Cancel"
                onClick={() => {
                  setshowCrop(false);
                }}
              />
            </div>
          )}
          <div className={`${showCropped ? 'visible' : 'invisible'}`}>
            <canvas
              ref={previewCanvasRef}
              width={Math.round(completedCrop?.width ?? 0)}
              height={Math.round(completedCrop?.height ?? 0)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCropper;
