import { Storage } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useRef, useCallback } from 'react';

import { Chapter } from 'API';

import { currentUserDetailsAtom } from 'atoms/modals';

import generateToast from 'helpers/utils/generateToast';

type IUseExportRootChapterAsPDF = {
  rootChapter: Partial<Chapter> | null;
};

export type PdfGenerationFunctionInput = {
  rootChapter: {
    id: string;
    version: string;
  };
  chapter?: {
    id: string;
    version: string;
  };
  doc?: {
    id: string;
    version: string;
  };
  userEmail: string | undefined;
  documentType: 'ROOT_CHAPTER' | 'CHAPTER' | 'DOC';
};

export type PdfGenerationFunctionResponse = {
  status: 'OK' | 'ERROR';
  body?: {
    url: string;
    key: string;
  };
  error?: string;
};

export const fetchPdf = async (data: PdfGenerationFunctionInput) => {
  try {
    const FUNCTION_URL = import.meta.env.VITE_APP_PDF_EXPORT_FUNCTION_URL;

    const res = await fetch(FUNCTION_URL || '', {
      method: 'POST',

      body: JSON.stringify({ data }),
    });

    const response = (await res.json()) as PdfGenerationFunctionResponse;

    return response;
  } catch (error) {
    console.log('Error from pdf function ->', error);

    throw error;
  }
};

export const handlePdfDownload = async ({
  fileKey,
  fileName,
}: {
  fileKey: string;
  fileName?: string;
}) => {
  try {
    const result = (await Storage.get(fileKey, {
      download: true,
      bucket: import.meta.env.VITE_APP_PDF_EXPORT_S3_BUCKET,
      customPrefix: { public: '' },
    })) as any;

    if (result.Body) {
      const blob = await result.Body?.blob();

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');

      a.href = url;

      a.download = fileName ?? fileKey;

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };

      a.addEventListener('click', clickHandler, false);

      a.click();
    }
  } catch (error) {
    console.error(error);

    generateToast({
      type: 'error',
      toastContent:
        'Something went wrong while exporting the pdf. Please try again!!',
    });
  }
};

const useExportRootChapterAsPDF = ({
  rootChapter,
}: IUseExportRootChapterAsPDF) => {
  const hiddenAnchorElementRefForPDF = useRef<HTMLAnchorElement>(null);

  const currentUserDetails = useAtomValue(currentUserDetailsAtom);

  const generatePdf = useCallback(async () => {
    if (rootChapter?.id && rootChapter.version) {
      const pdfGenerationFunctionInput: PdfGenerationFunctionInput = {
        rootChapter: {
          id: rootChapter.id,
          version: rootChapter.version,
        },
        userEmail: currentUserDetails?.secondaryEmail,
        documentType: 'ROOT_CHAPTER',
      };

      try {
        generateToast({
          type: 'info',
          toastContent: `Preparing to export the document "${rootChapter.name}". Please Wait! This may take upto 5-10 minutes.`,
        });

        generateToast({
          type: 'info',
          toastContent: `We will mail you with a link to the pdf once its ready.`,
        });

        const pdfGenerationFunctionResponse = await fetchPdf(
          pdfGenerationFunctionInput,
        );

        if (pdfGenerationFunctionResponse.status === 'ERROR') {
          throw new Error(
            pdfGenerationFunctionResponse.error ?? 'Something went wrong!!',
          );
        }
      } catch (error) {
        console.log('PDF Generation Error', error);

        generateToast({
          type: 'error',
          toastContent: `Something went wrong while exporting the document named ${rootChapter.name}. Please try agin!!`,
        });
      }
    }
  }, [
    currentUserDetails?.secondaryEmail,
    rootChapter?.id,
    rootChapter?.name,
    rootChapter?.version,
  ]);

  const handlePDFExportClick = useCallback(async () => {
    if (rootChapter) {
      const pdfFile = rootChapter.pdfFile;

      const pdfFileKey = pdfFile?.key;

      if (pdfFileKey) {
        generateToast({
          type: 'info',
          toastContent: `Preparing to export the document "${rootChapter.name}". Please Wait!`,
        });

        await handlePdfDownload({
          fileKey: pdfFileKey,
          fileName: rootChapter.name,
        });
      } else {
        await generatePdf();
      }
    }
  }, [generatePdf, rootChapter]);

  return {
    handlePDFExportClick,
    ignoreCacheAndForceGeneratePdf: generatePdf,
    hiddenAnchorElementRefForPDF,
  };
};

export default useExportRootChapterAsPDF;
