import { MenuItem, Text } from '@fluentui/react-components';
import {
  AddCircle20Filled,
  Delete20Filled,
  DocumentPdf20Filled,
  Edit20Filled,
} from '@fluentui/react-icons';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { StatusType } from 'API';

import type {
  AddChapterButtonType,
  AddContentButtonType,
  ClearCacheAndExportChapterButtonType,
  ClearCacheAndExportDocButtonType,
  DeleteOrDiscardChapterButtonType,
  DeleteOrDiscardContentButtonType,
  EditChapterButtonType,
  EditContentButtonType,
  ExportChapterButtonType,
  ExportDocButtonType,
} from 'types/nestedSidebar';

import {
  QueryKeys,
  chapterDeleteAtom,
  chapterEditAtom,
  currentUserDetailsAtom,
  docDeleteAtom,
  docEditAtom,
  showNestedSidebarPanelAtom,
} from 'atoms/modals';

import {
  fetchPdf,
  handlePdfDownload,
  PdfGenerationFunctionInput,
} from 'hooks/useExportRootChapterAsPDF';

import generateToast from 'helpers/utils/generateToast';

export const AddChapterButton = ({
  isParentDeleted,
  isSomeRootChapVersionSubmittedForApproval,
  documentId,
  chapter,
  setIsHovering,
}: AddChapterButtonType) => {
  const setChapterAtomValue = useSetAtom(chapterEditAtom);
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);

  return (
    <MenuItem
      icon={<AddCircle20Filled className="text-blue-900" />}
      onClick={() => {
        setShowNestedSidebarPanelView(false);
        setIsHovering(false);
        if (isParentDeleted) {
          generateToast({
            type: 'error',
            toastContent:
              'Cannot Add chapter as one of its parent chapters was deleted.',
          });
        } else if (isSomeRootChapVersionSubmittedForApproval) {
          generateToast({
            type: 'error',
            toastContent:
              'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
          });
        } else {
          setChapterAtomValue({
            chapter: {
              id: uuidv4(),
              name: '',
              version: '',
              status: StatusType.PENDING,
              description: '',
              parentChapterId: chapter?.id,
              rootChapterId: documentId,
            },
            isOpen: true,
            action: 'ADD',
            chapterType: QueryKeys.SUB_CHAPTER,
          });
        }
      }}
    >
      <Text className="text-black-db-1" size={300}>
        Add Chapter
      </Text>
    </MenuItem>
  );
};

export const EditChapterButton = ({
  isParentDeleted,
  isSomeRootChapVersionSubmittedForApproval,
  status,
  documentsMap,
  chapter,
  setIsHovering,
}: EditChapterButtonType) => {
  const setChapterAtomValue = useSetAtom(chapterEditAtom);
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);

  return (
    <MenuItem
      icon={<Edit20Filled className="text-red-900" />}
      onClick={() => {
        setShowNestedSidebarPanelView(false);
        setIsHovering(false);
        if (isParentDeleted) {
          generateToast({
            type: 'error',
            toastContent:
              'Cannot Edit chapter as one of its parent chapters was deleted.',
          });
        } else if (isSomeRootChapVersionSubmittedForApproval) {
          generateToast({
            type: 'error',
            toastContent:
              'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
          });
        } else {
          if (status === StatusType.PUBLISHED) {
            if (chapter?.id && documentsMap[chapter.id]) {
              const documentVersions = documentsMap[chapter.id] ?? [];

              const isEditable = !documentVersions.some(
                (item) => item.status === StatusType.PENDING,
              );

              if (isEditable) {
                setChapterAtomValue({
                  chapter: chapter as any,
                  isOpen: true,
                  action: 'UPDATE',
                  chapterType: QueryKeys.SUB_CHAPTER,
                });
              } else {
                generateToast({
                  type: 'error',
                  toastContent:
                    'Cannot edit as an edited version of this document already exists. Please check under "Edited Documents".',
                });
              }
            } else {
              generateToast({
                type: 'error',
                toastContent:
                  'Something went wrong. Please reload and try agin!!',
              });
            }
          }

          if (status === StatusType.PENDING) {
            setChapterAtomValue({
              chapter: chapter as any,
              isOpen: true,
              action: 'UPDATE',
              chapterType: QueryKeys.SUB_CHAPTER,
            });
          }

          if (status === StatusType.PENDING_APPROVAL) {
            generateToast({
              type: 'error',
              toastContent:
                'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
            });
          }
        }
      }}
    >
      <Text className="text-black-db-1" size={300}>
        Edit Chapter
      </Text>
    </MenuItem>
  );
};

export const DeleteOrDiscardChapterButton = ({
  isParentDeleted,
  isSomeRootChapVersionSubmittedForApproval,
  status,
  documentsMap,
  chapter,
  setIsHovering,
}: DeleteOrDiscardChapterButtonType) => {
  const setChapterDelete = useSetAtom(chapterDeleteAtom);
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);

  return (
    <MenuItem
      icon={<Delete20Filled className="text-red-900" />}
      onClick={() => {
        setShowNestedSidebarPanelView(false);
        setIsHovering(false);
        if (isParentDeleted) {
          generateToast({
            type: 'error',
            toastContent: `Cannot ${
              chapter?.status === StatusType.PUBLISHED
                ? 'Delete Chapter'
                : 'Discard Changes'
            } as one of its parent chapters was deleted.`,
          });
        } else if (isSomeRootChapVersionSubmittedForApproval) {
          generateToast({
            type: 'error',
            toastContent:
              'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
          });
        } else {
          if (status === StatusType.PUBLISHED) {
            if (chapter?.id && documentsMap[chapter.id]) {
              const documentVersions = documentsMap[chapter.id] ?? [];

              const isEditable = !documentVersions.some(
                (item) => item.status === StatusType.PENDING,
              );

              if (isEditable) {
                setChapterDelete({
                  isOpen: true,
                  chapter: chapter as any,
                });
              } else {
                generateToast({
                  type: 'error',
                  toastContent:
                    'Cannot edit as an edited version of this document already exists. Please check under "Edited Documents".',
                });
              }
            } else {
              generateToast({
                type: 'error',
                toastContent:
                  'Something went wrong. Please reload and try agin!!',
              });
            }
          }

          if (status === StatusType.PENDING) {
            setChapterDelete({
              isOpen: true,
              chapter: chapter as any,
            });
          }

          if (status === StatusType.PENDING_APPROVAL) {
            generateToast({
              type: 'error',
              toastContent:
                'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
            });
          }
        }
      }}
    >
      <Text className="text-black-db-1" size={300}>
        {chapter?.status === StatusType.PUBLISHED
          ? 'Delete Chapter'
          : 'Discard Changes'}
      </Text>
    </MenuItem>
  );
};

export const ExportChapterButton = ({
  rootChapter,
  chapter,
  setIsHovering,
}: ExportChapterButtonType) => {
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);

  const generatePdf = useCallback(async () => {
    if (
      rootChapter?.id &&
      rootChapter.version &&
      chapter?.id &&
      chapter.version
    ) {
      const pdfGenerationFunctionInput: PdfGenerationFunctionInput = {
        rootChapter: {
          id: rootChapter.id,
          version: rootChapter.version,
        },
        chapter: {
          id: chapter.id,
          version: chapter.version,
        },
        userEmail: currentUserDetails?.secondaryEmail,
        documentType: 'CHAPTER',
      };

      try {
        generateToast({
          type: 'info',
          toastContent: `Preparing to export the document "${chapter.name}". Please Wait! This may take upto 5-10 minutes.`,
        });

        generateToast({
          type: 'info',
          toastContent: `We will mail you with a link to the pdf once its ready.`,
        });

        const pdfGenerationFunctionResponse = await fetchPdf(
          pdfGenerationFunctionInput,
        );

        if (pdfGenerationFunctionResponse.status === 'ERROR') {
          throw new Error(
            pdfGenerationFunctionResponse.error ?? 'Something went wrong!!',
          );
        }
      } catch (error) {
        console.log('PDF Generation Error', error);

        generateToast({
          type: 'error',
          toastContent: `Something went wrong while exporting the document named ${chapter.name}. Please try agin!!`,
        });
      }
    }
  }, [
    currentUserDetails?.secondaryEmail,
    chapter?.id,
    chapter?.name,
    chapter?.version,
    rootChapter?.id,
    rootChapter?.version,
  ]);

  const handlePDFExportClick = useCallback(async () => {
    if (chapter) {
      const pdfFile = chapter.pdfFile;

      const pdfFileKey = pdfFile?.key;

      if (pdfFileKey) {
        generateToast({
          type: 'info',
          toastContent: `Preparing to export the document "${chapter.name}". Please Wait!`,
        });

        await handlePdfDownload({
          fileKey: pdfFileKey,
          fileName: chapter.name,
        });
      } else {
        await generatePdf();
      }
    }
  }, [generatePdf, chapter]);

  return (
    <MenuItem
      icon={<DocumentPdf20Filled className="text-blue-900" />}
      onClick={async () => {
        setShowNestedSidebarPanelView(false);
        setIsHovering(false);

        await handlePDFExportClick();
      }}
    >
      <Text className="text-black-db-1" size={300}>
        Export as PDF
      </Text>
    </MenuItem>
  );
};

export const ClearCacheAndExportChapterButton = ({
  rootChapter,
  chapter,
  setIsHovering,
}: ClearCacheAndExportChapterButtonType) => {
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);

  const generatePdf = useCallback(async () => {
    if (
      rootChapter?.id &&
      rootChapter.version &&
      chapter?.id &&
      chapter.version
    ) {
      const pdfGenerationFunctionInput: PdfGenerationFunctionInput = {
        rootChapter: {
          id: rootChapter.id,
          version: rootChapter.version,
        },
        chapter: {
          id: chapter.id,
          version: chapter.version,
        },
        userEmail: currentUserDetails?.secondaryEmail,
        documentType: 'CHAPTER',
      };

      try {
        generateToast({
          type: 'info',
          toastContent: `Preparing to export the document "${chapter.name}". Please Wait! This may take upto 5-10 minutes.`,
        });

        generateToast({
          type: 'info',
          toastContent: `We will mail you with a link to the pdf once its ready.`,
        });

        const pdfGenerationFunctionResponse = await fetchPdf(
          pdfGenerationFunctionInput,
        );

        if (pdfGenerationFunctionResponse.status === 'ERROR') {
          throw new Error(
            pdfGenerationFunctionResponse.error ?? 'Something went wrong!!',
          );
        }
      } catch (error) {
        console.log('PDF Generation Error', error);

        generateToast({
          type: 'error',
          toastContent: `Something went wrong while exporting the document named ${chapter.name}. Please try agin!!`,
        });
      }
    }
  }, [
    currentUserDetails?.secondaryEmail,
    chapter?.id,
    chapter?.name,
    chapter?.version,
    rootChapter?.id,
    rootChapter?.version,
  ]);

  return (
    <MenuItem
      icon={<DocumentPdf20Filled className="text-blue-900" />}
      onClick={async () => {
        setShowNestedSidebarPanelView(false);
        setIsHovering(false);
        await generatePdf();
      }}
    >
      <Text className="text-black-db-1" size={300}>
        Clear Cache and Export as PDF
      </Text>
    </MenuItem>
  );
};

export const AddContentButton = ({
  isParentDeleted,
  isSomeRootChapVersionSubmittedForApproval,
  documentId,
  chapter,
  setIsHovering,
}: AddContentButtonType) => {
  const setDocAtomValue = useSetAtom(docEditAtom);
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);

  return (
    <MenuItem
      icon={<AddCircle20Filled className="text-green-900" />}
      onClick={() => {
        setShowNestedSidebarPanelView(false);
        setIsHovering(false);
        if (isParentDeleted) {
          generateToast({
            type: 'error',
            toastContent:
              'Cannot Add Content as one of its parent chapters was deleted.',
          });
        } else if (isSomeRootChapVersionSubmittedForApproval) {
          generateToast({
            type: 'error',
            toastContent:
              'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
          });
        } else {
          setDocAtomValue({
            doc: {
              id: uuidv4(),
              title: '',
              version: '',
              status: StatusType.PENDING,
              description: '',
              chapterId: chapter?.id,
              rootChapterId: documentId,
              referenceDocId: null,
            },
            isOpen: true,
            action: 'ADD',
          });
        }
      }}
    >
      <Text className="text-black-db-1" size={300}>
        Add Content
      </Text>
    </MenuItem>
  );
};

export const EditContentButton = ({
  isParentDeleted,
  isSomeRootChapVersionSubmittedForApproval,
  status,
  documentsMap,
  doc,
  setIsHoveringDoc,
}: EditContentButtonType) => {
  const setDocAtomValue = useSetAtom(docEditAtom);
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);

  return (
    <MenuItem
      icon={<Edit20Filled className="text-red-900" />}
      onClick={() => {
        setShowNestedSidebarPanelView(false);
        setIsHoveringDoc(false);

        if (isParentDeleted) {
          generateToast({
            type: 'error',
            toastContent: `Cannot Edit Content as one of its parent chapters was deleted.`,
          });
        } else if (isSomeRootChapVersionSubmittedForApproval) {
          generateToast({
            type: 'error',
            toastContent:
              'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
          });
        } else {
          if (status === StatusType.PUBLISHED) {
            if (doc?.id && documentsMap[doc.id]) {
              const documentVersions = documentsMap[doc.id] ?? [];

              const isEditable = !documentVersions.some(
                (item) => item.status === StatusType.PENDING,
              );

              if (isEditable) {
                setDocAtomValue({
                  doc: doc as any,
                  isOpen: true,
                  action: 'UPDATE',
                });
              } else {
                generateToast({
                  type: 'error',
                  toastContent:
                    'Cannot edit as an edited version of this document already exists. Please check under "Edited Documents".',
                });
              }
            } else {
              generateToast({
                type: 'error',
                toastContent:
                  'Something went wrong. Please reload and try agin!!',
              });
            }
          }

          if (status === StatusType.PENDING) {
            setDocAtomValue({
              doc: doc as any,
              isOpen: true,
              action: 'UPDATE',
            });
          }

          if (status === StatusType.PENDING_APPROVAL) {
            generateToast({
              type: 'error',
              toastContent:
                'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
            });
          }
        }
      }}
    >
      <Text className="text-black-db-1" size={300}>
        Edit Content
      </Text>
    </MenuItem>
  );
};

export const DeleteOrDiscardContentButton = ({
  isParentDeleted,
  isSomeRootChapVersionSubmittedForApproval,
  status,
  documentsMap,
  doc,
  setIsHoveringDoc,
}: DeleteOrDiscardContentButtonType) => {
  const setDocDelete = useSetAtom(docDeleteAtom);
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);

  return (
    <MenuItem
      icon={<Delete20Filled className="text-red-900" />}
      onClick={() => {
        setShowNestedSidebarPanelView(false);
        setIsHoveringDoc(false);
        if (isParentDeleted) {
          generateToast({
            type: 'error',
            toastContent: `Cannot ${
              doc?.status === StatusType.PUBLISHED
                ? 'Delete Content'
                : 'Discard Changes'
            } as one of its parent chapters was deleted.`,
          });
        } else if (isSomeRootChapVersionSubmittedForApproval) {
          generateToast({
            type: 'error',
            toastContent:
              'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
          });
        } else {
          if (status === StatusType.PUBLISHED) {
            if (doc?.id && documentsMap[doc.id]) {
              const documentVersions = documentsMap[doc.id] ?? [];

              const isEditable = !documentVersions.some(
                (item) => item.status === StatusType.PENDING,
              );

              if (isEditable) {
                setDocDelete({
                  isOpen: true,
                  doc: doc as any,
                });
              } else {
                generateToast({
                  type: 'error',
                  toastContent:
                    'Cannot edit as an edited version of this document already exists. Please check under "Edited Documents".',
                });
              }
            } else {
              generateToast({
                type: 'error',
                toastContent:
                  'Something went wrong. Please reload and try agin!!',
              });
            }
          }

          if (status === StatusType.PENDING) {
            setDocDelete({
              isOpen: true,
              doc: doc as any,
            });
          }

          if (status === StatusType.PENDING_APPROVAL) {
            generateToast({
              type: 'error',
              toastContent:
                'An edited version of this document was submitted for approval. Cannot edit until it is reviewed by an approver. Please check under "Pending Approval".',
            });
          }
        }
      }}
    >
      <Text className="text-black-db-1" size={300}>
        {doc?.status === StatusType.PUBLISHED
          ? 'Delete Content'
          : 'Discard Changes'}
      </Text>
    </MenuItem>
  );
};

export const ExportDocButton = ({
  rootChapter,
  doc,
  setIsHoveringDoc,
}: ExportDocButtonType) => {
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);

  const generatePdf = useCallback(async () => {
    if (rootChapter?.id && rootChapter.version && doc?.id && doc.version) {
      const pdfGenerationFunctionInput: PdfGenerationFunctionInput = {
        rootChapter: {
          id: rootChapter.id,
          version: rootChapter.version,
        },
        doc: {
          id: doc.id,
          version: doc.version,
        },
        userEmail: currentUserDetails?.secondaryEmail,
        documentType: 'DOC',
      };

      try {
        generateToast({
          type: 'info',
          toastContent: `Preparing to export the document "${doc.title}". Please Wait! This may take upto 5-10 minutes.`,
        });

        generateToast({
          type: 'info',
          toastContent: `We will mail you with a link to the pdf once its ready.`,
        });

        const pdfGenerationFunctionResponse = await fetchPdf(
          pdfGenerationFunctionInput,
        );

        if (pdfGenerationFunctionResponse.status === 'ERROR') {
          throw new Error(
            pdfGenerationFunctionResponse.error ?? 'Something went wrong!!',
          );
        }
      } catch (error) {
        console.log('PDF Generation Error', error);

        generateToast({
          type: 'error',
          toastContent: `Something went wrong while exporting the document named ${doc.title}. Please try agin!!`,
        });
      }
    }
  }, [
    currentUserDetails?.secondaryEmail,
    doc?.id,
    doc?.title,
    doc?.version,
    rootChapter?.id,
    rootChapter?.version,
  ]);

  const handlePDFExportClick = useCallback(async () => {
    if (doc) {
      const pdfFile = doc.pdfFile;

      const pdfFileKey = pdfFile?.key;

      if (pdfFileKey) {
        generateToast({
          type: 'info',
          toastContent: `Preparing to export the document "${doc.title}". Please Wait!`,
        });

        await handlePdfDownload({
          fileKey: pdfFileKey,
          fileName: doc.title,
        });
      } else {
        await generatePdf();
      }
    }
  }, [generatePdf, doc]);

  return (
    <MenuItem
      icon={<DocumentPdf20Filled className="text-blue-900" />}
      onClick={async () => {
        setShowNestedSidebarPanelView(false);
        setIsHoveringDoc(false);
        await handlePDFExportClick();
      }}
    >
      <Text className="text-black-db-1" size={300}>
        Export as PDF
      </Text>
    </MenuItem>
  );
};

export const ClearCacheAndExportDocButton = ({
  rootChapter,
  doc,
  setIsHoveringDoc,
}: ClearCacheAndExportDocButtonType) => {
  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);

  const generatePdf = useCallback(async () => {
    if (rootChapter?.id && rootChapter.version && doc?.id && doc.version) {
      const pdfGenerationFunctionInput: PdfGenerationFunctionInput = {
        rootChapter: {
          id: rootChapter.id,
          version: rootChapter.version,
        },
        doc: {
          id: doc.id,
          version: doc.version,
        },
        userEmail: currentUserDetails?.secondaryEmail,
        documentType: 'DOC',
      };

      try {
        generateToast({
          type: 'info',
          toastContent: `Preparing to export the document "${doc.title}". Please Wait! This may take upto 5-10 minutes.`,
        });

        generateToast({
          type: 'info',
          toastContent: `We will mail you with a link to the pdf once its ready.`,
        });

        const pdfGenerationFunctionResponse = await fetchPdf(
          pdfGenerationFunctionInput,
        );

        if (pdfGenerationFunctionResponse.status === 'ERROR') {
          throw new Error(
            pdfGenerationFunctionResponse.error ?? 'Something went wrong!!',
          );
        }
      } catch (error) {
        console.log('PDF Generation Error', error);

        generateToast({
          type: 'error',
          toastContent: `Something went wrong while exporting the document named ${doc.title}. Please try agin!!`,
        });
      }
    }
  }, [
    currentUserDetails?.secondaryEmail,
    doc?.id,
    doc?.title,
    doc?.version,
    rootChapter?.id,
    rootChapter?.version,
  ]);

  return (
    <MenuItem
      icon={<DocumentPdf20Filled className="text-blue-900" />}
      onClick={async () => {
        setShowNestedSidebarPanelView(false);
        setIsHoveringDoc(false);

        await generatePdf();
      }}
    >
      <Text className="text-black-db-1" size={300}>
        Clear Cache and Export as PDF
      </Text>
    </MenuItem>
  );
};
