import { Chapter, StatusType } from 'API';

import { CustomDoc, CustomStatusType } from 'types/documents';
import { DocumentsMapType } from 'types/Pages/Products';

import getDiffClassName from 'helpers/utils/getDiffClassName';

const costructContentPageProps = ({
  mainDocumentsMap,
  contentId,
  isContentReader,
  rootChapter,
  content,
  status,
  allRootChapterVersions,
}: {
  mainDocumentsMap: DocumentsMapType;
  contentId: string;
  isContentReader: boolean;
  rootChapter: Partial<Chapter | null>;
  allRootChapterVersions: Partial<Chapter | null>[];
  status: CustomStatusType;
  content: CustomDoc;
}) => {
  const allDocumentVersions = mainDocumentsMap[contentId] || [];
  const currentDocumentVersion = (allDocumentVersions.find(
    (x) => x.version === content?.version,
  ) || null) as CustomDoc;

  let isAnyAncestorDeleted = false;

  if (!isContentReader) {
    const isRootChapterOrCurrentDocumentDeleted =
      rootChapter?.shouldBeDeleted === true ||
      currentDocumentVersion?.shouldBeDeleted === true;

    if (isRootChapterOrCurrentDocumentDeleted) {
      isAnyAncestorDeleted = true;
    } else {
      let ancestorId = currentDocumentVersion?.chapterId || null;

      while (ancestorId) {
        const parentDocumentVersions = mainDocumentsMap[ancestorId] || [];

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (parentDocumentVersions?.length > 0) {
          const pendingParentDocumentVersion: any = parentDocumentVersions.find(
            (x) => x.status === StatusType.PENDING,
          );

          if (!pendingParentDocumentVersion) {
            const publishedParentDocumentVersion: any =
              parentDocumentVersions.find(
                (x) => x.status === StatusType.PUBLISHED,
              );

            ancestorId =
              publishedParentDocumentVersion?.parentChapterId || null;
          } else if (pendingParentDocumentVersion.shouldBeDeleted === true) {
            ancestorId = null;
            isAnyAncestorDeleted = true;
          } else {
            ancestorId = pendingParentDocumentVersion?.parentChapterId || null;
          }
        } else {
          ancestorId = null;
        }
      }
    }
  }

  const diffClassNameForDoc = content
    ? getDiffClassName({
        isContentReader,
        defaultClassName:
          'font-microsoft-docs text-gray-microsoft-docs font-semibold  ',
        status,
        diffFor: 'DOC',
        isDeleted: isAnyAncestorDeleted,
        doc: content,
      })
    : 'text-gray-microsoft-docs text-3xl font-microsoft-docs font-semibold leading-10 -mt-2.5	';

  const isSomeRootChapVersionSubmittedForApproval = allRootChapterVersions.some(
    (x) => x?.status === StatusType.PENDING_APPROVAL,
  );

  return {
    isAnyAncestorDeleted,
    diffClassNameForDoc,
    isSomeRootChapVersionSubmittedForApproval,
  };
};

export default costructContentPageProps;
