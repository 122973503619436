import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateTaskInput, UpdateTaskInput } from 'API';

import { updateTaskRequest, createTaskRequest } from 'queries/tasks.mutation';

import type {
  DefaultTContext,
  DefaultTData,
  DefaultTError,
  IUseMutationProps,
} from 'types';

type IUseCreateTaskMutationProps = IUseMutationProps<
  DefaultTData,
  DefaultTError,
  CreateTaskInput,
  DefaultTContext
>;

type IUseUpdateTaskMutationProps = IUseMutationProps<
  DefaultTData,
  DefaultTError,
  UpdateTaskInput,
  DefaultTContext
>;

export const useCreateTaskMutation = (
  props: IUseCreateTaskMutationProps = {},
) => {
  const queryClient = useQueryClient();

  const onMutateCallback = props?.onMutateCallback ?? (() => {});
  const onSuccessCallback = props?.onSuccessCallback ?? (() => {});
  const onErrorCallback = props?.onErrorCallback ?? (() => {});
  const onSettledCallback = props?.onSettledCallback ?? (() => {});

  const createTaskMutation = useMutation({
    mutationFn: createTaskRequest,
    onMutate: (variables) => {
      onMutateCallback({ variables });
    },
    onSuccess: (data, variables, context) => {
      onSuccessCallback({ data, variables, context });
    },
    onError: (error, variables, context) => {
      onErrorCallback({ error, variables, context });
    },
    onSettled: (data, error, variables, context) => {
      void queryClient.invalidateQueries(['tasks']);
      void queryClient.invalidateQueries(['infinite-tasks']);
      void queryClient.invalidateQueries(['infinite-tickets']);

      void queryClient.invalidateQueries(['tickets']);
      void queryClient.invalidateQueries(['searchableTickets']);
      void queryClient.invalidateQueries([`rootChapter`]);

      onSettledCallback({ data, error, variables, context });
    },
  });

  return createTaskMutation;
};

export const useUpdateTaskMutation = (
  props: IUseUpdateTaskMutationProps = {},
) => {
  const queryClient = useQueryClient();

  const onMutateCallback = props?.onMutateCallback ?? (() => {});
  const onSuccessCallback = props?.onSuccessCallback ?? (() => {});
  const onErrorCallback = props?.onErrorCallback ?? (() => {});
  const onSettledCallback = props?.onSettledCallback ?? (() => {});

  const updateTaskMutation = useMutation({
    mutationFn: updateTaskRequest,
    onMutate: (variables) => {
      onMutateCallback({ variables });
    },
    onSuccess: (data, variables, context) => {
      onSuccessCallback({ data, variables, context });
    },
    onError: (error, variables, context) => {
      onErrorCallback({ error, variables, context });
    },
    onSettled: (data, error, variables, context) => {
      void queryClient.invalidateQueries(['tasks']);
      void queryClient.invalidateQueries(['infinite-tasks']);
      void queryClient.invalidateQueries(['infinite-tickets']);

      void queryClient.invalidateQueries(['tickets']);
      void queryClient.invalidateQueries(['searchableTickets']);
      void queryClient.invalidateQueries([`rootChapter`]);

      onSettledCallback({ data, error, variables, context });
    },
  });

  return updateTaskMutation;
};
