import { useMemo } from 'react';

import {
  CUSTOMER,
  DISTRIBUTOR,
  EMPLOYEE,
  ORGANIZATION_TYPES,
  PARTNER,
} from 'consts/roles';

import { QueryEnabledType } from 'types';

import { useInfiniteSFAccounts } from './useSFAccounts';
import { useInfiniteSFContracts } from './useSFContracts';

export const useConstructUserSalesForceOrganizations = ({
  organizationId,
  isQueryEnabled = true,
  customQueryKeys = [],
}: {
  organizationId: string | null | undefined;
} & QueryEnabledType) => {
  const { sfAccountsSource } = useInfiniteSFAccounts({
    queryKey: customQueryKeys,
    enabled: isQueryEnabled && !!organizationId,
  });

  const account = useMemo(() => {
    return (
      sfAccountsSource.find(
        (sfAccountSource) => sfAccountSource.organizationId === organizationId,
      ) ?? null
    );
  }, [sfAccountsSource, organizationId]);

  const accountId = account?.id ?? null;

  const result = useInfiniteSFContracts({
    queryKey: customQueryKeys,
    enabled: isQueryEnabled && !!accountId,
  });

  const { sfContractsSource } = result;

  const userSalesForceOrganizations = useMemo(
    () =>
      sfContractsSource
        .filter((item) => {
          if (organizationId && accountId) {
            if (ORGANIZATION_TYPES[organizationId[3]] === CUSTOMER) {
              return item.accountId === accountId;
            }

            if (ORGANIZATION_TYPES[organizationId[3]] === PARTNER) {
              return item.partnerAccountId === accountId;
            }

            if (ORGANIZATION_TYPES[organizationId[3]] === DISTRIBUTOR) {
              return item.distributorAccountId === accountId;
            }

            if (ORGANIZATION_TYPES[organizationId[3]] === EMPLOYEE) {
              return true;
            }

            return false;
          }

          return false;
        })
        .map((item) => ({
          Id: item.id,
          Distributor_Account__r: {
            Name: item.distributorAccount?.name,
            Organization_ID__c: item.distributorAccount?.organizationId,
          },
          Partner_Account__r: {
            Name: item.partnerAccount?.name,
            Organization_ID__c: item.partnerAccount?.organizationId,
          },
          Account: {
            Name: item.account?.name,
            Organization_ID__c: item.account?.organizationId,
          },
          Contract_Type__c: item.contractType,
        })),
    [accountId, sfContractsSource, organizationId],
  );

  return [userSalesForceOrganizations, result] as const;
};

export default useConstructUserSalesForceOrganizations;
