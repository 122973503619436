import type { UnknownRecord } from 'type-fest';

const sortDocuments = <T>({
  documentsMap,
  documents,
}: {
  documentsMap: Record<string, T[] | null | undefined>;
  documents: T[];
}): T[] => {
  const sortedFilteredDocuments = [...documents].sort((left, right) => {
    const leftId = (left as UnknownRecord).id;
    const rightId = (right as UnknownRecord).id;

    const leftCreatedAt = (left as UnknownRecord).createdAt;
    const rightCreatedAt = (right as UnknownRecord).createdAt;

    const leftAllDocumentVersions = leftId
      ? documentsMap[leftId as string] ?? []
      : [];

    const rightAllDocumentVersions = rightId
      ? documentsMap[rightId as string] ?? []
      : [];

    const leftBaseVersion =
      leftAllDocumentVersions.find(
        (x) => (x as UnknownRecord).version === '1',
      ) ?? null;

    const rightBaseVersion =
      rightAllDocumentVersions.find(
        (x) => (x as UnknownRecord).version === '1',
      ) ?? null;

    const leftBaseCreatedAt = leftBaseVersion
      ? (leftBaseVersion as UnknownRecord).createdAt
      : null;

    const rightBaseCreatedAt = rightBaseVersion
      ? (rightBaseVersion as UnknownRecord).createdAt
      : null;

    if (leftBaseCreatedAt && rightBaseCreatedAt) {
      return (
        +new Date(leftBaseCreatedAt as string) -
        +new Date(rightBaseCreatedAt as string)
      );
    }

    if (leftBaseCreatedAt) {
      return (
        +new Date(leftBaseCreatedAt as string) -
        (rightCreatedAt ? +new Date(rightCreatedAt as string) : 0)
      );
    }

    if (rightBaseCreatedAt) {
      return (
        (leftCreatedAt ? +new Date(leftCreatedAt as string) : 0) -
        +new Date(rightBaseCreatedAt as string)
      );
    }

    return (
      (leftCreatedAt ? +new Date(leftCreatedAt as string) : 0) -
      (rightCreatedAt ? +new Date(rightCreatedAt as string) : 0)
    );
  });

  return sortedFilteredDocuments;
};

export default sortDocuments;
