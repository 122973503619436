/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import {
  JUSTIFY_VARIANTS,
  DIRECTION_VARIANTS,
  ALIGN_ITEMS_VARIANTS,
  FLEX_WRAP_VARIANTS,
} from './variants';

type FlexProps = {
  direction?: 'row' | 'col' | 'col-rev';
  justify?: 'between' | 'around' | 'evenly' | 'center' | 'start' | 'end';
  position?: 'relative' | 'absolute' | 'fixed';
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' | null;
  flexWrap?: 'wrap' | 'reverse' | 'nowrap' | null;
  backgroundColor?: string;
  width?: string;
  margin?: string;
  padding?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  className?: string;
  rounded?: string;
  space?: string | null;
  border?: string;
  borderColor?: string;
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLDivElement> | undefined;
  style?: React.CSSProperties;
};

const Flex = ({
  direction = 'row',
  justify = 'start',
  position = 'relative',
  width = 'w-full',
  margin = 'm-0',
  padding = 'p-0',
  space,
  border = 'border-0',
  borderColor = 'border-gray-100',
  backgroundColor = '', // Do not ever set this to anything else. Leave it as an empty string.
  maxWidth,
  height = 'h-auto',
  minHeight,
  maxHeight,
  className,
  alignItems = null,
  rounded,
  flexWrap = null,
  children,
  onClick = () => {},
  onKeyPress = () => {},
  onBlur = () => {},
  style = {},
}: FlexProps) => {
  const baseClass = 'flex';
  const FLEX_DIRECTION = DIRECTION_VARIANTS[direction];
  const FLEX_JUSTIFY = JUSTIFY_VARIANTS[justify];
  const ALIGN_ITEMS = alignItems ? ALIGN_ITEMS_VARIANTS[alignItems] : null;
  const FLEX_WRAP = flexWrap ? FLEX_WRAP_VARIANTS[flexWrap] : null;

  const customClass = classNames(
    baseClass,
    FLEX_DIRECTION,
    FLEX_JUSTIFY,
    backgroundColor,
    position,
    `${width}`,
    `${height}`,
    `${margin}`,
    minHeight,
    maxHeight,
    padding,
    space,
    border,
    borderColor,
    rounded,
    ALIGN_ITEMS,
    FLEX_WRAP,
    maxWidth ? `${maxWidth}` : null,
    className,
  );

  return (
    <div
      role="main"
      tabIndex={0}
      className={customClass}
      onClick={(e) => onClick(e)}
      onKeyPress={(e) => onKeyPress(e)}
      onBlur={(e) => onBlur(e)}
      // eslint-disable-next-line react/forbid-dom-props
      style={style}
    >
      {children}
    </div>
  );
};

export default React.memo(Flex);
