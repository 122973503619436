import { Amplify, Auth } from 'aws-amplify';

import useInterval from 'hooks/useInterval';

import Routes from 'routes';
import AppSetting from 'settings';

import '@fontsource/inter';
import '@fontsource/open-sans';

const redirecturl = (event: unknown) => {
  window.location.replace(event as string | URL);
};

const awsconfig = {
  aws_project_region: import.meta.env.VITE_APP_aws_project_region,
  aws_appsync_graphqlEndpoint: import.meta.env
    .VITE_APP_aws_appsync_graphqlEndpoint,
  aws_appsync_region: import.meta.env.VITE_APP_aws_appsync_region,
  aws_appsync_authenticationType: import.meta.env
    .VITE_APP_aws_appsync_authenticationType,
  aws_appsync_apiKey: import.meta.env.VITE_APP_aws_appsync_apiKey,
  aws_cognito_identity_pool_id: import.meta.env
    .VITE_APP_aws_cognito_identity_pool_id,
  aws_cognito_region: import.meta.env.VITE_APP_aws_cognito_region,
  aws_user_pools_id: import.meta.env.VITE_APP_aws_user_pools_id,
  aws_user_pools_web_client_id: import.meta.env
    .VITE_APP_aws_user_pools_web_client_id,
  oauth: {
    // Domain name //********.auth.us-east-1.amazoncognito.com
    domain: import.meta.env.VITE_APP_aws_domain,
    // Authorized scopes
    scope: [
      // 'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],

    redirectSignIn: `${window.location.origin}/`,

    redirectSignOut: `${window.location.origin}/`,

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    // Note that REFRESH token will only be generated when the responseType is code
    responseType: 'code',
    clientId: import.meta.env.VITE_APP_aws_user_pools_web_client_id,
    // optional, for Cognito hosted ui specified options
    options: {
      // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag: true,
    },

    urlOpener: redirecturl,
  },
  aws_user_files_s3_bucket: import.meta.env.VITE_APP_aws_user_files_s3_bucket,
  aws_user_files_s3_bucket_region: import.meta.env
    .VITE_APP_aws_user_files_s3_bucket_region,
};

Amplify.configure(awsconfig);

const App = () => {
  // Refresh session every 10 minutes or so to prevent session timeout.
  useInterval(async () => {
    try {
      await Auth.currentSession();
    } catch (error) {
      console.error('APP - currentSession error', error);
    }
  }, 600_000);
  // }, 6000);

  return (
    <AppSetting>
      <Routes />
    </AppSetting>
  );
};

export default App;
