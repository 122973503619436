import { Auth } from 'aws-amplify';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { tenantCustomSettingsAtom } from 'atoms/modals';

import falainaTenantId from 'consts/tenant';

import getTenantSettings from 'helpers/utils/getTenantSettings';

import type { ChildrenProps } from 'types';

const ProviderSettings = ({ children }: ChildrenProps) => {
  const setTenantCustomSettings = useSetAtom(tenantCustomSettingsAtom);

  useEffect(() => {
    console.log('VERSION - 1.0.0');

    const data = getTenantSettings(falainaTenantId);
    setTenantCustomSettings(data);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user.attributes['custom:isVerified'] !== 'true') {
          window.location.replace('#/sign_up');
        }
      })
      .catch((error) => {
        console.error('AuthSetting - error', error);

        const currentUrl = window.location.href;
        const prevPage = localStorage.getItem('prev_visited_page_before_login');

        if (
          (!prevPage ||
            prevPage === 'http://localhost:3000/' ||
            prevPage === 'https://falaina.netlify.app/' ||
            prevPage === 'https://falainacc.com/') &&
          !currentUrl.includes('/#/sign_in') &&
          !currentUrl.includes('/#/sign_up')
        ) {
          localStorage.setItem('prev_visited_page_before_login', currentUrl);
        }

        if (!currentUrl.includes('/#/error_description')) {
          window.location.replace('#/sign_in');
        }
      });
  }, []);

  return <div>{children}</div>;
};

export default ProviderSettings;
