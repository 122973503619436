import { useMutation } from '@tanstack/react-query';
import gql from 'graphql-tag';

import {
  CreateDocInput,
  CustomCreateDocMutation,
  CustomDeleteDocMutation,
  CustomUpdateDocMutation,
  UpdateDocInput,
} from 'API';

import {
  customCreateDoc,
  customUpdateDoc,
  customDeleteDoc,
} from 'graphql/custom-mutations';

import client from 'helpers/utils/client';

export const createDocRequest = (payload: CreateDocInput) => {
  const input: CreateDocInput = {
    version: payload.version,
    status: payload.status,
    createdByUserId: payload.createdByUserId,
    lastModifiedByUserId: payload.lastModifiedByUserId,
    title: payload.title,
    description: payload.description,
    chapterId: payload.chapterId,
    rootChapterId: payload.rootChapterId,
    orderFloat: payload.orderFloat,
    referenceDocId: payload.referenceDocId,
    tenantId: payload.tenantId,
  };

  if (payload.id) {
    input.id = payload.id;
  }

  if (payload.shouldBeDeleted) {
    input.shouldBeDeleted = payload.shouldBeDeleted;
  }

  return client.mutate<CustomCreateDocMutation>({
    mutation: gql`
      ${customCreateDoc}
    `,
    variables: {
      input,
    },
  });
};

export const updateDocRequest = (payload: UpdateDocInput) => {
  const input: UpdateDocInput = {
    id: payload.id,
    version: payload.version,
    status: payload.status,
    lastModifiedByUserId: payload.lastModifiedByUserId,
    title: payload.title,
    description: payload.description,
    chapterId: payload.chapterId,
    orderFloat: payload.orderFloat,
    referenceDocId: payload.referenceDocId,
  };

  return client.mutate<CustomUpdateDocMutation>({
    mutation: gql`
      ${customUpdateDoc}
    `,
    variables: {
      input,
    },
  });
};

export const deleteDocRequest = ({
  id,
  version,
}: {
  id: string;
  version: string;
}) =>
  client.mutate<CustomDeleteDocMutation>({
    mutation: gql`
      ${customDeleteDoc}
    `,
    variables: {
      input: { id, version },
    },
  });

export const useCreateDoc = () => useMutation({ mutationFn: createDocRequest });
export const useUpdateDoc = () => useMutation({ mutationFn: updateDocRequest });
export const useDeleteDoc = () => useMutation({ mutationFn: deleteDocRequest });
