export const SAVED_SUCCESSFULLY = 'Saved Successfully';
export const DELETED_SUCCESSFULLY = 'Deleted Successfully';
export const UPDATED_SUCCESSFULLY = 'Updated Successfully';
export const FAILED_TO_SAVE = 'Failed to save, Please try again';
export const FAILED_TO_UPDATE = 'Failed to update, Please try again';
export const FAILED_TO_DELETE = 'Failed to delete, Please try again';
export const INVALID_USER_NAME = 'Invalid username or password';
export const INCORRECT_OTP = 'Incorrect otp. Please check again';
export const FAILED_TO_SAVE_GROUP = 'Failed to save group, Please try again';
export const FAILED_TO_UPDATE_GROUP =
  'Failed to update group, Please try again';
export const FAILED_TO_DELETE_GROUP =
  'Failed to delete group, Please try again';
export const FAILED_TO_SAVE_APPLICATION_ROLE =
  'Failed to save application role, Please try again';
export const FAILED_TO_UPDATE_APPLICATION_ROLE =
  'Failed to update application role, Please try again';
export const FAILED_TO_DELETE_APPLICATION_ROLE =
  'Failed to delete application role, Please try again';
export const FAILED_TO_VERIFY_COMPANY =
  'Failed to verify company, Please try again';
export const AUTH_SESSION_TIMED_OUT =
  'Session timed out. Redirecting you to the sign in page.';

export const FAILED = 'Failed to save. Please try again';

export const FAILED_TO_SAVE_SF = 'Failed to save, Please try again!';
export const FAILED_TO_UPDATE_SF = 'Failed to update, Please try again!';
export const FAILED_TO_DELETE_SF = 'Failed to delete, Please try again!';

export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again!';

export const FAILED_TO_CREATE_COMMENT =
  'Failed to create comment, Please try again';
export const FAILED_TO_UPDATE_COMMENT =
  'Failed to update comment, Please try again';
export const FAILED_TO_DELETE_COMMENT =
  'Failed to delete comment, Please try again';

export const FAILED_TO_CREATE_FILE_LIST =
  'Failed to create FILE_LIST, Please try again';
export const FAILED_TO_UPDATE_FILE_LIST =
  'Failed to update FILE_LIST, Please try again';
export const FAILED_TO_DELETE_FILE_LIST =
  'Failed to delete FILE_LIST, Please try again';
