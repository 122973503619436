import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToHashElement = ({
  scrollIntoViewOptions = {
    behavior: 'auto',
    block: 'start',
  },
}: {
  scrollIntoViewOptions?: ScrollIntoViewOptions;
}) => {
  const location = useLocation();
  const lastHash = useRef('');

  const defaultScrollIntoViewOptions: ScrollIntoViewOptions = {
    behavior: 'auto',
    block: 'start',
    ...scrollIntoViewOptions,
  };

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView(defaultScrollIntoViewOptions);
        lastHash.current = '';
      }, 100);
    }
  }, [location]);
};

export default useScrollToHashElement;
