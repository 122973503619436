import Text from 'components/Text';

import useS3FileUrl from 'hooks/useS3FileUrl';

export const NavBarProfilePictureCard = ({
  userDetails,
  isShowProfileCard,
  setIsShowProfileCard,
}: any) => {
  const fileurl = useS3FileUrl(userDetails || {});

  return fileurl ? (
    <img
      src={fileurl}
      role="presentation"
      alt="fetchedPic"
      className="w-full h-full object-cover rounded-full bg-white m-auto"
      onClick={() => setIsShowProfileCard(!isShowProfileCard)}
    />
  ) : (
    <Text
      onClick={() => setIsShowProfileCard(!isShowProfileCard)}
      variant="medium"
      className="w-full h-full flex justify-center text-4xl items-center"
    >
      {`${userDetails.firstName[0].toUpperCase()}${userDetails.lastName[0].toUpperCase()}`}
    </Text>
  );
};

const ProfileCard = ({
  userDetails,
  isShowProfileCard,
  setIsShowProfileCard,
}: any) => {
  const fileurl = useS3FileUrl(userDetails || {});

  return fileurl ? (
    <img
      src={fileurl}
      role="presentation"
      alt="fetchedPic"
      className="h-23.5 object-cover rounded-full bg-white m-auto w-23.5"
      onClick={() => setIsShowProfileCard(!isShowProfileCard)}
    />
  ) : (
    <Text
      onClick={() => setIsShowProfileCard(!isShowProfileCard)}
      variant="medium"
      className="h-22 w-22 text-white text-center text-4xl flex items-center justify-center"
      weight={600}
    >
      {`${userDetails.firstName[0].toUpperCase()}${userDetails.lastName[0].toUpperCase()}`}
    </Text>
  );
};

export default ProfileCard;
