import { DefaultButton } from '@fluentui/react';
import { useSetAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';

import Flex from 'components/Flex';

import { DocItemType, StatusType } from 'API';

import { docItemEditAtom } from 'atoms/modals';

const ContentButton = ({
  documentId,
  contentId,
}: {
  documentId: string;
  contentId: string;
}) => {
  const setDocItemAtomValueAtomValue = useSetAtom(docItemEditAtom);

  return (
    <DefaultButton
      iconProps={{ iconName: 'SkypeMessage' }}
      className="w-52 my-1 bg-white text-primary shadow-md hover:shadow-lg"
      text="Content"
      onClick={() => {
        setDocItemAtomValueAtomValue({
          action: 'ADD',
          docItem: {
            id: uuidv4(),
            title: '',
            version: '',
            orderFloat: '',
            status: StatusType.PENDING,
            description: '',
            docId: contentId,
            rootChapterId: documentId,
            itemType: DocItemType.content,
          },
          isOpen: true,
        });
      }}
    />
  );
};

const AlertButton = ({
  documentId,
  contentId,
}: {
  documentId: string;
  contentId: string;
}) => {
  const setDocItemAtomValueAtomValue = useSetAtom(docItemEditAtom);

  return (
    <DefaultButton
      iconProps={{ iconName: 'AlertSolid' }}
      className="w-52 my-1 bg-alert text-red-db-2 shadow-md hover:shadow-lg"
      text="Alert"
      onClick={() => {
        setDocItemAtomValueAtomValue({
          action: 'ADD',
          docItem: {
            id: uuidv4(),
            title: '',
            version: '',
            orderFloat: '',
            status: StatusType.PENDING,
            description: '',
            docId: contentId,
            rootChapterId: documentId,
            itemType: DocItemType.alert,
          },
          isOpen: true,
        });
      }}
    />
  );
};

const CodeButton = ({
  documentId,
  contentId,
}: {
  documentId: string;
  contentId: string;
}) => {
  const setDocItemAtomValue = useSetAtom(docItemEditAtom);

  return (
    <DefaultButton
      iconProps={{ iconName: 'Code' }}
      className="w-52 my-1 bg-white text-gray-fl-17 shadow-md hover:shadow-lg"
      text="Code"
      onClick={() => {
        setDocItemAtomValue({
          action: 'ADD',
          docItem: {
            id: uuidv4(),
            title: '',
            version: '',
            orderFloat: '',
            status: StatusType.PENDING,
            description: '',
            docId: contentId,
            rootChapterId: documentId,
            itemType: DocItemType.code,
          },
          isOpen: true,
        });
      }}
    />
  );
};

const TipButton = ({
  documentId,
  contentId,
}: {
  documentId: string;
  contentId: string;
}) => {
  const setDocItemAtomValue = useSetAtom(docItemEditAtom);

  return (
    <DefaultButton
      iconProps={{ iconName: 'DiagnosticDataBarTooltip' }}
      text="Tip"
      className="w-52 my-1 bg-info text-blue-700 shadow-md hover:shadow-lg"
      onClick={() => {
        setDocItemAtomValue({
          action: 'ADD',
          docItem: {
            id: uuidv4(),
            title: '',
            version: '',
            orderFloat: '',
            status: StatusType.PENDING,
            description: '',
            docId: contentId,
            rootChapterId: documentId,
            itemType: DocItemType.tip,
          },
          isOpen: true,
        });
      }}
    />
  );
};

const ImageButton = ({
  documentId,
  contentId,
}: {
  documentId: string;
  contentId: string;
}) => {
  const setDocItemAtomValue = useSetAtom(docItemEditAtom);

  return (
    <DefaultButton
      iconProps={{ iconName: 'ImageSearch' }}
      className="w-52 my-1 bg-blue-100 text-blue-500 shadow-md hover:shadow-lg"
      text="Image"
      onClick={() => {
        setDocItemAtomValue({
          action: 'ADD',
          docItem: {
            id: uuidv4(),
            title: '',
            version: '',
            orderFloat: '',
            status: StatusType.PENDING,
            description: '',
            docId: contentId,
            rootChapterId: documentId,
            itemType: DocItemType.image,
          },
          isOpen: true,
        });
      }}
    />
  );
};

const VideoButton = ({
  documentId,
  contentId,
}: {
  documentId: string;
  contentId: string;
}) => {
  const setDocItemAtomValue = useSetAtom(docItemEditAtom);

  return (
    <DefaultButton
      iconProps={{ iconName: 'Video' }}
      className="w-52 my-1 bg-indigo-100 text-indigo-500 shadow-md hover:shadow-lg"
      text="Video"
      onClick={() => {
        setDocItemAtomValue({
          action: 'ADD',
          docItem: {
            id: uuidv4(),
            title: '',
            version: '',
            orderFloat: '',
            status: StatusType.PENDING,
            description: '',
            docId: contentId,
            rootChapterId: documentId,
            itemType: DocItemType.video,
          },
          isOpen: true,
        });
      }}
    />
  );
};

const PreRequsiteButton = ({
  documentId,
  contentId,
}: {
  documentId: string;
  contentId: string;
}) => {
  const setDocItemAtomValue = useSetAtom(docItemEditAtom);

  return (
    <DefaultButton
      iconProps={{ iconName: 'Assign' }}
      className="w-52 my-1 bg-orange-fl-1 text-orange-fl-2 shadow-md hover:shadow-lg"
      text="Pre Requisite"
      onClick={() => {
        setDocItemAtomValue({
          action: 'ADD',
          docItem: {
            id: uuidv4(),
            title: '',
            version: '',
            orderFloat: '',
            status: StatusType.PENDING,
            description: '',
            docId: contentId,
            rootChapterId: documentId,
            itemType: DocItemType.preRequisite,
          },
          isOpen: true,
        });
      }}
    />
  );
};

const NoteButton = ({
  documentId,
  contentId,
}: {
  documentId: string;
  contentId: string;
}) => {
  const setDocItemAtomValue = useSetAtom(docItemEditAtom);

  return (
    <DefaultButton
      iconProps={{ iconName: 'EditNote' }}
      className="w-52 my-1 bg-green-fl-2 text-green-fl-3 shadow-md hover:shadow-lg"
      text="Note"
      onClick={() => {
        setDocItemAtomValue({
          action: 'ADD',
          docItem: {
            id: uuidv4(),
            title: '',
            version: '',
            orderFloat: '',
            status: StatusType.PENDING,
            description: '',
            docId: contentId,
            rootChapterId: documentId,
            itemType: DocItemType.note,
          },
          isOpen: true,
        });
      }}
    />
  );
};

const ContentActionButtons = ({
  documentId,
  contentId,
  handleAddReferenceDoc,
}: {
  documentId: string;
  contentId: string;
  handleAddReferenceDoc: () => void;
}) => {
  return (
    <>
      <ContentButton documentId={documentId} contentId={contentId} />

      <CodeButton documentId={documentId} contentId={contentId} />

      <TipButton documentId={documentId} contentId={contentId} />

      <AlertButton documentId={documentId} contentId={contentId} />

      <ImageButton documentId={documentId} contentId={contentId} />

      <VideoButton documentId={documentId} contentId={contentId} />

      <PreRequsiteButton documentId={documentId} contentId={contentId} />

      <NoteButton documentId={documentId} contentId={contentId} />

      <DefaultButton
        iconProps={{ iconName: 'SkypeMessage' }}
        className="w-52 my-1 bg-orange-fl-4 text-orange-fl-5 shadow-md hover:shadow-lg"
        text="Ref Content"
        onClick={handleAddReferenceDoc}
      />
    </>
  );
};

const ContentActionsWrapper = ({
  documentId,
  contentId,
  handleAddReferenceDoc,
}: {
  documentId: string;
  contentId: string;
  handleAddReferenceDoc: () => void;
}) => {
  return (
    <Flex
      direction="col"
      position="fixed"
      width="w-72"
      border="border-1"
      backgroundColor="bg-gray-50"
      className="right-2 md:right-8 top-44 md:top-32 shadow-inner transition ease-in-out delay-100 duration-75 z-20"
      minHeight="min-h-72"
      padding="p-1 py-4"
      justify="between"
      alignItems="center"
      rounded="rounded-lg"
    >
      <ContentActionButtons
        documentId={documentId}
        contentId={contentId}
        handleAddReferenceDoc={handleAddReferenceDoc}
      />
    </Flex>
  );
};

export default ContentActionsWrapper;
