/* eslint-disable react/no-array-index-key */
import { Link } from 'react-router-dom';

import Flex from 'components/Flex';
import Icon from 'components/Icon';
import Text from 'components/Text';

const Footer = () => {
  const leftItems: any[] = [];
  const rightItems = [
    {
      title: 'Privacy Policy',
    },
    {
      title: 'Falaina 2021',
    },
  ];

  return (
    <Flex
      width="w-full"
      backgroundColor="bg-gray-fl-20"
      className="flex-col justify-center items-center md:flex-row md:justify-between"
      padding="py-8 px-6 lg:py-12 lg:px-16 lg:z-30"
    >
      <Flex
        width="w-2/12"
        className="flex-col lg:flex-row"
        margin="mb-4 lg:mb-0"
      >
        {leftItems.map(({ title, icon }: any, index) => (
          <Flex
            key={index}
            alignItems="center"
            className="group mb-2 lg:mb-0 cursor-pointer"
          >
            <Icon
              name={icon}
              className="w-4 h-4 mr-2 text-gray-600 group-hover:text-gray-500"
            />
            <Text
              variant="small"
              color="text-gray-fl-19 group-hover:text-gray-fl-17 group-hover:underline"
              margin="mr-6"
            >
              {title}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Flex
        width="w-full md:w-8/12 lg:w-8/12"
        className="flex-col justify-center items-center lg:flex-row lg:justify-end"
      >
        {rightItems.map((item, index) =>
          index === rightItems.length - 1 ? (
            <Link key={index} to="/">
              <Text
                variant="small"
                color="text-gray-fl-19 hover:text-gray-fl-17  hover:underline"
                margin="mb-2 mr-0 lg:mb-0 lg:mr-6"
                className="cursor-pointer"
              >
                &#169; {item.title}
              </Text>
            </Link>
          ) : item.title === 'Privacy Policy' ? (
            <Link key={index} to={`/${item.title.replace(' ', '')}`}>
              <Text
                variant="small"
                color="text-gray-fl-19 hover:text-gray-fl-17  hover:underline "
                margin="mb-2 mr-0 lg:mb-0 lg:mr-6"
                className="cursor-pointer"
                onClick={() => {
                  if (item.title === 'Privacy Policy') {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                  }
                }}
              >
                {item.title}
              </Text>
            </Link>
          ) : (
            <Text
              key={index}
              variant="small"
              color="text-gray-fl-19 hover:text-gray-fl-17  hover:underline "
              margin="mb-2 mr-0 lg:mb-0 lg:mr-6"
              className="cursor-pointer"
            >
              {item.title}
            </Text>
          ),
        )}
      </Flex>
    </Flex>
  );
};

export default Footer;
