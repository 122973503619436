import Flex from 'components/Flex';
import {
  AtAGlance,
  ChartFour,
  ChartOne,
  ChartThree,
  ChartTwo,
  DetailsListOne,
  DetailsListTwo,
  Welcome,
} from 'components/Pages/LeadManagementDashboard';

import { useLeadManagementDashboard } from 'hooks/useLeadManagementDashboard';

import './dashboard.css';

const LeadManagementDashboard = () => {
  const {
    pieChartDataOne,

    currentUsersAccessibleLeads,

    selectedSolution,

    setSelectedSolution,

    solutionList,
  } = useLeadManagementDashboard();

  return (
    <Flex direction="col" margin="mx-auto lg:ml-12 lg:pl-12" width="w-11/12">
      <Flex direction="col" width="w-full" className="overflow-x-auto">
        <Welcome />

        <div className=" border-black-db-2 border-b w-full" />

        <AtAGlance leads={currentUsersAccessibleLeads} />

        <Flex
          direction="row"
          margin="mt-2"
          className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4  gap-4"
        >
          <ChartOne leads={currentUsersAccessibleLeads} />
          <ChartTwo leads={currentUsersAccessibleLeads} />
          <ChartThree
            selectedSolution={selectedSolution}
            setSelectedSolution={setSelectedSolution}
            solutionList={solutionList}
            pieChartDataOne={pieChartDataOne}
          />
          <ChartFour leads={currentUsersAccessibleLeads} />
        </Flex>

        <div className="flex flex-col md:flex-row mt-4 mb-8 justify-start md:justify-between">
          <DetailsListOne leads={currentUsersAccessibleLeads} />
          <DetailsListTwo leads={currentUsersAccessibleLeads} />
        </div>
      </Flex>
    </Flex>
  );
};

export default LeadManagementDashboard;
