import { useMemo } from 'react';

import { Lead, LeadOverallStatusType } from 'API';

export const useGetLeadsBasedOnStatus = ({
  leads,
  status,
}: {
  leads: Partial<Lead>[];
  status: LeadOverallStatusType;
}) => {
  const filteredLeads = useMemo(
    () => leads.filter((lead) => lead.overallStatus === status),
    [leads, status],
  );

  return { filteredLeads };
};

export default useGetLeadsBasedOnStatus;
