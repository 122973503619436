import { Text as MsText, ITextProps } from '@fluentui/react';
import classNames from 'classnames';
import React from 'react';

import { POSITION_VARIANTS, FONT_WEIGHT_VARIANTS } from './variants';

type Props = {
  className?: string;
  position?: 'left' | 'center' | 'right';
  variant?:
    | 'tiny'
    | 'xSmall'
    | 'small'
    | 'smallPlus'
    | 'medium'
    | 'mediumPlus'
    | 'large'
    | 'xLarge'
    | 'xxLarge'
    | 'mega';
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

  children?: React.ReactNode;
  onClick?: () => void;
  color?: string | null;
  textCase?: 'uppercase' | 'lowercase' | 'capitalize' | null;
  padding?: string | null;
  margin?: string | null;
  style?: React.CSSProperties;
} & ITextProps;

const Text = ({
  className = '',
  children,
  variant = 'medium',
  onClick,
  position = 'left',
  color = '',
  as = 'span',
  weight = 400,
  textCase = null,
  padding = 'p-0',
  margin = '',
  style = {},
}: Omit<Props, 'as'> & { as?: any }) => {
  const TEXT_POSITION = POSITION_VARIANTS[position];
  const FONT_WEIGHT = FONT_WEIGHT_VARIANTS[weight];
  return (
    <MsText
      variant={variant}
      className={classNames(
        className,
        textCase,
        TEXT_POSITION,
        FONT_WEIGHT,
        color,
        padding,
        margin,
      )}
      onClick={onClick}
      style={style}
      as={as}
    >
      {children}
    </MsText>
  );
};

export default Text;
