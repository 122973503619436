/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import upperFirst from 'lodash/upperFirst';
import { useNavigate } from 'react-router-dom';

import Flex from 'components/Flex';
import Text from 'components/Text';

import { Lead } from 'API';

import { useGetStatistics } from 'hooks/useLeadManagementDashboard';

const statisticsColors = {
  lead: 'border-[#DA3E52]',
  qualified: 'border-[#084E7E]',
  approved: 'border-[#0E613F]',
};

export const AtAGlance = ({ leads }: { leads: Partial<Lead>[] }) => {
  const navigate = useNavigate();

  const { statistics } = useGetStatistics({
    leads,
  });

  return (
    <Flex direction="col" className=" border-black border-b w-full pb-4 ">
      <Text className="my-4 text-base" weight={900}>
        Leads
      </Text>
      <Flex className="w-full overflow-auto">
        {Object.keys(statistics).map((key) => (
          <div
            key={key}
            className={`${
              statisticsColors[key as keyof typeof statisticsColors]
            } border-l-4  flex flex-col justify-start items-start min-w-25`}
          >
            <Text className="px-4 pb-2" weight={400}>
              {upperFirst(key)}
            </Text>
            <div
              className="w-full flex pb-2 pl-4 justify-start items-center cursor-pointer font-bold"
              onClick={() => {
                if (key === 'lead') {
                  navigate(`/leads?status=null`);
                } else {
                  navigate(`/leads?status=${key}`);
                }
              }}
            >
              {statistics[key as keyof typeof statistics]}
            </div>
          </div>
        ))}
      </Flex>
    </Flex>
  );
};

export default AtAGlance;
