import type { GraphQLQuery } from '@aws-amplify/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { CustomListCurrenciesQuery, ModelCurrencyConditionInput } from 'API';

import { customListCurrencies } from 'graphql/custom-queries';

export const useInfiniteCurrenciesList = ({
  filter,
  enabled = true,
}: {
  filter?: ModelCurrencyConditionInput;
  enabled?: boolean;
}) => {
  return useInfiniteQuery({
    queryKey: ['infiniteCurrencies', filter],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<GraphQLQuery<CustomListCurrenciesQuery>>({
        query: customListCurrencies,
        variables: filter
          ? { filter, limit: 1_000_000, nextToken: pageParam }
          : {
              limit: 1_000_000,
              nextToken: pageParam,
            },
      });
    },
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.listCurrencies?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
    enabled,
  });
};

export default useInfiniteCurrenciesList;
