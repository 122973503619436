import { IDetailsColumnStyles, IColumn, TooltipHost } from '@fluentui/react';

import Text from 'components/Text';

import { Lead } from 'API';

const headerStyle: Partial<IDetailsColumnStyles> = {
  cellName: {
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    fontWeight: '400',
    color: '#7f737a',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '12px',
    WebkitFontSmoothing: 'antialiased',
  },
};

const getColumns = (): IColumn[] => {
  return [
    {
      key: 'column1',
      fieldName: 'Customer',
      name: 'Customer',
      isResizable: true,
      minWidth: 150,
      maxWidth: 150,
      styles: headerStyle,
      onRender: (item: any) => {
        return (
          <div className="flex justify-between pr-2">
            <TooltipHost className="truncate" content={item.companyName}>
              <Text
                weight={600}
                className="text-gray-f1-25 text-sm overflow-ellipsis	cursor-pointer antialiased font-microsoft"
              >
                {item.companyName}
              </Text>
            </TooltipHost>
          </div>
        );
      },
    },
    {
      key: 'column2',
      fieldName: 'leadName',
      name: 'Lead Name',
      isResizable: true,
      minWidth: 150,
      maxWidth: 150,
      styles: headerStyle,
      onRender: (item: Partial<Lead>) => {
        return (
          <div className="flex justify-between">
            <TooltipHost className="truncate" content={item.leadName || ''}>
              <Text className="text-gray-f1-25 text-xs overflow-ellipsis	cursor-pointer antialiased font-microsoft">
                {item.leadName}
              </Text>
            </TooltipHost>
          </div>
        );
      },
    },
    {
      key: 'column3',
      name: 'Lead Description',
      isResizable: true,
      minWidth: 150,
      maxWidth: 150,
      styles: headerStyle,
      onRender: (item: any) => {
        return (
          <div className="flex justify-between">
            <TooltipHost className="truncate" content={item.description}>
              <Text className="text-gray-f1-25 text-xs overflow-ellipsis	cursor-pointer antialiased font-microsoft">
                {item.description}
              </Text>
            </TooltipHost>
          </div>
        );
      },
    },

    {
      key: 'column4',
      fieldName: 'leadBy',
      name: 'Lead By',
      isResizable: true,
      minWidth: 150,
      maxWidth: 150,
      styles: headerStyle,
      onRender: (item: any) => {
        return (
          <div className="flex justify-between">
            <TooltipHost
              className="truncate"
              content={`${item.createdByUser.firstName} ${item.createdByUser.lastName}`}
            >
              <Text className="text-gray-f1-25 text-xs overflow-ellipsis	cursor-pointer antialiased font-microsoft">
                {`${item.createdByUser.firstName} ${item.createdByUser.lastName}`}
              </Text>
            </TooltipHost>
          </div>
        );
      },
    },

    {
      key: 'column5',
      fieldName: 'organization',
      name: 'Organization',
      isResizable: true,
      minWidth: 150,
      maxWidth: 150,
      styles: headerStyle,
      onRender: (item: any) => {
        return (
          <div className="flex justify-between">
            <TooltipHost
              className="truncate"
              content={
                item.createdByUser.organization?.name ??
                item.tenant?.organization?.name ??
                ''
              }
            >
              <Text
                weight={400}
                className="text-gray-f1-25 text-xs overflow-ellipsis	cursor-pointer antialiased font-microsoft"
              >
                {item.createdByUser.organization?.name ??
                  item.tenant?.organization?.name ??
                  ''}
              </Text>
            </TooltipHost>
          </div>
        );
      },
    },

    {
      key: 'column6',
      fieldName: 'submissionDate',
      name: 'Submission Date',
      isResizable: true,
      minWidth: 150,
      maxWidth: 150,
      styles: headerStyle,
      onRender: (item: any) => {
        return (
          <div className="flex justify-between">
            <TooltipHost
              className="truncate"
              content={new Date(item.submissionDate).toString().slice(0, 24)}
            >
              <Text
                weight={400}
                className="text-gray-f1-25 text-xs overflow-ellipsis	cursor-pointer antialiased font-microsoft"
              >
                {new Date(item.submissionDate).toString().slice(0, 24)}
              </Text>
            </TooltipHost>
          </div>
        );
      },
    },

    {
      key: 'column7',
      fieldName: 'status',
      name: 'Status',
      isResizable: true,
      minWidth: 150,
      maxWidth: 150,
      styles: headerStyle,
      onRender: (item: any) => {
        return (
          <div className="flex justify-between">
            <TooltipHost
              className="truncate"
              content={item?.overallStatus || '-'}
            >
              <Text
                weight={400}
                className="text-gray-f1-25 text-xs overflow-ellipsis	cursor-pointer antialiased font-microsoft"
              >
                {item?.overallStatus || '-'}
              </Text>
            </TooltipHost>
          </div>
        );
      },
    },
  ];
};

export default getColumns;
