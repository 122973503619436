import { TooltipHost } from '@fluentui/react';
import { FaSquareFull } from 'react-icons/fa';
import { ContentType } from 'recharts/types/component/DefaultLegendContent';

import Text from 'components/Text';

export const renderCustomLegendForChart: ContentType = (props) => {
  const { payload } = props;

  return (
    <div className="flex mt-1 overflow-y-auto">
      {payload?.map((entry) => {
        const color = entry.color;
        const value = entry.value;

        return (
          <div
            key={entry.id}
            className="flex flex-row items-center justify-center mx-2"
          >
            <FaSquareFull className="mr-1" size={12} color={color} />

            <TooltipHost content={value}>
              <Text style={{ color }} className="antialiased font-microsoft">
                {value}
              </Text>
            </TooltipHost>
          </div>
        );
      })}
    </div>
  );
};
export const renderCustomLegendForChartTypeTwo: ContentType = (props) => {
  const { payload } = props;

  return (
    <div className="flex mt-1 overflow-y-auto">
      {payload?.map((entry) => {
        const color = entry.color;
        const value = entry.value;

        return (
          <div
            key={entry.id}
            className="flex flex-row items-center justify-center mx-2 pl-3"
          >
            <FaSquareFull className="mr-1" size={12} color={color} />

            <TooltipHost content={value}>
              <Text style={{ color }} className="antialiased font-microsoft ">
                {value}
              </Text>
            </TooltipHost>
          </div>
        );
      })}
    </div>
  );
};

export default renderCustomLegendForChart;
