import { useMemo } from 'react';

import { Currency } from 'API';

import { useInfiniteCurrenciesList } from 'queries/currency.query';

import getNonNullableList from 'helpers/utils/getNonNullableList';

const useGetAllCurrencies = () => {
  const {
    data,
    isInitialLoading,
    isError,
    isLoadingError,
    isFetching,
    isFetched,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteCurrenciesList({
    enabled: true,
  });

  const currencies = useMemo(() => {
    const pages = data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.listCurrencies?.items),
    );

    return tempItems as Partial<Currency>[];
  }, [data?.pages]);

  return {
    currencies,
    isInitialLoading,
    isError,
    isLoadingError,
    isFetching,
    isFetched,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  };
};

export default useGetAllCurrencies;
