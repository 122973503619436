import type { GraphQLQuery } from '@aws-amplify/api';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';

import type {
  ModelProductDocFilterInput,
  CustomListProductDocsQuery,
  ProductDoc,
} from 'API';

import { tenantAtom } from 'atoms/modals';

import { customListProductDocs } from 'graphql/custom-queries';

import getNonNullableList from 'helpers/utils/getNonNullableList';
import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseProductDocsType =
  | ({
      filter?: ModelProductDocFilterInput | null;
    } & UseInfiniteQueryOptions)
  | null
  | void;

const useProductDocsSource = (props: IUseProductDocsType = {}) => {
  const filter = props?.filter ?? null;

  const queryKey = props?.queryKey ?? [];

  const enabled = props?.enabled ?? true;

  const staleTime =
    props?.staleTime ?? toMilliseconds({ hours: 0, minutes: 0, seconds: 20 }); // 20secs

  return useInfiniteQuery({
    queryKey: [`productDocs`, filter, ...queryKey],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<GraphQLQuery<CustomListProductDocsQuery>>({
        query: customListProductDocs,
        variables: filter
          ? { filter, limit: 1_000_000, nextToken: pageParam }
          : { limit: 1_000_000, nextToken: pageParam },
      });
    },
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.listProductDocs?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
    enabled,
    staleTime,
  });
};

const useProductDocs = (props: IUseProductDocsType = {}) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const filter = props?.filter ?? null;

  const enabled = (props?.enabled ?? true) && !!tenantId;

  let sourceFilter: ModelProductDocFilterInput | null = null;

  if (tenantId) {
    const defaultFilter: ModelProductDocFilterInput | null = {
      tenantId: {
        eq: tenantId,
      },
    };

    sourceFilter = filter
      ? {
          ...filter,
          ...defaultFilter,
        }
      : { ...defaultFilter };
  }

  const result = useProductDocsSource({
    ...props,
    filter: sourceFilter,
    enabled,
  });

  const productDocsSource = useMemo(() => {
    const pages = result.data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.listProductDocs?.items),
    );

    return tempItems as Partial<ProductDoc>[];
  }, [result.data?.pages]);

  return {
    productDocsSource,
    ...result,
  };
};

export const useInfiniteProductDocs = (props: IUseProductDocsType = {}) => {
  const result = useProductDocs(props);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = result;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      try {
        void fetchNextPage();
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return result;
};

export default useProductDocs;
