import { useLocation } from 'react-router-dom';

import { Chapter, Doc, StatusType } from 'API';

import { CustomChapters, CustomDocs } from 'types/documents';
import { DocumentsMapType } from 'types/Pages/Products';

import useConstructStatus from 'hooks/useConstructStatus';

type UseTransformDocumentsType = {
  subChapters: Partial<Chapter | null>[];
  docs: Partial<Doc | null>[];
};

const useTransformDocuments = ({
  subChapters,
  docs,
}: UseTransformDocumentsType) => {
  const search = useLocation().search;
  const { status } = useConstructStatus({ search });

  const documentsMap: DocumentsMapType = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const document of [...subChapters, ...docs]) {
    if (document?.id && document.status) {
      if (documentsMap[document.id]) {
        if (
          [
            StatusType.PUBLISHED,
            StatusType.PENDING,
            StatusType.PENDING_APPROVAL,
          ].includes(document.status as StatusType)
        ) {
          const currentdocumentsMapItems = documentsMap[document.id] || [];

          if (
            !currentdocumentsMapItems.some(
              (item: any) => item.status === document.status,
            )
          ) {
            documentsMap[document.id] = [
              ...(documentsMap[document.id] || ([] as any)),
              document,
            ];
          }
        } else {
          documentsMap[document.id] = [
            ...(documentsMap[document.id] || ([] as any)),
            document,
          ];
        }
      } else {
        documentsMap[document.id] = [document as any];
      }
    }
  }

  const filterDocuments = (arr: any) => {
    return arr.filter((c: Chapter) => {
      if (status === StatusType.PUBLISHED) {
        const shouldKeep = c.status === StatusType.PUBLISHED;

        return shouldKeep;
      }

      if (
        status === StatusType.PENDING ||
        status === StatusType.PENDING_APPROVAL
      ) {
        if (documentsMap[c.id]) {
          const tempItems = [...(documentsMap[c.id] || [])];

          if (c.status === StatusType.PUBLISHED) {
            // If there is a pending version for an already existing published version, we show the pending version instead of the published version.
            // Otherwise, we show the published version directly.
            const shouldKeep = !tempItems.some(
              (item) => item.status === StatusType.PENDING,
            );

            return shouldKeep;
          }

          if (
            c.status === StatusType.PENDING ||
            c.status === StatusType.PENDING_APPROVAL
          ) {
            return true;
          }

          return false;
        }

        return false;
      }

      return false;
    });
  };

  const filteredSubChapters: CustomChapters = filterDocuments([...subChapters]);
  const filteredDocs: CustomDocs = filterDocuments([...docs]);

  return {
    filteredSubChapters,
    filteredDocs,
    documentsMap,
  };
};

export default useTransformDocuments;
