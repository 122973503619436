import { type GraphQLQuery } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import {
  type GetNestedSubChaptersUnderRootChapterQuery,
  type GetNestedSubChaptersUnderRootChapterQueryVariables,
  type ModelChapterFilterInput,
  ModelSortDirection,
  StatusType,
} from 'API';

import { getNestedSubChaptersUnderRootChapter } from 'graphql/custom-queries';

type UseRootChapterListType = {
  id: string;
  version?: string;
  enabled?: boolean;
  filter?: ModelChapterFilterInput;
};

const useNestedSideBar = ({
  id,
  version,
  filter = {},
  enabled = true,
}: UseRootChapterListType) => {
  const limit = 1_000_000;

  const tempFilter: ModelChapterFilterInput = {
    ...filter,
    status: {
      ne: StatusType.ARCHIVED,
    },
  };

  const variables: GetNestedSubChaptersUnderRootChapterQueryVariables = {
    limit: limit,
    filter: tempFilter,
    sortDirection: ModelSortDirection.DESC,
  };

  if (id) {
    variables.id = id;
  }

  if (version) {
    variables.version = {
      eq: version,
    };
  }

  return useQuery({
    queryKey: ['rootChapter', { id }, { version }, variables],
    queryFn: () => {
      return API.graphql<
        GraphQLQuery<GetNestedSubChaptersUnderRootChapterQuery>
      >({
        query: getNestedSubChaptersUnderRootChapter,
        variables,
      });
    },
    enabled,
  });
};

export default useNestedSideBar;
