import { SearchBox, ISearchBoxStyles } from '@fluentui/react';
import {
  Button,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderNavigation,
  DrawerHeaderTitle,
  OverlayDrawer,
  Toolbar,
  ToolbarButton,
  ToolbarGroup,
  makeStyles,
  Text,
  DrawerBody,
  Skeleton,
  SkeletonItem,
} from '@fluentui/react-components';
import {
  ArrowClockwise24Regular,
  ArrowLeft24Regular,
  Dismiss24Regular,
  Save24Regular,
} from '@fluentui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  type CustomDocs,
  type CustomDoc,
} from 'components/ReferenceDocSliderForm/types';

import { Chapter, CreateDocInput, Doc, StatusType, UpdateDocInput } from 'API';

import { FAILED_TO_SAVE } from 'consts/alertMessages';
import falainaTenantId from 'consts/tenant';

import { useCreateChapterMutation } from 'hooks/useChapterMutations';
import useConstructStatus from 'hooks/useConstructStatus';
import {
  useCreateDocMutation,
  useUpdateDocMutation,
} from 'hooks/useDocMutations';
import useGetCurrentAuthenticatedUser from 'hooks/useGetCurrentAuthenticatedUser';

import getProductDocQueryKey from 'helpers/utils/getProductDocQueryKey';

import { DocCard } from './components';
import { useUserAccessibleDocs } from './hooks';

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: { width: '100%', borderColor: 'lightgray' },
};

type AddReferenceDocPanelType = {
  doc: Partial<Doc>;
  rootChapter: Partial<Chapter | null>;
  allRootChapterVersions: Partial<Chapter | null>[];
  setMessage: (messageObject: any) => void;
  resetPanel: () => void;
};

const useSearchedDocs = ({ docs }: { docs: CustomDocs }) => {
  const [searchedDocs, setSearchedDocs] = useState<CustomDocs>([]);

  const handleClearSearch = useCallback(() => {
    setSearchedDocs([]);
  }, []);

  const handleSearch = useCallback(
    (query: string | undefined) => {
      if (query) {
        const searchList = docs.filter((doc) => {
          const isTitleMatched = query
            .toLowerCase()
            .split(' ')
            .some((data) => data && doc?.title?.toLowerCase()?.includes(data));

          return isTitleMatched;
        });

        setSearchedDocs(searchList);
      } else {
        handleClearSearch();
      }
    },
    [handleClearSearch, docs],
  );

  const handleSearchDocs = useMemo(() => {
    return debounce(handleSearch, 500);
  }, [handleSearch]);

  useEffect(() => {
    return () => {
      handleSearchDocs.cancel();
    };
  }, [handleSearchDocs]);

  return {
    searchedDocs,
    setSearchedDocs,
    handleSearchDocs,
    handleClearSearch,
  };
};

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
  },
  drawer: {
    marginTop: '50px',
  },
});

const AddReferenceDocPanel = ({
  doc,
  rootChapter,
  allRootChapterVersions = [],
  setMessage,
  resetPanel,
}: AddReferenceDocPanelType) => {
  const styles = useStyles();

  const search = useLocation().search;
  const navigate = useNavigate();

  const { status } = useConstructStatus({ search });

  const { documentId } = useParams();

  const [selectedReferenceDoc, setSelectedReferenceDoc] =
    useState<CustomDoc>(null);

  const currentAuthenticatedUser = useGetCurrentAuthenticatedUser();

  const docsResult = useUserAccessibleDocs();

  const { allUserAccessibleDocs, isInitialLoading, isRefetching, refetch } =
    docsResult;

  const { searchedDocs, handleSearchDocs, handleClearSearch } = useSearchedDocs(
    { docs: allUserAccessibleDocs },
  );

  const handleRefetch = useCallback(async () => {
    try {
      await refetch();
    } catch (error) {
      console.error(error);
    }
  }, [refetch]);

  const queryClient = useQueryClient();

  const createChapterMutation = useCreateChapterMutation({
    onSettledCallback: () => {
      void queryClient.invalidateQueries(
        getProductDocQueryKey({ id: rootChapter?.parentProductDocId }),
      );

      void queryClient.invalidateQueries([
        'rootChapter',
        { id: doc.rootChapterId },
      ]);

      void queryClient.invalidateQueries([`subChapters/${doc.rootChapterId}`]);
    },
  });

  const createDocMutation = useCreateDocMutation();
  const updateDocMutation = useUpdateDocMutation();

  const handleCreateNewRootChapterVersion = useCallback(async () => {
    if (
      status === StatusType.PUBLISHED &&
      rootChapter?.status === StatusType.PUBLISHED &&
      currentAuthenticatedUser
    ) {
      const final = {
        ...(rootChapter as Chapter),
        id: rootChapter.id,
        name: rootChapter.name ?? '',
        description: rootChapter.description ?? '',
        version: rootChapter.version
          ? String(Number(rootChapter.version) + 1)
          : '2',
        status: StatusType.PENDING,
        createdByUserId: currentAuthenticatedUser.sub,
        lastModifiedByUserId: currentAuthenticatedUser.sub,
        tenantId: falainaTenantId,
        rootChapterId: rootChapter.rootChapterId ?? '',
      };

      try {
        await createChapterMutation.mutateAsync(final);

        setMessage({
          message:
            'Added successfully. You can find this document in "Edited Documents"',
          type: 'success',
        });
      } catch (error) {
        console.error(error);

        throw error;
      }
    }
  }, [
    createChapterMutation,
    currentAuthenticatedUser,
    rootChapter,
    setMessage,
    status,
  ]);

  const submit = useCallback(async () => {
    if (currentAuthenticatedUser) {
      if (
        status === StatusType.PUBLISHED &&
        doc.status === StatusType.PUBLISHED
      ) {
        const final: CreateDocInput = {
          id: doc.id,
          version: doc.version ? String(Number(doc.version) + 1) : '2',
          status: StatusType.PENDING,
          title: doc.title ?? '',
          description: doc.description,
          createdByUserId: currentAuthenticatedUser.sub,
          lastModifiedByUserId: currentAuthenticatedUser.sub,
          rootChapterId: doc.rootChapterId ?? '',
          chapterId: doc.chapterId ?? '',
          orderFloat: doc.orderFloat,
          referenceDocId: selectedReferenceDoc?.id,
          tenantId: falainaTenantId,
        };

        try {
          await createDocMutation.mutateAsync(final);

          const isPendingRootChapterVersionPresent =
            allRootChapterVersions.some(
              (x) => x?.status === StatusType.PENDING,
            );

          if (!isPendingRootChapterVersionPresent) {
            await handleCreateNewRootChapterVersion();
          }

          resetPanel();
        } catch (error) {
          console.error(error);

          setMessage({ message: FAILED_TO_SAVE, type: 'error' });
        }
      }

      if (status === StatusType.PENDING) {
        if (doc.status === StatusType.PUBLISHED) {
          const newVersion = doc.version
            ? String(Number(doc.version) + 1)
            : '2';

          const final: CreateDocInput = {
            id: doc.id,
            version: newVersion,
            status: StatusType.PENDING,
            title: doc.title ?? '',
            description: doc.description,
            createdByUserId: currentAuthenticatedUser.sub,
            lastModifiedByUserId: currentAuthenticatedUser.sub,
            rootChapterId: doc.rootChapterId ?? '',
            chapterId: doc.chapterId ?? '',
            orderFloat: doc.orderFloat,
            referenceDocId: selectedReferenceDoc?.id,
            tenantId: falainaTenantId,
          };

          try {
            await createDocMutation.mutateAsync(final);

            resetPanel();

            navigate(
              `/products/documents/${documentId}/content/${doc.id}?status=PENDING`,
            );
          } catch (error) {
            console.error(error);

            setMessage({ message: FAILED_TO_SAVE, type: 'error' });
          }
        }

        if (doc.status === StatusType.PENDING) {
          const final: UpdateDocInput = {
            id: doc.id ?? '',
            version: doc.version ?? '',
            status: StatusType.PENDING,
            title: doc.title,
            description: doc.description,
            createdByUserId: currentAuthenticatedUser.sub,
            lastModifiedByUserId: currentAuthenticatedUser.sub,
            rootChapterId: doc.rootChapterId,
            chapterId: doc.chapterId,
            orderFloat: doc.orderFloat,
            referenceDocId: selectedReferenceDoc?.id,
          };

          try {
            await updateDocMutation.mutateAsync(final);

            resetPanel();
          } catch (error) {
            console.error(error);

            setMessage({ message: FAILED_TO_SAVE, type: 'error' });
          }
        }
      }
    }
  }, [
    allRootChapterVersions,
    createDocMutation,
    currentAuthenticatedUser,
    doc,
    documentId,
    handleCreateNewRootChapterVersion,
    navigate,
    resetPanel,
    selectedReferenceDoc?.id,
    setMessage,
    status,
    updateDocMutation,
  ]);

  return (
    <OverlayDrawer
      open
      onOpenChange={(_, { open }) => {
        if (!open) {
          resetPanel();
        }
      }}
      position="end"
      size="medium"
      className={styles.drawer}
    >
      <DrawerHeader>
        <DrawerHeaderNavigation>
          <Toolbar className={styles.toolbar}>
            <ToolbarButton
              aria-label="Back"
              appearance="subtle"
              icon={<ArrowLeft24Regular />}
              onClick={resetPanel}
            />

            <ToolbarGroup>
              <ToolbarButton
                aria-label="Reload content"
                appearance="subtle"
                icon={<ArrowClockwise24Regular />}
                onClick={handleRefetch}
              />

              <ToolbarButton
                aria-label="Close panel"
                appearance="subtle"
                icon={<Dismiss24Regular />}
                onClick={resetPanel}
              />
            </ToolbarGroup>
          </Toolbar>
        </DrawerHeaderNavigation>
        <DrawerHeaderTitle>
          <div className="flex flex-col mb-2">
            <Text>Search for content to add as reference</Text>
          </div>
        </DrawerHeaderTitle>
        <SearchBox
          className="w-full mb-2"
          styles={searchBoxStyles}
          placeholder="Search Content"
          onClear={handleClearSearch}
          onChange={(_event, value) => handleSearchDocs(value)}
        />
      </DrawerHeader>

      <DrawerBody>
        <div>
          {isInitialLoading || isRefetching ? (
            <Skeleton>
              {Array.from({ length: 50 }).map((_x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <SkeletonItem key={i} className="mb-2" />
              ))}
            </Skeleton>
          ) : selectedReferenceDoc ? (
            <div>
              <div className="mb-4">
                <Text weight="semibold">Selected Reference Content:</Text>
              </div>

              <DocCard
                doc={selectedReferenceDoc}
                selectedReferenceDoc={selectedReferenceDoc}
                setSelectedReferenceDoc={setSelectedReferenceDoc}
              />
            </div>
          ) : (
            <div className="mt-2 rounded shadow-microsoft">
              {searchedDocs.slice(0, 50).map((searchedDoc) => {
                return (
                  <DocCard
                    key={searchedDoc?.id}
                    doc={searchedDoc}
                    selectedReferenceDoc={selectedReferenceDoc}
                    setSelectedReferenceDoc={setSelectedReferenceDoc}
                  />
                );
              })}
            </div>
          )}
        </div>
      </DrawerBody>

      <DrawerFooter>
        <Button
          type="submit"
          appearance="primary"
          icon={<Save24Regular />}
          onClick={submit}
          disabled={
            createDocMutation.isLoading ||
            updateDocMutation.isLoading ||
            createChapterMutation.isLoading ||
            isInitialLoading ||
            isRefetching ||
            !selectedReferenceDoc
          }
        >
          Save Changes
        </Button>

        <Button appearance="transparent" onClick={resetPanel}>
          Cancel
        </Button>
      </DrawerFooter>
    </OverlayDrawer>
  );
};

export default AddReferenceDocPanel;
