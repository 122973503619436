import { useMemo } from 'react';

import type { User } from 'API';
import { ActiveStatusType } from 'API';

const useUserDisabled = ({
  userDetails,
}: {
  userDetails: Partial<User> | null;
}) => {
  const isUserDisabled = useMemo(() => {
    return userDetails?.status === ActiveStatusType.DELETED;
  }, [userDetails]);

  return [isUserDisabled];
};

export default useUserDisabled;
