import { Panel, Separator, PrimaryButton } from '@fluentui/react';
import { useCallback } from 'react';

type DeleteModalType = {
  documentName?: string;
  productName?: string;
  title?: string;
  description?: string;
  isSliderOpen: boolean;
  closeSlider: () => void;
  onDelete: () => void;
};

const buttonStyles = { root: { marginRight: 8 } };

const DeletePanel = ({
  title,
  description,
  documentName,
  productName,
  isSliderOpen,
  closeSlider,
  onDelete,
}: DeleteModalType) => {
  const onRenderFooterContent = useCallback(
    () => (
      <div>
        <Separator />
        <PrimaryButton
          text="Delete"
          type="submit"
          styles={buttonStyles}
          className="bg-blue-db-20 focus:outline-none"
          onClick={onDelete}
        />
      </div>
    ),
    [onDelete],
  );

  return (
    <Panel
      isOpen={isSliderOpen}
      onDismiss={closeSlider}
      type={7}
      styles={{ root: { marginTop: 50 } }}
      customWidth="448px"
      closeButtonAriaLabel="Close"
      isLightDismiss
      hasCloseButton
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
    >
      <div className="text-xl py-4 font-semibold ">{title}</div>

      <div>{description}</div>

      {documentName && (
        <div className="mt-5">
          <span className="mt-2 font-semibold">Document name: </span>
          <span className="">{documentName}</span>
        </div>
      )}

      {productName && (
        <div className="mt-5">
          <span className="mt-2 font-semibold">Product name: </span>
          <span className="">{productName}</span>
        </div>
      )}
    </Panel>
  );
};

export default DeletePanel;
