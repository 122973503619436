import {
  Button,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderNavigation,
  DrawerHeaderTitle,
  OverlayDrawer,
  Toolbar,
  ToolbarButton,
  ToolbarGroup,
  makeStyles,
  Text,
  DrawerBody,
  Tooltip,
} from '@fluentui/react-components';
import {
  ArrowClockwise24Regular,
  ArrowLeft24Regular,
  Dismiss24Regular,
} from '@fluentui/react-icons';
import { useAtomValue } from 'jotai';
import { Link, useNavigate } from 'react-router-dom';

import { Doc, StatusType } from 'API';

import {
  allUserAccessiblePublishedProductDocsAtom,
  allUserAccessiblePublishedRootChaptersAtom,
} from 'atoms/modals';

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
  },
  drawer: {
    marginTop: '50px',
  },
});

type ReferenceDocInfoPanelType = {
  referenceDoc: Partial<Doc>;
  resetPanel: () => void;
};

const ReferenceDocInfoPanel = ({
  referenceDoc,
  resetPanel,
}: ReferenceDocInfoPanelType) => {
  const styles = useStyles();

  const allUserAccessiblePublishedRootChapters = useAtomValue(
    allUserAccessiblePublishedRootChaptersAtom,
  );

  const allUserAccessiblePublishedProductDocs = useAtomValue(
    allUserAccessiblePublishedProductDocsAtom,
  );

  const rootChapter = allUserAccessiblePublishedRootChapters.find(
    (x) => x?.id === referenceDoc.rootChapterId,
  );

  const productDoc = allUserAccessiblePublishedProductDocs.find(
    (x) => x.id === rootChapter?.parentProductDocId,
  );

  return (
    <OverlayDrawer
      open
      onOpenChange={(_, { open }) => {
        if (!open) {
          resetPanel();
        }
      }}
      position="end"
      size="medium"
      className={styles.drawer}
    >
      <DrawerHeader>
        <DrawerHeaderNavigation>
          <Toolbar className={styles.toolbar}>
            <ToolbarButton
              aria-label="Back"
              appearance="subtle"
              icon={<ArrowLeft24Regular />}
              onClick={resetPanel}
            />

            <ToolbarGroup>
              <ToolbarButton
                aria-label="Reload content"
                appearance="subtle"
                icon={<ArrowClockwise24Regular />}
                // onClick={handleRefetch}
              />

              <ToolbarButton
                aria-label="Close panel"
                appearance="subtle"
                icon={<Dismiss24Regular />}
                onClick={resetPanel}
              />
            </ToolbarGroup>
          </Toolbar>
        </DrawerHeaderNavigation>
        <DrawerHeaderTitle>
          <div className="flex flex-col mb-2">
            <Text weight="bold"> Ref Content Details</Text>
          </div>
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        <div className="mb-5">
          <Text weight="semibold">
            Product:
            {'  '}
          </Text>
          <Tooltip content={productDoc?.name ?? ''} relationship="label">
            <Link
              className="text-blue-500 hover:underline"
              to={`/products/${productDoc?.id}?status=${StatusType.PUBLISHED}&view=read`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {productDoc?.name ?? ''}
            </Link>
          </Tooltip>
        </div>

        <div className="mb-5">
          <Text weight="semibold">
            Created Date & Time:
            {'  '}
          </Text>
          <Text>
            {referenceDoc.createdAt
              ? new Date(referenceDoc.createdAt).toString().slice(0, 24)
              : ''}
          </Text>
        </div>

        <div className="mb-5">
          <Text weight="semibold">
            Root Document:
            {'  '}
          </Text>
          <Tooltip content={rootChapter?.name ?? ''} relationship="label">
            <Link
              className="text-blue-500 hover:underline"
              to={`/products/documents/${referenceDoc.rootChapterId}?status=${StatusType.PUBLISHED}&view=read`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {rootChapter?.name ?? ''}
            </Link>
          </Tooltip>
        </div>

        <div className="mb-5">
          <Text weight="semibold">
            Content:
            {'  '}
          </Text>
          <Tooltip content={referenceDoc.title ?? ''} relationship="label">
            <Link
              className="text-blue-500 hover:underline"
              to={`/products/documents/${referenceDoc.rootChapterId}/content/${referenceDoc.id}?status=${StatusType.PUBLISHED}&view=read`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {referenceDoc.title ?? ''}
            </Link>
          </Tooltip>
        </div>
      </DrawerBody>

      <DrawerFooter>
        <Button appearance="outline" onClick={resetPanel}>
          Cancel
        </Button>
      </DrawerFooter>
    </OverlayDrawer>
  );
};

export default ReferenceDocInfoPanel;
