import { Auth } from 'aws-amplify';
import { useSetAtom, useAtomValue, useAtom } from 'jotai';
import { Suspense, lazy, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useLocation } from 'react-router-dom';

import ErrorFallback from 'components/ErrorFallback';
import HomeLoader from 'components/Loader/HomeLoader';

import {
  isLoggedinAtom,
  isAppUnderMaintenanceAtom,
  isTenantAdminAtom,
} from 'atoms/modals';

import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';

const MaintenanceBanner = lazy(() => import('pages/MaintenanceBanner'));

const useRoutes = () => {
  const setIsTenantAdmin = useSetAtom(isTenantAdminAtom);
  const [isLoggedIn, setIsLoggedin] = useAtom(isLoggedinAtom);

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        if (userData.attributes['custom:isVerified'] === 'true') {
          if (pathname.includes('access_token') || pathname.includes('code')) {
            navigate('/');
          }

          setIsLoggedin(true);
        }

        if (userData.attributes['custom:is_tenant_admin'] === 'true') {
          setIsTenantAdmin(true);
        }

        // throw new Error('Test'); // To test Auth.currentSession();
      })
      .catch(async (error) => {
        console.error('rotes - err', error);
        // Call Auth.currentSession() on every page change to refresh the current session.
        try {
          // This method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented
          await Auth.currentSession();

          setIsLoggedin(true);
        } catch (_error) {
          console.error('rotes - currentSession error', _error);

          setIsLoggedin(false);
        }
      });
  }, [navigate, pathname, setIsLoggedin, setIsTenantAdmin]);

  return [isLoggedIn];
};

const Routes = () => {
  const [isLoggedIn] = useRoutes();
  const isAppUnderMaintenance = useAtomValue(isAppUnderMaintenanceAtom);

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here

    console.error(error, info.componentStack);
  };

  if (isAppUnderMaintenance) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
        <Suspense fallback={<HomeLoader />}>
          <MaintenanceBanner />
        </Suspense>
      </ErrorBoundary>
    );
  }

  const routes = isLoggedIn ? <AppRoutes /> : <AuthRoutes />;

  return <div>{routes}</div>;
};

export default Routes;
