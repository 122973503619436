import { Text as MsText } from '@fluentui/react';
import {
  Button,
  Skeleton,
  SkeletonItem,
  Tooltip,
  makeStyles,
  shorthands,
  tokens,
} from '@fluentui/react-components';
import type { SkeletonProps } from '@fluentui/react-components';
import {
  Add28Regular,
  Delete28Filled,
  Info32Regular,
} from '@fluentui/react-icons';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import DocAlert from 'components/DocItems/DocAlert';
import DocInfo from 'components/DocItems/DocInfo';
import DocNote from 'components/DocItems/DocNote';
import DocPreRequisite from 'components/DocItems/DocPreRequisite';
import ImageBlock from 'components/DocItems/ImageBlock';
import Markdown from 'components/DocItems/Markdown';
import VideoBlock from 'components/DocItems/VideoBlock';
import Flex from 'components/Flex';
import ContentActionsWrapper from 'components/Pages/Content/ContentActionsWrapper';
import InThisSection from 'components/Pages/Content/InThisSection';
import ReferenceDocInfoForm from 'components/ReferenceDocInfoForm';
import ReferenceDocSliderForm from 'components/ReferenceDocSliderForm';
import Text from 'components/Text';

import { StatusType } from 'API';

import { CustomDocItem, CustomDocItemType } from 'types/documents';

import { referenceDocDeleteAtom } from 'atoms/modals';

import useContentPage from 'hooks/useContentPage';
import { isCurrentUserAuthenticated } from 'hooks/useGetCurrentAuthenticatedUser';

import costructContentPageProps from 'helpers/utils/contentPage';
import generateToast, {
  generateSessionTimeoutToast,
} from 'helpers/utils/generateToast';

import { useDocumentOutlet } from '.';

const docItemRender = {
  content: Markdown,
  code: Markdown,
  tip: DocInfo,
  alert: DocAlert,
  image: ImageBlock,
  video: VideoBlock,
  preRequisite: DocPreRequisite,
  note: DocNote,
};

const useStyles = makeStyles({
  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  firstRow: {
    alignItems: 'center',
    display: 'grid',
    paddingBottom: '50px',
    position: 'relative',
    ...shorthands.gap('10px'),
    // gridTemplateColumns: 'min-content 80%',
  },
  secondRow: {
    alignItems: 'center',
    display: 'grid',
    paddingBottom: '50px',
    position: 'relative',
    ...shorthands.gap('10px'),
  },
  thirdRow: {
    alignItems: 'center',
    display: 'grid',
    paddingBottom: '50px',
    position: 'relative',
    ...shorthands.gap('10px'),
  },
});

export const SkeletonLoader = (props: Partial<SkeletonProps>) => {
  const styles = useStyles();

  return (
    <div className={styles.invertedWrapper}>
      <Skeleton {...props}>
        <div className={styles.firstRow}>
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
        </div>
        <div className={styles.thirdRow}>
          <SkeletonItem shape="rectangle" size={128} />
        </div>
        <div className={styles.secondRow}>
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
        </div>
        <div className={styles.thirdRow}>
          <SkeletonItem shape="rectangle" size={128} />
        </div>
        <div className={styles.secondRow}>
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
          <SkeletonItem shape="rectangle" size={16} />
        </div>
      </Skeleton>
    </div>
  );
};
const Content = () => {
  const {
    rootChapter,
    allRootChapterVersions,
    documentsMap: mainDocumentsMap,
    hasCreateProductAccess,
    setMessage,
  } = useDocumentOutlet();

  const {
    isInitialLoading,
    isError,

    content,

    isReferenceDocsLoading,
    hasNextPageReferenceDocs,

    contentId,

    isContentReader,

    documentsMap,

    filteredDocItems,

    status,

    search,

    documentId,

    isDropdown,
    setIsDropdown,

    referenceDoc,
  } = useContentPage();

  const setReferenceDocDelete = useSetAtom(referenceDocDeleteAtom);

  const [isAddingReferenceDoc, setIsAddingReferenceDoc] = useState(false);

  const [isViewingReferenceDocInfo, setIsViewingReferenceDocInfo] =
    useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640); // 640px is typically the 'sm' breakpoint

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAddReferenceDoc = () => {
    setIsAddingReferenceDoc(true);
  };

  const handleViewReferenceDocInfo = () => {
    setIsViewingReferenceDocInfo(true);
  };

  const onResetAddReferenceDocPanel = () => {
    setIsAddingReferenceDoc(false);
  };

  const onResetReferenceDocInfoPanel = () => {
    setIsViewingReferenceDocInfo(false);
  };

  const {
    isAnyAncestorDeleted,
    diffClassNameForDoc,
    isSomeRootChapVersionSubmittedForApproval,
  } = costructContentPageProps({
    mainDocumentsMap,
    contentId: contentId!,
    isContentReader,
    rootChapter,
    content,
    status,
    allRootChapterVersions,
  });

  const renderDocItem = (item: CustomDocItem, index: number) => {
    if (item?.itemType && content) {
      const DocItemComp = docItemRender[item.itemType];

      return (
        <DocItemComp
          key={(item.id ?? '') + (item.version ?? '')}
          doc={content}
          docItem={item as CustomDocItemType}
          documentsMap={documentsMap}
          rootChapter={rootChapter}
          allRootChapterVersions={allRootChapterVersions}
          isAnyAncestorDeleted={isAnyAncestorDeleted}
          lastElement={filteredDocItems.length - 1 === index}
          setMessage={setMessage}
        />
      );
    }

    return null;
  };

  const allDocuments = content?.referenceDocId
    ? (referenceDoc?.docItems?.items ?? [])
        .flatMap((x) => (x?.status === StatusType.PUBLISHED ? [x] : []))
        .map((doc, index) => ({
          createdAt: doc.createdAt,
          order: Number.parseFloat(doc.orderFloat ?? '100000'),
          comp: renderDocItem(doc, index),
        }))
    : filteredDocItems.length > 0
      ? filteredDocItems.map((doc, index) => ({
          createdAt: doc.createdAt,
          order: Number.parseFloat(doc.orderFloat ?? '100000'),
          comp: renderDocItem(doc, index),
        }))
      : [];

  const sortedDocuments = [...allDocuments]
    .sort(
      (a, b) =>
        (a.createdAt ? +new Date(a.createdAt) : 0) -
        (b.createdAt ? +new Date(b.createdAt) : 0),
    )
    .sort((a, b) => a.order - b.order);

  const sortedElement = sortedDocuments.map((a) => a.comp);

  const isContentActionsVisible =
    !!documentId &&
    !!contentId &&
    hasCreateProductAccess &&
    isDropdown &&
    status !== StatusType.PENDING_APPROVAL &&
    rootChapter?.status !== StatusType.ARCHIVED &&
    !content?.referenceDocId;

  if (isError) {
    return <div>Something went wrong</div>;
  }

  if (
    isInitialLoading ||
    !content ||
    (content.referenceDocId &&
      (isReferenceDocsLoading || hasNextPageReferenceDocs))
  ) {
    return (
      <Flex
        direction="col"
        height="h-screen"
        width="w-full"
        padding="p-10"
        className="z-10 xl:pt-16"
      >
        <SkeletonLoader />
      </Flex>
    );
  }

  return (
    <>
      {isAddingReferenceDoc && (
        <ReferenceDocSliderForm
          doc={content}
          rootChapter={rootChapter}
          allRootChapterVersions={allRootChapterVersions}
          setMessage={setMessage}
          resetPanel={onResetAddReferenceDocPanel}
        />
      )}

      {isViewingReferenceDocInfo && referenceDoc && (
        <ReferenceDocInfoForm
          referenceDoc={referenceDoc}
          resetPanel={onResetReferenceDocInfoPanel}
        />
      )}

      <Flex className="pt-14 no-scrollbar overflow-y-hidden">
        <>
          <Flex
            direction="col"
            width="w-full"
            className="pt-4 px-4 gap-5 xl:max-w-60v"
          >
            <MsText
              className={diffClassNameForDoc}
              style={{
                fontSize:
                  'clamp(1.875rem,22.1052631579px + 1.6447368421vw,2.5rem)',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                lineHeight: '1',
              }}
            >
              {content.title ?? ''}
            </MsText>
            <Text
              variant="mediumPlus"
              weight={600}
              className={`${diffClassNameForDoc} leading-6.25 mt-1 whitespace-pre-wrap`}
              as="p"
            >
              {content.description ?? ''}
            </Text>

            <div className="flex xl:hidden relative">
              <InThisSection
                docItems={filteredDocItems}
                isAnyAncestorDeleted={isAnyAncestorDeleted}
              />
            </div>
            <Flex direction="col" justify="start">
              {sortedElement}
            </Flex>
          </Flex>
          <Flex
            width="w-full lg:w-3/12 md:w-36"
            margin="ml-5"
            style={{ height: 'inherit' }}
            className=" hidden xl:flex "
          >
            <div className="hidden xl:flex w-full relative  ">
              <InThisSection
                docItems={filteredDocItems}
                isAnyAncestorDeleted={isAnyAncestorDeleted}
              />
            </div>
          </Flex>

          {(hasCreateProductAccess &&
            status !== StatusType.PENDING_APPROVAL &&
            rootChapter?.status !== StatusType.ARCHIVED &&
            !search.split('view=')[1] &&
            (!content.referenceDocId || content.referenceDocId)) ||
          isContentActionsVisible ? (
            <>
              {rootChapter?.status !== StatusType.ARCHIVED &&
                !search.split('view=')[1] &&
                !content.referenceDocId && (
                  <div className="flex fixed top-16 right-10">
                    <Tooltip content="Add Content" relationship="label">
                      <div className="font-bold mr-1  p-4   text-gray-700  flex items-center justify-center mt-15 sm:mt-15 md:mt-2 lg:mt-2">
                        <Button
                          type="submit"
                          appearance="primary"
                          icon={isSmallScreen ? <Add28Regular /> : null}
                          shape={isSmallScreen ? 'circular' : 'square'}
                          onClick={async () => {
                            const isAuthenticated =
                              await isCurrentUserAuthenticated();
                            if (!isAuthenticated) {
                              generateSessionTimeoutToast();
                            }

                            if (isAnyAncestorDeleted) {
                              generateToast({
                                toastContent:
                                  'Cannot Add as one of its parent chapters was deleted.',
                                type: 'error',
                              });
                            } else if (
                              isSomeRootChapVersionSubmittedForApproval
                            ) {
                              generateToast({
                                toastContent:
                                  'An edited version was submitted for approval. Cannot edit until reviewed by approver.',
                                type: 'error',
                              });
                            } else {
                              setIsDropdown(!isDropdown);
                            }
                          }}
                        >
                          {!isSmallScreen && <span>Add</span>}
                        </Button>
                      </div>
                    </Tooltip>
                  </div>
                )}
              {rootChapter?.status !== StatusType.ARCHIVED &&
                !search.split('view=')[1] &&
                content.referenceDocId && (
                  <div className="flex fixed top-16 right-10 mt-18 sm:mt-18 md:mt-4 lg:mt-4">
                    <Tooltip content="Delete Ref Content" relationship="label">
                      <div className="font-bold mr-1   text-gray-700 rounded-full flex items-center justify-center ">
                        <Button
                          className="hover:bg-green-800"
                          type="submit"
                          style={{
                            backgroundColor: 'red',
                          }}
                          appearance="transparent"
                          icon={<Delete28Filled className="text-white" />}
                          shape="circular"
                          onClick={() => {
                            if (isAnyAncestorDeleted) {
                              generateToast({
                                toastContent:
                                  'Cannot Delete as one of its parent chapters was deleted.',
                                type: 'success',
                              });
                            } else if (
                              isSomeRootChapVersionSubmittedForApproval
                            ) {
                              generateToast({
                                toastContent:
                                  'An edited version was submitted for approval. Cannot edit until reviewed by approver.',
                                type: 'error',
                              });
                            } else {
                              if (status === StatusType.PUBLISHED) {
                                if (
                                  content.id &&
                                  mainDocumentsMap[content.id]
                                ) {
                                  const documentVersions =
                                    mainDocumentsMap[content.id] ?? [];

                                  const isEditable = !documentVersions.some(
                                    (item) =>
                                      item.status === StatusType.PENDING,
                                  );

                                  if (isEditable) {
                                    setReferenceDocDelete({
                                      isOpen: true,
                                      doc: {
                                        id: content.id,
                                        title: content.title ?? '',
                                        version: content.version ?? '',
                                        status: StatusType.PENDING,
                                        description: content.description ?? '',
                                        chapterId: content.chapterId ?? '',
                                        rootChapterId:
                                          content.rootChapterId ?? '',
                                        referenceDocId:
                                          content.referenceDocId ?? null,
                                      },
                                    });
                                  } else {
                                    generateToast({
                                      toastContent:
                                        'An edited version already exists. Please check "Edited Documents"',
                                      type: 'success',
                                    });
                                  }
                                } else {
                                  generateToast({
                                    toastContent:
                                      'Something went wrong. Please reload and try agin!!',
                                    type: 'error',
                                  });
                                }
                              }

                              if (status === StatusType.PENDING) {
                                setReferenceDocDelete({
                                  isOpen: true,
                                  doc: {
                                    id: content.id ?? '',
                                    title: content.title ?? '',
                                    version: content.version ?? '',
                                    status: StatusType.PENDING,
                                    description: content.description ?? '',
                                    chapterId: content.chapterId ?? '',
                                    rootChapterId: content.rootChapterId ?? '',
                                    referenceDocId:
                                      content.referenceDocId ?? null,
                                  },
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </Tooltip>

                    <Tooltip content="Ref Content Info" relationship="label">
                      <div className='  className="font-bold mr-1  text-gray-700 rounded-full flex items-center justify-center"'>
                        <Button
                          style={{
                            backgroundColor: 'blue',
                          }}
                          onClick={handleViewReferenceDocInfo}
                          icon={<Info32Regular className="text-blue-200" />}
                          shape="circular"
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}

              {isContentActionsVisible && (
                <ContentActionsWrapper
                  documentId={documentId}
                  contentId={contentId}
                  handleAddReferenceDoc={handleAddReferenceDoc}
                />
              )}
            </>
          ) : null}
        </>
      </Flex>
    </>
  );
};

export default Content;
