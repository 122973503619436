import { atom } from 'jotai';

import {
  DocItemType,
  User,
  Task,
  TaskStatusType,
  StatusType,
  Group,
  Chapter,
  Doc,
  S3ObjectInput,
  DocItem,
  Tenant,
  ProductDoc,
  TicketRule,
} from 'API';

import {
  CustomDocItems,
  CustomProductDocType,
  CustomStatusType,
} from 'types/documents';

import { SUPPORT_LEAD } from 'consts/roles';
import falainaTenantId from 'consts/tenant';
import {
  ADD_TICKET,
  UPDATE_TICKET,
  ASSIGN_TICKET,
  ADD_TASK,
  UPDATE_TASK,
  CREATE_TICKET_RULE,
  UPDATE_TICKET_RULE,
  DELETE_TICKET_RULE,
} from 'consts/ticket';

import type { Nullable } from 'types';

type CustomTask = Partial<
  Omit<
    Task,
    | 'id'
    | 'name'
    | 'description'
    | 'requestedByUserId'
    | 'status'
    | 'rootChapterId'
  >
> &
  Pick<
    Task,
    | 'id'
    | 'name'
    | 'description'
    | 'requestedByUserId'
    | 'status'
    | 'rootChapterId'
  >;

export type CurrentUserAccessDetailsAtomType = {
  currentUserDetails: Partial<User>;
  productDocsAccessMap: Record<string, string[] | null | undefined>;
  locationsAccessMap: Record<string, string[] | null | undefined>;

  hasSomeDocumentPortalAccess: boolean;
  hasSomeSupportPortalAccess: boolean;
  hasSomeLeadManagementPortalAccess: boolean;

  hasSomeReaderAccess: boolean;
  hasSomeCreatorAccess: boolean;
  hasSomeApproverAccess: boolean;
  hasSomeManagementAccess: boolean;
  hasSomeQAAccess: boolean;

  hasSomeSupportLeadAccess: boolean;
  hasSomeSupportEngineerAccess: boolean;
  hasSomeDistributorAccess: boolean;
  hasSomePartnerAccess: boolean;
  hasSomeCustomerAccess: boolean;
  hasSomePreSalesAccess: boolean;

  hasOnlyDistributorAccess: boolean;
  hasOnlyPartnerAccess: boolean;
  hasOnlyCustomerAccess: boolean;

  supportLeadProducts: string[];
  isSupportEngineer: boolean;

  hasSomeLeadManagementPartnerAccess: boolean;
  hasSomeLeadManagementDistributorAccess: boolean;
  hasSomeLeadManagementSalesTeamAccess: boolean;
  hasSomeLeadManagementSalesDirectorAccess: boolean;
  hasSomeLeadManagementSalesManagementAccess: boolean;

  hasOnlyLeadManagementPartnerAccess: boolean;
  hasOnlyLeadManagementDistributorAccess: boolean;
  hasOnlyLeadManagementSalesTeamAccess: boolean;
  hasOnlyLeadManagementSalesDirectorAccess: boolean;
  hasOnlyLeadManagementSalesManagementAccess: boolean;

  leadManagementSalesTeamLocations: string[];
  leadManagementSalesDirectorLocations: string[];
  leadManagementSalesManagementLocations: string[];
};

export enum QueryKeys {
  PRODUCT_DOC = 'PRODUCT_DOC',
  ROOT_CHAPTER = 'ROOT_CHAPTER',
  SUB_CHAPTER = 'SUB_CHAPTER',
}

export const noneValue = 'none';

export const getInitialChapter = ({
  parentProductDocId,
  rootChapterId,
  parentChapterId,
}: {
  parentProductDocId: string;
  rootChapterId: string;
  parentChapterId: string;
}) => ({
  id: '',
  version: '',
  orderFloat: '',
  name: '',
  description: '',
  parentProductDocId,
  rootChapterId,
  parentChapterId,
});

export type InitialChapterProps = Partial<Chapter> | null;
export type InitialChapterActionProps = 'ADD' | 'UPDATE' | null;
export type InitialChapterTypeProps =
  | QueryKeys.ROOT_CHAPTER
  | QueryKeys.SUB_CHAPTER
  | null;

export type InitialChapterAtomProps = {
  isOpen: boolean;
  action: InitialChapterActionProps;
  chapter: InitialChapterProps;
  chapterType: InitialChapterTypeProps;
};

export type InitialDocProps = Partial<Doc> | null;
export type InitialDocActionProps = 'ADD' | 'UPDATE' | null;

export type InitialDocAtomProps = {
  isOpen: boolean;
  action: InitialDocActionProps;
  doc: InitialDocProps;
};

export type InitialDocItemProps = Partial<DocItem> | null;
export type InitialDocItemActionProps = 'ADD' | 'UPDATE' | null;

export type InitialDocItemAtomProps = {
  isOpen: boolean;
  action: InitialDocItemActionProps;
  docItem: InitialDocItemProps;
};

export const initialChapterAtomProps: InitialChapterAtomProps = {
  isOpen: false,
  action: null,
  chapter: null,
  chapterType: null,
};

export const chapterEditAtom = atom<InitialChapterAtomProps>(
  initialChapterAtomProps,
);

export const initialDocAtomProps: InitialDocAtomProps = {
  isOpen: false,
  action: null,
  doc: null,
};

export const docEditAtom = atom<InitialDocAtomProps>(initialDocAtomProps);

export const initialDocItemAtomProps: InitialDocItemAtomProps = {
  isOpen: false,
  action: null,
  docItem: null,
};

export const docItemEditAtom = atom<InitialDocItemAtomProps>(
  initialDocItemAtomProps,
);

export type InitialTicketProps = Pick<Partial<Task>, 'id'> | null;
export type InitialTicketActionProps =
  | typeof ADD_TICKET
  | typeof UPDATE_TICKET
  | typeof ASSIGN_TICKET
  | null;

export type InitialTicketAtomProps = {
  isOpen: boolean;
  action: InitialTicketActionProps;
  ticket: InitialTicketProps;
};

export const initialTicketAtomProps: InitialTicketAtomProps = {
  isOpen: false,
  action: null,
  ticket: null,
};

export const selectedTicketAtom = atom<InitialTicketAtomProps>(
  initialTicketAtomProps,
);

export type InitialTaskProps = CustomTask | null;
export type InitialTaskActionProps =
  | typeof ADD_TASK
  | typeof UPDATE_TASK
  | null;

export type InitialTaskAtomProps = {
  isOpen: boolean;
  action: InitialTaskActionProps;
  task: InitialTaskProps;
};

export const initialTaskAtomProps: InitialTaskAtomProps = {
  isOpen: false,
  action: null,
  task: null,
};

export const taskEditAtom = atom<InitialTaskAtomProps>(initialTaskAtomProps);

export type InitialTicketRuleProps = Pick<Partial<TicketRule>, 'id'> | null;

export type InitialTicketRuleActionProps =
  | typeof CREATE_TICKET_RULE
  | typeof UPDATE_TICKET_RULE
  | typeof DELETE_TICKET_RULE
  | null;

export type InitialTicketRuleAtomProps = {
  isOpen: boolean;
  action: InitialTicketRuleActionProps;
  ticketRule: InitialTicketRuleProps;
};

export const initialTicketRuleAtomProps: InitialTicketRuleAtomProps = {
  isOpen: false,
  action: null,
  ticketRule: null,
};

export const selectedTicketRuleAtom = atom<InitialTicketRuleAtomProps>(
  initialTicketRuleAtomProps,
);

export const chapterDeleteAtom = atom<{
  isOpen: boolean;
  chapter: {
    id: string;
    name: string;
    version: string;
    status: StatusType;
    description: string;
    parentChapterId: string;
    rootChapterId: string;
    orderFloat?: string;
  };
}>({
  isOpen: false,
  chapter: {
    id: 'new',
    name: '',
    version: '',
    status: StatusType.PENDING,
    description: '',
    parentChapterId: '',
    rootChapterId: '',
  },
});

export const docDeleteAtom = atom<{
  isOpen: boolean;
  doc: {
    id: string;
    title: string;
    version: string;
    status: StatusType;
    description: string;
    chapterId: string;
    rootChapterId: string;
    orderFloat?: string;
  };
}>({
  isOpen: false,
  doc: {
    id: 'new',
    title: '',
    version: '',
    status: StatusType.PENDING,
    description: '',
    chapterId: '',
    rootChapterId: '',
  },
});

export const docItemDeleteAtom = atom<{
  isOpen: boolean;
  docItem: {
    id: string | null;
    version: string | null;
    status: CustomStatusType;
    title: string | null;
    description?: string | null;
    itemType: DocItemType | null;
    file?: S3ObjectInput | null;
    createdByUserId: string | null;
    lastModifiedByUserId: string | null;
    approvedByUserId?: string | null;
    rootChapterId: string | null;
    docId: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    orderFloat: string | null;
    shouldBeDeleted: boolean | null;
    tenantId: string | null;
  };
}>({
  isOpen: false,
  docItem: {
    id: null,
    version: null,
    status: null,
    title: null,
    description: null,
    itemType: null,
    file: null,
    createdByUserId: null,
    lastModifiedByUserId: null,
    approvedByUserId: null,
    rootChapterId: null,
    docId: null,
    createdAt: null,
    updatedAt: null,
    orderFloat: null,
    shouldBeDeleted: null,
    tenantId: null,
  },
});

export const referenceDocDeleteAtom = atom<{
  isOpen: boolean;
  doc: {
    id: string;
    title: string;
    version: string;
    status: StatusType;
    description: string;
    chapterId: string;
    rootChapterId: string;
    referenceDocId: string | null;
  };
}>({
  isOpen: false,
  doc: {
    id: 'new',
    title: '',
    version: '',
    status: StatusType.PENDING,
    description: '',
    chapterId: '',
    rootChapterId: '',
    referenceDocId: null,
  },
});

export const refDoctItemsAtom = atom<Partial<DocItem>[] | null>(null);

export const countAtom = atom(0);

export const nestedBreadCrumbAtom = atom('');

export const loggedInUser = atom({
  username: String,
});

export const loggedInUserId = atom<string | null>(null);

export const currentUserDetailsAtom = atom<Nullable<Partial<User>>>(null);
export const currentUserOrganizationAtom = atom<string | null>(null);
export const groupsByOrganizationAtom = atom<Partial<Group>[]>([]);
export const currentUserAccessDetailsAtom =
  atom<CurrentUserAccessDetailsAtomType | null>(null);

export const allSalesForceOrganizationsAtom = atom<
  {
    id: string;
    name: string;
  }[]
>([]);

export const userSalesForceOrganizationsAtom = atom<any[]>([]);
export const userSalesForceContractsInfoAtom = atom<{
  contracts: any[];
  products: any[];
} | null>(null);
export const allSalesForceProductsAtom = atom<any[]>([]);
export const isNoRolesUserAtom = atom(true);
export const isAdminAtom = atom<boolean>(false);
export const isTenantAdminAtom = atom<boolean>(false);

export const tenantIdAtom = atom<string | null>(
  '678eb28c-8029-4415-a7dc-a035c0751e3a',
);

export const tenantAtom = atom<Partial<Tenant> | null>({
  id: falainaTenantId,
});

export const tenantCustomSettingsAtom = atom({
  name: '',
  logoURL: '',
  color: '',
  signInImages: [''],
});
export const isLoggedinAtom = atom<boolean>(false);
export const selectedUserInfoFieldToEdit = atom<string | null>(null);
export const selectedReviewLeadInfoFieldToEdit = atom<Record<any, any>>({});

export const selectedDocContentSectionId = atom<string | null>(null);

export const showNestedSidebarPanelAtom = atom(false);
export const showDetailListForAtom = atom<string>(
  SUPPORT_LEAD.split(' ').join('-'),
);

export const allUserAccessiblePublishedProductDocsAtom = atom<
  Partial<ProductDoc>[]
>([]);

export const allUserAccessiblePublishedRootChaptersAtom = atom<
  (Partial<Chapter> | null)[]
>([]);

export const allUserAccessiblePublishedChaptersAtom = atom<Partial<Chapter>[]>(
  [],
);

export const allUserAccessiblePublishedDocsAtom = atom<Partial<Doc>[]>([]);
export const isQualifiedBySalesTeamAtom = atom<boolean>(true);
export const isApprovedBySalesDirectorAtom = atom<boolean>(true);
export const isAppUnderMaintenanceAtom = atom<boolean>(false);
export const chosenColumnsAtom = atom<string[]>([]);

export const isProductIconDeletedAtom = atom<boolean>(false);
export const isDocumentIconDeletedAtom = atom<boolean>(false);

export const isMyTaskDocPortalSearchEnabledAtom = atom<boolean>(false);
