import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import {
  CreateDocItemInput,
  DocItem,
  StatusType,
  UpdateDocItemInput,
} from 'API';

import {
  SAVED_SUCCESSFULLY,
  FAILED_TO_SAVE,
  UPDATED_SUCCESSFULLY,
  FAILED_TO_UPDATE,
  FAILED_TO_DELETE,
  DELETED_SUCCESSFULLY,
} from 'consts/alertMessages';

import useConstructStatus from 'hooks/useConstructStatus';

import {
  useCreateDocItem,
  useUpdateDocItem,
  useDeleteDocItem,
} from 'queries/docItems.mutation';

import generateToast from 'helpers/utils/generateToast';

import { WithRequiredProperty } from 'types';

type CustomUpdateDocItemInput = Partial<Omit<UpdateDocItemInput, 'id'>> &
  Pick<UpdateDocItemInput, 'id'>;

const useDocItemMutations = () => {
  const search = useLocation().search;

  const { status } = useConstructStatus({ search });

  const createDocItemMutation = useCreateDocItem();
  const updateDocItemMutation = useUpdateDocItem();
  const deleteDocItemMutation = useDeleteDocItem();

  const queryClient = useQueryClient();

  const { isLoading: isCreateLoading, isSuccess: isCreateSuccess } =
    createDocItemMutation;

  const { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess } =
    updateDocItemMutation;

  const onCreateDocItem = ({
    docItem,
    callback,
    showNotification,
    setToastMessage,
  }: {
    docItem: WithRequiredProperty<CreateDocItemInput, 'tenantId'>;
    callback: CallableFunction;
    showNotification: boolean;
    setToastMessage: (messageObject: { message: string; type: string }) => void;
  }) => {
    createDocItemMutation.mutate(docItem, {
      onSuccess: (data: any) => {
        void queryClient.invalidateQueries([
          'rootChapter',
          { id: docItem.rootChapterId },
        ]);

        void queryClient.invalidateQueries([`docVersions/${docItem.docId}`]);
        void queryClient.invalidateQueries([`docVersion/${docItem.docId}`]);
        setToastMessage({
          message: 'Auto saved successfully',
          type: 'success',
        });
        if (status === StatusType.PUBLISHED) {
          if (showNotification) {
            generateToast({
              toastContent:
                'Added successfully. You can find this document in "Edited Documents"',
              type: 'success',
            });
          }
        } else {
          generateToast({ toastContent: SAVED_SUCCESSFULLY, type: 'success' });
        }

        callback(data.data?.createDocItem);
      },
      onError: () => {
        generateToast({ toastContent: FAILED_TO_SAVE, type: 'error' });
      },
    });
  };

  const onUpdateDocItem = (
    docItem: CustomUpdateDocItemInput,
    callback: CallableFunction,
  ) => {
    updateDocItemMutation.mutate(docItem, {
      onSuccess: (data: any) => {
        void queryClient.invalidateQueries([
          'rootChapter',
          { id: docItem.rootChapterId },
        ]);

        void queryClient.invalidateQueries([`docVersions/${docItem.docId}`]);
        void queryClient.invalidateQueries([`docVersion/${docItem.docId}`]);

        if (status === StatusType.PUBLISHED) {
          generateToast({
            toastContent:
              'Updated successfully. You can find the updated document in "Edited Documents"',
            type: 'success',
          });
        } else {
          generateToast({
            toastContent: UPDATED_SUCCESSFULLY,
            type: 'success',
          });
        }

        callback(data.data.updateDocItem);
      },
      onError: () => {
        generateToast({ toastContent: FAILED_TO_UPDATE, type: 'error' });
      },
    });
  };

  const onDeleteDocItem = ({
    id,
    version,
    docId,
    setMessage,
  }: {
    id: string;
    version: string;
    docId: string;
    setMessage: any;
  }) => {
    deleteDocItemMutation.mutate(
      { id, version },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(['rootChapter']);

          queryClient.invalidateQueries([`docVersions/${docId}`]);
          queryClient.invalidateQueries([`docVersion/${docId}`]);

          generateToast({
            toastContent: DELETED_SUCCESSFULLY,
            type: 'success',
          });
        },
        onError: () => {
          generateToast({ toastContent: FAILED_TO_DELETE, type: 'error' });
        },
      },
    );
  };

  return {
    onCreateDocItem,
    onUpdateDocItem,
    onDeleteDocItem,
    isCreateLoading,
    isUpdateLoading,
    isCreateSuccess,
    isUpdateSuccess,
  };
};

export default useDocItemMutations;
