import { QueryKey } from '@tanstack/react-query';
import isString from 'lodash/isString';

import { QueryKeys } from 'atoms/modals';

const getChapterQueryKey = ({
  id = null,
  version = null,
  type = null,
}: {
  id?: string | null;
  version?: string | null;
  type?: QueryKeys.ROOT_CHAPTER | QueryKeys.SUB_CHAPTER | null;
}): QueryKey => {
  if (type === null) {
    return [];
  }

  const defaultQueryKey =
    type === QueryKeys.ROOT_CHAPTER
      ? [QueryKeys.ROOT_CHAPTER]
      : [QueryKeys.SUB_CHAPTER];

  if (isString(id) && isString(version)) {
    return [...defaultQueryKey, { id }, { version }];
  }

  if (isString(id)) {
    return [...defaultQueryKey, { id }];
  }

  if (isString(version)) {
    return [...defaultQueryKey, { version }];
  }

  return [...defaultQueryKey];
};

export const getRootChapterQueryKey = ({
  id = null,
  version = null,
}: {
  id?: string | null;
  version?: string | null;
}) => {
  const rootChapterQueryKey = getChapterQueryKey({
    id,
    version,
    type: QueryKeys.ROOT_CHAPTER,
  });

  return rootChapterQueryKey;
};

export const getSubChapterQueryKey = ({
  id = null,
  version = null,
}: {
  id?: string | null;
  version?: string | null;
}) => {
  const subChapterQueryKey = getChapterQueryKey({
    id,
    version,
    type: QueryKeys.SUB_CHAPTER,
  });

  return subChapterQueryKey;
};

export default getChapterQueryKey;
