import {
  Button,
  Text,
  Card,
  CardHeader,
  Tooltip,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { type Doc, StatusType } from 'API';

import {
  allUserAccessiblePublishedProductDocsAtom,
  allUserAccessiblePublishedRootChaptersAtom,
} from 'atoms/modals';

type CustomDoc = Partial<Doc> | null | undefined;

const DocCard = ({
  doc,
  selectedReferenceDoc,
  setSelectedReferenceDoc,
}: {
  doc: CustomDoc;
  selectedReferenceDoc: CustomDoc;
  setSelectedReferenceDoc: React.Dispatch<React.SetStateAction<CustomDoc>>;
}) => {
  const allUserAccessiblePublishedRootChapters = useAtomValue(
    allUserAccessiblePublishedRootChaptersAtom,
  );

  const allUserAccessiblePublishedProductDocs = useAtomValue(
    allUserAccessiblePublishedProductDocsAtom,
  );

  const rootChapter = allUserAccessiblePublishedRootChapters.find(
    (x) => x?.id === doc?.rootChapterId,
  );

  const productDoc = allUserAccessiblePublishedProductDocs.find(
    (x) => x.id === rootChapter?.parentProductDocId,
  );

  const handleReferenceDocSelection = useCallback(() => {
    if (!selectedReferenceDoc) {
      setSelectedReferenceDoc(doc);
    }
  }, [doc, selectedReferenceDoc, setSelectedReferenceDoc]);

  const handleResetSelectedReferenceDoc = useCallback(() => {
    setSelectedReferenceDoc(null);
  }, [setSelectedReferenceDoc]);

  return (
    <Card onClick={handleReferenceDocSelection}>
      <CardHeader
        header={
          <Tooltip content={doc?.title ?? ''} relationship="label">
            <Link
              className="text-blue-500 hover:underline"
              to={`/products/documents/${doc?.rootChapterId}/content/${doc?.id}?status=${StatusType.PUBLISHED}&view=read`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {doc?.title ?? ''}
            </Link>
          </Tooltip>
        }
        description={
          <div>
            <div className="mb-1 line-clamp-1">
              <Tooltip content={doc?.description ?? ''} relationship="label">
                <Text>{doc?.description}</Text>
              </Tooltip>
            </div>

            <div>
              <Text weight="semibold">
                Product:
                {'  '}
              </Text>

              <Tooltip content={productDoc?.name ?? ''} relationship="label">
                <Link
                  className="text-blue-500 hover:underline"
                  to={`/products/${productDoc?.id}?status=${StatusType.PUBLISHED}&view=read`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {productDoc?.name ?? ''}
                </Link>
              </Tooltip>
            </div>

            <div>
              <Text weight="semibold">
                Root Document:
                {'  '}
              </Text>

              <Tooltip content={rootChapter?.name ?? ''} relationship="label">
                <Link
                  className="text-blue-500 hover:underline"
                  to={`/products/documents/${doc?.rootChapterId}?status=${StatusType.PUBLISHED}&view=read`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {rootChapter?.name ?? ''}
                </Link>
              </Tooltip>
            </div>
          </div>
        }
        action={
          selectedReferenceDoc ? (
            <Button
              appearance="transparent"
              icon={<Dismiss24Regular />}
              aria-label="Cancel"
              onClick={handleResetSelectedReferenceDoc}
            />
          ) : null
        }
      />
    </Card>
  );
};

export default DocCard;
