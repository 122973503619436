import { useSetAtom, useAtomValue, useAtom } from 'jotai';
import qs from 'query-string';
import { useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import {
  Chapter,
  CreateChapterInput,
  CreateDocInput,
  DocItem,
  StatusType,
  TaskStatusType,
  UpdateDocInput,
} from 'API';

import { CustomDocType } from 'types/documents';

import {
  chapterEditAtom,
  docEditAtom,
  chapterDeleteAtom,
  docDeleteAtom,
  docItemDeleteAtom,
  nestedBreadCrumbAtom,
  docItemEditAtom,
  refDoctItemsAtom,
  showNestedSidebarPanelAtom,
  referenceDocDeleteAtom,
  isTenantAdminAtom,
  tenantIdAtom,
  taskEditAtom,
} from 'atoms/modals';

import falainaTenantId from 'consts/tenant';
import { ADD_TASK, UPDATE_TASK } from 'consts/ticket';

import useChapterMutations from 'hooks/useChapterMutations';
import useConstructStatus from 'hooks/useConstructStatus';
import useDocItemMutations from 'hooks/useDocItemMutations';
import useDocMutations from 'hooks/useDocMutations';
import useFeedbackMessage from 'hooks/useFeebackMessage';
import useGetCurrentAuthenticatedUser from 'hooks/useGetCurrentAuthenticatedUser';
import useGetCurrentUserAccess from 'hooks/useGetCurrentUserAccess';
import useTransformNestedSidebarDocuments from 'hooks/useTransformNestedSidebarDocuments';

type CustomCreateDocInput = Omit<CreateDocInput, 'description'> &
  Pick<CustomDocType, 'description'>;

type CustomUpdateDocInput = Partial<Omit<UpdateDocInput, 'id'>> &
  Pick<UpdateDocInput, 'id'>;

const useDocumentPage = () => {
  const search = useLocation().search;
  const navigate = useNavigate();

  const queryParams = qs.parse(search);

  const { status } = useConstructStatus({ search });

  const { documentId } = useParams();

  const tenantId = useAtomValue(tenantIdAtom);

  const [chapterEdit, setChapterEdit] = useAtom(chapterEditAtom);
  const [docEdit, setDocEdit] = useAtom(docEditAtom);
  const [docItemEdit, setDocItemEdit] = useAtom(docItemEditAtom);

  const [chapterDelete, setChapterDelete] = useAtom(chapterDeleteAtom);
  const [docDelete, setDocDelete] = useAtom(docDeleteAtom);
  const [docItemDelete, setDocItemDelete] = useAtom(docItemDeleteAtom);
  const [referenceDocDelete, setReferenceDocDelete] = useAtom(
    referenceDocDeleteAtom,
  );
  const setTaskAtomValue = useSetAtom(taskEditAtom);
  const [breadCrumbString, setBreadCrumbString] = useAtom(nestedBreadCrumbAtom);

  const isTenantAdmin = useAtomValue(isTenantAdminAtom);

  const setShowNestedSidebarPanelView = useSetAtom(showNestedSidebarPanelAtom);
  const setRefDocItems = useSetAtom(refDoctItemsAtom);

  const currentAuthenticatedUser = useGetCurrentAuthenticatedUser();

  const {
    isInitialLoading,
    isError,
    allRootChapterVersions,
    rootChapter,
    filteredSubChapters,
    filteredDocs,
    flattenedDocuments,
    documentsMap,
  } = useTransformNestedSidebarDocuments();

  const isPendingRootChapterVersionPresent = allRootChapterVersions.some(
    (x) => x?.status === StatusType.PENDING,
  );

  const {
    hasCreateProductAccess,
    hasApproveProductAccess,
    hasReadProductAccess,
  } = useGetCurrentUserAccess({
    productDocId: rootChapter?.parentProductDocId || '',
  });

  const hasAccess =
    isTenantAdmin ||
    !!hasReadProductAccess ||
    !!hasCreateProductAccess ||
    !!hasApproveProductAccess;

  const isReader =
    !!hasReadProductAccess &&
    !(hasCreateProductAccess || hasApproveProductAccess || isTenantAdmin);

  useEffect(() => {
    if (isReader && rootChapter?.status !== StatusType.PUBLISHED) {
      const latestPublishedRootChapterVersion = allRootChapterVersions.find(
        (x) => x?.status === StatusType.PUBLISHED,
      );

      if (latestPublishedRootChapterVersion) {
        const newQueries = {
          ...queryParams,
          version: latestPublishedRootChapterVersion.version,
          status: StatusType.PUBLISHED,
        };

        navigate({ search: qs.stringify(newQueries) });
      }
    }
  }, [allRootChapterVersions, navigate, isReader, queryParams, rootChapter]);

  const [message, setMessage, { setToastMessage }] = useFeedbackMessage();

  const { onDeleteChapter, onCreateChapter } = useChapterMutations();

  const { onDeleteDoc, onCreateDoc, onUpdateDoc } = useDocMutations();

  const { onDeleteDocItem, onCreateDocItem } = useDocItemMutations();

  const closeChapterDeleteModal = () => {
    setChapterDelete({ ...chapterDelete, isOpen: false });
  };

  const closeDocDeleteModal = () => {
    setDocDelete({ ...docDelete, isOpen: false });
  };

  const closeReferenceDocDeleteModal = () => {
    setReferenceDocDelete({ ...referenceDocDelete, isOpen: false });
  };

  const closeDocItemDeleteModal = () => {
    setDocItemDelete({ ...docItemDelete, isOpen: false });
  };

  const handleCreateNewRootChapterVersion = (callback: CallableFunction) => {
    const newChapter = {
      ...rootChapter!,
      version: rootChapter?.version
        ? String(Number(rootChapter.version) + 1)
        : '2',
      status: StatusType.PENDING,
      lastModifiedByUserId: currentAuthenticatedUser?.sub ?? '',
      tenantId: rootChapter?.tenantId || falainaTenantId,
    };

    onCreateChapter({
      chapter: newChapter,
      isParentProduct: true,
      callback,
      showNotification: true,
    });
  };

  const onChapterDelete = () => {
    const noneValue = 'none';

    const parentObj = {
      parentChapterId: chapterDelete.chapter.parentChapterId || noneValue,
      parentProductDocId: noneValue,
      rootChapterId: chapterDelete.chapter.rootChapterId || noneValue,
    };

    if (currentAuthenticatedUser) {
      if (
        status === StatusType.PUBLISHED &&
        chapterDelete.chapter.status === StatusType.PUBLISHED
      ) {
        const final: CreateChapterInput = {
          name: chapterDelete.chapter.name,
          description: chapterDelete.chapter.description,
          ...parentObj,
          id: chapterDelete.chapter.id,
          version: chapterDelete.chapter.version
            ? String(Number(chapterDelete.chapter.version) + 1)
            : '2',
          status: StatusType.PENDING,
          lastModifiedByUserId: currentAuthenticatedUser.sub ?? '',
          createdByUserId: currentAuthenticatedUser.sub ?? '',
          shouldBeDeleted: true,
          orderFloat: chapterDelete.chapter.orderFloat,
          tenantId: tenantId!,
        };

        onCreateChapter({
          chapter: {
            ...final,
            tenantId: falainaTenantId,
          },
          isParentProduct: false,
          callback: () => {
            if (isPendingRootChapterVersionPresent) {
              closeChapterDeleteModal();
            } else {
              handleCreateNewRootChapterVersion(closeChapterDeleteModal);
            }
          },
          showNotification: !!isPendingRootChapterVersionPresent,
        });
      }

      if (status === StatusType.PENDING) {
        if (chapterDelete.chapter.status === StatusType.PUBLISHED) {
          const final: CreateChapterInput = {
            name: chapterDelete.chapter.name,
            description: chapterDelete.chapter.description,
            ...parentObj,
            id: chapterDelete.chapter.id,
            version: chapterDelete.chapter.version
              ? String(Number(chapterDelete.chapter.version) + 1)
              : '2',
            status: StatusType.PENDING,
            lastModifiedByUserId: currentAuthenticatedUser.sub ?? '',
            createdByUserId: currentAuthenticatedUser.sub ?? '',
            shouldBeDeleted: true,
            orderFloat: chapterDelete.chapter.orderFloat,
            tenantId: tenantId!,
          };

          onCreateChapter({
            chapter: {
              ...final,
              tenantId: falainaTenantId,
            },
            isParentProduct: false,
            callback: closeChapterDeleteModal,
            showNotification: true,
          });
        }

        if (chapterDelete.chapter.status === StatusType.PENDING) {
          onDeleteChapter({
            id: chapterDelete.chapter.id,
            version: chapterDelete.chapter.version,
          });
          closeChapterDeleteModal();
        }
      }
    }
  };

  const onDocDelete = () => {
    const noneValue = 'none';

    const parentObj = {
      chapterId: docDelete.doc.chapterId || noneValue,
      rootChapterId: docDelete.doc.rootChapterId || noneValue,
    };

    if (currentAuthenticatedUser) {
      if (
        status === StatusType.PUBLISHED &&
        docDelete.doc.status === StatusType.PUBLISHED
      ) {
        const final: CustomCreateDocInput = {
          title: docDelete.doc.title,
          description: docDelete.doc.description,
          ...parentObj,
          id: docDelete.doc.id,
          version: docDelete.doc.version
            ? String(Number(docDelete.doc.version) + 1)
            : '2',
          status: StatusType.PENDING,
          lastModifiedByUserId: currentAuthenticatedUser.sub ?? '',
          createdByUserId: currentAuthenticatedUser.sub ?? '',
          shouldBeDeleted: true,
          orderFloat: docDelete.doc.orderFloat,
          tenantId: tenantId!,
        };

        onCreateDoc(
          {
            ...final,
            tenantId: falainaTenantId,
          },
          () => {
            if (isPendingRootChapterVersionPresent) {
              closeDocDeleteModal();
            } else {
              handleCreateNewRootChapterVersion(closeDocDeleteModal);
            }
          },
          !!isPendingRootChapterVersionPresent,
          setToastMessage,
        );
      }

      if (status === StatusType.PENDING) {
        if (docDelete.doc.status === StatusType.PUBLISHED) {
          const final: CustomCreateDocInput = {
            title: docDelete.doc.title,
            description: docDelete.doc.description,
            ...parentObj,
            id: docDelete.doc.id,
            version: docDelete.doc.version
              ? String(Number(docDelete.doc.version) + 1)
              : '2',
            status: StatusType.PENDING,
            lastModifiedByUserId: currentAuthenticatedUser.sub ?? '',
            createdByUserId: currentAuthenticatedUser.sub ?? '',
            shouldBeDeleted: true,
            orderFloat: docDelete.doc.orderFloat,
            tenantId: tenantId!,
          };

          onCreateDoc(
            {
              ...final,
              tenantId: falainaTenantId,
            },
            closeDocDeleteModal,
            true,
            setToastMessage,
          );
        }

        if (docDelete.doc.status === StatusType.PENDING) {
          onDeleteDoc({
            id: docDelete.doc.id,
            version: docDelete.doc.version,
            setMessage: setToastMessage,
          });
          closeDocDeleteModal();
        }
      }
    }
  };

  const onReferenceDocDelete = () => {
    const noneValue = 'none';

    const parentObj = {
      chapterId: referenceDocDelete.doc.chapterId || noneValue,
      rootChapterId: referenceDocDelete.doc.rootChapterId || noneValue,
    };

    if (currentAuthenticatedUser) {
      if (
        status === StatusType.PUBLISHED &&
        referenceDocDelete.doc.status === StatusType.PUBLISHED
      ) {
        const final: CustomCreateDocInput = {
          title: referenceDocDelete.doc.title,
          description: referenceDocDelete.doc.description,
          ...parentObj,
          id: referenceDocDelete.doc.id,
          version: referenceDocDelete.doc.version
            ? String(Number(referenceDocDelete.doc.version) + 1)
            : '2',
          status: StatusType.PENDING,
          lastModifiedByUserId: currentAuthenticatedUser.sub ?? '',
          createdByUserId: currentAuthenticatedUser.sub ?? '',
          referenceDocId: null,
          tenantId: tenantId!,
        };

        onCreateDoc(
          {
            ...final,
            tenantId: falainaTenantId,
          },
          () => {
            if (isPendingRootChapterVersionPresent) {
              closeReferenceDocDeleteModal();
            } else {
              handleCreateNewRootChapterVersion(closeReferenceDocDeleteModal);
            }
          },
          !!isPendingRootChapterVersionPresent,
          setToastMessage,
        );
      }

      if (status === StatusType.PENDING) {
        if (referenceDocDelete.doc.status === StatusType.PUBLISHED) {
          const final: CustomCreateDocInput = {
            title: referenceDocDelete.doc.title,
            description: referenceDocDelete.doc.description,
            ...parentObj,
            id: referenceDocDelete.doc.id,
            version: referenceDocDelete.doc.version
              ? String(Number(referenceDocDelete.doc.version) + 1)
              : '2',
            status: StatusType.PENDING,
            lastModifiedByUserId: currentAuthenticatedUser.sub ?? '',
            createdByUserId: currentAuthenticatedUser.sub ?? '',
            referenceDocId: null,
            tenantId: tenantId!,
          };

          onCreateDoc(
            {
              ...final,
              tenantId: falainaTenantId,
            },
            closeReferenceDocDeleteModal,
            true,
            setToastMessage,
          );
        }

        if (referenceDocDelete.doc.status === StatusType.PENDING) {
          const final: CustomUpdateDocInput = {
            id: referenceDocDelete.doc.id,
            version: referenceDocDelete.doc.version,
            lastModifiedByUserId: currentAuthenticatedUser.sub ?? '',
            referenceDocId: null,
            ...parentObj,
          };

          onUpdateDoc(
            { ...final, tenantId: falainaTenantId },
            closeReferenceDocDeleteModal,
            setToastMessage,
          );
        }
      }
    }

    setRefDocItems(null);
  };

  const onDocItemDelete = () => {
    const docItem = docItemDelete.docItem as Partial<DocItem | null>;

    if (docItem?.status === StatusType.PUBLISHED) {
      const newDocItem = {
        ...(docItem as DocItem),
        version: docItem.version ? String(Number(docItem.version) + 1) : '2',
        status: StatusType.PENDING,
        lastModifiedByUserId: currentAuthenticatedUser?.sub ?? '',
        shouldBeDeleted: true,
        tenantId: docItem.tenantId || falainaTenantId,
      };

      if (status === StatusType.PUBLISHED) {
        onCreateDocItem({
          docItem: newDocItem,
          callback: () => {
            if (isPendingRootChapterVersionPresent) {
              closeDocItemDeleteModal();
            } else {
              handleCreateNewRootChapterVersion(closeDocItemDeleteModal);
            }
          },
          showNotification: !!isPendingRootChapterVersionPresent,
          setToastMessage,
        });
      }

      if (status === StatusType.PENDING) {
        onCreateDocItem({
          docItem: newDocItem,
          callback: closeDocItemDeleteModal,
          showNotification: true,
          setToastMessage,
        });
      }
    }

    if (
      docItem?.status === StatusType.PENDING &&
      status === StatusType.PENDING
    ) {
      onDeleteDocItem({
        id: docItem.id!,
        version: docItem.version!,
        docId: docItem.docId!,
        setMessage: setToastMessage,
      });

      closeDocItemDeleteModal();
    }
  };

  const handleCrumbClick = (b: string) => {
    const precedingBreadCrumbStr = breadCrumbString.split(b)[0];
    const activeBreadCrumbStr = precedingBreadCrumbStr + b;
    setBreadCrumbString(activeBreadCrumbStr);
  };

  const breadCrumbs = breadCrumbString.split('[#!~*^&NESTED----CHAP&^*~!#]');

  const openSubmitForApprovalSlider = () => {
    if (rootChapter) {
      const currentChapterTask = rootChapter.currentTask;

      if (currentChapterTask) {
        setTaskAtomValue({
          task: {
            id: currentChapterTask.id,
            name: currentChapterTask.name,
            description: currentChapterTask.description,
            status: currentChapterTask.status,
            requestedByUserId: currentChapterTask.requestedByUserId,
            rootChapterId: currentChapterTask.rootChapterId,
            rootChapterVersion: currentChapterTask.rootChapterVersion,
          },
          isOpen: true,
          action: UPDATE_TASK,
        });
      } else {
        setTaskAtomValue({
          task: {
            id: 'new',
            name: '',
            description: '',
            status: TaskStatusType.PENDING,
            requestedByUserId: '',
            rootChapterId: rootChapter.id,
            rootChapterVersion: rootChapter.version,
          },
          isOpen: true,
          action: ADD_TASK,
        });
      }
    }
  };

  return {
    hasAccess,

    isInitialLoading,
    isError,
    status,

    hasCreateProductAccess,

    breadCrumbString,

    breadCrumbs,

    documentId,

    handleCrumbClick,

    message,

    setMessage,

    setToastMessage,

    setShowNestedSidebarPanelView,

    rootChapter,

    filteredSubChapters,

    filteredDocs,

    flattenedDocuments,

    documentsMap,

    allRootChapterVersions,

    openSubmitForApprovalSlider,

    chapterDelete,

    closeChapterDeleteModal,

    onChapterDelete,

    docDelete,

    closeDocDeleteModal,

    onDocDelete,

    referenceDocDelete,

    closeReferenceDocDeleteModal,

    onReferenceDocDelete,

    docItemDelete,

    closeDocItemDeleteModal,

    onDocItemDelete,
  };
};

export default useDocumentPage;
