import type { GraphQLQuery } from '@aws-amplify/api';
import type {
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useMemo, useEffect } from 'react';

import type {
  CustomListGroupsQuery,
  CustomGetGroupQuery,
  Group,
  ModelGroupFilterInput,
} from 'API';

import { tenantAtom } from 'atoms/modals';

import { customGetGroup, customListGroups } from 'graphql/custom-queries';

import getNonNullableList from 'helpers/utils/getNonNullableList';
import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseGroupsType =
  | ({
      filter?: ModelGroupFilterInput | null;
    } & UseInfiniteQueryOptions)
  | null
  | void;

type IUseGroupType = {
  id: string | null;
} & UseQueryOptions;

export const useGroupsSource = (props: IUseGroupsType = {}) => {
  const filter = props?.filter ?? null;

  const queryKey = props?.queryKey ?? [];

  const enabled = props?.enabled ?? true;

  const staleTime =
    props?.staleTime ?? toMilliseconds({ hours: 0, minutes: 0, seconds: 20 }); // 20secs

  return useInfiniteQuery({
    queryKey: ['groups', filter, ...queryKey],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<GraphQLQuery<CustomListGroupsQuery>>({
        query: customListGroups,
        variables: filter
          ? { filter, limit: 1_000_000, nextToken: pageParam }
          : { limit: 1_000_000, nextToken: pageParam },
      });
    },
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.listGroups?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
    enabled,
    staleTime,
  });
};

const useGroups = (props: IUseGroupsType = {}) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const filter = props?.filter ?? null;

  const enabled = (props?.enabled ?? true) && !!tenantId;

  const defaultFilter: ModelGroupFilterInput | null = tenantId
    ? {
        tenantId: {
          eq: tenantId,
        },
      }
    : null;

  const sourceFilter: ModelGroupFilterInput | null = tenantId
    ? filter
      ? { ...filter, ...defaultFilter }
      : { ...defaultFilter }
    : null;

  const result = useGroupsSource({
    ...props,
    filter: sourceFilter,
    enabled,
  });

  const groupsSource = useMemo(() => {
    const pages = result.data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.listGroups?.items),
    );

    return tempItems as Partial<Group>[];
  }, [result.data?.pages]);

  return {
    groupsSource,
    ...result,
  };
};

export const useInfiniteGroups = (props: IUseGroupsType = {}) => {
  const result = useGroups(props);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = result;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      try {
        void fetchNextPage();
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return result;
};

export const useGroupSource = (props: IUseGroupType) => {
  const queryKey = props.queryKey ?? [];

  const enabled = props.enabled ?? true;

  const staleTime =
    props.staleTime ?? toMilliseconds({ hours: 0, minutes: 0, seconds: 20 }); // 20secs

  return useQuery({
    queryKey: ['groups', { id: props.id }, ...queryKey],
    queryFn: () => {
      return API.graphql<GraphQLQuery<CustomGetGroupQuery>>({
        query: customGetGroup,
        variables: { id: props.id },
      });
    },
    enabled,
    staleTime,
  });
};

export const useGroup = (props: IUseGroupType) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const id = props.id;

  const enabled = (props.enabled ?? true) && !!tenantId && !!id;

  const result = useGroupSource({
    ...props,
    id,
    enabled,
  });

  const groupSource = useMemo(() => {
    return (result.data?.data?.getGroup ?? null) as Partial<Group> | null;
  }, [result.data?.data?.getGroup]);

  return {
    groupSource,
    ...result,
  };
};

export default useGroups;
