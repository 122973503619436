import flatMapDeep from 'lodash/flatMapDeep';
import { useLocation, useParams } from 'react-router-dom';

import { Chapter, Doc, StatusType } from 'API';

import { type CustomStatusType } from 'types/documents';
import { type DocType } from 'types/Pages/doc';
import {
  type CurrentDocumentType,
  type DocumentsMapType,
} from 'types/Pages/Products';

import useConstructStatus from 'hooks/useConstructStatus';

import useNestedSideBarQuery from 'queries/nestedSidebar.query';

import sortDocuments from 'helpers/utils/sortDocuments';

import { type Nullable } from 'types';
import { useInfiniteChaptersUnderRootChapter } from './useSubChaptersUnderRootChapter';
import falainaTenantId from 'consts/tenant';
import { useInfiniteDocsUnderRootChapter } from './useDocsUnderRootChapter';
import { uniqBy } from 'lodash';
import { useInfiniteDocItemsUnderRootChapter } from './useDocsItemsUnderRootChapter';
import { useMemo } from 'react';

const buildTreeArray = (chapters: Partial<Chapter>[], docs: Partial<Doc>[]) => {
  // Use reduce to create a nodeMap
  const nodeMap = chapters.reduce((acc: Record<string, any>, item) => {
    acc[`${item?.id}-${item.version}`] = {
      ...item,
      subChapters: {
        items: [],
      },
      docs: {
        items: [],
      },
    };
    return acc;
  }, {});

  // Recursive function to build nodes
  const buildNode = (id: string, version: string) => {
    const node = nodeMap[`${id}-${version}`];
    if (!node) return null;

    node.docs.items = docs.filter((doc) => doc.chapterId === id);

    // Filter chapters for items with parentChapterId === id
    const subChapters = chapters.filter((item) => item.parentChapterId === id);
    node.subChapters.items = subChapters.map((item) =>
      buildNode(item.id as string, item.version as string),
    ); // BUG: PENDING docs replaced with duplicate published doc;

    return node;
  };

  // Filter chapters for root nodes
  return (
    chapters
      // .filter((item) => !item.parentChapterId)
      .map((item) => buildNode(item.id as string, item.version as string))
  );
};

const getUniqueDocuments = <T>(documents: T[] = []) => {
  return uniqBy(documents, (x: any) => x?.id || x?.version);
};

const useTransformNestedSidebarDocuments = () => {
  const { documentId } = useParams();

  const search = useLocation().search;
  const { status } = useConstructStatus({ search });

  const { chaptersUnderRootChapterSource } =
    useInfiniteChaptersUnderRootChapter({
      enabled: !!documentId,
      rootChapterId: documentId!!,
      filter: {
        status: { ne: StatusType.ARCHIVED },
        tenantId: { eq: falainaTenantId },
      },
    });

  const { docsUnderRootChapterSource } = useInfiniteDocsUnderRootChapter({
    enabled: !!documentId,
    rootChapterId: documentId!!,
    filter: {
      status: { ne: StatusType.ARCHIVED },
      tenantId: { eq: falainaTenantId },
    },
  });

  const { docItemsUnderRootChapterSource } =
    useInfiniteDocItemsUnderRootChapter({
      enabled: !!documentId,
      rootChapterId: documentId!!,
      filter: {
        status: { ne: StatusType.ARCHIVED },
        tenantId: { eq: falainaTenantId },
      },
    });

  const {
    data: rootChapterData,
    isInitialLoading,
    isError,
  } = useNestedSideBarQuery({
    id: documentId!,
  });

  const tempRootChapterVersions = useMemo(
    () =>
      (rootChapterData?.data?.listChapters?.items ?? []) as Nullable<Chapter>[],
    [rootChapterData],
  );

  const tempRootChapter = useMemo(
    () =>
      (tempRootChapterVersions.find((x) => x?.status === status) ??
        null) as Chapter,
    [tempRootChapterVersions, status],
  );

  const chaptersUnderRootChapter = useMemo(
    () => chaptersUnderRootChapterSource,
    [chaptersUnderRootChapterSource],
  );

  const docItemsUnderRootChapter = useMemo(
    () => docItemsUnderRootChapterSource,
    [docItemsUnderRootChapterSource],
  );

  const docsUnderRootChapter = useMemo(
    () =>
      docsUnderRootChapterSource.map(
        (x) =>
          ({
            ...x,
            docItems: {
              items: docItemsUnderRootChapter.filter((y) => y.docId === x.id),
            },
          }) as Doc,
      ),
    [docsUnderRootChapterSource, docItemsUnderRootChapter],
  );

  const modifiedChapters = useMemo(() => {
    return chaptersUnderRootChapter.flatMap((x) => {
      if (x.parentChapterId === documentId || x.parentChapterId === 'none') {
        return [
          {
            ...x,
            parentChapterId: documentId,
          },
        ];
      }

      return [x];
    });
  }, [documentId, chaptersUnderRootChapter]);

  const chapters = useMemo(() => {
    const tempChapters = [tempRootChapter, ...modifiedChapters];

    if (tempRootChapter) {
      return buildTreeArray(tempChapters, docsUnderRootChapter) as Chapter[];
    }

    return [] as Chapter[];
  }, [tempRootChapter, modifiedChapters, docsUnderRootChapter]);

  const rootChapter = chapters.find((x) => x?.id === documentId) ?? null;

  const subChapters = rootChapter?.subChapters?.items || [];

  const docs = rootChapter?.docs?.items || [];

  const rootChapterVersions = (rootChapterData?.data?.listChapters?.items ??
    []) as Nullable<Chapter>[];

  const allRootChapterVersions = rootChapterVersions.map((x) => ({
    ...x,
    subChapters: { items: subChapters as Partial<Chapter>[] },
    docs: { items: docs as Partial<Doc>[] },
  })) as Nullable<Chapter>[];

  const flatten = (item: any): any => {
    return [
      item,
      item?.docs?.items || [],
      flatMapDeep(item?.subChapters?.items || [], flatten),
    ];
  };

  const flattenedDocuments = flatMapDeep(subChapters, flatten);
  const documentsMap: DocumentsMapType = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const document of [...flattenedDocuments, ...docs]) {
    if (documentsMap[document.id]) {
      if (
        [
          StatusType.PUBLISHED,
          StatusType.PENDING,
          StatusType.PENDING_APPROVAL,
        ].includes(document.status as StatusType)
      ) {
        const currentdocumentsMapItems = documentsMap[document.id] || [];

        if (
          !currentdocumentsMapItems.some(
            (item: any) => item.status === document.status,
          )
        ) {
          documentsMap[document.id] = [
            ...(documentsMap[document.id] || []),
            document,
          ];
        }
      } else {
        documentsMap[document.id] = [
          ...(documentsMap[document.id] || []),
          document,
        ];
      }
    } else {
      documentsMap[document.id] = [document];
    }
  }

  const flatFilterDocuments = (arr: Partial<Chapter | null>[]) => {
    // Very important: Must deep clone to avoid muatating original data structure in any way.
    return (
      arr
        /* 
          Note: Can use arr.map (enough for our scenario) to do somewhat of a deep clone without having to rely on lodash cloneDeep or JSON.parse(JSON.stringify(arr)) 
        */

        .map((item: any) => ({
          ...item,
          // subChapters: { items: [...item.subChapters.items] },
          subChapters: {
            items: item?.subChapters?.items ? [...item.subChapters.items] : [],
          },
          docs: { items: [...item.docs.items] },
        }))
        .filter((o: any) => {
          const obj = o;

          if (obj.subChapters.items) {
            obj.subChapters.items = flatFilterDocuments(obj.subChapters.items);
          }

          if (status === StatusType.PUBLISHED) {
            const shouldKeep = obj.status === StatusType.PUBLISHED;

            obj.docs.items = obj.docs.items.filter(
              (item: Doc) => item.status === StatusType.PUBLISHED,
            );

            return shouldKeep;
          }

          if (
            status === StatusType.PENDING ||
            status === StatusType.PENDING_APPROVAL
          ) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (documentsMap[obj.id]) {
              const tempItems = [...(documentsMap[obj.id] || [])];

              const validDocsInSubChapter = obj.docs.items.filter(
                (item: DocType) => {
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  if (documentsMap[obj.id]) {
                    const tempDocItems = [...(documentsMap[item.id] || [])];

                    if (item.status === StatusType.PUBLISHED) {
                      // If there is a pending version for an already existing published version, we show the pending version instead of the published version.
                      // Otherwise, we show the published version directly.
                      const shouldKeepDoc = !tempDocItems.some(
                        (i) => i.status === StatusType.PENDING,
                      );

                      return shouldKeepDoc;
                    }

                    if (
                      item.status === StatusType.PENDING ||
                      item.status === StatusType.PENDING_APPROVAL
                    ) {
                      return true;
                    }

                    return false;
                  }

                  return false;
                },
              );

              obj.docs.items = validDocsInSubChapter;

              if (obj.status === StatusType.PUBLISHED) {
                // If there is a pending version for an already existing published version, we show the pending version instead of the published version.
                // Otherwise, we show the published version directly.
                const shouldKeep = !tempItems.some(
                  (item) => item.status === StatusType.PENDING,
                );

                return shouldKeep;
              }

              if (
                obj.status === StatusType.PENDING ||
                obj.status === StatusType.PENDING_APPROVAL
              ) {
                return true;
              }

              return false;
            }

            return false;
          }

          return false;
        })
    );
  };

  // const t0 = performance.now();
  const filteredSubChapters = flatFilterDocuments(subChapters);
  // const t1 = performance.now();

  /* 
    lodash cloneDeep is the slowest way to deep clone objects but definitely the most reliable.
  */

  // const filteredSubChapters = flatFilterDocuments(cloneDeep(subChapters));

  /* 
    Fast cloning with data loss - JSON.parse/stringify

    JSON.parse(JSON.stringify(object)) is absolutely the fastest way to "deep" clone objects but there will be gotchas. https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
    
    JSON.parse(JSON.stringify(object)) is a very simple one liner to deep clone an object as long as your object does not contain Dates, functions, undefined, Infinity, RegExps, Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, Typed Arrays or other complex types.
  */

  // const filteredSubChapters = flatFilterDocuments(JSON.parse(JSON.stringify(subChapters)));

  // console.log(`Call to flatFilterDocuments took ${t1 - t0} milliseconds.`);

  const filteredDocs = docs.filter((item) => {
    if (status === StatusType.PUBLISHED) {
      const shouldKeep = item?.status === StatusType.PUBLISHED;

      return shouldKeep;
    }

    if (
      status === StatusType.PENDING ||
      status === StatusType.PENDING_APPROVAL
    ) {
      if (item?.id && documentsMap[item.id]) {
        const tempItems = [...(documentsMap[item.id] ?? [])];

        if ((item.status as CustomStatusType) === StatusType.PUBLISHED) {
          // If there is a pending version for an already existing published version, we show the pending version instead of the published version.
          // Otherwise, we show the published version directly.
          const shouldKeep = !tempItems.some(
            (i) => i.status === StatusType.PENDING,
          );

          return shouldKeep;
        }

        if (
          (item.status as CustomStatusType) === StatusType.PENDING ||
          (item.status as CustomStatusType) === StatusType.PENDING_APPROVAL
        ) {
          return true;
        }

        return false;
      }

      return false;
    }

    return false;
  });

  const sortedFilteredSubChapters: any[] = sortDocuments({
    documents: filteredSubChapters,
    documentsMap,
  });

  const sortedFilteredDocs: any[] = sortDocuments({
    documents: filteredDocs as Nullable<CurrentDocumentType>[],
    documentsMap,
  });

  return {
    isInitialLoading,
    isError,
    allRootChapterVersions,
    rootChapter,
    subChapters,
    docs,
    filteredSubChapters: sortedFilteredSubChapters,
    filteredDocs: sortedFilteredDocs,
    flattenedDocuments,
    documentsMap,
  };
};

export default useTransformNestedSidebarDocuments;
