import type { GraphQLQuery } from '@aws-amplify/api';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { CustomGetProductDocQuery } from 'API';

import { customGetProductDoc } from 'graphql/custom-queries';

import getProductDocQueryKey from 'helpers/utils/getProductDocQueryKey';

type UseDocumentListPropsType = {
  productDocId: string;
  enabled?: boolean;
};

const useProductDoc = ({
  productDocId: id,
  enabled = true,
}: UseDocumentListPropsType) => {
  return useQuery({
    queryKey: getProductDocQueryKey({ id }),
    queryFn: () =>
      API.graphql<GraphQLQuery<CustomGetProductDocQuery>>({
        query: customGetProductDoc,
        variables: { id, version: '1' },
      }),
    enabled,
  });
};

export default useProductDoc;
