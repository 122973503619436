import { Button } from '@fluentui/react-components';
import { TextBulletList20Filled } from '@fluentui/react-icons';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Link, useLocation, Outlet, useOutletContext } from 'react-router-dom';

import ChapterSliderForm from 'components/ChapterSliderForm';
import DeletePanel from 'components/DeletePanel';
import DocSliderForm from 'components/DocSliderForm';
import ErrorFallback from 'components/ErrorFallback';
import FeedbackMessage from 'components/FeedbackMessage';
import Flex from 'components/Flex';
import HomeLoader from 'components/Loader/HomeLoader';
import NestedSideBar from 'components/NestedSideBar';
import DocItemForm from 'components/Pages/Documents/DocItemForm';
import SubmitForApprovalButton from 'components/Pages/Documents/SubmitForApprovalButton';
import SubmitForApprovalForm from 'components/SubmitForApprovalForm';

import { Chapter as DefaultChapterType, StatusType } from 'API';

import { DocumentPageType } from 'types/documents';

import {
  chapterEditAtom,
  docEditAtom,
  docItemEditAtom,
  taskEditAtom,
} from 'atoms/modals';

import useConstructStatus from 'hooks/useConstructStatus';
import useDocumentPage from 'hooks/useDocumentPage';

export type OutletContextType = DocumentPageType & {
  hasCreateProductAccess: boolean;
  setMessage: (messageObject: any) => void;
};

export const useDocumentOutlet = () => useOutletContext<OutletContextType>();

const isValid = ({
  document,
  status,
}: {
  document: Partial<DefaultChapterType | null>;
  status: StatusType | null;
}) => {
  if (status === StatusType.PUBLISHED) {
    return document?.status === StatusType.PUBLISHED;
  }

  if (status === StatusType.PENDING) {
    return (
      document?.status === StatusType.PUBLISHED ||
      document?.status === StatusType.PENDING
    );
  }

  if (status === StatusType.PENDING_APPROVAL) {
    return document?.status === StatusType.PENDING_APPROVAL;
  }

  return document?.status === StatusType.PUBLISHED;
};

const BreadCrumb = ({
  breadCrumbs,
  handleClick,
  documentId,
}: {
  breadCrumbs: string[];
  handleClick: (b: string) => void;
  documentId: string;
}) => {
  const search = useLocation().search;

  const { status } = useConstructStatus({ search });

  if (breadCrumbs.length > 0) {
    return (
      <>
        {breadCrumbs.map((b, index) => {
          if (b) {
            const [name, version, id] = b.split('[#!NAME-ID!#]');

            let toUrl = status
              ? `/products/documents/${documentId}/subChapter/${id}?status=${status}${
                  search.split('view=')[1] ? '&view=read' : ''
                }`
              : `/products/documents/${documentId}/subChapter/${id}?${
                  search.split('view=')[1] ? 'view=read' : ''
                }`;

            // The first item in the breadCrumbs will have the details of the parentProduct. The rootChapter belongs under this parentProduct.
            if (index === 0) {
              toUrl = status
                ? `/products/${version}?status=${status}${
                    search.split('view=')[1] ? '&view=read' : ''
                  }`
                : `/products/${version}?${
                    search.split('view=')[1] ? 'view=read' : ''
                  }`;
            }

            // The second item in the breadCrumbs will have the details of the rootChapter to which all other chapters / docs belong.
            if (index === 1) {
              toUrl = status
                ? `/products/documents/${documentId}?status=${status}${
                    search.split('view=')[1] ? '&view=read' : ''
                  }`
                : `/products/documents/${documentId}${
                    search.split('view=')[1] ? '?view=read' : ''
                  }`;
            }

            return (
              <li className="flex font-microsoft-3 " key={b}>
                <div className="flex items-center lg:bg-transparent md:bg-transparent sm: bg-gray-fl-21">
                  {breadCrumbs.length - 1 === index ? (
                    <div className=" group w-full flex items-center font-normal text-sm text-gray-fl-19">
                      {`${name}`}
                    </div>
                  ) : (
                    <Link
                      to={toUrl}
                      className={`group  w-full flex items-center py-1 font-normal text-sm  ${
                        index === 0 ? 'text-blue-db-18' : 'text-gray-fl-19'
                      }`}
                      onClick={() => {
                        handleClick(b);
                      }}
                    >
                      {name}
                    </Link>
                  )}
                  {breadCrumbs.length - 1 !== index && (
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-gray-300  lg:mr-0 md:mr-0 sm: mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                  )}
                </div>
              </li>
            );
          }

          return null;
        })}
      </>
    );
  }

  return null;
};

const Document = () => {
  const {
    hasAccess,

    isInitialLoading,
    isError,

    status,

    hasCreateProductAccess,

    breadCrumbString,

    breadCrumbs,

    documentId,

    handleCrumbClick,

    message,

    setToastMessage,

    setShowNestedSidebarPanelView,

    rootChapter,

    filteredSubChapters,

    filteredDocs,

    flattenedDocuments,

    documentsMap,

    allRootChapterVersions,

    openSubmitForApprovalSlider,

    chapterDelete,

    closeChapterDeleteModal,

    onChapterDelete,

    docDelete,

    closeDocDeleteModal,

    onDocDelete,

    referenceDocDelete,

    closeReferenceDocDeleteModal,

    onReferenceDocDelete,

    docItemDelete,

    closeDocItemDeleteModal,

    onDocItemDelete,
  } = useDocumentPage();

  const chapterAtomValue = useAtomValue(chapterEditAtom);
  const docAtomValue = useAtomValue(docEditAtom);
  const docItemAtomValue = useAtomValue(docItemEditAtom);
  const taskAtomValue = useAtomValue(taskEditAtom);

  const { pathname } = useLocation();

  const outletContext: OutletContextType = {
    rootChapter,
    allRootChapterVersions,
    documentsMap,
    hasCreateProductAccess: hasCreateProductAccess || false,
    setMessage: setToastMessage,
  };

  const myErrorHandler = useCallback(
    (error: Error, info: { componentStack: string }) => {
      // Do something with the error
      // E.g. log to an error logging client here

      console.error(error, info.componentStack);
    },
    [],
  );

  const handleOpenNestedSidebarPanel = useCallback(() => {
    setShowNestedSidebarPanelView(true);
  }, [setShowNestedSidebarPanelView]);

  if (isError) {
    return <div>Something went wrong</div>;
  }

  if (isInitialLoading) {
    return <HomeLoader />;
  }

  if (!rootChapter) {
    return null;
  }

  if (hasAccess && isValid({ document: rootChapter, status })) {
    let showApprovalBtn = false;

    if (hasCreateProductAccess && status === StatusType.PENDING) {
      if (!rootChapter.currentTaskId) {
        showApprovalBtn = true;
      } else if (rootChapter.isRejected) {
        showApprovalBtn = true;
      }
    }

    return (
      <main className="mainContainer-document">
        <div className="flex">
          <nav
            className="w-full py-0.25 lg:py-0 my-0 md:my-2 pl-4 md:pl-5 lg:pl-10 bg-gray-fl-21 md:bg-transparent z-50"
            aria-label="Breadcrumb"
          >
            <ol className="flex flex-wrap">
              {breadCrumbString === '' ? (
                '..............'
              ) : (
                <ErrorBoundary
                  key={pathname} // To reset
                  fallbackRender={() => <div>Something went wrong!!....</div>}
                  onError={myErrorHandler}
                >
                  <BreadCrumb
                    breadCrumbs={breadCrumbs}
                    documentId={documentId!}
                    handleClick={handleCrumbClick}
                  />
                </ErrorBoundary>
              )}
            </ol>
          </nav>
        </div>
        <FeedbackMessage message={message.message} type={message.type} />

        <div className="p-4 xl:p-0">
          <Button
            appearance="outline"
            type="button"
            icon={<TextBulletList20Filled />}
            className="!absolute xl:!hidden !px-0.75 !py-1 z-50"
            onClick={handleOpenNestedSidebarPanel}
          >
            Table of contents
          </Button>
        </div>

        <Flex
          className="mb-36 -mt-9"
          width="w-full"
          padding="lg:px-0 md:px-0 sm:px-0"
          justify="between"
        >
          <div className="xl:contents sm: hidden">
            <ErrorBoundary
              key={pathname} // To reset
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorFallback
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  className="flex flex-col flex-grow xl:w-4/12 md:w-6/12 lg:pl-2 md:pl-0 sm: pl-0 lg:pt-1 sm: pt-0 lg:pb-0 md:pb-0 sm: pb-2 sticky top-0"
                />
              )}
              onError={myErrorHandler}
            >
              <NestedSideBar
                rootChapter={rootChapter}
                filteredSubChapters={filteredSubChapters}
                filteredDocs={filteredDocs}
                flattenedDocuments={flattenedDocuments}
                documentsMap={documentsMap}
                allRootChapterVersions={allRootChapterVersions}
                hasCreateProductAccess={hasCreateProductAccess || false}
                setMessage={setToastMessage}
              />
            </ErrorBoundary>
          </div>

          <Outlet context={outletContext} />

          {hasCreateProductAccess &&
            status === StatusType.PENDING &&
            showApprovalBtn && (
              <SubmitForApprovalButton
                title={
                  rootChapter.isRejected
                    ? 'RE-SUBMIT FOR APPROVAL'
                    : 'SUBMIT FOR APPROVAL'
                }
                openSlider={openSubmitForApprovalSlider}
              />
            )}
          {chapterAtomValue.isOpen && (
            <ChapterSliderForm
              rootChapter={rootChapter}
              allRootChapterVersions={allRootChapterVersions}
            />
          )}
          {docAtomValue.isOpen && (
            <DocSliderForm
              rootChapter={rootChapter}
              allRootChapterVersions={allRootChapterVersions}
              setMessage={setToastMessage}
            />
          )}
          {docItemAtomValue.isOpen && (
            <DocItemForm
              rootChapter={rootChapter}
              allRootChapterVersions={allRootChapterVersions}
              setMessage={setToastMessage}
              message={message}
            />
          )}
          {taskAtomValue.isOpen && (
            <SubmitForApprovalForm
              productDocId={rootChapter.parentProductDocId}
            />
          )}
          <DeletePanel
            key={chapterDelete.chapter.id}
            title="Are you sure you want to delete the Chapter?"
            description="Deleting this chapter will delete its associated documents and
              related sub chapters"
            isSliderOpen={chapterDelete.isOpen}
            closeSlider={closeChapterDeleteModal}
            onDelete={onChapterDelete}
          />
          <DeletePanel
            key={docDelete.doc.id}
            title="Are you sure you want to delete the Doc?"
            description="Deleting this doc will delete its associated content and
              related doc items"
            isSliderOpen={docDelete.isOpen}
            closeSlider={closeDocDeleteModal}
            onDelete={onDocDelete}
          />
          <DeletePanel
            key={referenceDocDelete.doc.id}
            title="Are you sure you want to delete the Reference Content?"
            description="Deleting the reference content will delete its associated content and
              related doc items"
            isSliderOpen={referenceDocDelete.isOpen}
            closeSlider={closeReferenceDocDeleteModal}
            onDelete={onReferenceDocDelete}
          />
          <DeletePanel
            key={docItemDelete.docItem.id}
            title="Are you sure you want to delete the Doc Item?"
            description="Deleting this doc item will delete its associated data"
            isSliderOpen={docItemDelete.isOpen}
            closeSlider={closeDocItemDeleteModal}
            onDelete={onDocItemDelete}
          />
        </Flex>
      </main>
    );
  }

  return null;
};

export default Document;
