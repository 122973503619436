import { useLocation } from 'react-router-dom';

import { type Chapter, StatusType } from 'API';

import type { CustomStatusType } from 'types/documents';

import useConstructStatus from 'hooks/useConstructStatus';

import useGetCurrentUserAccess from './useGetCurrentUserAccess';

const filterRootChapters = ({
  rootChapters,
  rootChaptersMap,
  status,
}: {
  rootChapters: Partial<Chapter>[];
  rootChaptersMap: Record<string, Partial<Chapter>[] | null | undefined>;
  status: CustomStatusType;
}) => {
  return rootChapters.filter((rootChapter) => {
    if (status === StatusType.PUBLISHED) {
      const shouldKeep = rootChapter.status === StatusType.PUBLISHED;

      return shouldKeep;
    }

    if (status === StatusType.PENDING) {
      if (rootChapter.id && rootChaptersMap[rootChapter.id]) {
        const shouldKeep = rootChapter.status === StatusType.PENDING;

        return shouldKeep;
      }

      return false;
    }

    if (status === StatusType.PENDING_APPROVAL) {
      const shouldKeep = rootChapter.status === StatusType.PENDING_APPROVAL;

      return shouldKeep;
    }

    return false;
  });
};

const getRootChaptersMap = ({
  rootChapters,
}: {
  rootChapters: Partial<Chapter>[];
}) => {
  const rootChaptersMap: Record<string, Partial<Chapter>[] | null | undefined> =
    {};

  // eslint-disable-next-line no-restricted-syntax
  for (const rootChapter of rootChapters) {
    if (rootChapter.id) {
      if (rootChaptersMap[rootChapter.id]) {
        if (
          [
            StatusType.PUBLISHED,
            StatusType.PENDING,
            StatusType.PENDING_APPROVAL,
          ].includes(rootChapter.status as StatusType)
        ) {
          const currentdocumentsMapItems =
            rootChaptersMap[rootChapter.id] ?? [];

          if (
            !currentdocumentsMapItems.some(
              (item) => item.status === rootChapter.status,
            )
          ) {
            rootChaptersMap[rootChapter.id] = [
              ...(rootChaptersMap[rootChapter.id] ?? []),
              rootChapter,
            ];
          }
        } else {
          rootChaptersMap[rootChapter.id] = [
            ...(rootChaptersMap[rootChapter.id] ?? []),
            rootChapter,
          ];
        }
      } else {
        rootChaptersMap[rootChapter.id] = [rootChapter];
      }
    }
  }

  return { rootChaptersMap };
};

const useTransformRootChapters = ({
  rootChapters,
}: {
  rootChapters: Partial<Chapter>[];
}) => {
  const search = useLocation().search;

  const { status } = useConstructStatus({ search });

  const { hasSomeCreatorAccess } = useGetCurrentUserAccess();

  const { rootChaptersMap } = getRootChaptersMap({ rootChapters });

  const filteredRootChapters = hasSomeCreatorAccess
    ? filterRootChapters({ rootChapters, rootChaptersMap, status })
    : rootChapters.filter(
        (rootChapter) => rootChapter.status === StatusType.PUBLISHED,
      );

  return {
    filteredRootChapters,
    rootChaptersMap,
  };
};

export default useTransformRootChapters;
