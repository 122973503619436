/* Button labels, form labels */

export const ADD_TASK = 'Add Task';
export const ADD_SECTION = 'Add Section';
export const FULL_NAME = 'Full Name';
export const EMAIL_ADDRESS = 'Email address';
export const EMAIL_ADDRESS_USERNAME = 'Email address / Username';
export const CREATE_PASSWORD = 'Create Password';
export const REGISTER = 'Register';
export const SHOW = 'SHOW';
export const HIDE = 'HIDE';
export const SIGN_OUT = 'Sign Out';
export const LOGGED_IN_NAME = 'Aashish Vivekanand';
export const COMPANY_NAME = 'Falaina Pte Ltd';
export const SIGN_IN = 'Sign In';
export const SIGN_UP = 'Sign Up';
export const SIGN_UP_TITLE = 'Already part of the';
export const SIGN_UP_DESC =
  'We will not take too much of your time. Quickly fillin your login information and get going!';
export const SIGN_IN_TITLE = 'Not part of the';
export const SIGN_IN_DESC =
  'Help us with a few simple questions and you have yourself professional workspace!';
export const FORGOT_PASSWORD = 'Forgot Password?';
export const REMEMBER_ME = 'Remember me';
export const SIGN_IN_WITH_GOOGLE = 'Sign in with google';

export const RESEND_VERIFICATION_CODE = 'Resend Verification Code';
export const CREATE = 'Create';
export const LETS_GO = "Let's go";
export const CONFIRM = 'Confirm';
export const SUBMIT = 'Submit';
export const SIGNUP_TITLE = 'Provide your basic details';
export const SIGNUP_DESCRIPTION =
  'To get started set up some basic information about who you are adding as a user';
export const EMAIL_RECEIPIENT_LABEL =
  'Email the new password to the following receipient';
export const CREATE_PASS_LABEL = 'Automatically create a password';
export const CHANGE_PASS_LABEL =
  'Require the user to change their password when they first sign in';
export const SEND_PASS_LABEL = 'Send password in the email for confirmation';
export const ENTER_PASSWORD = 'Enter password';
export const ENTER_NEW_PASSWORD = 'Enter new password';

export const WORKSPACE_DOMAIN = {
  placeholder: 'Workspace Domain',
};
export const EMAIL = {
  placeholder: 'Email Address',
};
export const WORKSPACE_NAME = {
  placeholder: 'Workspace Name',
};
export const PASSWORD = {
  placeholder: 'Password',
};
export const SSL_NOTIFICATION =
  'Click on the Configure Sensitive Source button to configure a New Sensitive Source Label or click on a preconfigured Label to edit it.';
