/* eslint-disable react/forbid-dom-props */
import { Dropdown } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import Flex from 'components/Flex';
import Text from 'components/Text';

import { Lead } from 'API';

import { useChartOne } from 'hooks/useLeadManagementDashboard';

import { renderCustomLegendForChartTypeTwo } from 'helpers/renderCustomLegendForChart';

type ChartOneDataType = {
  name: string;
  lead: number;
  qualified: number;
  approved: number;
  leadPercentage?: number;
  qualifiedPercentage?: number;
  approvedPercentage?: number;
  ya?: number;
};

const getCustomTooltipChartOne = ({
  active,
  payload,
  label,
  chartData,
}: TooltipProps<ValueType, NameType> & { chartData: ChartOneDataType[] }) => {
  const labelData = chartData.find((dataBar) => dataBar.name === label);

  if (active && payload && payload.length > 0) {
    return (
      <div className="custom-tooltip bg-white border border-black p-2">
        <div className="label text-xs">{`${label} `}</div>
        <div className="label text-xs">
          {`Lead : ${labelData?.lead},`}
          <span className="font-bold">{` ${labelData?.leadPercentage}%`}</span>
        </div>

        <div className="label text-xs">
          {`Qualified : ${labelData?.qualified},`}
          <span className="font-bold">
            {` ${labelData?.qualifiedPercentage}%`}
          </span>
        </div>

        <div className="label text-xs">
          {`Approved : ${labelData?.approved},`}
          <span className="font-bold">
            {` ${labelData?.approvedPercentage}%`}
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export const ChartOne = ({ leads }: { leads: Partial<Lead>[] }) => {
  const navigate = useNavigate();

  const { chartOneData, monthsList, selectedMonth, setSelectedMonth } =
    useChartOne({ leads });

  return (
    <Flex direction="col" className=" my-2 ">
      <Flex direction="row" height="h-8" width="w-full">
        <Text
          className="text-base flex-1 flex items-center pr-2 w-1/2"
          weight={600}
        >
          Leads by Month
        </Text>
        <Dropdown
          defaultSelectedKey="all"
          selectedKey={selectedMonth.barChartOneMonth}
          className="text-sm w-1/2 flex-1"
          options={[
            {
              id: 'all',
              key: 'all',
              text: 'All',
            },

            ...monthsList,
          ]}
          onChange={(_event, value) => {
            if (value?.text === 'All') {
              setSelectedMonth({
                barChartOneMonth: 'all',
                allMonth: 'all',
              });
            } else {
              setSelectedMonth({
                barChartOneMonth: value?.id || '',
                allMonth: value?.id || '',
              });
            }
          }}
        />
      </Flex>
      <div className="border-2 w-full  mt-4 pt-2 ">
        <ResponsiveContainer width="100%" aspect={1}>
          <BarChart
            width={500}
            height={300}
            data={chartOneData}
            margin={{
              top: 5,
              right: 15,
              left: -15,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis height={25} dataKey="name" />
            <YAxis type="number" dataKey="ya" />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={(args) =>
                getCustomTooltipChartOne({ ...args, chartData: chartOneData })
              }
            />
            <Legend content={renderCustomLegendForChartTypeTwo} />
            <Bar
              name="Lead"
              dataKey="lead"
              stackId="a"
              fill="#DA3E52"
              onClick={(e) => {
                navigate(`/leads?month=${e.name}&status=null`);
              }}
            />
            <Bar
              name="Qualified"
              dataKey="qualified"
              stackId="a"
              fill="#085984"
              onClick={(e) => {
                navigate(`/leads?month=${e.name}&status=Qualified`);
              }}
            />
            <Bar
              name="Approved"
              dataKey="approved"
              stackId="a"
              fill="#0E613F"
              onClick={(e) => {
                navigate(`/leads?month=${e.name}&status=Approved`);
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Flex>
  );
};

export default ChartOne;
