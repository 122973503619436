/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/forbid-elements */
import { useSetAtom } from 'jotai';
import { useLocation } from 'react-router-dom';

import Icon from 'components/Icon';

import { StatusType } from 'API';

import {
  CustomChapter,
  CustomChapters,
  CustomDoc,
  CustomDocItemType,
} from 'types/documents';
import { DocItemsMapType, DocumentsMapType } from 'types/Pages/Products';

import { docItemDeleteAtom, docItemEditAtom } from 'atoms/modals';

import useConstructStatus from 'hooks/useConstructStatus';
import { isCurrentUserAuthenticated } from 'hooks/useGetCurrentAuthenticatedUser';
import useGetCurrentUserAccess from 'hooks/useGetCurrentUserAccess';

import { generateSessionTimeoutToast } from 'helpers/utils/generateToast';

type DocItemModifierType = {
  doc: CustomDoc;
  docItem: CustomDocItemType;
  documentsMap: DocItemsMapType;
  primaryButtonClass?: string;
  secondaryButtonClass?: string;
  rootChapter: CustomChapter;
  allRootChapterVersions: CustomChapters;
  isAnyAncestorDeleted: boolean;
  lastElement?: boolean;
  setMessage: (messageObject: any) => void;
};

const DocItemModifier = ({
  doc,
  docItem,
  documentsMap,
  primaryButtonClass,
  secondaryButtonClass,
  rootChapter,
  allRootChapterVersions = [],
  isAnyAncestorDeleted,
  lastElement,
  setMessage,
}: DocItemModifierType) => {
  const search = useLocation().search;

  const { status } = useConstructStatus({ search });

  const { hasCreateProductAccess } = useGetCurrentUserAccess({
    productDocId: rootChapter?.parentProductDocId || '',
  });

  const setDocItemAtomValue = useSetAtom(docItemEditAtom);
  const setDocItemDelete = useSetAtom(docItemDeleteAtom);

  if (hasCreateProductAccess) {
    const isSomeRootChapVersionSubmittedForApproval =
      allRootChapterVersions.some(
        (x) => x?.status === StatusType.PENDING_APPROVAL,
      );

    return (
      <div
        className="flex w-1/12 justify-end items-center absolute right-5"
        key={docItem.id}
        style={lastElement ? { paddingBottom: '100vh' } : {}}
        onClick={async () => {
          const isAuthenticated = await isCurrentUserAuthenticated();
          if (!isAuthenticated) {
            generateSessionTimeoutToast();
          }
        }}
        onKeyPress={() => {}}
      >
        {status !== StatusType.PENDING_APPROVAL &&
          !search.split('view=')[1] &&
          !doc?.referenceDocId && (
            <>
              <button
                type="button"
                className={
                  primaryButtonClass ??
                  'font-bold mr-1 bg-blue-500 p-4 text-white hover:bg-blue-900 text-gray-700 rounded-full flex items-center justify-center'
                }
                style={{ height: '1px', width: '1px' }}
                onClick={() => {
                  if (isAnyAncestorDeleted) {
                    setMessage({
                      message:
                        'Cannot Edit as one of its parent chapters was deleted.',
                      type: 'error',
                    });
                  } else if (isSomeRootChapVersionSubmittedForApproval) {
                    setMessage({
                      message:
                        'An edited version was submitted for approval. Cannot edit until reviewed by approver.',
                      type: 'error',
                    });
                  } else {
                    if (status === StatusType.PUBLISHED) {
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      if (documentsMap[docItem.id]) {
                        const documentVersions = documentsMap[docItem.id] || [];

                        const isEditable = !documentVersions.some(
                          (item) => item.status === StatusType.PENDING,
                        );

                        if (isEditable) {
                          setDocItemAtomValue({
                            docItem,
                            isOpen: true,
                            action: 'UPDATE',
                          });
                        } else {
                          setMessage({
                            message:
                              'An edited version already exists. Please check "Edited Documents"',
                            type: 'success',
                          });
                        }
                      } else {
                        setMessage({
                          message:
                            'Something went wrong. Please reload and try again!!',
                          type: 'error',
                        });
                      }
                    }

                    if (status === StatusType.PENDING) {
                      setDocItemAtomValue({
                        docItem,
                        isOpen: true,
                        action: 'UPDATE',
                      });
                    }
                  }
                }}
              >
                <Icon name="edit" className="w-4 text-white " />
              </button>

              <button
                type="button"
                className={
                  secondaryButtonClass ??
                  'font-bold mr-1 bg-red-500 p-4 text-white hover:bg-red-900 text-gray-700 rounded-full flex items-center justify-center'
                }
                style={{ height: '1px', width: '1px' }}
                onClick={() => {
                  if (isAnyAncestorDeleted) {
                    setMessage({
                      message:
                        'Cannot Delete as one of its parent chapters was deleted.',
                      type: 'success',
                    });
                  } else if (isSomeRootChapVersionSubmittedForApproval) {
                    setMessage({
                      message:
                        'An edited version was submitted for approval. Cannot edit until reviewed by approver.',
                      type: 'error',
                    });
                  } else {
                    if (status === StatusType.PUBLISHED) {
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      if (documentsMap[docItem.id]) {
                        const documentVersions = documentsMap[docItem.id] || [];

                        const isEditable = !documentVersions.some(
                          (item) => item.status === StatusType.PENDING,
                        );

                        if (isEditable) {
                          setDocItemDelete({
                            isOpen: true,
                            docItem: docItem as any,
                          });
                        } else {
                          setMessage({
                            message:
                              'An edited version already exists. Please check "Edited Documents"',
                            type: 'success',
                          });
                        }
                      } else {
                        setMessage({
                          message:
                            'Something went wrong. Please reload and try agin!!',
                          type: 'error',
                        });
                      }
                    }

                    if (status === StatusType.PENDING) {
                      setDocItemDelete({
                        isOpen: true,
                        docItem: docItem as any,
                      });
                    }
                  }
                }}
              >
                <Icon name="trash" className="w-4  text-white " />
              </button>
            </>
          )}
      </div>
    );
  }

  return null;
};

export default DocItemModifier;
