import { Spinner } from '@fluentui/react-components';
import { IStackProps, Stack } from '@fluentui/react';

import Flex from 'components/Flex';

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  spinnerStack: {
    childrenGap: 20,
  },
};
const rowProps: IStackProps = { horizontal: true, verticalAlign: 'center' };

const HomeLoader = () => (
  <>
    <Flex className="flex flex-col justify-center items-center h-screen">
      <Stack {...rowProps} tokens={tokens.spinnerStack}>
        <Spinner size="large" />
      </Stack>
    </Flex>
  </>
);

export default HomeLoader;
