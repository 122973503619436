import { useState, useRef, useEffect, useCallback } from 'react';

import { SetToastMessageType, ToastMessageType } from 'types';

const useFeedbackMessage = () => {
  const timerRef = useRef<any>(null);
  const [message, setMessage] = useState<ToastMessageType>({
    message: '',
    type: '',
  });

  const setToastMessage: SetToastMessageType = useCallback((messageObject) => {
    setMessage(messageObject);

    timerRef.current = setTimeout(() => {
      setMessage({ message: '', type: '' });
    }, 3000);
  }, []);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return [message, setMessage, { setToastMessage }] as const;
};

export default useFeedbackMessage;
