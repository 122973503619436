/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

const policy: any = 'network-only';

const Client = () => {
  const options = {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: policy,
      },
    },
  };
  return new AWSAppSyncClient<any>(
    {
      url: import.meta.env.VITE_APP_aws_appsync_graphqlEndpoint || '',
      region: import.meta.env.VITE_APP_aws_appsync_region || '',
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        // Get the currently logged in users credential.
        jwtToken: async () =>
          (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      // Amplify uses Amazon IAM to authorize calls to Amazon S3. This provides the relevant IAM credentials.
      complexObjectsCredentials: () => Auth.currentCredentials(),
      // disableOffline: true,
    },
    options,
  );
};
const client = Client();

export default client;
