import { Text as MsText } from '@fluentui/react';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { useLocation, Link } from 'react-router-dom';

import Flex from 'components/Flex';
import Text from 'components/Text';

import type { DocItem } from 'API';
import { DocItemType, StatusType } from 'API';

import { selectedDocContentSectionId } from 'atoms/modals';

import { NORMAL_DIFF_CLASSNAME } from 'consts/diffConstants';

import useConstructStatus from 'hooks/useConstructStatus';
import useIsContentReader from 'hooks/useIsContentReader';
import useScrollToHashElement from 'hooks/useScrollToHashElement';

type InThisSectionProps = {
  docItems: Partial<DocItem>[];
  isAnyAncestorDeleted: boolean;
};

const InThisSection = ({
  docItems,
  isAnyAncestorDeleted,
}: InThisSectionProps) => {
  useScrollToHashElement({});

  const location = useLocation();
  const { search, pathname } = location;

  const { status } = useConstructStatus({ search });

  const [selectedId, setSelectedId] = useAtom(selectedDocContentSectionId);

  const contentDocItems = docItems.filter(
    (item) => item.itemType === DocItemType.content,
  );

  const sortedItems = contentDocItems
    .map((contentDocItem) => {
      return {
        ...contentDocItem,
        order: Number.parseFloat(contentDocItem.orderFloat ?? '100000'),
      };
    })
    .sort(
      (a, b) =>
        (a.createdAt ? +new Date(a.createdAt) : 0) -
        (b.createdAt ? +new Date(b.createdAt) : 0),
    )
    .sort((a, b) => a.order - b.order);

  const { isContentReader } = useIsContentReader();

  return (
    <Flex
      width="min-w-full"
      direction="col"
      margin="mb-2"
      padding="pb-4 pt-0"
      className="xl:sticky sm: relative lg:top-0 md:top-0 sm: top-0 lg:right-2 md:right-2 sm: right-0 bg-white rounded-lg h-auto xl:h-80vh xl:pt-0  "
    >
      {sortedItems.length > 0 && (
        <Flex
          direction="col"
          padding="xl:px-5  sm: px-0"
          className=" max-h-80vh overflow-y-auto"
        >
          <Text
            weight={600}
            className="font-microsoft-docs text-gray-microsoft-docs font-semibold antialiased break-words text-base leading-5 mt-3.5 mb-2.5"
          >
            In this article
          </Text>
          {sortedItems.map((item) => {
            const shouldShowDiff =
              !isContentReader &&
              (item.status === StatusType.PENDING || isAnyAncestorDeleted);

            const shouldShowDeletedDiff =
              isAnyAncestorDeleted || item.shouldBeDeleted === true;

            return (
              <Link
                to={`${pathname}?status=${status}${
                  search.split('view=')[1] ? '&view=read' : ''
                }#${item.id ?? ''}`}
                key={(item.id ?? '') + (item.version ?? '')}
                className={classNames(
                  'hover:underline',
                  {
                    'text-blue-db-16': !shouldShowDiff,
                  },
                  {
                    [NORMAL_DIFF_CLASSNAME]: shouldShowDiff,
                  },
                )}
              >
                <div
                  className={classNames(
                    `py-0.5 pl-1.75`,
                    {
                      'border-0 xl:border-l-3 border-blue-db-20':
                        selectedId === item.id && !shouldShowDiff,
                    },
                    {
                      'border-0 xl:border-l-3 border-green-diff-1':
                        selectedId === item.id && shouldShowDiff,
                    },
                    {
                      'xl:border-l-3 xl:border-transparent':
                        selectedId !== item.id,
                    },
                  )}
                >
                  <MsText
                    onClick={() => setSelectedId(item.id ?? null)}
                    variant="medium"
                    className={classNames(
                      `font-microsoft-docs $`,
                      {
                        'font-normal lg:font-semibold': selectedId === item.id,
                      },
                      {
                        'text-blue-db-16': !shouldShowDiff,
                      },

                      {
                        [NORMAL_DIFF_CLASSNAME]: shouldShowDiff,
                      },
                      {
                        'line-through': shouldShowDiff && shouldShowDeletedDiff,
                      },
                    )}
                  >
                    {item.title}
                  </MsText>
                </div>
              </Link>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default InThisSection;
