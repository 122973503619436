import { MessageBar, MessageBarType } from '@fluentui/react';
import { useState, useEffect } from 'react';

const FeedbackMessage = ({
  message,
  type,
}: {
  message: string;
  type: string;
}) => {
  const [isShow, setisShow] = useState(true);
  useEffect(() => {
    setisShow(true);
  }, [message]);
  return message !== '' && isShow ? (
    <MessageBar
      onDismiss={() => {
        setisShow(false);
      }}
      dismissButtonAriaLabel="Close"
      messageBarType={
        type === 'success'
          ? MessageBarType.success
          : type === 'error'
            ? MessageBarType.error
            : MessageBarType.info
      }
      className="justify-center mt-4"
    >
      {message}
    </MessageBar>
  ) : (
    <></>
  );
};

export default FeedbackMessage;
