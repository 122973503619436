import type { GraphQLQuery } from '@aws-amplify/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import {
  CustomGetDocVersionQuery,
  CustomGetDocVersionQueryVariables,
  CustomListDocVersionsQuery,
  CustomListDocVersionsQueryVariables,
  ListDocsQuery,
  ModelDocFilterInput,
  ModelDocItemFilterInput,
  ModelSortDirection,
  StatusType,
} from 'API';

import {
  customGetDocVersion,
  customListDocVersions,
} from 'graphql/custom-queries';

const customListDocs = /* GraphQL */ `
  query CustomListDocs(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelDocFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocs(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        status
        title
        description
        orderFloat
        createdByUserId
        referenceDocId
        lastModifiedByUserId
        approvedByUserId
        rootChapterId
        chapterId
        docItems {
          items {
            id
            version
            status
            title
            description
            orderFloat
            itemType
            file {
              bucket
              region
              key
            }
            createdByUserId
            lastModifiedByUserId
            approvedByUserId
            rootChapterId
            docId
            shouldBeDeleted
            createdAt
            updatedAt
          }
          nextToken
        }
        shouldBeDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

type useGetDocVersionType = {
  id: string;
  version: string;
  docItemNextToken?: string | null;
  enabled?: boolean;
  docItemFilter?: ModelDocItemFilterInput;
};

type UseListDocVersionsType = {
  id: string;
  version?: string;
  enabled?: boolean;
  docLevelFilter?: ModelDocFilterInput;
  docItemLevelFilter?: ModelDocItemFilterInput;
};

export const useGetDocVersion = ({
  id,
  version,
  docItemFilter = {},
  docItemNextToken,
  enabled = true,
}: useGetDocVersionType) => {
  const docItemLimit = 1_000_000;

  const finalDocItemFilter: ModelDocItemFilterInput = {
    status: {
      ne: StatusType.ARCHIVED,
    },
    ...docItemFilter,
  };

  const variables: CustomGetDocVersionQueryVariables = {
    id,
    version,
    docItemLimit,
    docItemFilter: finalDocItemFilter,
    sortDirection: ModelSortDirection.DESC,
    docItemNextToken,
  };

  if (docItemNextToken) {
    variables.docItemNextToken = docItemNextToken;
  }

  return useInfiniteQuery(
    [`docVersion/${id}`, `docVersion/${id}-${version}`],
    ({ pageParam }) => {
      variables.docItemNextToken = pageParam;

      return API.graphql<GraphQLQuery<CustomGetDocVersionQuery>>({
        query: customGetDocVersion,
        variables,
      });
    },
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const nextToken = lastPage.data?.getDoc?.docItems?.nextToken;

        return nextToken ?? undefined;
      },
    },
  );
};

export const useListDocVersions = ({
  id,
  version,
  docLevelFilter = {},
  docItemLevelFilter = {},
  enabled = true,
}: UseListDocVersionsType) => {
  const docLevelLimit = 1_000_000;
  const docItemLevelLimit = 1_000_000;

  const finalDocLevelFilter: ModelDocFilterInput = {
    status: {
      ne: StatusType.ARCHIVED,
    },
    ...docLevelFilter,
  };

  const finalDocItemLevelFilter: ModelDocItemFilterInput = {
    status: {
      ne: StatusType.ARCHIVED,
    },
    ...docItemLevelFilter,
  };

  const variables: CustomListDocVersionsQueryVariables = {
    limit: docLevelLimit,
    filter: finalDocLevelFilter,
    sortDirection: ModelSortDirection.DESC,
    docItemLevelLimit,
    docItemLevelFilter: finalDocItemLevelFilter,
  };

  if (id) {
    variables.id = id;
  }

  if (version) {
    variables.version = {
      eq: version,
    };
  }

  return useInfiniteQuery(
    [`docVersions/${id}`, `docVersions/${id}-${version}`],
    ({ pageParam }) => {
      variables.nextToken = pageParam;

      return API.graphql<GraphQLQuery<CustomListDocVersionsQuery>>({
        query: customListDocVersions,
        variables,
      });
    },
    {
      enabled,
      getNextPageParam: (lastPage) => {
        const nextToken = lastPage.data?.listDocs?.nextToken;

        return nextToken ?? undefined;
      },
    },
  );
};

export const useInfiniteDocsList = ({
  queryKeys,
  filter,
  enabled,
}: {
  queryKeys: string[];
  filter?: ModelDocFilterInput;
  enabled: boolean;
}) => {
  return useInfiniteQuery(
    [...queryKeys],
    ({ pageParam }: any) => {
      return API.graphql<GraphQLQuery<ListDocsQuery>>({
        query: customListDocs,
        variables: filter
          ? { filter, limit: 1_000_000, nextToken: pageParam }
          : { limit: 1_000_000, nextToken: pageParam },
      });
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const nextToken = lastPage.data?.listDocs?.nextToken;

        return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
      },
    },
  );
};
