import { Chapter, StatusType } from 'API';

import {
  CustomChapter,
  CustomDoc,
  CustomDocItem,
  CustomDocItems,
  CustomStatusType,
} from 'types/documents';

import {
  DELETED_DIFF_CLASSNAME,
  NORMAL_DIFF_CLASSNAME,
} from 'consts/diffConstants';

type DiffForType = 'ROOTCHAPTER' | 'CHAPTER' | 'DOC';

type GetDiffClassNameType = {
  isContentReader: boolean;
  defaultClassName?: string;
  status: CustomStatusType;
  diffFor: DiffForType;
  isDeleted: boolean;
  rootChapter?: Partial<Chapter | null>;
  chapter?: CustomChapter | any;
  doc?: CustomDoc | any;
};

const getDiffClassName = ({
  isContentReader,
  defaultClassName = '',
  status,
  diffFor,
  isDeleted,
  rootChapter,
  chapter,
  doc,
}: GetDiffClassNameType) => {
  if (isContentReader) {
    return defaultClassName;
  }

  if (
    status &&
    [StatusType.PENDING, StatusType.PENDING_APPROVAL].includes(status)
  ) {
    if (diffFor === 'ROOTCHAPTER' && rootChapter) {
      if (isDeleted) {
        return DELETED_DIFF_CLASSNAME;
      }

      if (
        rootChapter.status &&
        [StatusType.PENDING, StatusType.PENDING_APPROVAL].includes(
          rootChapter.status as StatusType,
        )
      ) {
        return NORMAL_DIFF_CLASSNAME;
      }

      return defaultClassName;
    }

    if (diffFor === 'CHAPTER' && chapter) {
      if (isDeleted) {
        return DELETED_DIFF_CLASSNAME;
      }

      if (chapter.status === StatusType.PENDING) {
        return NORMAL_DIFF_CLASSNAME;
      }

      return defaultClassName;
    }

    if (diffFor === 'DOC' && doc) {
      if (isDeleted) {
        return DELETED_DIFF_CLASSNAME;
      }

      if (doc.status === StatusType.PENDING) {
        return NORMAL_DIFF_CLASSNAME;
      }

      if (doc.status === StatusType.PUBLISHED && doc.docItems) {
        const docItems: CustomDocItems = doc.docItems.items || [];

        const isSomePendingDocItemVersionPresentInDoc = docItems.some(
          (x: CustomDocItem) => x?.status === StatusType.PENDING,
        );

        if (isSomePendingDocItemVersionPresentInDoc) {
          return NORMAL_DIFF_CLASSNAME;
        }
      }

      return defaultClassName;
    }

    return defaultClassName;
  }

  return defaultClassName;
};

export default getDiffClassName;
