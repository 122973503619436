/* eslint-disable react/forbid-elements */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Panel,
  Separator,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import Flex from 'components/Flex';
import ImageCropper from 'components/ImageInput';
import InputField from 'components/InputField';

import {
  Chapter,
  CreateDocItemInput,
  DocItemType,
  StatusType,
  UpdateDocItemInput,
} from 'API';

import { AddDocItemParams } from 'types/Pages/Products';

import {
  docItemEditAtom,
  initialDocItemAtomProps,
  noneValue,
} from 'atoms/modals';

import { INITIAL_DOC_SETTINGS } from 'consts/docVersions';
import falainaTenantId from 'consts/tenant';
import { AS_NUMBER, AS_REQUIRED } from 'consts/validations';

import useChapterMutations from 'hooks/useChapterMutations';
import useDocItemMutations from 'hooks/useDocItemMutations';
import useGetCurrentAuthenticatedUser, {
  isCurrentUserAuthenticated,
} from 'hooks/useGetCurrentAuthenticatedUser';
import useStatus from 'hooks/useStatus';

import { getS3InputObject } from 'helpers/transformFile';
import { generateSessionTimeoutToast } from 'helpers/utils/generateToast';

type CustomCreateDocItemInput = Omit<CreateDocItemInput, 'description'> &
  Pick<CreateDocItemInput, 'description'>;

type CustomUpdateDocItemInput = Partial<Omit<UpdateDocItemInput, 'id'>> &
  Pick<UpdateDocItemInput, 'id'>;

type SetToastMessageType = (messageObject: {
  message: string;
  type: string;
}) => void;

type ISliderFormProps = {
  rootChapter?: Partial<Chapter | null>;
  allRootChapterVersions: Partial<Chapter | null>[];
  setMessage: SetToastMessageType;
};

const buttonStyles = { root: { marginRight: 8 } };

const useParent = () => {
  const docItemAtomValue = useAtomValue(docItemEditAtom);

  const { docItem } = docItemAtomValue;

  const docId = docItem?.docId ?? noneValue;
  const rootChapterId = docItem?.rootChapterId ?? noneValue;

  const parent = useMemo(
    () => ({
      docId,
      rootChapterId,
    }),
    [docId, rootChapterId],
  );

  return parent;
};

const useClosePanel = () => {
  const setDocItemAtomValue = useSetAtom(docItemEditAtom);

  const closePanel = useCallback(() => {
    setDocItemAtomValue(initialDocItemAtomProps);
  }, [setDocItemAtomValue]);

  return closePanel;
};

const VideoComponent = ({ src }: any) => {
  const fileUrl = src?.length ? window.URL.createObjectURL(src?.[0]) : '';
  return (
    <div className="flex">
      {fileUrl && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          height="200"
          width="300"
          className="video-preview"
          controls
          src={fileUrl}
        />
      )}
    </div>
  );
};

const useDocItemForm = () => {
  const docItemAtomValue = useAtomValue(docItemEditAtom);

  const { docItem } = docItemAtomValue;

  const defaultValues: AddDocItemParams = useMemo(
    () => ({
      title: '',
      description: '',
      orderFloat: '',
      file: '',
    }),
    [],
  );

  const formProps = useForm<AddDocItemParams>({
    defaultValues,
  });

  const { reset } = formProps;

  useEffect(() => {
    if (docItem) {
      const defaults = {
        title: docItem.title ?? defaultValues.title,
        description: docItem.description ?? defaultValues.description,
        orderFloat: docItem.orderFloat ?? defaultValues.orderFloat,
        file: defaultValues.file,
      };

      reset(defaults);
    }
  }, [
    defaultValues.description,
    defaultValues.file,
    defaultValues.orderFloat,
    defaultValues.title,
    docItem,
    reset,
  ]);

  return formProps;
};

const SliderForm = ({
  rootChapter,
  allRootChapterVersions = [],
  setMessage,
}: ISliderFormProps) => {
  const imageInput: any = useRef(null);

  const [fileType, setFileType] = useState('');
  const [showCrop, setshowCrop] = useState(false);

  const docItemAtomValue = useAtomValue(docItemEditAtom);

  const { isOpen, action, docItem } = docItemAtomValue;

  const status = useStatus();

  const parentObj = useParent();

  const closePanel = useClosePanel();

  const currentAuthenticatedUser = useGetCurrentAuthenticatedUser();

  const currentAuthenticatedUserId = currentAuthenticatedUser?.sub ?? null;

  const tenantId = falainaTenantId;

  let sliderTitle: unknown = docItem?.itemType;

  if (sliderTitle === DocItemType.preRequisite) {
    sliderTitle = 'pre requisite';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
    control,
  } = useDocItemForm();

  const imageView = getValues('file')
    ? URL.createObjectURL(getValues('file')[0])
    : '';

  const isSVGFile = getValues('file')[0]?.name.split('.').at(-1) === 'svg';

  const {
    onCreateDocItem,
    onUpdateDocItem,
    isCreateLoading,
    isUpdateLoading,
    isCreateSuccess,
    isUpdateSuccess,
  } = useDocItemMutations();

  const { onCreateChapter } = useChapterMutations();

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      reset();
    }
  }, [isCreateSuccess, isUpdateSuccess, reset]);

  const renderSecondaryInput = () => {
    const { ref, ...rest } = register('file');
    if (docItem?.itemType === DocItemType.image) {
      return (
        <>
          <div className="flex flex-col">
            <label htmlFor="id" className="pt-10">
              Image
            </label>
            <div className="relative mt-4">
              <input
                required
                id="file"
                className="mt-4 hidden"
                type="file"
                placeholder="Image"
                accept="image/*"
                ref={(e) => {
                  ref(e);
                  imageInput.current = e; // you can still assign to ref
                }}
                {...rest}
              />
              <DefaultButton
                text="Upload Image"
                onClick={() => {
                  imageInput?.current?.click();
                }}
                allowDisabledFocus
                disabled={false}
                checked
              />
            </div>
            {errors.file && <label>{errors.file}</label>}
          </div>
          <InputField
            required
            type="number"
            name="orderFloat"
            control={control}
            label="Order"
            error={errors.orderFloat}
            placeholder="Order"
            rules={AS_NUMBER}
          />
        </>
      );
    }

    if (docItem?.itemType === DocItemType.video) {
      return (
        <>
          <div className="flex flex-col">
            <label htmlFor="id" className="pt-10">
              Video
            </label>
            <div className="relative mt-4">
              <input
                required
                id="file"
                className="mt-8 hidden"
                type="file"
                placeholder="Video"
                accept="video/*"
                ref={(e) => {
                  ref(e);
                  imageInput.current = e; // you can still assign to ref
                }}
                {...rest}
              />
              <DefaultButton
                text="Upload Video"
                onClick={() => {
                  imageInput?.current?.click();
                }}
                allowDisabledFocus
                disabled={false}
                checked
              />
            </div>
            {errors.file && <label>{errors.file}</label>}
          </div>
          <InputField
            required
            type="number"
            name="orderFloat"
            control={control}
            label="Order"
            error={errors.orderFloat}
            placeholder="Order"
            rules={AS_NUMBER}
          />
        </>
      );
    }

    return (
      <>
        <InputField
          required
          type="text"
          multiline
          rows={6}
          name="description"
          control={control}
          label="Description"
          error={errors.description}
          placeholder={`Add ${sliderTitle as string} content here...`}
          rules={AS_REQUIRED}
          width="w-full"
          errorPlacement="bottom"
        />
        <InputField
          required
          type="number"
          name="orderFloat"
          control={control}
          label="Order"
          error={errors.orderFloat}
          placeholder="Order"
          rules={AS_NUMBER}
        />
      </>
    );
  };

  const transformArgs = useCallback(
    (args: AddDocItemParams) => {
      if (
        (docItem?.itemType === DocItemType.image ||
          docItem?.itemType === DocItemType.video) &&
        args.file
      ) {
        const file = getS3InputObject(args.file);

        return { description: args.description, file };
      }

      return { description: args.description };
    },
    [docItem?.itemType],
  );

  const handleCreateNewRootChapterVersion = useCallback(() => {
    const isPendingRootChapterVersionPresent = allRootChapterVersions.some(
      (x) => x?.status === StatusType.PENDING,
    );

    const shouldCreateNewRootChapterVersion =
      currentAuthenticatedUserId &&
      status === StatusType.PUBLISHED &&
      rootChapter?.status === StatusType.PUBLISHED &&
      !isPendingRootChapterVersionPresent;

    if (shouldCreateNewRootChapterVersion) {
      const final = {
        ...rootChapter,
        id: rootChapter.id,
        name: rootChapter.name ?? '',
        description: rootChapter.description ?? '',
        version: rootChapter.version
          ? String(Number(rootChapter.version) + 1)
          : '2',
        status: StatusType.PENDING,
        createdByUserId: currentAuthenticatedUserId,
        lastModifiedByUserId: currentAuthenticatedUserId,
        tenantId,
        rootChapterId: rootChapter.rootChapterId ?? '',
        file: rootChapter.file,
      };

      onCreateChapter({
        chapter: {
          ...final,
        },
        isParentProduct: true,
        callback: closePanel,
        showNotification: true,
      });
    }
  }, [
    allRootChapterVersions,
    closePanel,
    currentAuthenticatedUserId,
    onCreateChapter,
    rootChapter,
    status,
    tenantId,
  ]);

  const addNewDocument = useCallback(
    (data: AddDocItemParams) => {
      const transformedArgs = transformArgs(data);

      if (currentAuthenticatedUserId && docItem?.itemType) {
        const final: CustomCreateDocItemInput = {
          ...transformedArgs,
          title: docItem.itemType,
          orderFloat: data.orderFloat,
          itemType: docItem.itemType,
          ...INITIAL_DOC_SETTINGS,
          ...parentObj,
          createdByUserId: currentAuthenticatedUserId,
          lastModifiedByUserId: currentAuthenticatedUserId,
          tenantId,
        };

        const isPendingRootChapterVersionPresent = allRootChapterVersions.some(
          (x) => x?.status === StatusType.PENDING,
        );

        onCreateDocItem({
          docItem: {
            ...final,
          },
          callback: () => {
            handleCreateNewRootChapterVersion();

            closePanel();
          },
          showNotification: !!isPendingRootChapterVersionPresent,
          setToastMessage: setMessage,
        });
      }
    },
    [
      allRootChapterVersions,
      closePanel,
      currentAuthenticatedUserId,
      docItem?.itemType,
      handleCreateNewRootChapterVersion,
      onCreateDocItem,
      parentObj,
      setMessage,
      tenantId,
      transformArgs,
    ],
  );

  const updateDocument = useCallback(
    (data: AddDocItemParams) => {
      const transformedArgs = transformArgs(data);

      if (currentAuthenticatedUserId && docItem?.itemType) {
        if (
          status === StatusType.PUBLISHED &&
          docItem.status === StatusType.PUBLISHED
        ) {
          const final: CustomCreateDocItemInput = {
            ...transformedArgs,
            title: docItem.itemType,
            orderFloat: data.orderFloat,
            itemType: docItem.itemType,
            ...parentObj,
            id: docItem.id,
            version: docItem.version
              ? String(Number(docItem.version) + 1)
              : '2',
            status: StatusType.PENDING,
            lastModifiedByUserId: currentAuthenticatedUserId,
            createdByUserId: currentAuthenticatedUserId,
            tenantId,
          };

          const isPendingRootChapterVersionPresent =
            allRootChapterVersions.some(
              (x) => x?.status === StatusType.PENDING,
            );

          onCreateDocItem({
            docItem: {
              ...final,
            },
            callback: () => {
              handleCreateNewRootChapterVersion();

              closePanel();
            },
            showNotification: !!isPendingRootChapterVersionPresent,
            setToastMessage: setMessage,
          });
        }

        if (status === StatusType.PENDING) {
          if (docItem.status === StatusType.PUBLISHED) {
            const final: CustomCreateDocItemInput = {
              ...transformedArgs,
              title: docItem.itemType,
              orderFloat: data.orderFloat,
              itemType: docItem.itemType,
              ...parentObj,
              id: docItem.id,
              version: docItem.version
                ? String(Number(docItem.version) + 1)
                : '2',
              status: StatusType.PENDING,
              lastModifiedByUserId: currentAuthenticatedUserId,
              createdByUserId: currentAuthenticatedUserId,
              tenantId,
            };

            onCreateDocItem({
              docItem: {
                ...final,
              },
              callback: closePanel,
              showNotification: true,
              setToastMessage: setMessage,
            });
          }

          if (docItem.status === StatusType.PENDING) {
            const final: CustomUpdateDocItemInput = {
              id: docItem.id ?? '',
              orderFloat: data.orderFloat,
              version: docItem.version,
              lastModifiedByUserId: currentAuthenticatedUserId,
              ...transformedArgs,
              ...parentObj,
            };

            onUpdateDocItem({ ...final }, closePanel);
          }
        }
      }
    },
    [
      allRootChapterVersions,
      closePanel,
      currentAuthenticatedUserId,
      docItem,
      handleCreateNewRootChapterVersion,
      onCreateDocItem,
      onUpdateDocItem,
      parentObj,
      setMessage,
      status,
      tenantId,
      transformArgs,
    ],
  );

  const submit = useCallback(
    async (data: AddDocItemParams) => {
      const isAuthenticated = await isCurrentUserAuthenticated();
      if (isAuthenticated) {
        const isAdd = action === 'ADD';
        const isUpdate = action === 'UPDATE';

        if (isAdd) {
          addNewDocument(data);
        }

        if (isUpdate) {
          updateDocument(data);
        }
      } else {
        closePanel();
        generateSessionTimeoutToast();
      }
    },
    [action, addNewDocument, closePanel, updateDocument],
  );

  const isTitleChanged = watch('title') !== docItem?.title;
  const isDescriptionChanged = watch('description') !== docItem?.description;
  const isOrderFloatChanged = watch('orderFloat') !== docItem?.orderFloat;
  const isFileChanged = !!watch('file');

  const isSaveButtonEnabled =
    isTitleChanged ||
    isDescriptionChanged ||
    isOrderFloatChanged ||
    isFileChanged;

  const onRenderFooterContent = useCallback(
    () => (
      <div>
        <Separator />
        {isCreateLoading || isUpdateLoading || !isSaveButtonEnabled ? (
          <PrimaryButton disabled text="Save" styles={buttonStyles} />
        ) : (
          <PrimaryButton
            text="Save"
            type="submit"
            styles={buttonStyles}
            className="bg-blue-db-20 focus:outline-none"
            onClick={handleSubmit(submit)}
          />
        )}
        <DefaultButton
          text="Cancel"
          onClick={() => {
            closePanel();
          }}
        />
      </div>
    ),
    [
      isCreateLoading,
      isUpdateLoading,
      isSaveButtonEnabled,
      handleSubmit,
      submit,
      closePanel,
    ],
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={() => {
        closePanel();
      }}
      type={7}
      styles={{ root: { marginTop: 50 } }}
      customWidth="448px"
      closeButtonAriaLabel="Close"
      isLightDismiss
      hasCloseButton
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
    >
      <Flex width="w-full" direction="col" space="space-y-6">
        {renderSecondaryInput()}
        {docItem?.itemType === DocItemType.image && showCrop && (
          <ImageCropper
            src={getValues('file')}
            setValue={setValue}
            setshowCrop={setshowCrop}
            fileType={fileType}
          />
        )}
        {docItem?.itemType === DocItemType.image &&
          getValues('file') &&
          !showCrop && (
            <>
              <img src={imageView} alt="" className="w-3/4" />
              <PrimaryButton
                title={`${
                  isSVGFile ? "SVG images doesn't support cropping" : ''
                }`}
                text="Crop"
                type="submit"
                styles={buttonStyles}
                disabled={isSVGFile}
                className={`bg-blue-db-20 focus:outline-none w-max px-2 ${
                  isSVGFile ? 'cursor-not-allowed' : ''
                }`}
                onClick={() => {
                  setFileType(getValues('file')[0]?.name.split('.').at(-1));
                  setshowCrop(true);
                }}
              />
            </>
          )}

        {docItem?.itemType === DocItemType.video && (
          <VideoComponent src={getValues('file')} />
        )}
      </Flex>
    </Panel>
  );
};

export default SliderForm;
