import { useAtomValue } from 'jotai';
import capitalize from 'lodash/capitalize';
import { useMemo, useState } from 'react';

import {
  Lead,
  LeadOverallStatusType,
  ApplicationRole,
  ActiveStatusType,
  UserGroup,
} from 'API';

import {
  allSalesForceOrganizationsAtom,
  currentUserDetailsAtom,
  currentUserOrganizationAtom,
} from 'atoms/modals';

import { useInfiniteGroups } from 'hooks/useGroups';

import { sortList } from 'helpers/sortList';
import countriesJson from 'helpers/utils/countriesJson';

import { useGetLeadsBasedOnStatus } from './useGetLeadsBasedOnStatus';

export const useChartTwo = ({ leads }: { leads: Partial<Lead>[] }) => {
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);
  const currentUserOrganization = useAtomValue(currentUserOrganizationAtom);
  const allSalesForceOrganizations = useAtomValue(
    allSalesForceOrganizationsAtom,
  );

  const [selectedOrganization, setSelectedOrganization] = useState('all');

  const { groupsSource } = useInfiniteGroups({
    filter: { status: { eq: ActiveStatusType.ACTIVE } },
    staleTime: 0,
  });

  const { currentSalesTeamsRelatedOrgs, currentsalesTeamsRelatedOrgIds } =
    useMemo(() => {
      const userGroups = (currentUserDetails?.groups?.items ?? []).flatMap(
        (item) => (item ? [item] : []),
      ) as Partial<UserGroup>[];

      const allDirectApplicationRoles = userGroups.flatMap((userGroup) =>
        (userGroup.groups?.applicationRoles?.items ?? []).flatMap((item) =>
          item ? [item] : [],
        ),
      ) as Partial<ApplicationRole>[];

      const inDirectGroups = groupsSource.filter(
        (group) => group.organizationId === currentUserOrganization,
      );

      const allInDirectApplicationRoles = inDirectGroups.flatMap((group) =>
        (group.applicationRoles?.items ?? []).flatMap((item) =>
          item ? [item] : [],
        ),
      ) as Partial<ApplicationRole>[];

      const finalApplicationRoles = [
        ...allDirectApplicationRoles,
        ...sortList(allInDirectApplicationRoles),
      ];

      const transformedApplicationRolesForCurrentSalesTeams =
        finalApplicationRoles.flatMap((applicationRole) => {
          const locationCodes = applicationRole.locations ?? [];

          const locations = countriesJson.filter((c) =>
            locationCodes.includes(c['alpha-3']),
          );

          return locations.map((location) => ({
            name: location.name,
            code: location['alpha-3'],
          }));
        });

      const salesTeamsRelatedOrgs: {
        id: string;
        key: string;
        name: string;
        text: string;
      }[] = allSalesForceOrganizations
        .filter((org) => {
          return (
            ['P', 'D'].includes(org.id[3]) &&
            transformedApplicationRolesForCurrentSalesTeams.some((role) =>
              org.id.startsWith(role.code),
            )
          );
        })
        .map((org) => ({
          id: org.id,
          key: org.id,
          name: org.name,
          text: org.name,
        }));

      const sortedSalesTeamRelatedOrgs = [...salesTeamsRelatedOrgs].sort(
        (a, b) => {
          if (a.name < b.name) {
            return -1;
          }

          if (a.name > b.name) {
            return 1;
          }

          return 0;
        },
      );

      const salesTeamsRelatedOrgIds: string[] = sortedSalesTeamRelatedOrgs.map(
        (org) => org.id,
      );

      return {
        userGroups,
        allDirectApplicationRoles,
        allInDirectApplicationRoles,
        finalApplicationRoles,
        transformedApplicationRolesForCurrentSalesTeams,
        currentSalesTeamsRelatedOrgs: sortedSalesTeamRelatedOrgs,
        currentsalesTeamsRelatedOrgIds: salesTeamsRelatedOrgIds,
      };
    }, [
      allSalesForceOrganizations,
      currentUserDetails?.groups?.items,
      currentUserOrganization,
      groupsSource,
    ]);

  const qualifiedLeads = useGetLeadsBasedOnStatus({
    leads,
    status: LeadOverallStatusType.QUALIFIED,
  });

  const disqualifiedLeads = useGetLeadsBasedOnStatus({
    leads,
    status: LeadOverallStatusType.DISQUALIFIED,
  });

  const approvedLeads = useGetLeadsBasedOnStatus({
    leads,
    status: LeadOverallStatusType.APPROVED,
  });

  const rejectedLeads = useGetLeadsBasedOnStatus({
    leads,
    status: LeadOverallStatusType.REJECTED,
  });

  const chart2Data = {
    [LeadOverallStatusType.QUALIFIED]: {
      name: capitalize(LeadOverallStatusType.QUALIFIED),
      value: qualifiedLeads.filteredLeads.length,
    },
    [LeadOverallStatusType.DISQUALIFIED]: {
      name: capitalize(LeadOverallStatusType.DISQUALIFIED),
      value: disqualifiedLeads.filteredLeads.length,
    },
    [LeadOverallStatusType.APPROVED]: {
      name: capitalize(LeadOverallStatusType.APPROVED),
      value: approvedLeads.filteredLeads.length,
    },
    [LeadOverallStatusType.REJECTED]: {
      name: capitalize(LeadOverallStatusType.REJECTED),
      value: rejectedLeads.filteredLeads.length,
    },
  };

  const leadsOfAllOrganisation = [
    {
      value: qualifiedLeads.filteredLeads.length,
      name: 'Qualified',
      orgId: 'ALL',
    },
    {
      value: disqualifiedLeads.filteredLeads.length,
      name: 'Disqualified',
      orgId: 'ALL',
    },
    {
      value: approvedLeads.filteredLeads.length,
      name: 'Approved',
      orgId: 'ALL',
    },
    {
      value: rejectedLeads.filteredLeads.length,
      name: 'Rejected',
      orgId: 'ALL',
    },
  ];

  const leadsOfSelectedOrganisation = [
    {
      value: qualifiedLeads.filteredLeads.filter(
        (x) => x.createdByUser?.organizationId === selectedOrganization,
      ).length,
      name: 'Qualified',
      orgId: selectedOrganization,
    },
    {
      value: disqualifiedLeads.filteredLeads.filter(
        (x) => x.createdByUser?.organizationId === selectedOrganization,
      ).length,
      name: 'Disqualified',
      orgId: selectedOrganization,
    },
    {
      value: approvedLeads.filteredLeads.filter(
        (x) => x.createdByUser?.organizationId === selectedOrganization,
      ).length,
      name: 'Approved',
      orgId: selectedOrganization,
    },
    {
      value: rejectedLeads.filteredLeads.filter(
        (x) => x.createdByUser?.organizationId === selectedOrganization,
      ).length,
      name: 'Rejected',
      orgId: selectedOrganization,
    },
  ];

  return {
    chart2Data: Object.values(chart2Data),
    currentUserOrganization,
    leadsOfAllOrganisation,
    selectedOrganization,
    setSelectedOrganization,
    currentSalesTeamsRelatedOrgs,
    currentsalesTeamsRelatedOrgIds,
    leadsOfSelectedOrganisation,
  };
};

export default useChartTwo;
