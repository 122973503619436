import { useEffect } from 'react';

import { isCurrentUserAuthenticated } from 'hooks/useGetCurrentAuthenticatedUser';

import { generateSessionTimeoutToast } from 'helpers/utils/generateToast';

const useSessionTimeout = ({
  navigate,
  location,
}: {
  navigate: any;
  location: any;
}) => {
  useEffect(() => {
    isCurrentUserAuthenticated().then((isAuthenticated) => {
      if (!isAuthenticated) {
        generateSessionTimeoutToast();
      }
    });
  }, [location, navigate]);
};

export default useSessionTimeout;
