import { SetStateAction } from 'jotai';
import { useEffect } from 'react';

import { Chapter, ProductDoc } from 'API';

import { CustomDocs } from 'types/documents';
import type { ChapterType } from 'types/Pages/chapter';

import useDocumentList from 'queries/documents.query';

/*
  Notes:
  
  A. The goal of the useEffect() function in here is to create a string called "breadCrumbString" and save it in the global state.
  
  B. This "breadCrumbString" string will have the details of all the parentChapters upto to the root level for the subChapterId or the contentId in question.
  
  C. We will use this "breadCrumbString" string to enable automatic un-collapsing of the sidebar on full page reload, build a breadcrumb etc.

  1. "breadCrumbString" is a string in which the substrings (chapters) are separated by [#!~*^&NESTED----CHAP&^*~!#]
    - The substrings (chapters) themselves are of the format `chapterName[#!NAME-ID!#]chapterId (or) docTitle[#!NAME-ID!#]docId.
    - The part before [#!NAME-ID!#] is the chapter "Name". The part after [#!NAME-ID!#] is the chapter "id".
  
  2. The part of the string before the [#!~*^&NESTED----CHAP&^*~!#] is the parentChapter of the part of the string that is after the [#!~*^&NESTED----CHAP&^*~!#]
  
  3. This pattern repeats until we get the current subChapter (whose id === subChapterId) or the current doc (whose id ==== contentId)
    
    - Examples 
      - `chapter1Name[#!NAME-ID!#]Chapter1Id[#!~*^&NESTED----CHAP&^*~!#]chapter2Name[#!NAME-ID!#]chapter2Id[#!~*^&NESTED----CHAP&^*~!#]chapter3Name[#!NAME-ID!#]Chapter3Id
      - `chapter1Name[#!NAME-ID!#]Chapter1Id[#!~*^&NESTED----CHAP&^*~!#]chapter2Name[#!NAME-ID!#]chapter2Id[#!~*^&NESTED----CHAP&^*~!#]docTitle[#!NAME-ID!#]docId

      - Here:
      - In example 1, chapter 3 (let' say, whose id === subChapterId) is the subChapter of chapter2 which is itself a subChapter of chapter1 and so on.
      - In example 2, doc (let' say whose id ==== contentId) exists under chapter2 which is a subChapter of chapter 1 and so on.
  
  4. So, When we split the "breadCrumbString" by [#!~*^&NESTED----CHAP&^*~!#], it will give us in order, all the top level parent chapters of the current subChapter or the current doc.
*/

type UseGenerateBreadCrumbStringType = {
  subChapterId: string;
  contentId: string;
  menus: ChapterType[];
  docs: CustomDocs;
  rootChapter: Partial<Chapter> | null;
  setBreadCrumbString: (update: SetStateAction<string>) => void | Promise<void>;
};

const useGenerateBreadCrumbString = ({
  subChapterId,
  contentId,
  menus,
  docs,
  rootChapter,
  setBreadCrumbString,
}: UseGenerateBreadCrumbStringType) => {
  const { data: parentProductDocData } = useDocumentList({
    productDocId: rootChapter?.parentProductDocId ?? '',
    enabled: !!rootChapter?.parentProductDocId,
  });

  useEffect(() => {
    const rootChaptersParentProductDoc = parentProductDocData?.data
      ?.getProductDoc as Partial<ProductDoc> | null;

    if (rootChaptersParentProductDoc) {
      if (subChapterId !== '' || contentId !== '') {
        // By the end, this "breadCrumbString" string will have the details of all the parentChapters upto to the root level for the subChapterId or the contentId in question.
        let breadCrumbString: string | undefined = '';

        // A variable to exit out of the main "for loop".
        let shouldBreak = false;

        /*
            nestedMenusMap will have all the chapters that exist at each loop level until we find the current subChapterId or the current contentId.
            So, nestedMenusMap[0] -> [allFirstLevelChapters], nestedMenusMap[1] -> [allSecondLevelChapters] and so on.
        */
        const nestedMenusMap = new Map<number, ChapterType[]>();

        /*
            nestedMenusBreadCrumbStringsMap will have the "breadCrumbsString" string for all chapters that exist at each loop level until we find the current subChapterId or the current contentId.
            So, nestedMenusBreadCrumbStringsMap[0] -> [allFirstLevelChaptersBreadCrumbStr], nestedMenusBreadCrumbStringsMap[1] -> [allSecondLevelChaptersBreadCrumbsStr] and so on.
        */
        const nestedMenusBreadCrumbStringsMap = new Map<number, string[]>();

        // By default, we set level 0 to "menus" which are just chapters directly under the root chapter.
        nestedMenusMap.set(0, menus);

        // A function to construct the next level chapters: (i.e., flatten all the subChapters under all the parentChapters in a particular nested level.)
        const getNextLevelNestedMenusFlattened = ({
          currentLevelMenus,
        }: {
          currentLevelMenus: ChapterType[];
        }) => currentLevelMenus.flatMap((m) => m.subChapters?.items || []);

        // A function to create the "breadCrumbString" for the subChapterId / contentId in question.
        const constructBreadCrumbString = ({
          level,
          currentLevelMenus,
        }: {
          level: number;
          currentLevelMenus: ChapterType[];
        }) => {
          // This below "if" block is to check if "contentId" matches any of the docs directly under the rootChapter itself.
          if (contentId !== '') {
            const rootChapterDoc = docs.find((f) => f?.id === contentId);

            if (rootChapterDoc) {
              /*
                    [#!~*^&NESTED----CHAP&^*~!#] is just some unique enough string we use to separate parentChapters from their subChapters / docs.
                    
                    READ THE "NOTES" AT THE TOP OF THIS FILE for how this works.
                */

              breadCrumbString = `${rootChaptersParentProductDoc.name}[#!NAME-ID!#]${rootChaptersParentProductDoc.id}[#!~*^&NESTED----CHAP&^*~!#]${rootChapter?.name}[#!NAME-ID!#]${rootChapter?.id}[#!~*^&NESTED----CHAP&^*~!#]${rootChapterDoc.title}[#!NAME-ID!#]${rootChapterDoc.id}`;
              shouldBreak = true;

              return;
            }
          }

          // This maps through all chapters in a particular nested level and returns the "breadCrumbString" for each of them.
          const allBreadCrumbStringsForTheCurrentMenus = currentLevelMenus.map(
            (m) => {
              const breadCrumbStringMaker = (currentLevel: number) => {
                const rootBreadCrumbString = `${rootChaptersParentProductDoc.name}[#!NAME-ID!#]${rootChaptersParentProductDoc.id}[#!~*^&NESTED----CHAP&^*~!#]${rootChapter?.name}[#!NAME-ID!#]${rootChapter?.id}`;

                if (currentLevel === 0) {
                  /*
                        [#!~*^&NESTED----CHAP&^*~!#] is just some unique enough string we use to separate parentChapters from their subChapters / docs.
                    
                        READ THE "NOTES" AT THE TOP OF THIS FILE for how this works.
                    */

                  let currentBreadCrumbString = `${rootBreadCrumbString}[#!~*^&NESTED----CHAP&^*~!#]${m.name}[#!NAME-ID!#]${m.version}[#!NAME-ID!#]${m.id}`;

                  // Add doc details to the breadCrumbString in case the doc with the required contentId exists under the current chapter.
                  if (contentId !== '') {
                    const docsUnderCurrentChapter = m.docs?.items ?? [];
                    // eslint-disable-next-line unicorn/no-for-loop, @typescript-eslint/prefer-for-of
                    for (
                      let i = 0;
                      i < docsUnderCurrentChapter.length;
                      i += 1
                    ) {
                      if (docsUnderCurrentChapter[i].id === contentId) {
                        currentBreadCrumbString = `${currentBreadCrumbString}[#!~*^&NESTED----CHAP&^*~!#]${docsUnderCurrentChapter[i].title}[#!NAME-ID!#]${docsUnderCurrentChapter[i].id}`;
                      }
                    }
                  }

                  return currentBreadCrumbString;
                }

                const previousLevelMenusBreadCrumbStrings =
                  nestedMenusBreadCrumbStringsMap.get(currentLevel - 1) || [];

                const possibleParentMenus =
                  nestedMenusMap.get(currentLevel - 1) || [];

                // Find the parent chapter that has the current level sub chapter.
                const parentMenu = possibleParentMenus.find((p) =>
                  p.subChapters?.items?.some((c) => c.id === m.id),
                );

                if (parentMenu) {
                  const parentBreadCrumbString =
                    previousLevelMenusBreadCrumbStrings.find((t) => {
                      return t.includes(parentMenu.id);
                    });

                  let currentBreadCrumbString = `${parentBreadCrumbString}[#!~*^&NESTED----CHAP&^*~!#]${m.name}[#!NAME-ID!#]${m.version}[#!NAME-ID!#]${m.id}`;

                  // Add doc details to the breadCrumbString in case the doc with the required contentId exists under the current chapter.
                  if (contentId !== '') {
                    const docsUnderCurrentChapter = m.docs?.items ?? [];
                    // eslint-disable-next-line unicorn/no-for-loop, @typescript-eslint/prefer-for-of
                    for (
                      let i = 0;
                      i < docsUnderCurrentChapter.length;
                      i += 1
                    ) {
                      if (docsUnderCurrentChapter[i].id === contentId) {
                        currentBreadCrumbString = `${currentBreadCrumbString}[#!~*^&NESTED----CHAP&^*~!#]${docsUnderCurrentChapter[i].title}[#!NAME-ID!#]${docsUnderCurrentChapter[i].id}`;
                      }
                    }
                  }

                  return currentBreadCrumbString;
                }

                return '';
              };

              return breadCrumbStringMaker(level);
            },
          );

          // We check if any of the current level menus breadCrumbStrings end with either the current subChapterId or the current contentId
          breadCrumbString = allBreadCrumbStringsForTheCurrentMenus.find((f) =>
            f.endsWith(subChapterId || contentId),
          );

          // If a breadCrumbString that ends with the current subChapterId / contentId exists. We break out of the main loop by setting "shouldBreak" to true.
          if (breadCrumbString) {
            shouldBreak = true;
          } else {
            // If a breadCrumbString that ends with the current subChapterId / contentId does not exist.
            // We generate next level chapters from the current level chapters and update nestedMenusMap and nestedMenusBreadCrumbStringsMap accordingly.
            // and continue on with the main "for" loop for the next level.
            const nextLevelNestedMenusFlattened =
              getNextLevelNestedMenusFlattened({
                currentLevelMenus,
              });

            nestedMenusMap.set(level + 1, nextLevelNestedMenusFlattened);
            nestedMenusBreadCrumbStringsMap.set(
              level,
              allBreadCrumbStringsForTheCurrentMenus,
            );
          }
        };

        // The main "for" loop. We loop the first 5 nested levels of chapters only.
        // This is done depending upon the graphql query called "getNestedSubChapters" in graphql/custom-queries.
        // This graphql query is written so we can only get 4 levels of subChapters.
        // Increase (i < 5) to (i < n + 1) if the graphql query is later updated to fetch "n" levels of subChapters.
        for (let i = 0; i < 5; i += 1) {
          // shouldBreak is set in the constructBreadCrumbString function upon building the breadCrumbString with the required subChapterId or the required contentId.
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (shouldBreak) {
            break;
          } else {
            constructBreadCrumbString({
              level: i,
              currentLevelMenus: nestedMenusMap.get(i) || [],
            });
          }
        }

        // After we break out of the for loop with the variable "shouldBreak", we will have the required "breadCrumbString" unless the contentId / subChapterId entered by the user in the url itself is wrong.
        // We then set that in the global state with "setBreadCrumbString".

        if (breadCrumbString) {
          setBreadCrumbString(breadCrumbString);
        }
      } else {
        // If subChapterId and contentId are both empty strings, then we are in the rootChapter page.
        // So, we reset the breadCrumbString if it was previously set to show only the rootChapter and the parentProduct to which the rootChapter belongs.
        // This prevents a bug, which is that the previous outdated breadCrumb (if any) is being displayed when it should not.
        setBreadCrumbString(
          `${rootChaptersParentProductDoc.name}[#!NAME-ID!#]${rootChaptersParentProductDoc.id}[#!~*^&NESTED----CHAP&^*~!#]${rootChapter?.name}[#!NAME-ID!#]${rootChapter?.id}`,
        );
      }
    }
  }, [
    subChapterId,
    contentId,
    menus,
    docs,
    rootChapter,
    setBreadCrumbString,
    parentProductDocData,
  ]);
};

export default useGenerateBreadCrumbString;
