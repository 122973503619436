import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { UserType } from 'types/user';

import { isAdminAtom } from 'atoms/modals';

const useSetSuperAdmin = ({
  currentAuthenticatedUser,
}: {
  currentAuthenticatedUser: UserType | null;
}) => {
  const setIsSuperAdmin = useSetAtom(isAdminAtom);

  useEffect(() => {
    const userGroups = currentAuthenticatedUser?.['cognito:groups'] || [];

    if (userGroups?.length) {
      const isadmin = userGroups?.filter(
        (group: string) => group === 'superAdmin',
      );

      setIsSuperAdmin(!!isadmin?.length);
    }
  }, [currentAuthenticatedUser, setIsSuperAdmin]);
};

export default useSetSuperAdmin;
