import { useMemo } from 'react';

import { Currency, Lead, LeadOverallStatusType } from 'API';

import useGetAllCurrencies from 'hooks/useGetCurrencies';

const getBudget = (lead: Partial<Lead>, currencies: Partial<Currency>[]) => {
  if (lead.estimatedBudget) {
    const currentCurrencyCode = lead.estimatedBudget.split(' ')[0];
    const currentCurrencyAmount = lead.estimatedBudget.split(' ')[1];

    const currentCurrencyDetails = currencies.find(
      (c) => c.code?.toLowerCase() === currentCurrencyCode.toLowerCase(),
    );

    const currentCurrencyExchangeRateUsd = currentCurrencyDetails?.exchangeRate;

    if (currentCurrencyAmount && currentCurrencyExchangeRateUsd) {
      return (
        Number(currentCurrencyAmount) * Number(currentCurrencyExchangeRateUsd)
      );
    }
  }

  return 0;
};

export const useLeadsRevenue = ({ leads }: { leads: Partial<Lead>[] }) => {
  const { currencies } = useGetAllCurrencies();

  const { leadsRevenue } = useMemo(() => {
    const defaultLeadsRevenue = {
      overallLeadsBudget: 0,
      qualifiedAndApprovedLeadsBudget: 0,
      qualifiedAndApprovedAndDisquallifiedLeadsBudget: 0,
    };

    const {
      overallLeadsBudget,
      qualifiedAndApprovedLeadsBudget,
      qualifiedAndApprovedAndDisquallifiedLeadsBudget,
    } = leads.reduce((acc, curr) => {
      const tempOverallLeadsBudget =
        acc.overallLeadsBudget + getBudget(curr, currencies);

      let tempLeadsRevenue = {
        ...acc,
        overallLeadsBudget: tempOverallLeadsBudget,
      };

      if (
        curr.overallStatus === LeadOverallStatusType.QUALIFIED ||
        curr.overallStatus === LeadOverallStatusType.APPROVED
      ) {
        const tempQualifiedAndApprovedLeadsBudget =
          acc.qualifiedAndApprovedLeadsBudget + getBudget(curr, currencies);

        tempLeadsRevenue = {
          ...tempLeadsRevenue,
          qualifiedAndApprovedLeadsBudget: tempQualifiedAndApprovedLeadsBudget,
        };
      }

      if (curr.overallStatus !== LeadOverallStatusType.REJECTED) {
        const tempQualifiedAndApprovedAndDisquallifiedLeadsBudget =
          acc.qualifiedAndApprovedAndDisquallifiedLeadsBudget +
          getBudget(curr, currencies);

        tempLeadsRevenue = {
          ...tempLeadsRevenue,
          qualifiedAndApprovedAndDisquallifiedLeadsBudget:
            tempQualifiedAndApprovedAndDisquallifiedLeadsBudget,
        };
      }

      return { ...tempLeadsRevenue };
    }, defaultLeadsRevenue);

    const formattedRevenue = {
      overallLeadsBudget: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(overallLeadsBudget),
      qualifiedAndApprovedLeadsBudget: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
      }).format(qualifiedAndApprovedLeadsBudget),
      qualifiedAndApprovedAndDisquallifiedLeadsBudget: new Intl.NumberFormat(
        'en-US',
        { style: 'currency', currency: 'USD', maximumFractionDigits: 0 },
      ).format(qualifiedAndApprovedAndDisquallifiedLeadsBudget),
    };

    return { leadsRevenue: formattedRevenue };
  }, [currencies, leads]);

  return { leadsRevenue };
};

export default useLeadsRevenue;
