import { DefaultButton } from '@fluentui/react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useRef } from 'react';

import Flex from 'components/Flex';
import Icon from 'components/Icon';
import { IconNameType } from 'components/Icon/Icon';

import styles from './modal.module.css';

type ChildrenPropType = {
  children: React.ReactNode;
};

type ModalPropType = {
  children?: React.ReactNode;
  isOpen?: boolean;
  closeModal(): void;
  title?: string;
  iconContainerColor?: string;
  iconColor?: string;
  containerWidth?: string;
  iconSize?: string;
  iconName?: IconNameType;
};
const Modal = ({
  title,
  iconContainerColor,
  iconColor = 'text-gray-500',
  iconSize = 'w-8',
  containerWidth = 'max-w-md',
  children,
  isOpen,
  iconName,
  closeModal,
}: ModalPropType) => {
  const ref = useRef(null);

  const IconClass = classNames(iconColor, iconSize);

  const bodyContainerClass = classNames(
    styles['dialog-body-container'],
    containerWidth,
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={ref}
        as="div"
        className={styles['dialog-container']}
        onClose={() => {}}
        style={{ zIndex: 10_000 }}
      >
        <div className={styles['outer-container']}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={styles['dialog-overlay']} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen bg-black align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={bodyContainerClass}>
              <div ref={ref} className="absolute right-6.5">
                <DefaultButton type="button" onClick={closeModal}>
                  x
                </DefaultButton>
              </div>

              {iconName && (
                <Flex
                  backgroundColor={iconContainerColor}
                  height="h-16"
                  width="w-16"
                  justify="center"
                  direction="col"
                  alignItems="center"
                  margin="mb-5 mx-auto"
                  rounded="rounded-full"
                >
                  <Icon name={iconName} className={IconClass} />
                </Flex>
              )}

              <Dialog.Title as="h3" className={styles['dialog-title']}>
                {title}
              </Dialog.Title>
              <div className="mt-2">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

Modal.Body = ({ children }: ChildrenPropType) => <div>{children}</div>;
Modal.ButtonPannel = ({ children }: ChildrenPropType) => <div>{children}</div>;

export default Modal;
