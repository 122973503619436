import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CreateDocInput, StatusType, UpdateDocInput } from 'API';

import {
  SAVED_SUCCESSFULLY,
  FAILED_TO_SAVE,
  UPDATED_SUCCESSFULLY,
  FAILED_TO_UPDATE,
  FAILED_TO_DELETE,
  DELETED_SUCCESSFULLY,
} from 'consts/alertMessages';

import useConstructStatus from 'hooks/useConstructStatus';

import {
  useCreateDoc,
  useUpdateDoc,
  useDeleteDoc,
  createDocRequest,
  updateDocRequest,
} from 'queries/docs.mutation';

import generateToast from 'helpers/utils/generateToast';

import { WithRequiredProperty } from 'types';
import type {
  DefaultTContext,
  DefaultTData,
  DefaultTError,
  IUseMutationProps,
} from 'types';
import getChapterQueryKey from 'helpers/utils/getChapterQueryKey';

type CustomUpdateDocInput = Partial<Omit<UpdateDocInput, 'id'>> &
  Pick<UpdateDocInput, 'id'>;

type IUseCreateDocMutationProps = IUseMutationProps<
  DefaultTData,
  DefaultTError,
  CreateDocInput,
  DefaultTContext
>;

type IUseUpdateDocMutationProps = IUseMutationProps<
  DefaultTData,
  DefaultTError,
  UpdateDocInput,
  DefaultTContext
>;

export const useCreateDocMutation = (
  props: IUseCreateDocMutationProps = {},
) => {
  const onMutateCallback = props?.onMutateCallback ?? (() => {});
  const onSuccessCallback = props?.onSuccessCallback ?? (() => {});
  const onErrorCallback = props?.onErrorCallback ?? (() => {});
  const onSettledCallback = props?.onSettledCallback ?? (() => {});

  const queryClient = useQueryClient();

  const createDocMutation = useMutation({
    mutationFn: createDocRequest,
    onMutate: (variables) => {
      onMutateCallback({ variables });
    },
    onSuccess: (data, variables, context) => {
      onSuccessCallback({ data, variables, context });
    },
    onError: (error, variables, context) => {
      onErrorCallback({ error, variables, context });
    },
    onSettled: (data, error, variables, context) => {
      void queryClient.invalidateQueries([
        'rootChapter',
        { id: variables.rootChapterId },
      ]);

      void queryClient.invalidateQueries([`docVersions/${variables.id}`]);
      void queryClient.invalidateQueries([`docVersion/${variables.id}`]);

      onSettledCallback({ data, error, variables, context });
    },
  });

  return createDocMutation;
};

export const useUpdateDocMutation = (
  props: IUseUpdateDocMutationProps = {},
) => {
  const onMutateCallback = props?.onMutateCallback ?? (() => {});
  const onSuccessCallback = props?.onSuccessCallback ?? (() => {});
  const onErrorCallback = props?.onErrorCallback ?? (() => {});
  const onSettledCallback = props?.onSettledCallback ?? (() => {});

  const queryClient = useQueryClient();

  const updateDocMutation = useMutation({
    mutationFn: updateDocRequest,
    onMutate: (variables) => {
      onMutateCallback({ variables });
    },
    onSuccess: (data, variables, context) => {
      onSuccessCallback({ data, variables, context });
    },
    onError: (error, variables, context) => {
      onErrorCallback({ error, variables, context });
    },
    onSettled: (data, error, variables, context) => {
      void queryClient.invalidateQueries([
        'rootChapter',
        { id: variables.rootChapterId },
      ]);

      void queryClient.invalidateQueries([`docVersions/${variables.id}`]);
      void queryClient.invalidateQueries([`docVersion/${variables.id}`]);

      onSettledCallback({ data, error, variables, context });
    },
  });

  return updateDocMutation;
};

const useDocMutations = () => {
  const search = useLocation().search;
  const pathname = useLocation().pathname;

  const { status } = useConstructStatus({ search });

  const createDocMutation = useCreateDoc();
  const updateDocMutation = useUpdateDoc();
  const deleteDocMutation = useDeleteDoc();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { documentId } = useParams();

  const { isLoading: isCreateLoading, isSuccess: isCreateSuccess } =
    createDocMutation;
  const { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess } =
    updateDocMutation;

  const onCreateDoc = (
    doc: WithRequiredProperty<CreateDocInput, 'tenantId'>,
    callback: CallableFunction,
    showNotification = true,
    setMessage: any,
  ) => {
    createDocMutation.mutate(doc, {
      onSuccess: () => {
        void queryClient.invalidateQueries([
          'rootChapter',
          { id: doc.rootChapterId },
        ]);

        void queryClient.invalidateQueries([`docVersions/${doc.id}`]);
        void queryClient.invalidateQueries([`docVersion/${doc.id}`]);
        void queryClient.invalidateQueries(
          getChapterQueryKey({ id: doc.chapterId }),
        );

        if (status === StatusType.PUBLISHED) {
          if (showNotification) {
            generateToast({
              toastContent:
                'Added successfully. You can find this document in "Edited Documents"',
              type: 'success',
            });
          }
        } else {
          generateToast({ toastContent: SAVED_SUCCESSFULLY, type: 'success' });
        }

        callback();
      },
      onError: () => {
        generateToast({ toastContent: FAILED_TO_SAVE, type: 'error' });
      },
    });
  };

  const onUpdateDoc = (
    doc: CustomUpdateDocInput,
    callback: CallableFunction,
    setMessage: any,
  ) => {
    updateDocMutation.mutate(doc as UpdateDocInput, {
      onSuccess: () => {
        void queryClient.invalidateQueries([
          'rootChapter',
          { id: doc.rootChapterId },
        ]);

        void queryClient.invalidateQueries([`docVersions/${doc.id}`]);
        void queryClient.invalidateQueries([`docVersion/${doc.id}`]);
        void queryClient.invalidateQueries(
          getChapterQueryKey({ id: doc.chapterId }),
        );

        if (status === StatusType.PUBLISHED) {
          generateToast({
            toastContent:
              'Updated successfully. You can find the updated document in "Edited Documents"',

            type: 'success',
          });
        } else {
          generateToast({
            toastContent: UPDATED_SUCCESSFULLY,
            type: 'success',
          });
        }

        callback();
      },
      onError: () => {
        generateToast({ toastContent: FAILED_TO_UPDATE, type: 'error' });
      },
    });
  };

  const onDeleteDoc = ({
    id,
    version,
    setMessage,
  }: {
    id: string;
    version: string;
    setMessage: any;
  }) => {
    deleteDocMutation.mutate(
      { id, version },
      {
        onSuccess: () => {
          if (id === pathname.split('/content/')[1]) {
            navigate(`/products/documents/${documentId}?status=${status}`);
          }
          void queryClient.invalidateQueries(['rootChapter']);
          queryClient.invalidateQueries([`docVersions/${id}`]);
          queryClient.invalidateQueries([`docVersion/${id}`]);
          generateToast({
            toastContent: DELETED_SUCCESSFULLY,
            type: 'success',
          });
        },
        onError: () => {
          generateToast({ toastContent: FAILED_TO_DELETE, type: 'error' });
        },
      },
    );
  };

  return {
    onCreateDoc,
    onUpdateDoc,
    onDeleteDoc,
    isCreateLoading,
    isUpdateLoading,
    isCreateSuccess,
    isUpdateSuccess,
  };
};

export default useDocMutations;
