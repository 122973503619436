import { DefaultButton, PrimaryButton, DialogType } from '@fluentui/react';
import { Storage } from 'aws-amplify';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import markdownit from 'markdown-it';
import qs from 'query-string';
import { useState, useEffect, useMemo, useCallback, createRef } from 'react';
import { useForm } from 'react-hook-form';
import MdEditor from 'react-markdown-editor-lite';
import { HtmlType } from 'react-markdown-editor-lite/cjs/editor/preview';
import { UploadFunc } from 'react-markdown-editor-lite/cjs/share/var';

import 'react-markdown-editor-lite/lib/index.css';
import { useNavigate, useLocation } from 'react-router-dom';

import FeedbackMessage from 'components/FeedbackMessage';
import Flex from 'components/Flex';
import InputField from 'components/InputField';
import Modal from 'components/Modal';
import Text from 'components/Text';

import {
  Chapter,
  CreateDocItemInput,
  DocItem,
  DocItemType,
  StatusType,
  UpdateDocItemInput,
} from 'API';

import { AddDocItemParams } from 'types/Pages/Products';

import {
  InitialDocItemProps,
  docItemEditAtom,
  initialDocItemAtomProps,
  noneValue,
} from 'atoms/modals';

import { INITIAL_DOC_SETTINGS } from 'consts/docVersions';
import falainaTenantId from 'consts/tenant';
import { AS_NAME, AS_NUMBER_NOT_REQUIRED } from 'consts/validations';

import useChapterMutations from 'hooks/useChapterMutations';
import useDocItemMutations from 'hooks/useDocItemMutations';
import useGetCurrentAuthenticatedUser, {
  isCurrentUserAuthenticated,
} from 'hooks/useGetCurrentAuthenticatedUser';
import useStatus from 'hooks/useStatus';

import { getS3InputObjectForMarkdown } from 'helpers/transformFile';
import { generateSessionTimeoutToast } from 'helpers/utils/generateToast';

import markdownitIns from 'markdown-it-ins';
import './modalForm.module.css';
import { ToastMessageType } from 'types';

type CustomCreateDocItemInput = Omit<CreateDocItemInput, 'description'> &
  Pick<CreateDocItemInput, 'description'>;

type CustomUpdateDocItemInput = Partial<Omit<UpdateDocItemInput, 'id'>> &
  Pick<UpdateDocItemInput, 'id'>;

type SetToastMessageType = (messageObject: {
  message: string;
  type: string;
}) => void;

type IModalFormProps = {
  rootChapter?: Partial<Chapter | null>;
  allRootChapterVersions: Partial<Chapter | null>[];
  setMessage: SetToastMessageType;
  message: ToastMessageType;
};

let oldContent = '';
let prevStatus:
  | StatusType.PENDING
  | StatusType.PUBLISHED
  | StatusType.PENDING_APPROVAL
  | null;

const submitButtonRef: React.Ref<HTMLButtonElement> = createRef();

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
}).use(markdownitIns);

const useParent = () => {
  const docItemAtomValue = useAtomValue(docItemEditAtom);

  const { docItem } = docItemAtomValue;

  const docId = docItem?.docId ?? noneValue;
  const rootChapterId = docItem?.rootChapterId ?? noneValue;

  const parent = useMemo(
    () => ({
      docId,
      rootChapterId,
    }),
    [docId, rootChapterId],
  );

  return parent;
};

const useClosePanel = () => {
  const setDocItemAtomValue = useSetAtom(docItemEditAtom);

  const closePanel = useCallback(() => {
    setDocItemAtomValue(initialDocItemAtomProps);
  }, [setDocItemAtomValue]);

  return closePanel;
};

const useDocItemForm = () => {
  const docItemAtomValue = useAtomValue(docItemEditAtom);

  const { docItem } = docItemAtomValue;

  const defaultValues: AddDocItemParams = useMemo(
    () => ({
      title: '',
      description: '',
      orderFloat: '',
      file: '',
    }),
    [],
  );

  const formProps = useForm<AddDocItemParams>({
    defaultValues,
  });

  const { reset } = formProps;

  useEffect(() => {
    if (docItem) {
      const defaults = {
        title: docItem.title ?? defaultValues.title,
        orderFloat: docItem.orderFloat ?? defaultValues.orderFloat,
      };

      reset(defaults);
    }
  }, [
    defaultValues.description,
    defaultValues.orderFloat,
    defaultValues.title,
    docItem,
    reset,
  ]);

  return formProps;
};

const ModalForm = ({
  rootChapter,
  allRootChapterVersions = [],
  setMessage,
  message,
}: IModalFormProps) => {
  const docItemAtomValue = useAtomValue(docItemEditAtom);

  const [, setDocItemEdit] = useAtom(docItemEditAtom);

  const search = useLocation().search;

  const { isOpen, action, docItem } = docItemAtomValue;

  const status = useStatus();
  if (!prevStatus) {
    prevStatus = status;
  }

  const parentObj = useParent();

  const closePanel = useClosePanel();

  const currentAuthenticatedUser = useGetCurrentAuthenticatedUser();

  const currentAuthenticatedUserId = currentAuthenticatedUser?.sub ?? null;

  const tenantId = falainaTenantId;

  const docItemType = docItem?.itemType ?? DocItemType.content;

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useDocItemForm();

  const { onCreateDocItem, onUpdateDocItem, isCreateLoading, isUpdateLoading } =
    useDocItemMutations();

  const { onCreateChapter } = useChapterMutations();

  const [markdownContent, setMarkdownContent] = useState<string>(
    docItemType === DocItemType.code
      ? '```javascript'
      : docItem?.description ?? '',
  );

  const setNewDocItemEdit = useCallback(
    (docsItem: Partial<DocItem> | null) => {
      setDocItemEdit({
        docItem: docsItem,
        isOpen: true,
        action: 'UPDATE',
      });
    },
    [setDocItemEdit],
  );

  const resetMarkdownContent = useCallback(() => {
    if (docItem?.description) {
      setMarkdownContent(docItem.description);
    } else {
      setMarkdownContent(
        docItemType === DocItemType.code ? '```javascript \n' : '',
      );
    }
  }, [docItemType, docItem?.description]);
  const handleCloseModal = useCallback(() => {
    closePanel();
    resetMarkdownContent();
  }, [resetMarkdownContent, closePanel]);

  useEffect(() => {
    if (docItem?.description) {
      oldContent = docItem.description;
      setMarkdownContent(docItem.description);
    } else {
      setMarkdownContent(
        docItemType === DocItemType.code ? '```javascript \n' : '',
      );
    }
  }, [docItemType, docItem?.description]);

  useEffect(() => {
    const handlePopState = () => {
      handleCloseModal();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleCloseModal, status]);

  useEffect(() => {
    const autoSaveDebounce = debounce(() => {
      if (
        isOpen &&
        markdownContent !== '```javascript \n' &&
        markdownContent !== oldContent
      ) {
        submitButtonRef.current?.click();
      }
    }, 3000);

    autoSaveDebounce();

    return () => {
      autoSaveDebounce.cancel();
    };
  }, [isOpen, markdownContent]);

  const handleCreateNewRootChapterVersion = useCallback(() => {
    const isPendingRootChapterVersionPresent = allRootChapterVersions.some(
      (x) => x?.status === StatusType.PENDING,
    );

    const shouldCreateNewRootChapterVersion =
      currentAuthenticatedUserId &&
      status === StatusType.PUBLISHED &&
      rootChapter?.status === StatusType.PUBLISHED &&
      !isPendingRootChapterVersionPresent;

    if (shouldCreateNewRootChapterVersion) {
      const final = {
        ...rootChapter,
        id: rootChapter.id,
        name: rootChapter.name ?? '',
        description: rootChapter.description ?? '',
        version: rootChapter.version
          ? String(Number(rootChapter.version) + 1)
          : '2',
        status: StatusType.PENDING,
        createdByUserId: currentAuthenticatedUserId,
        lastModifiedByUserId: currentAuthenticatedUserId,
        tenantId,
        rootChapterId: rootChapter.rootChapterId ?? '',
        file: rootChapter.file,
      };

      onCreateChapter({
        chapter: {
          ...final,
        },
        isParentProduct: true,
        callback: () => {},
        showNotification: true,
      });
    }
  }, [
    allRootChapterVersions,
    currentAuthenticatedUserId,
    onCreateChapter,
    rootChapter,
    status,
    tenantId,
  ]);

  const addDocument = useCallback(
    (data: AddDocItemParams) => {
      if (currentAuthenticatedUserId) {
        const final: CustomCreateDocItemInput = {
          orderFloat: data.orderFloat,
          title: (data.title || docItem?.itemType) ?? '',
          description: markdownContent,
          itemType: docItem?.itemType ?? docItemType,
          ...INITIAL_DOC_SETTINGS,
          ...parentObj,
          createdByUserId: currentAuthenticatedUserId,
          lastModifiedByUserId: currentAuthenticatedUserId,
          tenantId,
        };

        const isPendingRootChapterVersionPresent = allRootChapterVersions.some(
          (x) => x?.status === StatusType.PENDING,
        );

        onCreateDocItem({
          docItem: final,
          callback: (docsItem: Partial<DocItem> | null) => {
            setNewDocItemEdit(docsItem);

            setMessage({
              message: 'Auto saved successfully',
              type: 'success',
            });

            if (!isPendingRootChapterVersionPresent) {
              handleCreateNewRootChapterVersion();
            }
          },
          showNotification: !!isPendingRootChapterVersionPresent,
          setToastMessage: setMessage,
        });
      }
    },
    [
      allRootChapterVersions,
      currentAuthenticatedUserId,
      docItem?.itemType,
      docItemType,
      handleCreateNewRootChapterVersion,
      markdownContent,
      onCreateDocItem,
      parentObj,
      setMessage,
      setNewDocItemEdit,
      tenantId,
    ],
  );

  const updateDocument = useCallback(
    (data: AddDocItemParams) => {
      if (currentAuthenticatedUserId) {
        if (
          status === StatusType.PUBLISHED &&
          docItem?.status === StatusType.PUBLISHED
        ) {
          const final: CustomCreateDocItemInput = {
            orderFloat: data.orderFloat,
            title: (data.title || docItem.itemType) ?? '',
            description: markdownContent,
            itemType: docItem.itemType ?? docItemType,
            ...parentObj,
            id: docItem.id,
            version: docItem.version
              ? String(Number(docItem.version) + 1)
              : '2',
            status: StatusType.PENDING,
            lastModifiedByUserId: currentAuthenticatedUserId,
            createdByUserId: currentAuthenticatedUserId,
            tenantId,
          };

          const isPendingRootChapterVersionPresent =
            allRootChapterVersions.some(
              (x) => x?.status === StatusType.PENDING,
            );
          onCreateDocItem({
            docItem: final,
            callback: (docsItem: Partial<DocItem> | null) => {
              setNewDocItemEdit(docsItem);

              if (!isPendingRootChapterVersionPresent) {
                handleCreateNewRootChapterVersion();
              }
            },
            showNotification: !!isPendingRootChapterVersionPresent,
            setToastMessage: setMessage,
          });
        } else if (
          status === StatusType.PUBLISHED &&
          docItem?.status === StatusType.PENDING
        ) {
          const final: CustomUpdateDocItemInput = {
            id: docItem.id ?? '',
            version: docItem.version,
            lastModifiedByUserId: currentAuthenticatedUserId,
            orderFloat: data.orderFloat,
            title: data.title || docItem.itemType,
            description: markdownContent,
            ...parentObj,
          };

          onUpdateDocItem(
            { ...final },
            (docsItem: Partial<DocItem> | null) => {},
          );
        }

        if (status === StatusType.PENDING) {
          if (docItem?.status === StatusType.PUBLISHED) {
            const final: CustomCreateDocItemInput = {
              orderFloat: data.orderFloat,
              title: (data.title || docItem.itemType) ?? '',
              description: markdownContent,
              itemType: docItem.itemType ?? docItemType,
              ...parentObj,
              id: docItem.id,
              version: docItem.version
                ? String(Number(docItem.version) + 1)
                : '2',
              status: StatusType.PENDING,
              lastModifiedByUserId: currentAuthenticatedUserId,
              createdByUserId: currentAuthenticatedUserId,
              tenantId,
            };

            onCreateDocItem({
              docItem: final,
              callback: (docsItem: Partial<DocItem> | null) => {
                setNewDocItemEdit(docsItem);
              },

              showNotification: true,
              setToastMessage: setMessage,
            });
          }

          if (docItem?.status === StatusType.PENDING) {
            const final: CustomUpdateDocItemInput = {
              id: docItem.id ?? '',
              version: docItem.version,
              lastModifiedByUserId: currentAuthenticatedUserId,
              orderFloat: data.orderFloat,
              title: data.title || docItem.itemType,
              description: markdownContent,
              ...parentObj,
            };

            onUpdateDocItem(
              { ...final },
              (docsItem: Partial<DocItem> | null) => {},
            );
          }
        }
      }
    },
    [
      allRootChapterVersions,
      currentAuthenticatedUserId,
      docItem,
      docItemType,
      handleCreateNewRootChapterVersion,
      markdownContent,
      onCreateDocItem,
      onUpdateDocItem,
      parentObj,
      setMessage,
      setNewDocItemEdit,
      status,
      tenantId,
    ],
  );

  const [hasDescriptionError, setHasDescriptionError] = useState(false);

  const handleEditorChange = ({ html, text }: any): void => {
    if (text.length > 0 && html.length > 0) {
      setHasDescriptionError(false);
    } else {
      setHasDescriptionError(true);
    }

    setMarkdownContent(text);
  };

  const onMarkdownFormSubmit = useCallback(
    async (data: AddDocItemParams) => {
      const isValid = markdownContent.trim().length > 0; // Trimming is necessary here for validation.

      if (isValid) {
        const isAuthenticated = await isCurrentUserAuthenticated();

        if (isAuthenticated) {
          const isAdd = action === 'ADD';
          const isUpdate = action === 'UPDATE';

          if (isAdd) {
            addDocument(data);
            return;
          }

          if (isUpdate) {
            updateDocument(data);
            return;
          }
        }

        return generateSessionTimeoutToast();
      }

      setHasDescriptionError(true);
    },
    [action, addDocument, markdownContent, updateDocument],
  );

  const handleImageUpload: UploadFunc = async (file, callback) => {
    try {
      if (file) {
        const s3FileObj = getS3InputObjectForMarkdown([file]);

        if (s3FileObj?.key) {
          const s3File: any = await Storage.put(s3FileObj.key, file, {
            contentType: file.type,
          });

          if (s3File.key) {
            callback(
              `https://${
                import.meta.env.VITE_APP_aws_user_files_s3_bucket
              }.s3.amazonaws.com/public/${s3File.key}`,
            );
          }
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleRenderHtml: (
    text: string,
  ) => HtmlType | Promise<HtmlType> | (() => HtmlType) = (text) =>
    md.render(text);

  return (
    <Modal
      containerWidth="w-full"
      isOpen={isOpen}
      closeModal={() => {
        handleCloseModal();
        // closeModalAutomatically = false;
      }}
      title={`Enter the ${
        docItemType === DocItemType.code ? 'code' : 'content'
      } for your markdown below`}
    >
      <Modal.Body>
        <form onSubmit={handleSubmit(onMarkdownFormSubmit)}>
          <Text>
            Note: Changes in &quot;Reference for in this article&quot; &
            &quot;Order&quot; inputs are needed to be saved manually!
          </Text>
          <Flex direction="col" space="space-y-7.5">
            <Flex
              width="w-max"
              space="gap-x-8"
              className="flex-col md:flex-row"
            >
              {docItemType === DocItemType.content && (
                <InputField
                  required
                  width="w-80"
                  control={control}
                  name="title"
                  type="text"
                  label="Reference for in this article"
                  placeholder="Enter Reference"
                  rules={AS_NAME}
                  error={errors.title}
                />
              )}
              <InputField
                width="w-80"
                type="number"
                name="orderFloat"
                control={control}
                label="Order"
                error={errors.orderFloat}
                placeholder="Order"
                rules={AS_NUMBER_NOT_REQUIRED}
              />
            </Flex>

            {docItemType === DocItemType.code && (
              <Text color="text-gray-500" className="italic">
                Note : Please change the language as per your need. i.e.,
                ```python or ```ruby or ```javascript.
              </Text>
            )}

            <MdEditor
              key="md-editor"
              style={{
                height: '500px',
                textAlign: 'left',
                marginTop: '24px',
              }}
              renderHTML={handleRenderHtml}
              onChange={handleEditorChange}
              value={markdownContent}
              onImageUpload={handleImageUpload}
            />
          </Flex>

          <Flex justify="end" direction="col">
            <Flex justify="end" space="space-x-10" margin="mt-5">
              <FeedbackMessage
                message={
                  message.type === 'success'
                    ? 'Auto saved successfully'
                    : message.type === 'error'
                      ? 'Could not save, please try again!'
                      : ''
                }
                type={message.type}
              />
              {hasDescriptionError && (
                <Text color="text-red-500" className="italic">
                  Content cannot be empty. Please enter a value.
                </Text>
              )}
            </Flex>
            <Flex justify="end" space="space-x-10" margin="mt-5">
              <DefaultButton
                onClick={() => {
                  handleCloseModal();
                  // closeModalAutomatically = false;
                }}
                text="Close"
              />

              {isCreateLoading || isUpdateLoading ? (
                <PrimaryButton disabled text="Saving Markdown..." />
              ) : (
                <PrimaryButton
                  elementRef={submitButtonRef}
                  text="Save Markdown"
                  type="submit"
                  className="bg-primary focus:outline-none"
                />
              )}
            </Flex>
          </Flex>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalForm;
