export const TAG_TYPE_VARIANTS = {
  span: 'span',
  p: 'p',
  psm: 'p',
  plg: 'p',
  pxs: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'h6',
  'sub-heading': 'p',
  title: 'h3',
  button: 'p',
};

export const POSITION_VARIANTS = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
};

export const FONT_WEIGHT_VARIANTS = {
  100: 'font-thin',
  200: 'font-extralight',
  300: 'font-light',
  400: 'font-normal',
  500: 'font-medium',
  600: 'font-semibold',
  700: 'font-bold',
  800: 'font-extrabold',
  900: 'font-black',
};
