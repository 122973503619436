import { v4 as uuidv4 } from 'uuid';

export const getS3InputObject = (input: any) => {
  const imagekey = uuidv4();

  const selectedFile = input[0];

  if (selectedFile) {
    const { name, type: mimeType } = selectedFile;
    const extension1 = /([^.]+)(\.(\w+))?$/.exec(name)?.[3];

    const key = [`${imagekey}`, extension1].filter((x) => !!x).join('.');
    const bucket = import.meta.env.VITE_APP_aws_user_files_s3_bucket || ''; // keep it like this
    const region =
      import.meta.env.VITE_APP_aws_user_files_s3_bucket_region || ''; // keep it like this

    const file = {
      region,
      bucket,
      key: `public/products/documents/${key}`,
      mimeType,
      localUri: selectedFile,
    };

    return file;
  }

  return null;
};

export const getMultipleS3InputObject = ({
  files,
  type,
}: {
  files: any;
  type?: string;
}) => {
  const convertedFiles = files ? [...files] : [];

  const s3FileInputs =
    convertedFiles.length > 0
      ? convertedFiles.map((convertedFile) => {
          const imagekey = uuidv4();
          const { name, type: mimeType, data } = convertedFile;
          const extension1 = /([^.]+)(\.(\w+))?$/.exec(name)?.[3];

          const key = [`${imagekey}`, extension1].filter((x) => !!x).join('.');
          const bucket =
            import.meta.env.VITE_APP_aws_user_files_s3_bucket || ''; // keep it like this
          const region =
            import.meta.env.VITE_APP_aws_user_files_s3_bucket_region || ''; // keep it like this

          const file = {
            region,
            bucket,
            key: `public/products/documents/${key}`,
            mimeType,
            localUri: type === 'uppyAttachments' ? data : convertedFile,
          };

          return file;
        })
      : [];

  return s3FileInputs;
};

export const getS3InputObjectForMarkdown = (input: any) => {
  const imagekey = uuidv4();

  const selectedFile = input[0];

  if (selectedFile) {
    const { name, type: mimeType } = selectedFile;
    const extension1 = /([^.]+)(\.(\w+))?$/.exec(name)?.[3];

    const key = [`${imagekey}`, extension1].filter((x) => !!x).join('.');
    const bucket = import.meta.env.VITE_APP_aws_user_files_s3_bucket || ''; // keep it like this
    const region =
      import.meta.env.VITE_APP_aws_user_files_s3_bucket_region || ''; // keep it like this

    const file = {
      region,
      bucket,
      key: `products/docMarkdownAttachments/${key}`,
      mimeType,
      localUri: selectedFile,
    };

    return file;
  }

  return null;
};

export default getS3InputObject;
