import { Button, Tooltip } from '@fluentui/react-components';
import { Add32Filled, Save24Regular } from '@fluentui/react-icons';
import React, { useState, useEffect } from 'react';

const SubmitForApprovalButton = ({
  title = 'SUBMIT FOR APPROVAL',
  bottom = 'bottom-12',
  openSlider = () => {},
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640); // 640px is typically the 'sm' breakpoint

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Tooltip content={title} relationship="label">
      <div
        className={`bg-blue-db-20 rounded-full sm:rounded-none flex flex-col z-10 justify-center items-center fixed right-12 ${bottom} `}
      >
        <Button
          onClick={() => openSlider()}
          name="submit"
          icon={<Save24Regular />}
          appearance="primary"
          shape="circular"
        >
          {!isSmallScreen && <span>{title}</span>}
        </Button>
      </div>
    </Tooltip>
  );
};

export default SubmitForApprovalButton;
