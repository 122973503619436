import { useAtomValue } from 'jotai';

import Text from 'components/Text';

import { currentUserDetailsAtom } from 'atoms/modals';

export const Welcome = () => {
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);

  return (
    <div className="my-10 flex flex-col md:flex-row">
      <Text weight={900} className="text-3xl text-center md:text-left">
        Welcome {currentUserDetails?.firstName || ''}
      </Text>
    </div>
  );
};

export default Welcome;
