import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CreateChapterInput, StatusType, UpdateChapterInput } from 'API';

import {
  SAVED_SUCCESSFULLY,
  FAILED_TO_SAVE,
  UPDATED_SUCCESSFULLY,
  FAILED_TO_UPDATE,
  FAILED_TO_DELETE,
  DELETED_SUCCESSFULLY,
} from 'consts/alertMessages';

import useConstructStatus from 'hooks/useConstructStatus';

import {
  useCreateChapter,
  useUpdateChapter,
  useDeleteChapter,
  updateChapterRequest,
  createChapterRequest,
} from 'queries/chapters.mutation';

import generateToast from 'helpers/utils/generateToast';
import getProductDocQueryKey from 'helpers/utils/getProductDocQueryKey';

import type {
  DefaultTContext,
  DefaultTData,
  DefaultTError,
  IUseMutationProps,
} from 'types';
import getChapterQueryKey from 'helpers/utils/getChapterQueryKey';

type IUseCreateChapterMutationProps = IUseMutationProps<
  DefaultTData,
  DefaultTError,
  CreateChapterInput
>;

type IUseUpdateChapterMutationProps = IUseMutationProps<
  DefaultTData,
  DefaultTError,
  UpdateChapterInput
>;

export const useCreateChapterMutation = (
  props: IUseCreateChapterMutationProps = {},
) => {
  const onMutateCallback = props?.onMutateCallback ?? (() => {});
  const onSuccessCallback = props?.onSuccessCallback ?? (() => {});
  const onErrorCallback = props?.onErrorCallback ?? (() => {});
  const onSettledCallback = props?.onSettledCallback ?? (() => {});

  const createChapterMutation = useMutation({
    mutationFn: createChapterRequest,
    onMutate: (variables) => {
      onMutateCallback({ variables });
    },
    onSuccess: (data, variables, context) => {
      onSuccessCallback({ data, variables, context });
    },
    onError: (error, variables, context) => {
      onErrorCallback({ error, variables, context });
    },
    onSettled: (data, error, variables, context) => {
      onSettledCallback({ data, error, variables, context });
    },
  });

  return createChapterMutation;
};

export const useUpdateChapterMutation = (
  props: IUseUpdateChapterMutationProps = {},
) => {
  const onMutateCallback = props?.onMutateCallback ?? (() => {});
  const onSuccessCallback = props?.onSuccessCallback ?? (() => {});
  const onErrorCallback = props?.onErrorCallback ?? (() => {});
  const onSettledCallback = props?.onSettledCallback ?? (() => {});

  const updateChapterMutation = useMutation({
    mutationFn: updateChapterRequest,
    onMutate: (variables) => {
      onMutateCallback({ variables });
    },
    onSuccess: (data, variables, context) => {
      onSuccessCallback({ data, variables, context });
    },
    onError: (error, variables, context) => {
      onErrorCallback({ error, variables, context });
    },
    onSettled: (data, error, variables, context) => {
      onSettledCallback({ data, error, variables, context });
    },
  });

  return updateChapterMutation;
};

const useChapterMutations = () => {
  const search = useLocation().search;
  const pathname = useLocation().pathname;
  const { documentId } = useParams();

  const navigate = useNavigate();

  const { status } = useConstructStatus({ search });

  const createChapterMutation = useCreateChapter();
  const updateChapterMutation = useUpdateChapter();
  const deleteChapterMutation = useDeleteChapter();

  const queryClient = useQueryClient();

  const { isLoading: isCreateLoading, isSuccess: isCreateSuccess } =
    createChapterMutation;
  const { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess } =
    updateChapterMutation;

  const onCreateChapter = ({
    chapter,
    isParentProduct,
    callback,
    showNotification = true,
  }: {
    chapter: CreateChapterInput;
    isParentProduct: boolean;
    callback: CallableFunction;
    showNotification: boolean;
  }) => {
    createChapterMutation.mutate(chapter, {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          getChapterQueryKey({ id: chapter.parentProductDocId }),
        );
        void queryClient.invalidateQueries([
          'rootChapter',
          { id: chapter.rootChapterId },
        ]);
        void queryClient.invalidateQueries([`subChapters/${chapter.id}`]);

        if (showNotification) {
          if (status === StatusType.PUBLISHED) {
            generateToast({
              type: 'success',
              toastContent:
                'Added successfully. You can find this document in "Edited Documents"',
            });
          } else {
            generateToast({
              type: 'success',
              toastContent: SAVED_SUCCESSFULLY,
            });
          }
        }

        callback();
      },
      onError: () => {
        generateToast({
          type: 'error',
          toastContent: FAILED_TO_SAVE,
        });
      },
    });
  };

  const onUpdateChapter = ({
    chapter,
    isParentProduct,
    callback,
    showNotification = true,
  }: {
    chapter: UpdateChapterInput;
    isParentProduct: boolean;
    callback: CallableFunction;
    showNotification: boolean;
  }) => {
    updateChapterMutation.mutate(chapter, {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          getChapterQueryKey({ id: chapter.parentProductDocId }),
        );
        void queryClient.invalidateQueries([
          'rootChapter',
          { id: chapter.rootChapterId },
        ]);
        void queryClient.invalidateQueries([`subChapters/${chapter.id}`]);

        if (showNotification) {
          if (status === StatusType.PUBLISHED) {
            generateToast({
              type: 'success',
              toastContent:
                'Updated successfully. You can find the updated document in "Edited Documents"',
            });
          } else {
            generateToast({
              type: 'success',
              toastContent: UPDATED_SUCCESSFULLY,
            });
          }
        }

        callback();
      },
      onError: () => {
        generateToast({
          type: 'error',
          toastContent: FAILED_TO_UPDATE,
        });
      },
    });
  };

  const onDeleteChapter = ({
    id,
    version,
    productId,
  }: {
    id: string;
    version: string;
    productId?: string;
  }) => {
    deleteChapterMutation.mutate(
      { id, version },
      {
        onSuccess: () => {
          if (id === pathname.split('/subChapter/')[1]) {
            navigate(`/products/documents/${documentId}?status=${status}`);
          }

          void queryClient.invalidateQueries(
            getChapterQueryKey({ id: productId }),
          );

          void queryClient.invalidateQueries(['rootChapter']);

          void queryClient.invalidateQueries([`subChapters/${id}`]);

          generateToast({
            type: 'success',
            toastContent: DELETED_SUCCESSFULLY,
          });
        },
        onError: () => {
          generateToast({
            type: 'error',
            toastContent: FAILED_TO_DELETE,
          });
        },
      },
    );
  };

  return {
    onCreateChapter,
    onUpdateChapter,
    onDeleteChapter,
    isCreateLoading,
    isUpdateLoading,
    isCreateSuccess,
    isUpdateSuccess,
  };
};

export default useChapterMutations;
