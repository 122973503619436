export const customListSFAccounts = /* GraphQL */ `
  query CustomListSFAccounts(
    $filter: ModelSFAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        accountName
        countryId
        parentAccountId
        emailDomain
        industry
        organizationId
        billingAddress
        shippingAddress
        copyBillingAddressToShippingAddress
        type
        phone
        website
        createdBy
        createdDate
        updatedBy
        updatedDate
        files {
          bucket
          region
          key
        }
        notesAndAttachments {
          bucket
          region
          key
        }
        tenantId
        allSFData
        createdAt
        updatedAt
        parentAccount {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
        }
        sfProducts {
          items {
            id
            productName
            productVersion
            productMetric
            productDescription
            sfAccountId
            createdBy
            updatedBy
            productCode
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          nextToken
        }
        country {
          id
          name
          countryId
          region
          tenantId
          allSFData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const customListSFContracts = /* GraphQL */ `
  query CustomListSFContracts(
    $filter: ModelSFContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contractNumber
        accountId
        contractTerm
        status
        ownerExpirationNotice
        totalContractValue
        contractType
        poNumber
        poDate
        referenceNumber
        partnerAccountId
        distributorAccountId
        paymentTerms
        poIssuedById
        contactId
        salesRepresentativeNameId
        startDate
        endDate
        customerAddressId
        designationId
        emailId
        mobileId
        year1MaintenanceEndDate
        year1MaintenanceStartDate
        year2MaintenanceEndDate
        year2MaintenanceStartDate
        year3MaintenanceEndDate
        year3MaintenanceStartDate
        invoiceDate
        value
        billingAddress
        description
        specialTerms
        signedById
        companySignedId
        activatedById
        activatedDate
        files {
          bucket
          region
          key
        }
        notesAndAttachments {
          bucket
          region
          key
        }
        tenantId
        allSFData
        createdAt
        updatedAt
        account {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          parentAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          sfProducts {
            nextToken
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        partnerAccount {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          parentAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          sfProducts {
            nextToken
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        distributorAccount {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          parentAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          sfProducts {
            nextToken
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        poIssuedBy {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          parentAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          sfProducts {
            nextToken
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        customerAddress {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          parentAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          sfProducts {
            nextToken
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        contact {
          id
          name
          phone
          accountId
          email
          title
          role
          countryId
          superVisorId
          region
          mailingAddress
          otherAddress
          createdBy
          updatedBy
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          account {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          superVisor {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        salesRepresentativeName {
          id
          name
          phone
          accountId
          email
          title
          role
          countryId
          superVisorId
          region
          mailingAddress
          otherAddress
          createdBy
          updatedBy
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          account {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          superVisor {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        designation {
          id
          name
          phone
          accountId
          email
          title
          role
          countryId
          superVisorId
          region
          mailingAddress
          otherAddress
          createdBy
          updatedBy
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          account {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          superVisor {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        email {
          id
          name
          phone
          accountId
          email
          title
          role
          countryId
          superVisorId
          region
          mailingAddress
          otherAddress
          createdBy
          updatedBy
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          account {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          superVisor {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        mobile {
          id
          name
          phone
          accountId
          email
          title
          role
          countryId
          superVisorId
          region
          mailingAddress
          otherAddress
          createdBy
          updatedBy
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          account {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          superVisor {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        signedBy {
          id
          name
          phone
          accountId
          email
          title
          role
          countryId
          superVisorId
          region
          mailingAddress
          otherAddress
          createdBy
          updatedBy
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          account {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          superVisor {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;

export const customListSFContractLineItems = /* GraphQL */ `
  query CustomListSFContractLineItems(
    $filter: ModelSFContractLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFContractLineItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contractName
        itemNo
        productId
        quantity
        contractId
        productListPrice
        discount
        amount
        version
        tenantId
        allSFData
        createdAt
        updatedAt
        contract {
          id
          contractNumber
          accountId
          contractTerm
          status
          ownerExpirationNotice
          totalContractValue
          contractType
          poNumber
          poDate
          referenceNumber
          partnerAccountId
          distributorAccountId
          paymentTerms
          poIssuedById
          contactId
          salesRepresentativeNameId
          startDate
          endDate
          customerAddressId
          designationId
          emailId
          mobileId
          year1MaintenanceEndDate
          year1MaintenanceStartDate
          year2MaintenanceEndDate
          year2MaintenanceStartDate
          year3MaintenanceEndDate
          year3MaintenanceStartDate
          value
          billingAddress
          description
          specialTerms
          signedById
          companySignedId
          activatedById
          activatedDate
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          account {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          partnerAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          distributorAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          poIssuedBy {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          customerAddress {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          contact {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          salesRepresentativeName {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          designation {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          email {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          mobile {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          signedBy {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        product {
          id
          productName
          productVersion
          productMetric
          productDescription
          sfAccountId
          createdBy
          updatedBy
          productCode
          tenantId
          allSFData
          createdAt
          updatedAt
          sfAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          sfProductSubCategories {
            nextToken
          }
          sfProductVersions {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;

export const customListSFProducts = /* GraphQL */ `
  query CustomListSFProducts(
    $filter: ModelSFProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productName
        productVersion
        productMetric
        productDescription
        sfAccountId
        createdBy
        updatedBy
        productCode
        tenantId
        allSFData
        createdAt
        updatedAt
        sfAccount {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          tenantId
          allSFData
          createdAt
          updatedAt
        }
        sfProductSubCategories {
          items {
            id
            name
            sfProductId
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          nextToken
        }
        sfProductVersions {
          items {
            id
            name
            version
            sfProductId
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const customListSFProductSubCategories = /* GraphQL */ `
  query CustomListSFProductSubCategories(
    $filter: ModelSFProductSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFProductSubCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sfProductId
        tenantId
        allSFData
        createdAt
        updatedAt
        sfProduct {
          id
          productName
          productVersion
          productMetric
          productDescription
          sfAccountId
          createdBy
          updatedBy
          productCode
          tenantId
          allSFData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const customListSFProductVersions = /* GraphQL */ `
  query CustomListSFProductVersions(
    $filter: ModelSFProductVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFProductVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        version
        sfProductId
        tenantId
        allSFData
        createdAt
        updatedAt
        sfProduct {
          id
          productName
          productVersion
          productMetric
          productDescription
          sfAccountId
          createdBy
          updatedBy
          productCode
          tenantId
          allSFData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const customGetGroup = /* GraphQL */ `
  query CustomGetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          users {
            id
            userType
            firstName
            lastName
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            organization {
              id
              name
              domain
              accountId
              recordId
              type
              createdAt
              updatedAt
            }
            status
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      applicationRoles {
        items {
          id
          roleId
          applicationId
          application {
            id
            name
            description
            defaultRoles
            createdAt
            updatedAt
          }
          groupId
          group {
            name
            description
          }
          productDocs
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      organizationId
      assignedTickets {
        items {
          id
          groupId
          taskId
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      tenantId
    }
  }
`;

export const customListGroups = /* GraphQL */ `
  query CustomListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
            users {
              id
              userType
              firstName
              lastName
              email
              organizationId
              salesForceId
              role
              secondaryEmail
              organization {
                id
                name
                domain
                accountId
                recordId
                type
                createdAt
                updatedAt
              }
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        applicationRoles {
          items {
            id
            roleId
            applicationId
            application {
              id
              name
              description
              defaultRoles
              createdAt
              updatedAt
            }
            groupId
            group {
              name
              description
            }
            productDocs
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        organizationId
        assignedTickets {
          items {
            id
            groupId
            taskId
            status
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        tenantId
      }
      nextToken
    }
  }
`;

export const customGetDoc = /* GraphQL */ `
  query CustomGetDoc($id: ID!, $version: String!) {
    getDoc(id: $id, version: $version) {
      id
      version
      status
      title
      description
      orderFloat
      createdByUserId
      referenceDocId
      tenantId
      lastModifiedByUserId
      approvedByUserId
      rootChapterId
      chapterId
      docItems {
        items {
          id
          version
          status
          title
          description
          orderFloat
          itemType
          file {
            bucket
            region
            key
          }
          createdByUserId
          lastModifiedByUserId
          approvedByUserId
          rootChapterId
          docId
          shouldBeDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      shouldBeDeleted
      createdAt
      updatedAt
      pdfFile {
        bucket
        region
        key
      }
    }
  }
`;

export const customGetTask = /* GraphQL */ `
  query CustomGetTask($id: ID!) {
    getTask(id: $id) {  
      id
      name
      description
      salesForceTicketId
      tenantId
      requestedByUserId
      requestedByUser {
        id
        userType
        firstName
        lastName
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      reviewedByUserId
      reviewedByUser {
        id
        userType
        firstName
        lastName
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
        status
        createdAt
        updatedAt
      }
      status
      comments {
        items {
          id
          comment
          commentedByUserId
          commentedByUser {
            id
            userType
            firstName
            lastName
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            status
            createdAt
            updatedAt
          }
          parentCommentId
          parentComment {
            id
            comment
            commentedByUserId
            parentCommentId
            taskId
            createdAt
            updatedAt
          }
          replies {
            nextToken
          }
          taskId
          task {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            productDocId
            createdAt
            updatedAt
            organizationId
            severity
            issueCategory
            subCategory
            type
            ticketRef
            closureReason
            linkedTickets
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      rootChapterId
      organizationId
      ticketRef
      severity
      severityUpdatedAt
      issueCategory
      subCategory
      productVersion
      closureReason
      type
      file {
        items {
          id
          file {
            bucket
            region
            key
          }
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      rootChapterVersion
      productDocId
      isRejected
      usersToNotify
      ticketWatchers {
        items {
          id
          userId
          taskId
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketAssignedUsers {
        items {
          id
          userId
          taskId
          status
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketAssignedGroups {
        items {
          id
          groupId
          taskId
          status
          groups {
            id
            name
            description
            status
            organizationId
            createdAt
            updatedAt
          }
          tasks {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      linkedTickets
      createdAt
      updatedAt
      hasSensitiveInfo
    }
  }
`;

export const customGetTicket = /* GraphQL */ `
  query CustomGetTicket($id: ID!) {
    getTask(id: $id) {
      __typename
      id
      name
      description
      requestForInformation
      tenantId
      requestedByUserId
      issueType
      requestedByUser {
        id
        firstName
        lastName
        email
        organizationId
        organization {
          id
          name
        }
        secondaryEmail
        status
      }
      status
      comments {
        items {
          id
          comment
          commentedByUserId
          commentedByUser {
            id
            firstName
            lastName
            email
            organizationId
            organization {
              id
              name
            }
            secondaryEmail
            status
          }
          taskId
          file {
            bucket
            region
            key
          }
          attachments {
            bucket
            region
            key
          }
          createdAt
          updatedAt
          hasSensitiveInfo
          requestForInformation
        }
        nextToken
      }
      organizationId
      ticketRef
      severity
      severityUpdatedAt
      issueCategory
      subCategory
      productVersion
      closureReason
      type
      file {
        items {
          id
          file {
            bucket
            region
            key
          }
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      productDocId
      usersToNotify
      ticketWatchers {
        items {
          id
          userId
          taskId
          users {
            id
            firstName
            lastName
            email
            organizationId
            secondaryEmail
            status
          }
        }
        nextToken
      }
      ticketAssignedUsers {
        items {
          id
          userId
          taskId
          status
          users {
            id
            firstName
            lastName
            email
            organizationId
            secondaryEmail
            status
          }
          createdByUserId
          createdByUser {
            firstName
            lastName
            email
            secondaryEmail
          }
          updatedByUserId
          updatedByUser {
            firstName
            lastName
            email
            secondaryEmail
          }
        }
        nextToken
      }
      ticketAssignedGroups {
        items {
          id
          groupId
          taskId
          status
          groups {
            id
            name
            description
            status
            organizationId
            users{
              items {
                id
                userId
                users {
                  id
                  firstName
                  lastName
                  email
                  organizationId
                  salesForceId
                  secondaryEmail
                  status
                }
              }
            }
          }
          createdByUserId
          createdByUser {
            firstName
            lastName
            email
            secondaryEmail
          }
          updatedByUserId
          updatedByUser {
            firstName
            lastName
            email
            secondaryEmail            
          }
        }
        nextToken
      }
      linkedTickets
      createdAt
      updatedAt
      hasSensitiveInfo
      isSupportEngineerIdentityHidden
    }
  }
`;

export const customGetUser = /* GraphQL */ `
  query CustomGetUser($id: ID!) {
    getUser(id: $id) {
      id
      tenantId
      userType
      firstName
      lastName
      email
      displayName
      jobTitle
      address
      city
      state
      postalCode
      country
      organizationId
      salesForceId
      preferredCurrency
      role
      status
      secondaryEmail
      file {
        bucket
        region
        key
      }
      groups {
        items {
          id
          userId
          groupId
          users {
            id
            userType
            firstName
            lastName
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdAt
            updatedAt
          }
          groups {
            id
            name
            description
            organizationId
            status
            createdAt
            updatedAt
            applicationRoles {
              items {
                id
                roleId
                applicationId
                application {
                  id
                  name
                  description
                  defaultRoles
                  createdAt
                  updatedAt
                }
                groupId
                group {
                  id
                  name
                  description
                  createdAt
                  updatedAt
                  status
                }
                productDocs
                locations
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      organization {
        id
        name
        domain
        accountId
        recordId
        type
        createdAt
        updatedAt
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const customListUsers = /* GraphQL */ `
  query CustomListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        userType
        firstName
        lastName
        email
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        organizationId
        salesForceId
        role
        secondaryEmail
        file {
          bucket
          region
          key
        }
        groups {
          items {
            id
            userId
            groupId
            users {
              id
              userType
              firstName
              lastName
              email
              organizationId
              salesForceId
              role
              secondaryEmail
              createdAt
              updatedAt
            }
            groups {
              id
              name
              description
              status
              createdAt
              updatedAt
              applicationRoles {
                items {
                  id
                  roleId
                  applicationId
                  application {
                    id
                    name
                    description
                    defaultRoles
                    createdAt
                    updatedAt
                  }
                  groupId
                  group {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    status
                  }
                  productDocs
                  locations
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customListApplications = /* GraphQL */ `
  query CustomListApplications(
    $filter: ModelApplicationFilterInput
    $productDocFilter: ModelProductDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        defaultRoles
        productDocs(filter: $productDocFilter) {
          items {
            id
            version
            status
            name
            description
            createdByUserId
            lastModifiedByUserId
            approvedByUserId
            applicationId
            createdAt
            updatedAt
          }
          nextToken
        }
        applicationRoles {
          items {
            id
            roleId
            applicationId
            groupId
            productDocs
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customGetProductDoc = /* GraphQL */ `
  query CustomGetProductDoc($id: ID!, $version: String!) {
    getProductDoc(id: $id, version: $version) {
      id
      tenantId
      version
      status
      name
      description
      file {
        bucket
        region
        key
      }
      createdByUserId
      lastModifiedByUserId
      approvedByUserId
      chapters {
        items {
          id
          version
          status
          name
          description
          orderFloat
          file {
            bucket
            region
            key
          }
          createdByUserId
          lastModifiedByUserId
          approvedByUserId
          rootChapterId
          parentProductDocId
          docs {
            nextToken
          }
          parentChapterId
          subChapters {
            nextToken
          }
          shouldBeDeleted
          isRejected
          currentTaskId
          currentTask {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            rootChapterVersion
            productDocId
            isRejected
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          tenantId
          pdfFile {
            bucket
            region
            key
          }
        }
        nextToken
      }
      applicationId
      application {
        id
        name
        description
        productDocs {
          items {
            id
            version
            status
            name
            description
            createdByUserId
            lastModifiedByUserId
            approvedByUserId
            applicationId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const customGetChapter = /* GraphQL */ `
  query CustomGetChapter($id: ID!, $version: String!) {
    getChapter(id: $id, version: $version) {
      id
      tenantId
      version
      status
      name
      orderFloat
      description
      file {
        bucket
        region
        key
      }
      createdByUserId
      lastModifiedByUserId
      approvedByUserId
      rootChapterId
      parentProductDocId
      docs {
        items {
          id
          version
          status
          title
          description
          orderFloat
          pdfFile {
            bucket
            region
            key
          }
          createdByUserId
          lastModifiedByUserId
          approvedByUserId
          rootChapterId
          chapterId
          docItems {
            items {
              id
              version
              status
              itemType
              title
              description
              rootChapterId
              docId
              shouldBeDeleted
              createdAt
              updatedAt
              orderFloat
            }
            nextToken
          }
          shouldBeDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      parentChapterId
      subChapters {
        items {
          id
          version
          status
          name
          description
          orderFloat
          pdfFile {
            bucket
            region
            key
          }
          file {
            bucket
            region
            key
          }
          createdByUserId
          lastModifiedByUserId
          approvedByUserId
          rootChapterId
          parentProductDocId
          docs {
            nextToken
          }
          parentChapterId
          subChapters {
            nextToken
          }
          shouldBeDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
      shouldBeDeleted
      isRejected
      currentTaskId
      currentTask {
        id
        name
        description
        requestedByUserId
        reviewedByUserId
        status
        rootChapterId
        rootChapterVersion
        productDocId
        isRejected
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const allChaptersUnderRootChapterForImportExport = /* GraphQL */ `
  query AllChaptersUnderRootChapterForImportExport(
    $rootChapterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChapterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allChaptersUnderRootChapter(
      rootChapterId: $rootChapterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        version
        name
        status
        createdAt
        updatedAt
        createdByUserId
        lastModifiedByUserId
        description
        parentChapterId
        parentProductDocId
        tenantId
        rootChapterId
        shouldBeDeleted
        file {
          bucket
          region
          key
        }
        orderFloat
        pdfFile {
          bucket
          region
          key
        }
      }
      nextToken
    }
  }
`;

export const allDocsUnderRootChapterForImportExport = /* GraphQL */ `
  query AllDocsUnderRootChapterForImportExport(
    $rootChapterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allDocsUnderRootChapter(
      rootChapterId: $rootChapterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        version
        title
        status
        createdAt
        updatedAt
        createdByUserId
        lastModifiedByUserId
        description
        rootChapterId
        chapterId
        orderFloat
        tenantId
        referenceDocId
        shouldBeDeleted
        pdfFile {
          bucket
          region
          key
        }
      }
      nextToken
    }
  }
`;

export const allDocItemsUnderRootChapterForImportExport = /* GraphQL */ `
  query AllDocItemsUnderRootChapterForImportExport(
    $rootChapterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDocItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allDocItemsUnderRootChapter(
      rootChapterId: $rootChapterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        version
        title
        status
        createdAt
        updatedAt
        createdByUserId
        lastModifiedByUserId
        description
        itemType
        orderFloat
        tenantId
        rootChapterId
        shouldBeDeleted
        file {
          bucket
          region
          key
        }
        docId
      }
      nextToken
    }
  }
`;

export const getNestedSubChapters = /* GraphQL */ `
  query GetNestedSubChapters(
    $id: ID!
    $version: String!
    $subChapterLevelFilter: ModelChapterFilterInput
    $docLevelFilter: ModelDocFilterInput
    $docItemLevelFilter: ModelDocItemFilterInput
    $subChapterLevelLimit: Int
    $docLevelLimit: Int
    $docItemLevelLimit: Int
  ) {
    getChapter(id: $id, version: $version) {
      id
      tenantId
      version
      status
      name
      description
      orderFloat
      rootChapterId
      parentProductDocId
      docs(filter: $docLevelFilter, limit: $docLevelLimit) {
        items {
          id
          version
          status
          title
          description
          orderFloat
          pdfFile {
            bucket
            region
            key
          }
          rootChapterId
          chapterId
          shouldBeDeleted
          createdAt
          updatedAt
          referenceDocId
          docItems(filter: $docItemLevelFilter, limit: $docItemLevelLimit) {
            items {
              id
              version
              status
              itemType
              title
              description
              rootChapterId
              docId
              shouldBeDeleted
              createdAt
              updatedAt
              orderFloat
            }
            nextToken
          }
        }
        nextToken
      }
      parentChapterId
      subChapters(filter: $subChapterLevelFilter, limit: $subChapterLevelLimit) {
        items {
          id
          version
          status
          name
          description
          rootChapterId
          orderFloat
          pdfFile {
            bucket
            region
            key
          }
          parentProductDocId
          parentChapterId
          shouldBeDeleted
          createdAt
          updatedAt
          docs(filter: $docLevelFilter, limit: $docLevelLimit) {
            items {
              id
              version
              status
              title
              description
              orderFloat
              pdfFile {
                bucket
                region
                key
              }
              rootChapterId
              shouldBeDeleted
              chapterId
              createdAt
              updatedAt
              referenceDocId
              docItems(filter: $docItemLevelFilter, limit: $docItemLevelLimit) {
                items {
                  id
                  version
                  status
                  itemType
                  title
                  description
                  rootChapterId
                  docId
                  shouldBeDeleted
                  createdAt
                  updatedAt
                  orderFloat
                }
                nextToken
              }
            }
            nextToken
          }
          subChapters(filter: $subChapterLevelFilter, limit: $subChapterLevelLimit) {
            items {
              id
              version
              status
              name
              description
              rootChapterId
              orderFloat
              pdfFile {
                bucket
                region
                key
              }
              parentProductDocId
              parentChapterId
              shouldBeDeleted
              createdAt
              updatedAt
              docs(filter: $docLevelFilter, limit: $docLevelLimit) {
                items {
                  id
                  version
                  status
                  title
                  description
                  orderFloat
                  pdfFile {
                    bucket
                    region
                    key
                  }
                  rootChapterId
                  chapterId
                  shouldBeDeleted
                  createdAt
                  updatedAt
                  referenceDocId
                  docItems(filter: $docItemLevelFilter, limit: $docItemLevelLimit) {
                    items {
                      id
                      version
                      status
                      itemType
                      title
                      description
                      rootChapterId
                      docId
                      shouldBeDeleted
                      createdAt
                      updatedAt
                      orderFloat
                    }
                    nextToken
                  }
                }
                nextToken
              }
              subChapters(filter: $subChapterLevelFilter, limit: $subChapterLevelLimit) {
                items {
                  id
                  version
                  status
                  name
                  description
                  rootChapterId
                  orderFloat
                  pdfFile {
                    bucket
                    region
                    key
                  }
                  parentProductDocId
                  parentChapterId
                  shouldBeDeleted
                  createdAt
                  updatedAt
                  docs(filter: $docLevelFilter, limit: $docLevelLimit) {
                    items {
                      id
                      version
                      status
                      title
                      description
                      orderFloat
                      pdfFile {
                        bucket
                        region
                        key
                      }
                      rootChapterId
                      chapterId
                      shouldBeDeleted
                      createdAt
                      updatedAt
                      referenceDocId
                      docItems(filter: $docItemLevelFilter, limit: $docItemLevelLimit) {
                        items {
                          id
                          version
                          status
                          itemType
                          title
                          description
                          rootChapterId
                          docId
                          shouldBeDeleted
                          createdAt
                          updatedAt
                          orderFloat
                        }
                        nextToken
                      }
                    }
                    nextToken
                  }
                  subChapters(filter: $subChapterLevelFilter, limit: $subChapterLevelLimit) {
                    items {
                      id
                      version
                      status
                      name
                      description
                      orderFloat
                      pdfFile {
                        bucket
                        region
                        key
                      }
                      rootChapterId
                      parentProductDocId
                      parentChapterId
                      shouldBeDeleted
                      createdAt
                      updatedAt
                      docs(filter: $docLevelFilter, limit: $docLevelLimit) {
                        items {
                          id
                          version
                          status
                          title
                          description
                          orderFloat
                          pdfFile {
                            bucket
                            region
                            key
                          }
                          rootChapterId
                          chapterId
                          shouldBeDeleted
                          createdAt
                          updatedAt
                          referenceDocId
                          docItems(filter: $docItemLevelFilter, limit: $docItemLevelLimit) {
                            items {
                              id
                              version
                              status
                              itemType
                              title
                              description
                              rootChapterId
                              docId
                              shouldBeDeleted
                              createdAt
                              updatedAt
                              orderFloat
                            }
                            nextToken
                          }
                        }
                        nextToken
                      }
                      subChapters(filter: $subChapterLevelFilter, limit: $subChapterLevelLimit) {
                        items {
                          id
                          version
                          status
                          name
                          description
                          orderFloat
                          pdfFile {
                            bucket
                            region
                            key
                          }
                          rootChapterId
                          parentProductDocId
                          parentChapterId
                          shouldBeDeleted
                          createdAt
                          updatedAt
                          docs(filter: $docLevelFilter, limit: $docLevelLimit) {
                            items {
                              id
                              version
                              status
                              title
                              description
                              orderFloat
                              pdfFile {
                                bucket
                                region
                                key
                              }
                              rootChapterId
                              chapterId
                              shouldBeDeleted
                              createdAt
                              updatedAt
                              referenceDocId
                              docItems(filter: $docItemLevelFilter, limit: $docItemLevelLimit) {
                                items {
                                  id
                                  version
                                  status
                                  itemType
                                  title
                                  description
                                  rootChapterId
                                  docId
                                  shouldBeDeleted
                                  createdAt
                                  updatedAt
                                  orderFloat
                                }
                                nextToken
                              }
                            }
                            nextToken
                          }
                        }
                        nextToken
                      }
                    }
                    nextToken
                  }
                }
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
      shouldBeDeleted
      isRejected
      currentTaskId
      currentTask {
        id
        name
        description
        requestedByUserId
        reviewedByUserId
        status
        rootChapterId
        rootChapterVersion
        productDocId
        isRejected
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const getNestedSubChaptersUnderRootChapter = /* GraphQL */ `
  query GetNestedSubChaptersUnderRootChapter(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelChapterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChapters(
      id: $id,
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tenantId
        version
        status
        name
        description
        orderFloat
        pdfFile {
          bucket
          region
          key
        }
        file {
          bucket
          region
          key
        }
        rootChapterId
        parentProductDocId
        parentChapterId
        shouldBeDeleted
        isRejected
        currentTaskId
        currentTask {
          id
          name
          description
          requestedByUserId
          reviewedByUserId
          status
          rootChapterId
          rootChapterVersion
          productDocId
          isRejected
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const customListTickets = /* GraphQL */ `
  query CustomListTickets(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
        name
        description
        salesForceTicketId
        requestForInformation
        tenantId
        issueType
        requestedByUserId
        requestedByUser {
          id
          firstName
          lastName
          organizationId
        }
        reviewedByUserId
        status
        comments {
          items {
            id
            comment
            file {
              bucket
              region
              key
            }
            commentedByUserId
            commentedByUser {
              id
              firstName
              lastName
              organizationId
            }
            taskId
            createdAt
            updatedAt
            hasSensitiveInfo
            requestForInformation
          }
          nextToken
        }
        rootChapterId
        organizationId
        ticketRef
        severity
        severityUpdatedAt
        issueCategory
        subCategory
        productVersion
        closureReason
        type
        rootChapterVersion
        productDocId
        isRejected
        usersToNotify
        ticketAssignedUsers {
          items {
            id
            userId
            taskId
            status
            createdByUserId
            updatedByUserId
            users {
              id
              userType
              firstName
              lastName
              displayName
              jobTitle
              address
              city
              state
              postalCode
              country
              email
              organizationId
              salesForceId
              role
              secondaryEmail
              createdBy
              updatedBy
              createdAt
              updatedAt
              status
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketAssignedGroups {
          items {
            id
            groupId
            taskId
            status
            createdByUserId
            updatedByUserId
            groups {
              id
              name
              description
              status
              organizationId
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                }
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        linkedTickets
        createdAt
        updatedAt
        hasSensitiveInfo
        isSupportEngineerIdentityHidden
      }
      nextToken
    }
  }
`;

export const customSearchTickets = /* GraphQL */ `
  query CustomSearchTickets(
    $filter: SearchableTaskFilterInput
    $sort: SearchableTaskSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTasks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        __typename
        id
        name
        description
        salesForceTicketId
        requestForInformation
        tenantId
        issueType
        requestedByUserId
        requestedByUser {
          id
          firstName
          lastName
          organizationId
        }
        reviewedByUserId
        status
        comments {
          items {
            id
            comment
            commentedByUserId
            commentedByUser {
              id
              firstName
              lastName
              organizationId
            }
            taskId
            createdAt
            updatedAt
            hasSensitiveInfo
            requestForInformation
          }
          nextToken
        }
        rootChapterId
        organizationId
        ticketRef
        severity
        severityUpdatedAt
        issueCategory
        subCategory
        productVersion
        closureReason
        type
        rootChapterVersion
        productDocId
        isRejected
        usersToNotify
        ticketAssignedUsers {
          items {
            id
            userId
            taskId
            status
            createdByUserId
            updatedByUserId
            users {
              id
              userType
              firstName
              lastName
              displayName
              jobTitle
              address
              city
              state
              postalCode
              country
              email
              organizationId
              salesForceId
              role
              secondaryEmail
              createdBy
              updatedBy
              createdAt
              updatedAt
              status
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketAssignedGroups {
          items {
            id
            groupId
            taskId
            status
            createdByUserId
            updatedByUserId
            groups {
              id
              name
              description
              status
              organizationId
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                }
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        linkedTickets
        createdAt
        updatedAt
        hasSensitiveInfo
        isSupportEngineerIdentityHidden
      }
      nextToken
      total
    }
  }
`;

export const customListTasks = /* GraphQL */ `
  query CustomListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
        name
        description
        salesForceTicketId
        requestForInformation
        tenantId
        issueType
        requestedByUserId
        requestedByUser {
          id
          firstName
          lastName
          organizationId
        }
        reviewedByUserId
        status
        comments {
          items {
            id
            comment
            file {
              bucket
              region
              key
            }
            commentedByUserId
            commentedByUser {
              id
              firstName
              lastName
              organizationId
            }
            taskId
            createdAt
            updatedAt
            hasSensitiveInfo
            requestForInformation
          }
          nextToken
        }
        rootChapterId
        organizationId
        ticketRef
        severity
        severityUpdatedAt
        issueCategory
        subCategory
        productVersion
        closureReason
        type
        rootChapterVersion
        productDocId
        isRejected
        usersToNotify
        ticketAssignedUsers {
          items {
            id
            userId
            taskId
            status
            createdByUserId
            updatedByUserId
            users {
              id
              userType
              firstName
              lastName
              displayName
              jobTitle
              address
              city
              state
              postalCode
              country
              email
              organizationId
              salesForceId
              role
              secondaryEmail
              createdBy
              updatedBy
              createdAt
              updatedAt
              status
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketAssignedGroups {
          items {
            id
            groupId
            taskId
            status
            createdByUserId
            updatedByUserId
            groups {
              id
              name
              description
              status
              organizationId
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                }
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        linkedTickets
        createdAt
        updatedAt
        hasSensitiveInfo
        isSupportEngineerIdentityHidden
      }
      nextToken
    }
  }
`;

export const customSearchTasks = /* GraphQL */ `
  query CustomSearchTasks(
    $filter: SearchableTaskFilterInput
    $sort: SearchableTaskSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTasks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        __typename
        id
        name
        description
        salesForceTicketId
        requestForInformation
        tenantId
        issueType
        requestedByUserId
        requestedByUser {
          id
          firstName
          lastName
          organizationId
        }
        reviewedByUserId
        status
        comments {
          items {
            id
            comment
            commentedByUserId
            commentedByUser {
              id
              firstName
              lastName
              organizationId
            }
            taskId
            createdAt
            updatedAt
            hasSensitiveInfo
            requestForInformation
          }
          nextToken
        }
        rootChapterId
        organizationId
        ticketRef
        severity
        severityUpdatedAt
        issueCategory
        subCategory
        productVersion
        closureReason
        type
        rootChapterVersion
        productDocId
        isRejected
        usersToNotify
        ticketAssignedUsers {
          items {
            id
            userId
            taskId
            status
            createdByUserId
            updatedByUserId
            users {
              id
              userType
              firstName
              lastName
              displayName
              jobTitle
              address
              city
              state
              postalCode
              country
              email
              organizationId
              salesForceId
              role
              secondaryEmail
              createdBy
              updatedBy
              createdAt
              updatedAt
              status
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketAssignedGroups {
          items {
            id
            groupId
            taskId
            status
            createdByUserId
            updatedByUserId
            groups {
              id
              name
              description
              status
              organizationId
              createdAt
              updatedAt
              users {
                items {
                  id
                  userId
                }
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        linkedTickets
        createdAt
        updatedAt
        hasSensitiveInfo
        isSupportEngineerIdentityHidden
      }
      nextToken
      total
    }
  }
`;

export const customSearchLeads = /* GraphQL */ `
  query CustomSearchLeads(
    $filter: SearchableLeadFilterInput
    $sort: SearchableLeadSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLeads(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        tenant {
          id
          name
          organizationId
          organization {
            name
          }
        }
        leadReferenceNumber
        firstName
        lastName
        email
        phoneNumber
        role
        jobTitle
        companyName
        companyAddress
        industry
        companyUrl
        leadName
        description
        timeline
        estimatedBudget
        estimatedNumberOfUsers
        solution
        incumbentProduct
        competitors
        submissionDate
        remarks
        createdByUserId
        lastModifiedByUserId
        qualifiedByUserId
        disqualifiedByUserId
        approvedByUserId
        rejectedByUserId
        usersToNotify
        overallStatus
        qualifiedStatus
        approvedStatus
        qualifiedDate
        approvedDate
        qualifiedRemarks
        approvedRemarks
        salesForceLeadId
        createdAt
        updatedAt
        __typename
        createdByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        qualifiedByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        disqualifiedByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        approvedByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        rejectedByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        comments {
          items {
            id
            comment
            commentedByUserId
            parentCommentId
            taskId
            leadId
            hasSensitiveInfo
            type
            createdAt
            updatedAt
          }
          nextToken
        }
        attchments {
          items {
            id
            taskId
            leadId
            createdAt
            updatedAt
          }
          nextToken
        }
        leadWatchers {
          items {
            id
            userId
            leadId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;

export const customGetLead = /* GraphQL */ `
  query CustomGetLead($id: ID!) {
    getLead(id: $id) {
      id
      tenantId
      leadReferenceNumber
      firstName
      lastName
      email
      phoneNumber
      role
      jobTitle
      companyName
      companyAddress
      industry
      companyUrl
      leadName
      description
      timeline
      estimatedBudget
      estimatedNumberOfUsers
      solution
      incumbentProduct
      competitors
      submissionDate
      remarks
      createdByUserId
      lastModifiedByUserId
      qualifiedByUserId
      disqualifiedByUserId
      approvedByUserId
      rejectedByUserId
      usersToNotify
      overallStatus
      qualifiedStatus
      approvedStatus
      qualifiedDate
      approvedDate
      qualifiedRemarks
      approvedRemarks
      salesForceLeadId
      createdAt
      updatedAt
      createdByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      qualifiedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      disqualifiedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      approvedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      rejectedByUser {
        id
        userType
        firstName
        lastName
        displayName
        jobTitle
        address
        city
        state
        postalCode
        country
        email
        organizationId
        salesForceId
        role
        secondaryEmail
        createdBy
        updatedBy
        createdAt
        updatedAt
        file {
          bucket
          region
          key
        }
        status
        groups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          domain
          accountId
          recordId
          type
          createdAt
          updatedAt
        }
      }
      comments {
        items {
          id
          comment
          commentedByUserId
          parentCommentId
          taskId
          leadId
          file {
            bucket
            region
            key
          }
          attachments {
            bucket
            region
            key
          }
          hasSensitiveInfo
          type
          createdAt
          updatedAt
          commentedByUser {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
        }
        nextToken
      }
      attchments {
        items {
          id
          file {
            bucket
            region
            key
          }
          taskId
          leadId
          createdAt
          updatedAt
        }
        nextToken
      }
      leadWatchers {
        items {
          id
          userId
          leadId
          createdAt
          updatedAt
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
          }
          leads {
            id
            leadReferenceNumber
            firstName
            lastName
            email
            phoneNumber
            role
            jobTitle
            companyName
            companyAddress
            industry
            companyUrl
            leadName
            description
            timeline
            estimatedBudget
            estimatedNumberOfUsers
            solution
            incumbentProduct
            competitors
            submissionDate
            remarks
            createdByUserId
            lastModifiedByUserId
            qualifiedByUserId
            disqualifiedByUserId
            approvedByUserId
            rejectedByUserId
            usersToNotify
            overallStatus
            qualifiedStatus
            approvedStatus
            qualifiedDate
            approvedDate
            qualifiedRemarks
            approvedRemarks
            salesForceLeadId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;

export const customListLeads = /* GraphQL */ `
  query CustomListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
        tenantId
        tenant {
          id
          name
          organizationId
          organization {
            name
          }
        }
        leadReferenceNumber
        firstName
        lastName
        email
        phoneNumber
        role
        jobTitle
        companyName
        companyAddress
        industry
        companyUrl
        leadName
        description
        timeline
        estimatedBudget
        estimatedNumberOfUsers
        solution
        incumbentProduct
        competitors
        submissionDate
        remarks
        createdByUserId
        lastModifiedByUserId
        qualifiedByUserId
        disqualifiedByUserId
        approvedByUserId
        rejectedByUserId
        usersToNotify
        overallStatus
        qualifiedStatus
        approvedStatus
        qualifiedDate
        approvedDate
        qualifiedRemarks
        approvedRemarks
        salesForceLeadId
        createdAt
        updatedAt
        createdByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        qualifiedByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        disqualifiedByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        approvedByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        rejectedByUser {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          file {
            bucket
            region
            key
          }
          status
          organization {
            id
            name
            domain
            accountId
            recordId
            type
            createdAt
            updatedAt
          }
        }
        comments {
          items {
            id
            comment
            commentedByUserId
            parentCommentId
            taskId
            leadId
            file {
              bucket
              region
              key
            }
            attachments {
              bucket
              region
              key
            }
            hasSensitiveInfo
            type
            createdAt
            updatedAt
            commentedByUser {
              id
              userType
              firstName
              lastName
              displayName
              jobTitle
              address
              city
              state
              postalCode
              country
              email
              organizationId
              salesForceId
              role
              secondaryEmail
              createdBy
              updatedBy
              createdAt
              updatedAt
              status
            }
          }
          nextToken
        }
        attchments {
          items {
            id
            file {
              bucket
              region
              key
            }
            taskId
            leadId
            createdAt
            updatedAt
          }
          nextToken
        }
        leadWatchers {
          items {
            id
            userId
            leadId
            createdAt
            updatedAt
            users {
              id
              userType
              firstName
              lastName
              displayName
              jobTitle
              address
              city
              state
              postalCode
              country
              email
              organizationId
              salesForceId
              role
              secondaryEmail
              createdBy
              updatedBy
              createdAt
              updatedAt
              status
            }
            leads {
              id
              leadReferenceNumber
              firstName
              lastName
              email
              phoneNumber
              role
              jobTitle
              companyName
              companyAddress
              industry
              companyUrl
              leadName
              description
              timeline
              estimatedBudget
              estimatedNumberOfUsers
              solution
              incumbentProduct
              competitors
              submissionDate
              remarks
              createdByUserId
              lastModifiedByUserId
              qualifiedByUserId
              disqualifiedByUserId
              approvedByUserId
              rejectedByUserId
              usersToNotify
              overallStatus
              qualifiedStatus
              approvedStatus
              qualifiedDate
              approvedDate
              qualifiedRemarks
              approvedRemarks
              salesForceLeadId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const customGetCurrency = /* GraphQL */ `
  query CustomGetCurrency($id: ID!) {
    getCurrency(id: $id) {
      id
      tenantId
      name
      symbol
      isDefaultCurrency
      code
      country
      exchangeRate
      createdAt
      updatedAt
    }
  }
`;

export const customListCurrencies = /* GraphQL */ `
  query CustomListCurrencies(
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        symbol
        isDefaultCurrency
        code
        country
        exchangeRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customSearchCurrencies = /* GraphQL */ `
  query CustomSearchCurrencies(
    $filter: SearchableCurrencyFilterInput
    $sort: SearchableCurrencySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCurrencies(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        name
        code
        country
        exchangeRate
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

export const customListTenants = /* GraphQL */ `
  query CustomListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subscribedServices
        organizationId
        createdAt
        updatedAt
        applications {
          items {
            id
            tenantId
            applicationId
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
            isMigrated
            isTenantAdmin
            tenantId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const customGetTenant = /* GraphQL */ `
  query CustomGetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      name
      subscribedServices
      organizationId
      createdAt
      updatedAt
      applications {
        items {
          id
          tenantId
          applicationId
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          status
          isMigrated
          isTenantAdmin
          tenantId
        }
        nextToken
      }
    }
  }
`;

export const customListSFCaseSeverities = /* GraphQL */ `
  query CustomListSFCaseSeverities(
    $filter: ModelSFCaseSeverityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFCaseSeverities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        value
        tenantId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customListSFClosureReasons = /* GraphQL */ `
  query CustomListSFClosureReasons(
    $filter: ModelSFClosureReasonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFClosureReasons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        value
        tenantId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customListSFContacts = /* GraphQL */ `
  query CustomListSFContacts(
    $filter: ModelSFContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone
        accountId
        email
        title
        role
        countryId
        superVisorId
        region
        mailingAddress
        otherAddress
        createdBy
        updatedBy
        files {
          bucket
          region
          key
        }
        notesAndAttachments {
          bucket
          region
          key
        }
        tenantId
        allSFData
        createdAt
        updatedAt
        account {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          parentAccount {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          sfProducts {
            nextToken
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        superVisor {
          id
          name
          phone
          accountId
          email
          title
          role
          countryId
          superVisorId
          region
          mailingAddress
          otherAddress
          createdBy
          updatedBy
          files {
            bucket
            region
            key
          }
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          account {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          superVisor {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          country {
            id
            name
            countryId
            region
            tenantId
            allSFData
            createdAt
            updatedAt
          }
        }
        country {
          id
          name
          countryId
          region
          notesAndAttachments {
            bucket
            region
            key
          }
          tenantId
          allSFData
          createdAt
          updatedAt
          accounts {
            nextToken
          }
          contacts {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;

export const customListSFCountries = /* GraphQL */ `
  query CustomListSFCountries(
    $filter: ModelSFCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        countryId
        region
        notesAndAttachments {
          bucket
          region
          key
        }
        tenantId
        allSFData
        createdAt
        updatedAt
        accounts {
          items {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          nextToken
        }
        contacts {
          items {
            id
            name
            phone
            accountId
            email
            title
            role
            countryId
            superVisorId
            region
            mailingAddress
            otherAddress
            createdBy
            updatedBy
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const customListSFIndustries = /* GraphQL */ `
  query CustomListSFIndustries(
    $filter: ModelSFIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFIndustries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        value
        tenantId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customListSFIssueCategories = /* GraphQL */ `
  query CustomListSFIssueCategories(
    $filter: ModelSFIssueCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFIssueCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        value
        tenantId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customListSFRoles = /* GraphQL */ `
  query CustomListSFRoles(
    $filter: ModelSFRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        value
        tenantId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customListSFSolutions = /* GraphQL */ `
  query CustomListSFSolutions(
    $filter: ModelSFSolutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFSolutions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        leadId
        tenantId
        allSFData
        createdAt
        updatedAt
        competitors {
          items {
            id
            name
            domain
            competitors
            solutionId
            tenantId
            allSFData
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const customListSFCompetitors = /* GraphQL */ `
  query CustomListSFCompetitors(
    $filter: ModelSFCompetitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFCompetitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        domain
        competitors
        solutionId
        tenantId
        allSFData
        createdAt
        updatedAt
        solution {
          id
          name
          leadId
          tenantId
          allSFData
          createdAt
          updatedAt
          competitors {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;

export const customListApplicationRolesQueryTwo = /* GraphQL */`
query customListApplicationRoles(
  $limit: Int
  $nextToken: String
  $filter: ModelApplicationRoleFilterInput
){
  listApplicationRoles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ){
    items {
      id
      productDocs
      roleId
      groupId
      group {
        id
        users {
          items {
            id
            userId
            users {
              id
              firstName
              lastName
              email
              secondaryEmail
              status
              organizationId
              organization {
                name
              }
            }
          }
        }
      }
    }
    nextToken
  }
}`;

export const customGetDocVersion = /* GraphQL */ `
  query CustomGetDocVersion(
    $id: ID!,
    $version: String!,
    $sortDirection: ModelSortDirection,
    $docItemLimit: Int,
    $docItemFilter: ModelDocItemFilterInput,
    $docItemNextToken: String
  ) {
    getDoc(
      id: $id,
      version: $version
    ) {
      id
      version
      status
      title
      description
      orderFloat
      createdByUserId
      referenceDocId
      tenantId
      lastModifiedByUserId
      approvedByUserId
      rootChapterId
      chapterId
      shouldBeDeleted
      createdAt
      updatedAt
      docItems(
        limit: $docItemLimit,
        filter: $docItemFilter, 
        nextToken: $docItemNextToken,
        sortDirection: $sortDirection
      ) {
        items {
          id
          version
          status
          title
          description
          orderFloat
          itemType
          file {
            bucket
            region
            key
          }
          createdByUserId
          lastModifiedByUserId
          approvedByUserId
          rootChapterId
          docId
          shouldBeDeleted
          createdAt
          updatedAt
        }
        nextToken
      } 
    }
  }
`;

export const customListDocVersions = /* GraphQL */ `
  query CustomListDocVersions(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelDocFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $docItemLevelLimit: Int
    $docItemLevelFilter: ModelDocItemFilterInput
  ) {
    listDocs(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        status
        title
        description
        orderFloat
        pdfFile {
          bucket
          region
          key
        }
        createdByUserId
        referenceDocId
        tenantId
        lastModifiedByUserId
        approvedByUserId
        rootChapterId
        chapterId
        shouldBeDeleted
        createdAt
        updatedAt
        docItems(
          filter: $docItemLevelFilter, 
          limit: $docItemLevelLimit,
          sortDirection: $sortDirection
        ) {
          items {
            id
            version
            status
            title
            description
            orderFloat
            itemType
            file {
              bucket
              region
              key
            }
            createdByUserId
            lastModifiedByUserId
            approvedByUserId
            rootChapterId
            docId
            shouldBeDeleted
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const customListProductDocs = /* GraphQL */ `
  query CustomListProductDocs(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelProductDocFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductDocs(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        tenantId
        status
        name
        description
        file {
          bucket
          region
          key
        }
        createdByUserId
        lastModifiedByUserId
        approvedByUserId
        chapters {
          items {
            id
            version
            status
            name
            description
            file {
              bucket
              region
              key
            }
            pdfFile {
              bucket
              region
              key
            }
            createdByUserId
            lastModifiedByUserId
            approvedByUserId
            rootChapterId
            parentProductDocId
            parentChapterId
            shouldBeDeleted
            currentTaskId
            isRejected
            orderFloat
            createdAt
            updatedAt
          }
          nextToken
        }
        applicationId
        application {
          id
          name
          description
          defaultRoles
          createdAt
          updatedAt
        }
        tasks {
          items {
            id
            name
            description
            requestedByUserId
            reviewedByUserId
            status
            rootChapterId
            organizationId
            ticketRef
            severity
            severityUpdatedAt
            issueCategory
            subCategory
            productVersion
            closureReason
            type
            rootChapterVersion
            productDocId
            isRejected
            usersToNotify
            linkedTickets
            salesForceTicketId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderFloat
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const customListApplicationRoles = /* GraphQL */ `
  query CustomListApplicationRoles(
    $filter: ModelApplicationRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleId
        applicationId
        groupId
        productDocs
        locations
        tenantId
        createdAt
        updatedAt
        application {
          id
          name
          description
          defaultRoles
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          status
          organizationId
          tenantId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const customByGroupByOrganization = /* GraphQL */ `
  query CustomByGroupByOrganization(
    $organizationId: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGroupByOrganization(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        status
        organizationId
        tenantId
        createdAt
        updatedAt
        users {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        applicationRoles {
          items {
            id
            roleId
            applicationId
            groupId
            productDocs
            locations
            tenantId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const customListChapters = /* GraphQL */ `
  query CustomSearchableListChapters(
    $id: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelChapterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChapters(
      id: $id
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        version
        status
        name
        description
        orderFloat
        file {
          bucket
          region
          key
        }
        parentProductDocId
        rootChapterId
        parentChapterId
        createdByUserId
        lastModifiedByUserId
        approvedByUserId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const customGetTicketRule = /* GraphQL */ `query CustomGetTicketRule($id: ID!) {
  getTicketRule(id: $id) {
    id
    name
    description
    status
    createdAt
    updatedAt
    sfCountries {
      items {
        id
        ticketRuleId
        sfCountryId
        status
        createdByUserId
        lastModifiedByUserId
        createdAt
        updatedAt
        sfCountries {
          id
          name
          countryId
          region
          allSFData
          tenantId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    sfAccounts {
      items {
        id
        ticketRuleId
        sfAccountId
        status
        createdByUserId
        lastModifiedByUserId
        createdAt
        updatedAt
        sfAccounts {
          id
          name
          accountName
          countryId
          parentAccountId
          emailDomain
          industry
          organizationId
          billingAddress
          shippingAddress
          copyBillingAddressToShippingAddress
          type
          phone
          website
          createdBy
          createdDate
          updatedBy
          updatedDate
          allSFData
          tenantId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        ticketRuleId
        userId
        status
        createdByUserId
        lastModifiedByUserId
        createdAt
        updatedAt
        users {
          id
          userType
          firstName
          lastName
          displayName
          jobTitle
          address
          city
          state
          postalCode
          country
          email
          organizationId
          salesForceId
          role
          secondaryEmail
          createdBy
          updatedBy
          createdAt
          updatedAt
          status
          isMigrated
          isTenantAdmin
          ticketsToReviewOrder
          supportPortalDashBoardChartsOrder
          tenantId
          preferredCurrency
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    groups {
      items {
        id
        ticketRuleId
        groupId
        status
        createdByUserId
        lastModifiedByUserId
        createdAt
        updatedAt
        groups {
          id
          name
          description
          status
          organizationId
          tenantId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
`

export const customListTicketRules = /* GraphQL */ `query CustomListTicketRules(
  $filter: ModelTicketRuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listTicketRules(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      status
      createdAt
      updatedAt
      sfCountries {
        items {
          id
          ticketRuleId
          sfCountryId
          status
          createdByUserId
          lastModifiedByUserId
          createdAt
          updatedAt
          sfCountries {
            id
            name
            countryId
            region
            allSFData
            tenantId
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      sfAccounts {
        items {
          id
          ticketRuleId
          sfAccountId
          status
          createdByUserId
          lastModifiedByUserId
          createdAt
          updatedAt
          sfAccounts {
            id
            name
            accountName
            countryId
            parentAccountId
            emailDomain
            industry
            organizationId
            billingAddress
            shippingAddress
            copyBillingAddressToShippingAddress
            type
            phone
            website
            createdBy
            createdDate
            updatedBy
            updatedDate
            allSFData
            tenantId
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          ticketRuleId
          userId
          status
          createdByUserId
          lastModifiedByUserId
          createdAt
          updatedAt
          users {
            id
            userType
            firstName
            lastName
            displayName
            jobTitle
            address
            city
            state
            postalCode
            country
            email
            organizationId
            salesForceId
            role
            secondaryEmail
            createdBy
            updatedBy
            createdAt
            updatedAt
            status
            isMigrated
            isTenantAdmin
            ticketsToReviewOrder
            supportPortalDashBoardChartsOrder
            tenantId
            preferredCurrency
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      groups {
        items {
          id
          ticketRuleId
          groupId
          status
          createdByUserId
          lastModifiedByUserId
          createdAt
          updatedAt
          groups {
            id
            name
            description
            status
            organizationId
            tenantId
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
`
