import { Text as MsText } from '@fluentui/react';

import Flex from 'components/Flex';
import Text from 'components/Text';

import useChapterPage from 'hooks/useChapterPage';

import { useDocumentOutlet } from '.';

const SubChapter = () => {
  const { rootChapter, documentsMap: mainDocumentsMap } = useDocumentOutlet();

  const { chapter, diffClassNameForChapter, finalReturnElement } =
    useChapterPage({ rootChapter, mainDocumentsMap });

  if (!chapter) {
    return (
      <div className="h-screen w-full px-4">
        <Flex direction="col">
          <Text
            variant="mediumPlus"
            weight={600}
            className="font-microsoft-docs text-gray-microsoft-docs font-semibold leading-5 antialiased mt-4"
            as="h3"
          >
            Something went wrong. Cannot find the page.
          </Text>
        </Flex>
      </div>
    );
  }

  return (
    <div className="w-full px-4  pt-16">
      <Flex direction="col" className="gap-5">
        <MsText
          className={diffClassNameForChapter}
          style={{
            fontSize: 'clamp(1.875rem,22.1052631579px + 1.6447368421vw,2.5rem)',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            lineHeight: '1',
          }}
        >
          {chapter.name ?? ''}
        </MsText>
        <Text
          variant="mediumPlus"
          className={`${diffClassNameForChapter} leading-6.25 mt-1 whitespace-pre-wrap`}
          as="p"
        >
          {chapter.description ?? ''}
        </Text>
        <Text
          variant="mediumPlus"
          weight={600}
          className="font-microsoft-docs text-gray-microsoft-docs font-semibold leading-5 antialiased mt-4"
          as="h3"
        >
          In this article
        </Text>
      </Flex>

      <div className="mt-2 mb-2" />
      {finalReturnElement}
    </div>
  );
};

export default SubChapter;
