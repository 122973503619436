import { useMemo, useState } from 'react';

import { Lead, LeadOverallStatusType } from 'API';

type ChartOneDataType = {
  name: string;
  lead: number;
  qualified: number;
  approved: number;
  leadPercentage?: number;
  qualifiedPercentage?: number;
  approvedPercentage?: number;
  ya?: number;
};

const percentage = (value: number, total: number) => {
  if (value === 0 || total === 0) {
    return 0;
  }
  return Number(((value / total) * 100).toFixed(2));
};

export const useChartOne = ({ leads }: { leads: Partial<Lead>[] }) => {
  const [selectedMonth, setSelectedMonth] = useState({
    barChartOneMonth: 'all',
    allMonth: 'all',
  });

  const { chartOneData, allMonthsDropdownList: monthsList } = useMemo(() => {
    const availableMonths = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const allMonthsDropdownList = availableMonths.map((m) => {
      return {
        key: m,
        text: m.toUpperCase(),
        id: m,
      };
    });

    const monthsOfLeads = leads.map((lead) => {
      return new Date(lead.createdAt || '').toLocaleDateString(undefined, {
        month: 'short',
      });
    });

    const months = [...new Set(monthsOfLeads)];

    let tempChartOneData: ChartOneDataType[] = months.map((month: string) => {
      return { name: month, lead: 0, qualified: 0, approved: 0 };
    });

    const monthDropdownList = months
      .map((m) => {
        return {
          key: m,
          text: m.toUpperCase(),
          id: m,
        };
      })
      .reverse();

    monthDropdownList.forEach((list) => {
      [...availableMonths].forEach((month) => {
        if (monthDropdownList.length < 4 && month !== list.key) {
          const indexOfMonth = availableMonths.indexOf(monthDropdownList[0].id);

          monthDropdownList.unshift({
            key: availableMonths[indexOfMonth - 1],
            text: availableMonths[indexOfMonth - 1]?.toUpperCase(),
            id: availableMonths[indexOfMonth - 1],
          });

          tempChartOneData.push({
            name: availableMonths[indexOfMonth - 1],
            lead: 0,
            qualified: 0,
            approved: 0,
          });
        }
      });
    });

    const newMonths: string[] = [];

    if (selectedMonth.barChartOneMonth !== 'all') {
      tempChartOneData = [];
      let count = availableMonths.indexOf(selectedMonth.barChartOneMonth);

      while (newMonths.length < 4) {
        newMonths.push(
          count < 0 ? availableMonths[12 + count] : availableMonths[count],
        );
        tempChartOneData.push({
          name:
            count < 0 ? availableMonths[12 + count] : availableMonths[count],
          lead: 0,
          qualified: 0,
          approved: 0,
        });
        count -= 1;
      }
    }

    leads.forEach((lead) => {
      const leadOverallStatus = lead.overallStatus;

      // bar chart 1
      const month = new Date(lead.createdAt || '').toLocaleDateString(
        undefined,
        {
          month: 'short',
        },
      );

      const index = tempChartOneData.findIndex((d) => d.name === month);

      if (
        selectedMonth.barChartOneMonth !== 'all' &&
        (selectedMonth.barChartOneMonth === month || newMonths.includes(month))
      ) {
        if (leadOverallStatus === null) {
          tempChartOneData[index].lead += 1;
        }

        if (leadOverallStatus === LeadOverallStatusType.QUALIFIED) {
          tempChartOneData[index].qualified += 1;
        }

        if (leadOverallStatus === LeadOverallStatusType.APPROVED) {
          tempChartOneData[index].approved += 1;
        }
        // show ticket for all org for bar chart one
      } else if (selectedMonth.barChartOneMonth === 'all') {
        if (leadOverallStatus === null) {
          tempChartOneData[index].lead += 1;
        }

        if (leadOverallStatus === LeadOverallStatusType.QUALIFIED) {
          tempChartOneData[index].qualified += 1;
        }

        if (leadOverallStatus === LeadOverallStatusType.APPROVED) {
          tempChartOneData[index].approved += 1;
        }
      }
    });

    const finalChartOneData = tempChartOneData
      .map((d, index) => {
        return {
          ...d,
          leadPercentage: percentage(
            tempChartOneData[index].lead,
            leads.length,
          ),
          qualifiedPercentage: percentage(
            tempChartOneData[index].qualified,
            leads.length,
          ),
          approvedPercentage: percentage(
            tempChartOneData[index].approved,
            leads.length,
          ),
          ya: 40,
        };
      })
      .reverse();

    return {
      chartOneData: finalChartOneData,
      allMonthsDropdownList,
    };
  }, [leads, selectedMonth.barChartOneMonth]);

  return {
    chartOneData,
    monthsList,
    selectedMonth,
    setSelectedMonth,
  };
};

export default useChartOne;
