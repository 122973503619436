import type { GraphQLQuery } from '@aws-amplify/api';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import {
  CustomListLeadsQuery,
  CustomSearchLeadsQuery,
  ModelLeadFilterInput,
  SearchableLeadFilterInput,
} from 'API';

import {
  customListLeads,
  customGetLead,
  customSearchLeads,
} from 'graphql/custom-queries';

export const useLead = ({ id, enabled }: { id: string; enabled: boolean }) => {
  return useQuery<any, any>({
    queryKey: [`leads/${id}`],
    queryFn: () => {
      return API.graphql({
        query: customGetLead,
        variables: { id },
      });
    },
    enabled,
  });
};

export const useLeadsList = ({
  filter = {},
  enabled,
}: {
  filter?: ModelLeadFilterInput;
  enabled: boolean;
}) =>
  useQuery<any, any>({
    queryKey: ['leads'],
    queryFn: () => {
      return API.graphql({
        query: customListLeads,
        variables: { filter, limit: 1_000_000 },
      });
    },
    enabled,
  });

export const useInfiniteLeadsList = ({
  filter,
  enabled,
}: {
  filter?: ModelLeadFilterInput;
  enabled: boolean;
}) => {
  return useInfiniteQuery(
    ['infinite-leads'],
    ({ pageParam }: any) => {
      return API.graphql<GraphQLQuery<CustomListLeadsQuery>>({
        query: customListLeads,
        variables: filter
          ? { filter, limit: 50, nextToken: pageParam }
          : { limit: 50, nextToken: pageParam },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled,
      getNextPageParam: (lastPage) => {
        const nextToken = lastPage.data?.listLeads?.nextToken;

        return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
      },
    },
  );
};

export const useSearchableLeadsList = ({
  filter = {},
  enabled,
}: {
  filter?: SearchableLeadFilterInput;
  enabled: boolean;
}) =>
  useQuery<any, any>({
    queryKey: ['searchableLeads'],
    queryFn: () => {
      return API.graphql({
        query: customSearchLeads,
        variables: { filter, limit: 50 },
      });
    },
    enabled,
  });

export const useInfiniteSearchableLeadsList = ({
  filter,
  enabled,
}: {
  filter?: SearchableLeadFilterInput;
  enabled: boolean;
}) => {
  return useInfiniteQuery(
    ['infinite-searchableLeads'],
    ({ pageParam }: any) => {
      return API.graphql<GraphQLQuery<CustomSearchLeadsQuery>>({
        query: customSearchLeads,
        variables: filter
          ? { filter, limit: 10_000, nextToken: pageParam }
          : { limit: 10_000, nextToken: pageParam },
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled,
      getNextPageParam: (lastPage) => {
        const nextToken = lastPage.data?.searchLeads?.nextToken;

        return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
      },
    },
  );
};
