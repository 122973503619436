import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

import Flex from 'components/Flex';
import Text from 'components/Text';

import { DocItem, StatusType } from 'API';

import { DocItemPageType } from 'types/documents';

import { DIFF_BG_CLASSNAME, NORMAL_DIFF_CLASSNAME } from 'consts/diffConstants';

import useIsContentReader from 'hooks/useIsContentReader';

import DocItemModifier from './DocItemModifier';

const Alert = ({
  doc,
  docItem,
  documentsMap,
  rootChapter,
  allRootChapterVersions = [],
  isAnyAncestorDeleted,
  setMessage,
}: DocItemPageType) => {
  const [isHoveringDocItem, setIsHoveringDocItem] = useState(false);

  const { isContentReader } = useIsContentReader();

  let bgColor = '';
  let color = 'text-blue-db-15';

  let titleStyle: React.CSSProperties = {};
  let contentStyle: React.CSSProperties = {};

  if (
    !isContentReader &&
    (docItem.status === StatusType.PENDING || isAnyAncestorDeleted)
  ) {
    bgColor = DIFF_BG_CLASSNAME;
    color = NORMAL_DIFF_CLASSNAME;

    contentStyle =
      isAnyAncestorDeleted || docItem.shouldBeDeleted === true
        ? {
            ...contentStyle,
            textDecoration: 'line-through',
          }
        : {
            ...contentStyle,
          };

    titleStyle =
      isAnyAncestorDeleted || docItem.shouldBeDeleted === true
        ? {
            ...titleStyle,
            textDecoration: 'line-through',
          }
        : {
            ...titleStyle,
          };
  }

  const showSideBySideDiffView =
    !isContentReader &&
    docItem.status === StatusType.PENDING &&
    !isAnyAncestorDeleted &&
    !docItem.shouldBeDeleted;

  const publishedDocItemVersion = (documentsMap[docItem.id]?.find(
    (x) => x.status === StatusType.PUBLISHED,
  ) || null) as DocItem | null;

  return (
    <div
      id={docItem.id}
      onMouseEnter={() => {
        setIsHoveringDocItem(true);
      }}
      onMouseLeave={() => {
        setIsHoveringDocItem(false);
      }}
      className="rounded-md bg-info  my-3 flex flex-row justify-between relative w-full "
    >
      <div className="bg-blue-400 w-0.5 h-full" />

      <div className={`${`flex w-full p-4 py-6 ${bgColor}`}`}>
        <div className="ml-3 w-11/12">
          {showSideBySideDiffView && publishedDocItemVersion && (
            <Flex alignItems="center">
              <ArrowRightCircleIcon className="h-5 w-5 mr-2 text-red-500" />

              <Text
                variant="mediumPlus"
                color="text-red-500"
                weight={700}
                style={titleStyle}
                className="leading-6.25"
                as="p"
              >
                {publishedDocItemVersion.title || ''}
              </Text>
            </Flex>
          )}

          <Flex alignItems="center">
            <ArrowRightCircleIcon className={`h-5 w-5 mr-2 ${color}`} />

            <Text
              variant="mediumPlus"
              color={color}
              weight={700}
              textCase="capitalize"
              style={titleStyle}
              className="leading-6.25"
              as="p"
            >
              {docItem.title || ''}
            </Text>
          </Flex>

          {showSideBySideDiffView && publishedDocItemVersion && (
            <div className="mt-2">
              <Text
                variant="mediumPlus"
                color="text-red-500"
                style={contentStyle}
                className="leading-6.25 whitespace-pre-wrap"
                as="p"
              >
                {publishedDocItemVersion.description || ''}
              </Text>
            </div>
          )}

          <div className="mt-2">
            <Text
              variant="mediumPlus"
              color={color}
              style={contentStyle}
              className="leading-6.25 whitespace-pre-wrap"
              as="p"
            >
              {docItem.description || ''}
            </Text>
          </div>
        </div>
        {isHoveringDocItem && (
          <DocItemModifier
            doc={doc}
            docItem={docItem}
            documentsMap={documentsMap}
            rootChapter={rootChapter}
            allRootChapterVersions={allRootChapterVersions}
            isAnyAncestorDeleted={isAnyAncestorDeleted}
            setMessage={setMessage}
          />
        )}
      </div>
    </div>
  );
};

export default Alert;
