import type { GraphQLQuery } from '@aws-amplify/api';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';

import type {
  CustomByGroupByOrganizationQuery,
  Group,
  ModelGroupFilterInput,
} from 'API';

import { tenantAtom } from 'atoms/modals';

import { customByGroupByOrganization } from 'graphql/custom-queries';

import getNonNullableList from 'helpers/utils/getNonNullableList';
import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseGroupsByOrganizationType = {
  organizationId: string;
  filter?: ModelGroupFilterInput | null;
} & UseInfiniteQueryOptions;

export const useGroupsByOrganizationSource = (
  props: IUseGroupsByOrganizationType,
) => {
  const filter = props.filter ?? null;

  const queryKey = props.queryKey ?? [];

  const organizationId = props.organizationId;

  const enabled = props.enabled ?? true;

  const staleTime =
    props.staleTime ?? toMilliseconds({ hours: 0, minutes: 0, seconds: 20 }); // 20secs

  return useInfiniteQuery({
    queryKey: ['groupsByOrganization', { organizationId }, filter, ...queryKey],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<GraphQLQuery<CustomByGroupByOrganizationQuery>>({
        query: customByGroupByOrganization,
        variables: filter
          ? { filter, limit: 1_000_000, nextToken: pageParam, organizationId }
          : { limit: 1_000_000, nextToken: pageParam, organizationId },
      });
    },
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.byGroupByOrganization?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
    enabled,
    staleTime,
  });
};

const useGroupsByOrganization = (props: IUseGroupsByOrganizationType) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const filter = props.filter ?? null;

  const organizationId = props.organizationId;

  const enabled = (props.enabled ?? true) && !!tenantId && !!organizationId;

  let sourceFilter: ModelGroupFilterInput | null = null;

  if (tenantId) {
    const defaultFilter: ModelGroupFilterInput | null = {
      tenantId: {
        eq: tenantId,
      },
    };

    sourceFilter = filter
      ? {
          ...filter,
          ...defaultFilter,
        }
      : { ...defaultFilter };
  }

  const result = useGroupsByOrganizationSource({
    ...props,
    filter: sourceFilter,
    enabled,
    organizationId,
  });

  const groupsByOrganizationSource = useMemo(() => {
    const pages = result.data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.byGroupByOrganization?.items),
    );

    return tempItems as Partial<Group>[];
  }, [result.data?.pages]);

  return {
    groupsByOrganizationSource,
    ...result,
  };
};

export const useInfiniteGroupsByOrganization = (
  props: IUseGroupsByOrganizationType,
) => {
  const result = useGroupsByOrganization(props);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = result;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      try {
        void fetchNextPage();
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return result;
};

export default useGroupsByOrganization;
