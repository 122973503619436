type IToMilliSecondsProps = {
  hours?: number;
  minutes?: number;
  seconds?: number;
} | void;

const toMilliseconds = (props: IToMilliSecondsProps = {}) => {
  const hours = props?.hours ?? 0;
  const minutes = props?.minutes ?? 0;
  const seconds = props?.seconds ?? 0;

  return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
};

export default toMilliseconds;
