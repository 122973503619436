import classNames from 'classnames';
import React from 'react';

import Flex from 'components/Flex';

import styles from './button.module.css';

// different buttons, default is `primary`
type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'default'
  | 'success'
  | 'cancel'
  | 'rounded'
  | 'disabled'
  | 'Add';
// different sizes, default is `md`
type Size = 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
// three different button types, default is `button`
type ButtonHtmlType = 'button' | 'submit' | 'reset';

type ButtonProps = {
  icon?: React.ReactNode | null;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label?: string;
  className?: string;
  variant?: Variant;
  size?: Size;
  type?: ButtonHtmlType;
  children?: React.ReactNode;
};

const Button = ({
  icon,
  label,
  onClick,
  className = '',
  variant = 'primary',
  size = 'md',
  type = 'button',
  children,
}: ButtonProps) => {
  const btnClasses = classNames(
    className,
    styles[`btn-${variant}`],
    styles[`btn-${size}`],
  );

  return (
    // eslint-disable-next-line react/button-has-type, react/forbid-elements
    <button
      className={btnClasses}
      // eslint-disable-next-line react/button-has-type, react/forbid-elements
      type={type}
      onClick={onClick}
      // eslint-disable-next-line no-unneeded-ternary
      disabled={variant === 'disabled' ? true : false}
    >
      {children || (
        <Flex justify={icon ? 'between' : 'center'} alignItems="center">
          {icon ? <span className={styles['icon-span']}>{icon}</span> : null}
          <span
            className={classNames(
              styles[`text-${variant}`],
              icon && 'text-left ml-1',
            )}
          >
            {label}
          </span>
        </Flex>
      )}
    </button>
  );
};

export default Button;
