import { useAtomValue } from 'jotai';

import ModalForm from 'components/Pages/Documents/ModalForm';
import SliderForm from 'components/Pages/Documents/SliderForm';

import { Chapter, DocItemType } from 'API';

import { AddRootDocItemSliderProps } from 'types/Pages/Products';

import { docItemEditAtom } from 'atoms/modals';

import { ToastMessageType } from 'types';

type SetToastMessageType = (messageObject: {
  message: string;
  type: string;
}) => void;

type IDocItemFormProps = {
  rootChapter?: Partial<Chapter | null>;
  allRootChapterVersions: Partial<Chapter | null>[];
  setMessage: SetToastMessageType;
  message: ToastMessageType;
};

const DocItemForm = ({
  rootChapter,
  allRootChapterVersions = [],
  setMessage,
  message,
}: IDocItemFormProps) => {
  const docItemAtomValue = useAtomValue(docItemEditAtom);

  const { docItem } = docItemAtomValue;

  if (
    docItem?.itemType &&
    [DocItemType.content, DocItemType.code].includes(docItem.itemType)
  ) {
    return (
      <ModalForm
        rootChapter={rootChapter}
        allRootChapterVersions={allRootChapterVersions}
        setMessage={setMessage}
        message={message}
      />
    );
  }

  return (
    <SliderForm
      rootChapter={rootChapter}
      allRootChapterVersions={allRootChapterVersions}
      setMessage={setMessage}
    />
  );
};

export default DocItemForm;
