import { Storage } from 'aws-amplify';
import isString from 'lodash/isString';
import { useState, useEffect, useCallback } from 'react';
import type { UnknownRecord } from 'type-fest';

import { S3Object } from 'API';

const useS3FileUrl = (data: UnknownRecord) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const file: Partial<S3Object> | null = data.file ?? null;

  const key = file?.key ?? null;

  const id = key?.replace('public/', '') ?? null;

  useEffect(() => {
    const fetchUrl = async () => {
      if (id && isString(id)) {
        try {
          const result = await Storage.get(id);
          setFileUrl(result);
        } catch (error) {
          console.error(error);
          setFileUrl(null);
        }
      } else {
        setFileUrl(null); // Set to null if id is not present
      }
    };

    void fetchUrl();
  }, [id]); // Dependency on id ensures reactiveness to file changes

  return fileUrl;
};

export const useGetMultpleS3Urls = ({ files }: { files: S3Object[] }) => {
  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const getS3FileByKey = async (key: string) => {
    try {
      const result = await Storage.get(key);
      return result;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line unicorn/no-for-loop
      for (let i = 0; i < files.length; i += 1) {
        const fileId = files[i].key.replace('public/', '');
        if (fileId) {
          getS3FileByKey(fileId)
            .then((fileurl: any) => {
              setFileUrls((urls) => [...urls, fileurl]);
            })
            .catch((error) => {
              console.error('error', error);
            });
        }
      }
    })();
  }, [files]);

  return fileUrls;
};

export default useS3FileUrl;
