import { IconButton } from '@fluentui/react';

import { ArrowIconType } from 'types/nestedSidebar';

const ArrowIcon = ({
  toggle,
  onClick = () => {},
  disabled = false,
}: ArrowIconType) => {
  return (
    <IconButton
      iconProps={{
        iconName: toggle ? 'ChevronDown' : 'ChevronRight',
        className: 'text-0.55',
      }}
      onClick={(e) => onClick(e)}
      className="h-1 ml-2 w-2 text-gray-fl-17 font-extrabold"
      disabled={disabled}
    />
  );
};

export default ArrowIcon;
