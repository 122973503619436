import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';

import { selectedDocContentSectionId } from 'atoms/modals';

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1,
};

const useIsSectionVisible = () => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [sectionId, setSelectedId] = useAtom(selectedDocContentSectionId);

  useEffect(() => {
    const section = sectionRef.current;

    const callback = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const id = section?.getAttribute('id') || '';
        setSelectedId(id);
      }
      setIsVisible(entry.isIntersecting);
    };

    const observer = new IntersectionObserver(callback, options);

    if (section) observer.observe(section);

    return () => {
      if (section) observer.unobserve(section);
    };
  }, [sectionRef, setSelectedId]);

  return { sectionRef, sectionId, isVisible };
};

export default useIsSectionVisible;
