interface Props {
  children: React.ReactNode;
}

const NavLink = ({ children }: Props) => (
  <div className="group relative">
    {children}
    <div className=" absolute left-0 -bottom-2 h-1 w-1/2 group-hover:h-10 group-hover:bg-white transition duration-500 ease-in-out" />
    <div className=" absolute right-0 -bottom-2 h-1 w-1/2 group-hover:h-10 group-hover:bg-white transition duration-500 ease-in-out" />
    <div />
  </div>
);

export default NavLink;
