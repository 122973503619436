/* eslint-disable react/self-closing-comp */
type ClassNameProps = {
  className?: string;
};
export const Check = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 13l4 4L19 7"
    />
  </svg>
);
export const People = ({ className }: ClassNameProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
    />
  </svg>
);

export const Eye = ({ className }: ClassNameProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);
export const TrendUp = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
    />
  </svg>
);
export const ChevronDown = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="currentColor"
  >
    <path
      d="M505.183,123.179c-9.087-9.087-23.824-9.089-32.912,0.002l-216.266,216.27L39.729,123.179  c-9.087-9.087-23.824-9.089-32.912,0.002c-9.089,9.089-9.089,23.824,0,32.912L239.55,388.82c4.364,4.364,10.283,6.816,16.455,6.816  c6.172,0,12.092-2.453,16.455-6.817l232.721-232.727C514.272,147.004,514.272,132.268,505.183,123.179z"
      fill="currentColor"
    />
  </svg>
);

export const ChevronRight = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M15.422 7.406l-4.594 4.594 4.594 4.594-1.406 1.406-6-6 6-6z"></path>
  </svg>
);
export const Dashboard = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50004 10C5.43777 10 5.37567 10.0007 5.31372 10.002L10.8968 0L24 24H11.981C13.2403 22.5176 14 20.5975 14 18.5C14 13.8056 10.1945 10 5.50004 10ZM5.5 24C8.53757 24 11 21.5376 11 18.5C11 15.4624 8.53757 13 5.5 13C2.46243 13 0 15.4624 0 18.5C0 21.5376 2.46243 24 5.5 24Z"
    />
  </svg>
);
export const VisitLink = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 26 26"
    stroke="currentColor"
  >
    <g>
      <path
        d="M18,17.759v3.366C18,22.159,17.159,23,16.125,23H4.875C3.841,23,3,22.159,3,21.125V9.875
          C3,8.841,3.841,8,4.875,8h3.429l3.001-3h-6.43C2.182,5,0,7.182,0,9.875v11.25C0,23.818,2.182,26,4.875,26h11.25
          C18.818,26,21,23.818,21,21.125v-6.367L18,17.759z"
      />
      <g>
        <path
          d="M22.581,0H12.322c-1.886,0.002-1.755,0.51-0.76,1.504l3.22,3.22l-5.52,5.519
              c-1.145,1.144-1.144,2.998,0,4.141l2.41,2.411c1.144,1.141,2.996,1.142,4.14-0.001l5.52-5.52l3.16,3.16
              c1.101,1.1,1.507,1.129,1.507-0.757L26,3.419C25.999-0.018,26.024-0.001,22.581,0z"
        />
      </g>
    </g>
  </svg>
);
export const Trash = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 430 512"
    stroke="currentColor"
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m416.875 114.441406-11.304688-33.886718c-4.304687-12.90625-16.339843-21.578126-29.941406-21.578126h-95.011718v-30.933593c0-15.460938-12.570313-28.042969-28.027344-28.042969h-87.007813c-15.453125 0-28.027343 12.582031-28.027343 28.042969v30.933593h-95.007813c-13.605469 0-25.640625 8.671876-29.945313 21.578126l-11.304687 33.886718c-2.574219 7.714844-1.2695312 16.257813 3.484375 22.855469 4.753906 6.597656 12.445312 10.539063 20.578125 10.539063h11.816406l26.007813 321.605468c1.933594 23.863282 22.183594 42.558594 46.109375 42.558594h204.863281c23.921875 0 44.175781-18.695312 46.105469-42.5625l26.007812-321.601562h6.542969c8.132812 0 15.824219-3.941407 20.578125-10.535157 4.753906-6.597656 6.058594-15.144531 3.484375-22.859375zm-249.320312-84.441406h83.0625v28.976562h-83.0625zm162.804687 437.019531c-.679687 8.402344-7.796875 14.980469-16.203125 14.980469h-204.863281c-8.40625 0-15.523438-6.578125-16.203125-14.980469l-25.816406-319.183593h288.898437zm-298.566406-349.183593 9.269531-27.789063c.210938-.640625.808594-1.070313 1.484375-1.070313h333.082031c.675782 0 1.269532.429688 1.484375 1.070313l9.269531 27.789063zm0 0"
        fill="currentColor"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m282.515625 465.957031c.265625.015625.527344.019531.792969.019531 7.925781 0 14.550781-6.210937 14.964844-14.21875l14.085937-270.398437c.429687-8.273437-5.929687-15.332031-14.199219-15.761719-8.292968-.441406-15.328125 5.925782-15.761718 14.199219l-14.082032 270.398437c-.429687 8.273438 5.925782 15.332032 14.199219 15.761719zm0 0"
        fill="currentColor"
        data-original="#000000"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m120.566406 451.792969c.4375 7.996093 7.054688 14.183593 14.964844 14.183593.273438 0 .554688-.007812.832031-.023437 8.269531-.449219 14.609375-7.519531 14.160157-15.792969l-14.753907-270.398437c-.449219-8.273438-7.519531-14.613281-15.792969-14.160157-8.269531.449219-14.609374 7.519532-14.160156 15.792969zm0 0"
        fill="currentColor"
        data-original="#000000"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m209.253906 465.976562c8.285156 0 15-6.714843 15-15v-270.398437c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v270.398437c0 8.285157 6.714844 15 15 15zm0 0"
        fill="currentColor"
        data-original="#000000"
      />
    </g>
  </svg>
);
export const ClusterManagement = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7499 0C15.6717 0 14.6535 0.2412 13.7334 0.663001C15.4395 2.3418 16.5 4.6737 16.5 7.2501C16.5 9.8262 15.4395 12.1581 13.7334 13.8369C14.6532 14.2587 15.6717 14.4999 16.7499 14.4999C20.754 14.4999 24 11.2539 24 7.2501C24 3.246 20.754 0 16.7499 0ZM10.2657 10.1631C8.5605 11.8419 7.5 14.1738 7.5 16.7499C7.5 19.3263 8.5605 21.6582 10.2657 23.337C9.3465 23.7588 8.3283 24 7.2501 24C3.246 24 0 20.754 0 16.7499C0 12.7461 3.246 9.5001 7.2501 9.5001C8.3283 9.5001 9.3468 9.7413 10.2657 10.1631ZM10.1631 13.7334C11.8419 15.4395 14.1738 16.5 16.7499 16.5C19.3263 16.5 21.6582 15.4395 23.337 13.7334C23.7588 14.6535 24 15.6717 24 16.7499C24 20.754 20.754 24 16.7499 24C12.7461 24 9.5001 20.754 9.5001 16.7499C9.5001 15.6717 9.7413 14.6532 10.1631 13.7334ZM13.8369 10.2657C12.1581 8.5605 9.82621 7.5 7.25011 7.5C4.67371 7.5 2.34181 8.5605 0.663008 10.2657C0.241208 9.3465 7.62939e-06 8.3283 7.62939e-06 7.2501C7.62939e-06 3.246 3.24601 0 7.25011 0C11.2539 0 14.4999 3.246 14.4999 7.2501C14.4999 8.3283 14.2587 9.3468 13.8369 10.2657Z"
    />
  </svg>
);
export const PolicyManager = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0L17.5002 2.0001L23 0V9.0273C22.9984 11.1759 22.5327 13.2813 21.6447 15.2307C19.898 19.0626 16.5461 22.2882 12 24C7.45387 22.2882 4.10203 19.0626 2.35532 15.2307C1.46728 13.2813 1.00198 11.1759 1 9.0273V0L6.49984 2.0001L12 0ZM17.5216 7.06175L18.4415 8.03951C18.5195 8.12184 18.5196 8.25569 18.4412 8.33802L11.2412 15.9379C11.1632 16.0207 11.0364 16.0207 10.9584 15.9379L6.5585 11.2935C6.4805 11.2112 6.4805 11.0774 6.5585 10.995L7.47848 10.0173C7.55648 9.93494 7.68328 9.93494 7.76128 10.0173L11.1 13.5344L17.2388 7.06175C17.3168 6.97942 17.4436 6.97942 17.5216 7.06175Z"
    />
  </svg>
);
export const Telemetry = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.0001C11.5813 2.0001 8.0002 5.5821 8.0002 9.99929C8.0002 14.418 11.5813 18 16 18V20.0001C10.4767 20.0001 6.0001 15.5223 6.0001 9.99899C6.0001 4.4766 10.4767 0 16 0V2.0001ZM2 9.99901C2 17.7312 8.26759 24 15.9998 24V21.9999C9.37189 21.9999 3.9998 16.6269 3.9998 9.99901H2ZM15.9997 15.9999V9.99992L21.9997 9.99902C21.9997 6.68552 19.3144 3.99993 15.9997 3.99993C12.6853 3.99993 9.99973 6.68552 9.99973 9.99902C9.99973 13.3134 12.6853 15.9999 15.9997 15.9999Z"
    />
  </svg>
);
export const TeamManager = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 16V0H24V6.00004H11.0001V16H0ZM12.9999 18V8H24V24H0V18H12.9999Z"
    />
  </svg>
);
export const LabelManager = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6H17.4765C16.2402 2.5068 12.9171 0 9 0C4.0293 0 0 4.0293 0 9C0 12.9171 2.5068 16.2402 6 17.4765V6ZM19.9491 8.0001C19.9797 8.3301 20.0001 8.6622 20.0001 9C20.0001 15.0654 15.0654 20.0001 8.99999 20.0001C8.66219 20.0001 8.33009 19.9797 8.00009 19.9491V24H24V8.0001H19.9491ZM8.9999 17.999C8.6621 17.999 8.3282 17.9765 8 17.9405V8H17.9414C17.9774 8.3291 17.9999 8.6621 17.9999 8.9999C17.9999 13.9706 13.9706 17.999 8.9999 17.999Z"
    />
  </svg>
);
export const Integration = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H11.0001V11.0001H0V0ZM15 13.0002H21.9999C23.0997 13.0002 24 13.9005 24 15V22.0002C24 23.1 23.0997 24 21.9999 24H15C13.9005 24 12.9999 23.1 12.9999 22.0002V15C12.9999 13.9005 13.9005 13.0002 15 13.0002ZM24 5.5011C24 8.5371 21.537 11.0001 18.5001 11.0001C15.4629 11.0001 12.9999 8.5371 12.9999 5.5011C12.9999 2.463 15.4629 0 18.5001 0C21.537 0 24 2.463 24 5.5011ZM7.62939e-06 24L6.00001 13.0002L12 24H7.62939e-06Z"
    />
  </svg>
);
export const Like = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="currentColor"
    stroke="#237EFC"
    strokeWidth={2}
  >
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m1.75 23h2.5c.965 0 1.75-.785 1.75-1.75v-11.5c0-.965-.785-1.75-1.75-1.75h-2.5c-.965 0-1.75.785-1.75 1.75v11.5c0 .965.785 1.75 1.75 1.75z"
        fill="currentColor"
        data-original="#ffffff"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m12.781.75c-1 0-1.5.5-1.5 3 0 2.376-2.301 4.288-3.781 5.273v12.388c1.601.741 4.806 1.839 9.781 1.839h1.6c1.95 0 3.61-1.4 3.94-3.32l1.12-6.5c.42-2.45-1.46-4.68-3.94-4.68h-4.72s.75-1.5.75-4c0-3-2.25-4-3.25-4z"
        fill="currentColor"
        data-original="#ffffff"
      />
    </g>
  </svg>
);
export const UnLike = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    fill="currentColor"
    stroke="#237EFC"
    strokeWidth={10}
  >
    <path
      d="M133.893,45.886c2.466,2.466,2.466,6.465,0,8.932l-26.971,26.969c-2.467,2.467-4.466,4.518-4.466,4.579   s-10.901,20.56-26.858,31.03c-15.959,10.474-11.471,18.951-11.471,27.43c0,7.143-8.849,8.975-14.917,1.025   c-2.116-2.771-4.194-8.148-4.61-11.611c-1.262-10.523,5.621-17.534,8.152-21.331c1.713-2.569,2.7-7.332,0.917-13.252H12.786   c-8.086,0-12.459-6.226-9.719-13.832l20.802-57.738c1.178-3.268,4.983-5.942,8.457-5.942h49.126c3.475,0,8.326-2.01,10.782-4.466   l2.26-2.26c2.467-2.466,6.467-2.466,8.934,0L133.893,45.886z M112.68,0l-8.27,8.269l36.118,36.119l8.271-8.269L112.68,0z"
      fill="currentColor"
      data-original="#000000"
    />
  </svg>
);
export const Logout = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <g opacity="0.8">
      <path
        d="M3.47891 7.4373H14.0508C14.6352 7.4373 15.1133 7.91543 15.1133 8.4998C15.1133 9.08418 14.6352 9.5623 14.0508 9.5623H3.47891L5.60391 11.6873C6.02891 12.1123 6.02891 12.7764 5.60391 13.2014C5.39141 13.4139 5.12578 13.5201 4.86016 13.5201C4.59453 13.5201 4.32891 13.4139 4.11641 13.2014L0.185157 9.27012V9.13731C0.0523443 8.95137 -0.000782013 8.76543 -0.000782013 8.52637C-0.000782013 8.2873 0.078907 8.0748 0.185157 7.91543V7.78262L4.11641 3.79824C4.54141 3.37324 5.20547 3.37324 5.63047 3.79824C6.05547 4.22324 6.05547 4.8873 5.63047 5.3123L3.47891 7.4373Z"
        fill="white"
      />
      <path
        d="M10.5445 17C9.11016 17 7.94141 15.8312 7.94141 14.3969V12.2188C7.94141 11.6344 8.41953 11.1562 9.00391 11.1562C9.58828 11.1562 10.0664 11.6344 10.0664 12.2188V14.3969C10.0664 14.6625 10.2789 14.875 10.5445 14.875H19.682C19.9477 14.875 20.1602 14.6625 20.1602 14.3969V2.60312C20.1602 2.3375 19.9477 2.125 19.682 2.125H10.5445C10.2789 2.125 10.0664 2.3375 10.0664 2.60312V4.78125C10.0664 5.36562 9.58828 5.84375 9.00391 5.84375C8.41953 5.84375 7.94141 5.36562 7.94141 4.78125V2.60312C7.94141 1.16875 9.11016 0 10.5445 0H19.682C21.1164 0 22.2852 1.16875 22.2852 2.60312V14.3969C22.2852 15.8312 21.1164 17 19.682 17H10.5445Z"
        fill="white"
      />
    </g>
  </svg>
);
export const Scoop = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      d="M6.45573 24.9693C8.62195 23.6248 9.53699 20.7054 9.72373 19.4138C5.52202 21.9535 2.52636 19.8495 1.55374 18.48C-0.705848 15.6602 1.18803 12.4654 2.41742 11.2204C7.40345 7.35485 10.2061 3.46282 10.9842 2C12.5342 5.6975 17.5202 9.42302 19.481 11.2204C22.8657 15.3054 20.8349 18.3011 19.481 19.4138C16.8293 21.6733 13.4586 20.3552 12.1047 19.4138C12.5155 22.4577 14.4857 24.3858 15.4194 24.9693V25.8097H6.45573V24.9693Z"
      stroke="white"
    />
    <path
      d="M12.5999 25.5C12.3996 25.5 12.1996 25.4924 12 25.4787V15.5H21.9786C21.9923 15.6997 22 15.8996 22 16.1C22 21.2922 17.7916 25.5 12.5999 25.5Z"
      stroke="white"
    />
  </svg>
);
export const Documentation = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12L17.9995 2H5.99982L0 12L5.99982 22H17.9995L24 12ZM17.728 20.4608L14.2305 14.6313C13.9402 14.8602 13.6089 15.0421 13.2517 15.1728L16.7507 21.0044H17.4025L17.728 20.4608ZM8.5198 11.4568C8.48962 11.6345 8.47022 11.815 8.47022 12.0003C8.47022 12.1856 8.48962 12.3661 8.5198 12.5438H1.52109L1.19556 12.0003L1.52109 11.4568H8.5198ZM14.2302 9.36885L17.7278 3.53938L17.4023 2.99585H16.7505L13.2515 8.8274C13.6086 8.95809 13.9399 9.13996 14.2302 9.36885ZM9.5 12C9.5 13.3805 10.619 14.5 12.0004 14.5C13.381 14.5 14.5 13.3805 14.5 12C14.5 10.6188 13.381 9.5 12.0004 9.5C10.619 9.5 9.5 10.6188 9.5 12Z"
    />
  </svg>
);
export const Policies = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path d="M5.00019 24L7.22879 15.9768C7.37803 15.4395 6.93741 15 6.25005 15H0L14.9998 0L12.7712 8.0235C12.622 8.5605 13.0626 9 13.75 9H20L5.00019 24Z" />
  </svg>
);
export const Edit = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    stroke="currentColor"
  >
    <path d="M16.6762 3.67688L13.8481 0.847947C13.46 0.45962 12.823 0.45962 12.434 0.847947L2.02178 11.2608L0.967957 16.5567L6.26371 15.5036L16.6762 5.09098C17.0652 4.70169 17.0652 4.06592 16.6762 3.67688Z" />
  </svg>
);
export const Archive = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6346 0.556625L16.492 8.55663H1.44455L4.30135 0.556625H13.6346ZM16.968 15.2232V9.89001H12.968L11.6346 11.2232H6.30136L4.96796 9.89001H0.967957V15.2232C0.967957 15.9564 1.56816 16.5566 2.30136 16.5566H15.6346C16.3678 16.5566 16.968 15.9564 16.968 15.2232Z"
    />
  </svg>
);
export const graph = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      d="M14.9729 7.48087L17.8061 3.78409"
      stroke="#9F9C9C"
      strokeWidth="1.15392"
    />
    <path
      d="M3.53231 8.35129L8.08834 9.26883"
      stroke="#9F9C9C"
      strokeWidth="0.923134"
    />
    <path
      d="M14.2041 14.0025L17.2415 17.5322"
      stroke="#9F9C9C"
      strokeWidth="0.923134"
    />
    <ellipse cx="17.6945" cy="18.3656" rx="2.49434" ry="2.50355" />
    <ellipse cx="18.7862" cy="2.74775" rx="2.49434" ry="2.50354" />
    <ellipse cx="3.14979" cy="7.89264" rx="2.49434" ry="2.50354" />
    <ellipse cx="12.0389" cy="10.5459" rx="5.20247" ry="5.22168" />
  </svg>
);
export const S3Bucket = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <ellipse
      cx="13.0368"
      cy="4.05049"
      rx="12.4706"
      ry="3.26354"
      fill="#05147D"
    />
    <path
      d="M4.69904 24.2819L0.624756 5.65884C1.65152 7.92564 10.1504 8.53864 13.9035 8.44335C21.6848 8.48113 24.9207 6.60275 25.566 5.65884L21.4626 23.9221C21.3706 24.4629 21.303 24.5939 21.2807 24.5918C20.2038 26.2306 13.902 26.4168 10.8858 26.305C5.73758 25.9246 4.94481 24.7777 4.84482 24.6533C4.7341 24.561 4.7015 24.3673 4.69904 24.2819Z"
      fill="#05147D"
    />
  </svg>
);
export const Database = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    stroke="currentColor"
  >
    <path
      d="M1.0062 6.80969H25.7772V21.1522C25.7772 23.3614 23.9863 25.1522 21.7772 25.1522H5.0062C2.79706 25.1522 1.0062 23.3614 1.0062 21.1522V6.80969Z"
      fill="white"
    />
    <path
      d="M12.605 13.4583C5.48617 13.4583 1.89864 10.5965 0.994723 9.16564L0.886902 12.8522C2.14081 16.708 9.22145 17.7787 12.605 17.8321C21.4533 18.1137 25.0731 14.6649 25.7769 12.9053V8.78278C23.042 12.9254 15.8561 13.6259 12.605 13.4583Z"
      fill="#ffffff"
    />
    <path
      d="M12.605 19.1601C5.48617 19.1602 1.89864 16.2984 0.994723 14.8675L0.886902 18.554C2.14081 22.4098 9.22145 23.4806 12.605 23.534C21.4533 23.8155 25.0731 20.3667 25.7769 18.6071V14.4846C23.042 18.6272 15.8561 19.3277 12.605 19.1601Z"
      fill="#ffffff"
    />
    <path
      d="M12.605 24.8619C5.48617 24.8619 1.89864 22.0001 0.994723 20.5692L0.886902 24.2558C2.14081 28.1116 9.22145 29.1823 12.605 29.2357C21.4533 29.5173 25.0731 26.0685 25.7769 24.3089V20.1864C23.042 24.329 15.8561 25.0295 12.605 24.8619Z"
      fill="#ffffff"
    />
    <ellipse
      cx="13.3948"
      cy="6.42441"
      rx="12.4924"
      ry="5.59283"
      fill="#ffffff"
    />
  </svg>
);
export const S3BucketAndDB = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 35 35"
    stroke="currentColor"
  >
    <ellipse
      cx="13.1494"
      cy="3.78694"
      rx="12.4706"
      ry="3.26354"
      fill="#05147D"
    />
    <path
      d="M4.81168 24.0184L0.737396 5.39529C1.76416 7.66209 10.263 8.27509 14.0161 8.1798C21.7974 8.21758 25.0334 6.3392 25.6786 5.39529L21.5752 23.6586C21.4833 24.1993 21.4157 24.3303 21.3934 24.3283C20.3164 25.9671 14.0147 26.1532 10.9984 26.0414C5.85022 25.661 5.05745 24.5141 4.95746 24.3897C4.84674 24.2974 4.81414 24.1037 4.81168 24.0184Z"
      fill="#05147D"
    />
    <path
      d="M13.3033 14.5781H33.1199V25.252C33.1199 27.4611 31.3291 29.252 29.1199 29.252H17.3033C15.0942 29.252 13.3033 27.4611 13.3033 25.252V14.5781Z"
      fill="white"
    />
    <path
      d="M22.5825 19.8974C16.8875 19.8974 14.0175 17.608 13.2944 16.4632L13.2081 19.4125C14.2112 22.4971 19.8757 23.3537 22.5825 23.3964C29.6611 23.6216 32.5569 20.8626 33.1199 19.4549V16.157C30.932 19.471 25.1834 20.0314 22.5825 19.8974Z"
      fill="#3065C6"
    />
    <path
      d="M22.5825 24.4579C16.8875 24.4579 14.0175 22.1685 13.2944 21.0238L13.2081 23.973C14.2112 27.0576 19.8757 27.9142 22.5825 27.9569C29.6611 28.1822 32.5569 25.4231 33.1199 24.0155V20.7175C30.932 24.0316 25.1834 24.592 22.5825 24.4579Z"
      fill="#3065C6"
    />
    <path
      d="M22.5825 29.0197C16.8875 29.0197 14.0175 26.7303 13.2944 25.5856L13.2081 28.5348C14.2112 31.6194 19.8757 32.476 22.5825 32.5187C29.6611 32.7439 32.5569 29.9849 33.1199 28.5772V25.2793C30.932 28.5933 25.1834 29.1537 22.5825 29.0197Z"
      fill="#3065C6"
    />
    <ellipse
      cx="23.2141"
      cy="14.2701"
      rx="9.99381"
      ry="4.47423"
      fill="#3065C6"
    />
  </svg>
);
export const ProvenanceGraph = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      d="M15.005 7.92417L17.8382 4.22739"
      stroke="#9F9C9C"
      strokeWidth="1.15392"
    />
    <path
      d="M3.56433 8.79459L8.12035 9.71213"
      stroke="#9F9C9C"
      strokeWidth="0.923134"
    />
    <path
      d="M14.2362 14.4458L17.2736 17.9755"
      stroke="#9F9C9C"
      strokeWidth="0.923134"
    />
    <ellipse cx="17.7265" cy="18.8089" rx="2.49434" ry="2.50355" />
    <ellipse cx="18.8183" cy="3.19104" rx="2.49434" ry="2.50354" />
    <ellipse cx="3.18184" cy="8.33594" rx="2.49434" ry="2.50354" />
    <ellipse cx="12.0709" cy="10.9892" rx="5.20247" ry="5.22168" />
  </svg>
);

export const List = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
      clipRule="evenodd"
    />
  </svg>
);
export const BriefCase = ({ className }: ClassNameProps) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M488.727,279.273c-6.982,0-11.636,4.655-11.636,11.636v151.273c0,6.982-4.655,11.636-11.636,11.636H46.545
          c-6.982,0-11.636-4.655-11.636-11.636V290.909c0-6.982-4.655-11.636-11.636-11.636s-11.636,4.655-11.636,11.636v151.273
          c0,19.782,15.127,34.909,34.909,34.909h418.909c19.782,0,34.909-15.127,34.909-34.909V290.909
          C500.364,283.927,495.709,279.273,488.727,279.273z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M477.091,116.364H34.909C15.127,116.364,0,131.491,0,151.273v74.473C0,242.036,11.636,256,26.764,259.491l182.691,40.727
          v37.236c0,6.982,4.655,11.636,11.636,11.636h69.818c6.982,0,11.636-4.655,11.636-11.636v-37.236l182.691-40.727
          C500.364,256,512,242.036,512,225.745v-74.473C512,131.491,496.873,116.364,477.091,116.364z M279.273,325.818h-46.545v-46.545
          h46.545V325.818z M488.727,225.745c0,5.818-3.491,10.473-9.309,11.636l-176.873,39.564v-9.309c0-6.982-4.655-11.636-11.636-11.636
          h-69.818c-6.982,0-11.636,4.655-11.636,11.636v9.309L32.582,237.382c-5.818-1.164-9.309-5.818-9.309-11.636v-74.473
          c0-6.982,4.655-11.636,11.636-11.636h442.182c6.982,0,11.636,4.655,11.636,11.636V225.745z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M314.182,34.909H197.818c-19.782,0-34.909,15.127-34.909,34.909v11.636c0,6.982,4.655,11.636,11.636,11.636
          s11.636-4.655,11.636-11.636V69.818c0-6.982,4.655-11.636,11.636-11.636h116.364c6.982,0,11.636,4.655,11.636,11.636v11.636
          c0,6.982,4.655,11.636,11.636,11.636c6.982,0,11.636-4.655,11.636-11.636V69.818C349.091,50.036,333.964,34.909,314.182,34.909z"
        />
      </g>
    </g>
  </svg>
);

export const ArrowRight = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5893 0.694582C7.26386 0.340982 6.73622 0.340982 6.41079 0.694582C6.08535 1.04818 6.08535 1.62148 6.41079 1.97508L10.8215 6.76752H1.16671C0.70647 6.76752 0.333374 7.17291 0.333374 7.67297C0.333374 8.17304 0.70647 8.57842 1.16671 8.57842H10.8215L6.41079 13.3709C6.08535 13.7245 6.08535 14.2978 6.41079 14.6514C6.73622 15.005 7.26386 15.005 7.5893 14.6514L13.4226 8.31322C13.7481 7.95962 13.7481 7.38632 13.4226 7.03272L7.5893 0.694582Z"
      fill="#05147D"
    />
  </svg>
);

export const Bell = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
    />
  </svg>
);

export const DotImage = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
    />
  </svg>
);
export const Image = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    stroke="currentColor"
    fill="currentColor"
  >
    <g>
      <g id="Icon-Image" transform="translate(278.000000, 232.000000)">
        <path
          d="M-226.2-181.6h-39.5c-2.3,0-4.2-1.9-4.2-4.2v-28.2c0-2.3,1.9-4.2,4.2-4.2h39.5     c2.3,0,4.2,1.9,4.2,4.2v28.2C-222-183.5-223.9-181.6-226.2-181.6L-226.2-181.6z M-265.8-215.5c-0.8,0-1.4,0.6-1.4,1.4v28.2     c0,0.8,0.6,1.4,1.4,1.4h39.5c0.8,0,1.4-0.6,1.4-1.4v-28.2c0-0.8-0.6-1.4-1.4-1.4H-265.8L-265.8-215.5z"
          id="Fill-12"
        />
        <path
          d="M-238.9-201.5c-3.1,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5s5.5,2.5,5.5,5.5     S-235.9-201.5-238.9-201.5L-238.9-201.5z M-238.9-210c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9     C-236-208.7-237.3-210-238.9-210L-238.9-210z"
          id="Fill-13"
        />
        <polyline
          id="Fill-14"
          points="-231.4,-182.1 -254.5,-203.8 -267.7,-191.6 -269.5,-193.5 -254.5,-207.4 -229.6,-184      -231.4,-182.1    "
        />
        <polyline
          id="Fill-15"
          points="-224.2,-189.3 -231.9,-195.5 -238.3,-190.2 -240,-192.3 -231.9,-198.9 -222.6,-191.3      -224.2,-189.3    "
        />
      </g>
    </g>
  </svg>
);
export const Settings = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    stroke="currentColor"
    fill="currentColor"
  >
    <path d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97l-.75-5.3c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97l-4.98-2.01c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31c-.08.64-.14 1.29-.14 1.95s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zm-14.86 5.05c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
  </svg>
);
export const QuestionIcon = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="currentColor"
  >
    <path
      d="M12,17a1,1,0,0,1-1-1v-.21a4.7,4.7,0,0,1,2.75-4.19A4,4,0,0,0,12,4h0A4,4,0,0,0,8,8,1,1,0,0,1,6,8a6,6,0,0,1,6-6h0a6.08,6.08,0,0,1,6,6,6,6,0,0,1-3.37,5.39A2.73,2.73,0,0,0,13,15.79V16A1,1,0,0,1,12,17Z"
      fill="currentColor"
    />
    <circle cx="12" cy="20" fill="currentColor" r="1" />
  </svg>
);
export const MenuIcon = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    stroke="currentColor"
    fill="currentColor"
  >
    <g data-name="Square Menu Alt" id="Square_Menu_Alt">
      <path d="M17.5,11h-3a2,2,0,0,1-2-2V6a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2V9A2,2,0,0,1,17.5,11Z" />
      <path d="M9,11H6A2,2,0,0,1,4,9V6A2,2,0,0,1,6,4H9a2,2,0,0,1,2,2V9A2,2,0,0,1,9,11Z" />
      <path d="M26,11H23a2,2,0,0,1-2-2V6a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2V9A2,2,0,0,1,26,11Z" />
      <path d="M17.5,28h-3a2,2,0,0,1-2-2V23a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2v3A2,2,0,0,1,17.5,28Z" />
      <path d="M9,28H6a2,2,0,0,1-2-2V23a2,2,0,0,1,2-2H9a2,2,0,0,1,2,2v3A2,2,0,0,1,9,28Z" />
      <path d="M26,28H23a2,2,0,0,1-2-2V23a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2v3A2,2,0,0,1,26,28Z" />
      <path d="M17.5,19.5h-3a2,2,0,0,1-2-2v-3a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2v3A2,2,0,0,1,17.5,19.5Z" />
      <path d="M9,19.5H6a2,2,0,0,1-2-2v-3a2,2,0,0,1,2-2H9a2,2,0,0,1,2,2v3A2,2,0,0,1,9,19.5Z" />
      <path d="M26,19.5H23a2,2,0,0,1-2-2v-3a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2v3A2,2,0,0,1,26,19.5Z" />
    </g>
  </svg>
);
export const World = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    stroke="currentColor"
    fill="currentColor"
  >
    <path
      clipRule="evenodd"
      d="M31.949,250c0,120.473,97.58,218.051,218.051,218.051  c120.473,0,218.051-97.578,218.051-218.051c0-120.471-97.578-218.051-218.051-218.051C129.529,31.949,31.949,129.529,31.949,250z   M77.375,250c0-13.992,1.638-27.62,4.814-40.61L122.802,250l-9.084,54.513l36.342,36.34v49.883  C106.088,359.48,77.375,308.063,77.375,250z M210.295,82.007c12.72-3.087,25.988-4.631,39.705-4.631c15.173,0,29.891,2,43.883,5.634  l-7.542,12.539l9.086,9.084h27.258l7.543-7.541c6.35,3.362,12.35,6.996,18.169,11.084l-16.625,5.542l-18.173,18.17l18.173,9.086  v9.086h-18.173v18.17c0,0,12.536,9.086,27.254,9.086c14.448,0,7.998-19.897,18.174-27.256c10.267-7.361,22.715-5.724,22.715-5.724  l5.906,1.544c11.085,14.626,19.892,30.984,25.891,48.697v0.909c0,0-12.812-9.086-36.34-9.086c-23.53,0-45.427,9.086-45.427,9.086  s-25.627,7.453-27.259,27.257c-2.82,34.069,9.086,45.428,9.086,45.428l45.428,27.255v88.408  C329.322,408.09,291.339,422.624,250,422.624c-19.532,0-38.249-3.174-55.785-9.174l46.699-108.938l-9.084-45.427  c0,0-36.071-45.428-54.513-45.428c-18.441,0-27.256,27.256-27.256,27.256l-18.172-36.34l54.512-36.343l27.257-72.683L210.295,82.007  z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export const FacebookIcon = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    stroke="currentColor"
    fill="currentColor"
  >
    <g>
      <path
        d="M512,256c0,-141.385 -114.615,-256 -256,-256c-141.385,0 -256,114.615 -256,256c0,127.777 93.616,233.685 216,252.89l0,-178.89l-65,0l0,-74l65,0l0,-56.4c0,-64.16 38.219,-99.6 96.695,-99.6c28.009,0 57.305,5 57.305,5l0,63l-32.281,0c-31.801,0 -41.719,19.733 -41.719,39.978l0,48.022l71,0l-11.35,74l-59.65,0l0,178.89c122.385,-19.205 216,-125.113 216,-252.89Z"
        fill="#1877f2"
        fillRule="nonzero"
      />
      <path
        d="M355.65,330l11.35,-74l-71,0l0,-48.022c0,-20.245 9.917,-39.978 41.719,-39.978l32.281,0l0,-63c0,0 -29.297,-5 -57.305,-5c-58.476,0 -96.695,35.44 -96.695,99.6l0,56.4l-65,0l0,74l65,0l0,178.89c13.033,2.045 26.392,3.11 40,3.11c13.608,0 26.966,-1.065 40,-3.11l0,-178.89l59.65,0Z"
        fill="#fff"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
export const GooglePlusIcon = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
  >
    <g id="_x31__stroke">
      <g id="Google">
        <rect
          clipRule="evenodd"
          fill="none"
          fillRule="evenodd"
          height="128"
          width="128"
        />
        <path
          clipRule="evenodd"
          d="M27.585,64c0-4.157,0.69-8.143,1.923-11.881L7.938,35.648    C3.734,44.183,1.366,53.801,1.366,64c0,10.191,2.366,19.802,6.563,28.332l21.558-16.503C28.266,72.108,27.585,68.137,27.585,64"
          fill="#FBBC05"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M65.457,26.182c9.031,0,17.188,3.2,23.597,8.436L107.698,16    C96.337,6.109,81.771,0,65.457,0C40.129,0,18.361,14.484,7.938,35.648l21.569,16.471C34.477,37.033,48.644,26.182,65.457,26.182"
          fill="#EA4335"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M65.457,101.818c-16.812,0-30.979-10.851-35.949-25.937    L7.938,92.349C18.361,113.516,40.129,128,65.457,128c15.632,0,30.557-5.551,41.758-15.951L86.741,96.221    C80.964,99.86,73.689,101.818,65.457,101.818"
          fill="#34A853"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M126.634,64c0-3.782-0.583-7.855-1.457-11.636H65.457v24.727    h34.376c-1.719,8.431-6.397,14.912-13.092,19.13l20.474,15.828C118.981,101.129,126.634,84.861,126.634,64"
          fill="#4285F4"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
export const WindowsIcon = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    stroke="currentColor"
    fill="currentColor"
  >
    <g>
      <g>
        <rect
          height="200.646"
          fill="#F25022"
          width="200.646"
          x="40.001"
          y="40"
        />
      </g>
      <g>
        <rect
          height="200.646"
          fill="#7FBA00"
          width="202.564"
          x="269.435"
          y="40"
        />
      </g>
      <g>
        <rect
          height="202.564"
          fill="#00A4EF"
          width="200.646"
          x="40.001"
          y="269.436"
        />
      </g>
      <g>
        <rect
          height="202.564"
          fill="#FFB900"
          width="202.564"
          x="269.435"
          y="269.436"
        />
      </g>
    </g>
  </svg>
);
export const AppleIcon = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    style={{ fill: '#000000' }}
  >
    <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z"></path>
  </svg>
);
export const Theme = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    stroke="currentColor"
    fill="currentColor"
  >
    <path d="M8,3.5C5.519,3.5,3.5,5.519,3.5,8s2.019,4.5,4.5,4.5s4.5-2.019,4.5-4.5S10.481,3.5,8,3.5z M8,11.5c-1.93,0-3.5-1.57-3.5-3.5  S6.07,4.5,8,4.5s3.5,1.57,3.5,3.5S9.93,11.5,8,11.5z" />
    <rect height="2" width="1" x="7.5" />
    <rect height="2" width="1" x="7.5" y="14" />
    <rect height="1" width="2" x="14" y="7.5" />
    <rect height="1" width="2" y="7.5" />
    <rect
      height="2"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.3639 12.9497)"
      width="1"
      x="12.45"
      y="11.95"
    />
    <rect
      height="2"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1.2635 3.0503)"
      width="1"
      x="2.55"
      y="2.05"
    />
    <rect
      height="1"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.2634 5.9498)"
      width="2"
      x="2.05"
      y="12.45"
    />
    <rect
      height="1"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 1.636 10.0502)"
      width="2"
      x="11.95"
      y="2.55"
    />
  </svg>
);

export const Users = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
);
export const User = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="20"
      height="20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM6 15.98C7.29 17.92 9.5 19.2 12 19.2C14.5 19.2 16.71 17.92 18 15.98C17.97 13.99 13.99 12.9 12 12.9C10 12.9 6.03 13.99 6 15.98Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect width="24" height="24" fill="#858C94" />
    </g>
  </svg>
);

export const Close = ({ className }: ClassNameProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);
export const Product = ({ className }: ClassNameProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 512 512"
    stroke="currentColor"
    className={className}
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        d="m71.858 418.114 48.142 24.067v59.819c0 5.523 4.478 10 10 10h106c5.522 0 10-4.477 10-10v-136.55c0-30.637-22.983-56.045-53.391-59.093-.118-.033-42.3-6.618-61.87-26.189l-22.458-22.448c-7.556-7.558-17.6-11.72-28.281-11.72v-80c0-22.056-17.944-40-40-40s-40 17.944-40 40v135.84c0 49.212 27.798 94.239 71.858 116.274zm-51.858-252.114c0-11.028 8.972-20 20-20s20 8.972 20 20v85.361c-12.196 7.052-20 20.2-20 34.639 0 10.68 4.162 20.723 11.719 28.28l51.21 51.22c3.903 3.905 10.236 3.907 14.142.001 3.905-3.905 3.906-10.236.001-14.142l-51.21-51.221c-3.78-3.779-5.862-8.8-5.862-14.138 0-8.464 5.357-16.044 13.332-18.861.003-.001.006-.003.009-.004h.001c6.896-2.444 15.077-.994 20.799 4.728l22.458 22.449c23.186 23.185 73.843 31.91 73.944 31.938 20.214 2.026 35.457 18.879 35.457 39.2v126.55h-86v-56c0-3.788-2.141-7.251-5.528-8.944l-53.669-26.83c-37.504-18.757-60.803-56.456-60.803-98.386z"
        fill="#ffffff"
      />
      <path
        d="m136 246h240c5.522 0 10-4.477 10-10v-226c0-5.523-4.478-10-10-10h-240c-5.522 0-10 4.477-10 10v226c0 5.523 4.478 10 10 10zm90-226h60v53.893l-25.975-12.855c-2.82-1.397-6.136-1.382-8.944.037l-25.081 12.67zm-80 0h60v70c0 3.473 1.802 6.698 4.761 8.518 2.957 1.819 6.649 1.974 9.748.408l35.127-17.745 35.929 17.782c3.046 1.508 6.722 1.389 9.71-.467 2.938-1.825 4.725-5.038 4.725-8.496v-70h60v206h-220z"
        fill="#ffffff"
      />
      <path
        d="m336 176h-60c-5.522 0-10 4.477-10 10s4.478 10 10 10h60c5.522 0 10-4.477 10-10s-4.478-10-10-10z"
        fill="#ffffff"
      />
      <circle cx="459" cy="394" r="10" fill="#ffffff" />
      <path
        d="m472 126c-22.056 0-40 17.944-40 40v80c-10.681 0-20.725 4.162-28.279 11.717l-22.462 22.452c-12.3 12.301-43.459 24.337-61.128 26.108l-.668.072c-30.48 3.056-53.463 28.464-53.463 59.101v136.55c0 5.523 4.478 10 10 10h106c5.522 0 10-4.477 10-10v-59.819l32.73-16.362c4.939-2.47 6.942-8.476 4.473-13.417-2.469-4.938-8.474-6.942-13.416-4.473l-38.259 19.125c-3.387 1.695-5.528 5.158-5.528 8.946v56h-86v-126.55c0-20.321 15.243-37.174 35.529-39.208l.669-.072c21.691-2.175 57.605-16.262 73.201-31.857l22.462-22.452c5.724-5.724 13.913-7.166 20.797-4.726h.001c.003.001.006.003.009.004 13.771 4.865 17.917 22.551 7.471 33.001l-51.21 51.22c-3.905 3.906-3.904 10.237.001 14.142 3.904 3.904 10.237 3.905 14.142-.001l51.21-51.22c18.487-18.49 14.636-49.669-8.281-62.92v-85.361c0-11.028 8.972-20 20-20s20 8.972 20 20v135.84c0 18.296-4.617 36.46-13.353 52.528-2.639 4.852-.844 10.924 4.009 13.562 4.851 2.636 10.924.843 13.562-4.009 10.324-18.99 15.781-40.458 15.781-62.081v-135.84c0-22.056-17.944-40-40-40z"
        fill="#ffffff"
      />
    </g>
  </svg>
);
export const Sort = ({ className }: ClassNameProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 12 12"
    stroke="currentColor"
    className={className}
  >
    <path d="M6.27203 10.4859L9.38731 7.79699C9.53756 7.6673 9.53756 7.45705 9.38731 7.32738L9.02397 7.01376C8.87398 6.8843 8.63088 6.88405 8.48054 7.01321L5.99999 9.14423L3.51946 7.01321C3.36912 6.88405 3.12602 6.8843 2.97603 7.01376L2.61269 7.32738C2.46244 7.45707 2.46244 7.66732 2.61269 7.79699L5.72795 10.4859C5.8782 10.6156 6.12179 10.6156 6.27203 10.4859Z" />
    <path d="M6.27203 1.51375L9.38731 4.20267C9.53756 4.33236 9.53756 4.54261 9.38731 4.67228L9.02397 4.9859C8.87398 5.11536 8.63088 5.11561 8.48054 4.98645L5.99999 2.85544L3.51946 4.98646C3.36912 5.11562 3.12602 5.11537 2.97603 4.98591L2.61269 4.67229C2.46244 4.54261 2.46244 4.33236 2.61269 4.20269L5.72797 1.51377C5.8782 1.38408 6.12179 1.38408 6.27203 1.51375Z" />
  </svg>
);

export const ArrowLeft = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 16l-4-4m0 0l4-4m-4 4h18"
    />
  </svg>
);

export const AddCircle = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);
export const Plus = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="#ffffff"
    viewBox="0 0 24 24"
    stroke="#ffffff"
    strokeWidth={3}
  >
    <line x1="12" x2="12" y1="5" y2="19" />
    <line x1="5" x2="19" y1="12" y2="12" />
  </svg>
);

export const ChevronLeft = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 512 512"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M388.819,239.537L156.092,6.816c-9.087-9.089-23.824-9.089-32.912,0.002  c-9.087,9.089-9.087,23.824,0.002,32.912l216.27,216.266L123.179,472.272c-9.087,9.089-9.087,23.824,0.002,32.912  c4.543,4.544,10.499,6.816,16.455,6.816c5.956,0,11.913-2.271,16.457-6.817L388.819,272.45c4.366-4.364,6.817-10.283,6.817-16.455  C395.636,249.822,393.185,243.902,388.819,239.537z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const DotsHorizaontal = ({ className }: ClassNameProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
    />
  </svg>
);

export const InfoButton = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 50"
    stroke="currentColor"
  >
    <g>
      <g xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M39.264,6.736c-8.982-8.981-23.545-8.982-32.528,0c-8.982,8.982-8.981,23.545,0,32.528c8.982,8.98,23.545,8.981,32.528,0    C48.245,30.281,48.244,15.719,39.264,6.736z M25.999,33c0,1.657-1.343,3-3,3s-3-1.343-3-3V21c0-1.657,1.343-3,3-3s3,1.343,3,3V33z     M22.946,15.872c-1.728,0-2.88-1.224-2.844-2.735c-0.036-1.584,1.116-2.771,2.879-2.771c1.764,0,2.88,1.188,2.917,2.771    C25.897,14.648,24.746,15.872,22.946,15.872z"
            fill="#960002"
            className={className}
          />
        </g>
      </g>
    </g>
  </svg>
);
export const Filter = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
    />
  </svg>
);
export const LinkedIn = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 320 350"
    stroke="currentColor"
  >
    <g>
      <path
        id="XMLID_802_"
        d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73   C77.16,101.969,74.922,99.73,72.16,99.73z"
        fill="#ffffff"
      />
      <path
        id="XMLID_803_"
        d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4   c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
        fill="#ffffff"
      />
      <path
        id="XMLID_804_"
        d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599   c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319   c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995   C310,145.43,300.549,94.761,230.454,94.761z"
        fill="#ffffff"
      />
    </g>
  </svg>
);

export const profileCamera = ({ className }: ClassNameProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="white"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
    <circle cx="12" cy="13" r="4"></circle>
  </svg>
);
