import type { GraphQLQuery } from '@aws-amplify/api';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';

import type {
  ModelDocItemFilterInput,
  DocItem,
  AllDocItemsUnderRootChapterForImportExportQuery,
} from 'API';

import { tenantAtom } from 'atoms/modals';

import { allDocItemsUnderRootChapterForImportExport } from 'graphql/custom-queries';

import getNonNullableList from 'helpers/utils/getNonNullableList';
import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseDocItemsUnderRootChapterType =
  | ({
      rootChapterId: string;
      filter?: ModelDocItemFilterInput | null;
    } & UseInfiniteQueryOptions)
  | null
  | void;

const useDocItemsUnderRootChapterSource = (
  props: IUseDocItemsUnderRootChapterType,
) => {
  const filter = props?.filter ?? null;

  const queryKey = props?.queryKey ?? [];

  const rootChapterId = props?.rootChapterId;

  const enabled = (props?.enabled ?? true) && !!rootChapterId;

  const staleTime =
    props?.staleTime ?? toMilliseconds({ hours: 0, minutes: 0, seconds: 20 }); // 20secs

  return useInfiniteQuery({
    queryKey: [
      `docItemsUnderRootChapter`,
      { rootChapterId },
      filter,
      ...queryKey,
    ],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<
        GraphQLQuery<AllDocItemsUnderRootChapterForImportExportQuery>
      >({
        query: allDocItemsUnderRootChapterForImportExport,
        variables: filter
          ? { rootChapterId, filter, limit: 1_000_000, nextToken: pageParam }
          : { rootChapterId, limit: 1_000_000, nextToken: pageParam },
      });
    },
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.allDocItemsUnderRootChapter?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
    enabled,
    staleTime,
  });
};

const useDocItemsUnderRootChapter = (
  props: IUseDocItemsUnderRootChapterType,
) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const filter = props?.filter ?? null;

  const rootChapterId = props?.rootChapterId ?? '';

  const enabled = (props?.enabled ?? true) && !!tenantId && !!rootChapterId;

  let sourceFilter: ModelDocItemFilterInput | null = null;

  if (tenantId) {
    const defaultFilter: ModelDocItemFilterInput | null = {
      tenantId: {
        eq: tenantId,
      },
    };

    sourceFilter = filter
      ? {
          ...filter,
          ...defaultFilter,
        }
      : { ...defaultFilter };
  }

  const result = useDocItemsUnderRootChapterSource({
    ...props,
    rootChapterId,
    filter: sourceFilter,
    enabled,
  });

  const docItemsUnderRootChapterSource = useMemo(() => {
    const pages = result.data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.allDocItemsUnderRootChapter?.items),
    );

    return tempItems as DocItem[];
  }, [result.data?.pages]);

  return {
    docItemsUnderRootChapterSource,
    ...result,
  };
};

export const useInfiniteDocItemsUnderRootChapter = (
  props: IUseDocItemsUnderRootChapterType,
) => {
  const result = useDocItemsUnderRootChapter(props);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = result;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      try {
        void fetchNextPage();
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return result;
};

export default useDocItemsUnderRootChapter;
