import { useEffect, useRef } from 'react';

type CallbackType = () => unknown;

// Why do this instead of using setInterval directly?? Check: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: CallbackType, delay: number | null) => {
  const savedCallback = useRef<CallbackType>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const tick = () => {
      savedCallback.current!();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;
