/* eslint-disable react/forbid-dom-props */
import { Dropdown, TooltipHost } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import {
  Legend,
  ResponsiveContainer,
  Cell,
  Pie,
  PieChart,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import Flex from 'components/Flex';
import Text from 'components/Text';

import { Lead } from 'API';

import { useChartTwo } from 'hooks/useLeadManagementDashboard/useChartTwo';

import renderCustomLegendForChart from 'helpers/renderCustomLegendForChart';
import isPartnerOrDistributor from 'helpers/utils/leadManagement/isPartnerOrDistributor';

const colorsForPieChartForPnD = [' #084E7E', ' #1077B8', '#0E613F', '#BA2216'];

const getCustomTooltipChartTwo = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length > 0) {
    return (
      <div className="custom-tooltip bg-white border border-black p-2">
        <div className={`text-[${payload[0].payload.fill.trim()}]`}>
          {payload[0].name} : {payload[0].value}
        </div>
      </div>
    );
  }

  return null;
};

export const ChartTwo = ({ leads }: { leads: Partial<Lead>[] }) => {
  const navigate = useNavigate();

  const {
    chart2Data,
    currentUserOrganization,
    leadsOfAllOrganisation,
    selectedOrganization,
    setSelectedOrganization,
    currentSalesTeamsRelatedOrgs,
    leadsOfSelectedOrganisation,
  } = useChartTwo({ leads });

  return (
    <Flex direction="col" className="  my-2">
      <Flex direction="row" height="h-8" width="w-full">
        <Text
          className="text-base flex-1 flex items-center pr-2  truncate overflow-hidden relative"
          weight={600}
        >
          <TooltipHost
            content={
              isPartnerOrDistributor(currentUserOrganization)
                ? 'Overall Leads'
                : 'Leads by Organization'
            }
          >
            <div className="text-base w-full truncate cursor-pointer ">
              {isPartnerOrDistributor(currentUserOrganization)
                ? 'Overall Leads'
                : 'Leads by Organization'}
            </div>
          </TooltipHost>
        </Text>
        {isPartnerOrDistributor(currentUserOrganization) ? null : (
          <Dropdown
            defaultSelectedKey="all"
            selectedKey={selectedOrganization}
            className="text-sm w-1/2 flex-1 ml-2 bg-white"
            options={[
              {
                id: 'all',
                key: 'all',
                text: 'All',
              },

              ...currentSalesTeamsRelatedOrgs,
            ]}
            onChange={(_event, item) => {
              if (item?.text === 'All') {
                setSelectedOrganization('all');
              } else {
                setSelectedOrganization((item?.key as string) || '');
              }
            }}
          />
        )}
      </Flex>
      <div className="border-2 w-full mt-4 pt-2">
        <ResponsiveContainer width="100%" aspect={1}>
          {isPartnerOrDistributor(currentUserOrganization) ? (
            <PieChart width={400} height={400}>
              <Pie
                data={chart2Data}
                cx="50%"
                cy="50%"
                innerRadius="60%"
                outerRadius="80%"
                fill="#8884d8"
                dataKey="value"
                onClick={(e) => {
                  navigate(`/leads?status=${e.name}`);
                }}
              >
                {chart2Data.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={colorsForPieChartForPnD[index]}
                  />
                ))}
              </Pie>
              <Tooltip
                content={(args) => getCustomTooltipChartTwo({ ...args })}
              />

              <Legend
                align="center"
                // height={50}
                content={(props) => {
                  const { payload } = props;

                  return (
                    <div className="max-w-fit mx-auto grid gap-y-1 gap-x-2 grid-cols-2 justify-center">
                      {payload?.map((entry, i) => (
                        <div
                          // eslint-disable-next-line react/no-array-index-key
                          key={`item-${entry.id}-${i}`}
                          className="max-w-fit flex justify-center"
                          style={{
                            color: entry.color,
                          }}
                        >
                          <svg
                            className="recharts-surface"
                            width="14"
                            // height="14"
                            viewBox="0 0 32 32"
                            version="1.1"
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginRight: '4px',
                            }}
                          >
                            <title />
                            <desc />
                            <path
                              stroke="none"
                              fill={entry.color}
                              d="M0,4h32v24h-32z"
                              className="recharts-legend-icon"
                            />
                          </svg>
                          <TooltipHost content={entry.value}>
                            <Text
                              style={{ color: entry.color }}
                              weight={400}
                              className="text-base font-microsoft"
                            >
                              <span className="truncate">
                                {`${entry.value}`}
                                {/* {`${entry.value}dssdsdsddsdsdssddsdsdsdsdsdsds`} */}
                              </span>
                            </Text>
                          </TooltipHost>
                        </div>
                      ))}
                    </div>
                  );
                }}
              />
            </PieChart>
          ) : (
            <PieChart width={400} height={400}>
              <Pie
                data={
                  selectedOrganization === 'all'
                    ? leadsOfAllOrganisation
                    : leadsOfSelectedOrganisation
                }
                cx="50%"
                cy="50%"
                innerRadius="60%"
                outerRadius="80%"
                fill="#8884d8"
                dataKey="value"
                nameKey="name"
                onClick={(e) => {
                  navigate(`/leads?status=${e.name}&orgId=${e.orgId}`);
                }}
              >
                {leadsOfAllOrganisation.map((entry, index) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={colorsForPieChartForPnD[index]}
                  />
                ))}
              </Pie>
              <Tooltip
                content={(args) => getCustomTooltipChartTwo({ ...args })}
              />
              <Legend content={renderCustomLegendForChart} />
            </PieChart>
          )}
        </ResponsiveContainer>
      </div>
    </Flex>
  );
};

export default ChartTwo;
