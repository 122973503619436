import { INavLinkGroup, INavLink } from '@fluentui/react';
import { useAtomValue } from 'jotai';
import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

import { StatusType } from 'API';

import {
  currentUserAccessDetailsAtom,
  currentUserDetailsAtom,
  isAdminAtom,
  isTenantAdminAtom,
} from 'atoms/modals';

import './Sidebar.css';

import useConstructStatus from 'hooks/useConstructStatus';
import useGetCurrentUserAccess from 'hooks/useGetCurrentUserAccess';
import useIsContentReader from 'hooks/useIsContentReader';

import CollapsedSideBar from './CollapsedSideBar';
import MainSideBar from './MainSideBar';

const Sidebar = ({
  setSidebarOpen,
  isSidebarOpen,
}: {
  isSidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [navLinkGroups1, setNavLinkGroups] = useState<INavLinkGroup[] | null>(
    null,
  );

  const [key, setKey] = useState<string | undefined>('#');

  const isSuperAdmin = useAtomValue(isAdminAtom);
  const isTenantAdmin = useAtomValue(isTenantAdminAtom);
  const currentUserDetails = useAtomValue(currentUserDetailsAtom);
  const currentUserAccessDetails = useAtomValue(currentUserAccessDetailsAtom);

  const pathname = useLocation().pathname;
  const search = useLocation().search;

  const { status } = useConstructStatus({ search });

  const { isContentReader } = useIsContentReader();

  const {
    hasSomeApproverAccess,
    hasSomeCreatorAccess,
    hasSomeReaderAccess,
    hasSomeSupportPortalAccess,
    hasSomeSupportLeadAccess,
    hasSomeSupportEngineerAccess,
  } = useGetCurrentUserAccess({});

  const hasSomeLeadManagementPortalAccess = useMemo(
    () => !!currentUserAccessDetails?.hasSomeLeadManagementPortalAccess,
    [currentUserAccessDetails?.hasSomeLeadManagementPortalAccess],
  );

  const isLeadManagementSalesTeamOrDirector = useMemo(
    () =>
      !!currentUserAccessDetails?.hasSomeLeadManagementSalesTeamAccess ||
      currentUserAccessDetails?.hasSomeLeadManagementSalesDirectorAccess,
    [
      currentUserAccessDetails?.hasSomeLeadManagementSalesDirectorAccess,
      currentUserAccessDetails?.hasSomeLeadManagementSalesTeamAccess,
    ],
  );

  const internalUserNavLinks: INavLinkGroup[] = useMemo(() => {
    const organizationId = currentUserDetails?.organizationId;

    const isEmployeeOrg = organizationId?.[3] === 'E';

    const crmMenu = isEmployeeOrg
      ? [
          {
            name: 'Falaina CRM',
            url: '#', // Keep a value for accessibility
            onClick: () => {
              window.location.href = 'https://www.zoho.com/en-in/crm/';
            },
            icon: 'People',
            title: '',
            isExpanded: false,
            key: 'falainaCRM',
          },
        ]
      : [];

    const navLinks = [
      {
        links: [
          {
            name: 'Home',
            url: '/#/',
            icon: 'Home',
            expandAriaLabel: 'Expand Home section',
            collapseAriaLabel: 'Collapse Home section',
            title: '',
            isExpanded: false,
            key: 'home',
          },
          ...crmMenu,
          {
            name: 'My Task',
            url: '/#/task',
            icon: 'Repair',
            expandAriaLabel: 'Expand Task section',
            collapseAriaLabel: 'Collapse Task section',
            title: '',
            isExpanded: false,
            key: 'userTask',
          },
          {
            name: 'Salesforce',
            url: '',
            icon: 'Org',
            expandAriaLabel: 'Expand Salesforce section',
            collapseAriaLabel: 'Collapse Salesforce section',
            isExpanded:
              pathname.includes('/product-management') ||
              pathname.includes('/product-sub-categories') ||
              pathname.includes('/product-versions') ||
              pathname.includes('/product-metric') ||
              pathname.includes('/contract-line') ||
              pathname.includes('/contract-management') ||
              pathname.includes('/contact-management') ||
              pathname.includes('/organization-management') ||
              pathname.includes('/solutions') ||
              pathname.includes('/issue-categories') ||
              pathname.includes('/case-severities') ||
              pathname.includes('/closure-reasons') ||
              pathname.includes('/roles') ||
              pathname.includes('/industries') ||
              pathname.includes('/countries'),
            title: '',
            shouldShowWhenCollapsed: false,
            links: [
              {
                name: 'Product Management',
                url: `/#/product-management`,
                icon: 'ContactList',
                key: 'product-management',
              },
              {
                name: 'Product Sub-categories',
                url: `/#/product-sub-categories`,
                icon: 'CustomList',
                key: 'product-sub-categories',
              },
              {
                name: 'Product Versions',
                url: `/#/product-versions`,
                icon: 'ContactList',
                key: 'product-versions',
              },
              // {
              //   name: 'Product Metric',
              //   url: `/#/product-metric`,
              //   key: 'product-metric',
              // },
              {
                name: 'Contract Line',
                url: `/#/contract-line`,
                icon: 'Documentation',
                key: 'contract-line',
              },
              {
                name: 'Contract Management',
                url: `/#/contract-management`,
                icon: 'CustomList',
                key: 'contract-management',
              },
              {
                name: 'Contact Management',
                url: `/#/contact-management`,
                icon: 'ContactList',
                key: 'contact-management',
              },
              {
                name: 'Organization Management',
                url: `/#/organization-management`,
                icon: 'ContactList',
                key: 'organization-management',
              },
              {
                name: 'Solutions',
                url: `/#/solutions`,
                icon: 'CustomList',
                key: 'solutions',
              },
              {
                name: 'Issue Categories',
                url: `/#/issue-categories`,
                icon: 'ContactList',
                key: 'issue-categories',
              },
              {
                name: 'Case Severities',
                url: `/#/case-severities`,
                icon: 'CustomList',
                key: 'case-severities',
              },
              {
                name: 'Closure Reasons',
                url: `/#/closure-reasons`,
                icon: 'ContactList',
                key: 'closure-reasons',
              },
              {
                name: 'Roles',
                url: `/#/roles`,
                icon: 'CustomList',
                key: 'roles',
              },
              {
                name: 'Industries',
                url: `/#/industries`,
                icon: 'ContactList',
                key: 'industries',
              },
              {
                name: 'Countries',
                url: `/#/countries`,
                icon: 'CustomList',
                key: 'countries',
              },
            ],
            key: 'salesforce',
          },
          {
            name: 'User Management',
            url: '',
            icon: 'ContactList',
            expandAriaLabel: 'Expand Products section',
            collapseAriaLabel: 'Collapse Products section',
            isExpanded:
              pathname.includes('/deletedusers') ||
              pathname.includes('/applicationroleassignment') ||
              pathname.includes('/activeusers'),
            title: '',
            shouldShowWhenCollapsed: false,
            links: [
              {
                name: 'Active Users',
                url: '/#/activeusers',
                icon: 'Contact',
                title: '',
                key: 'activeUsers',
              },
              {
                name: 'Application Role Assignment',
                url: '/#/applicationroleassignment',
                icon: 'Permissions',
                title: '',
                key: 'applicationRoleAssignment',
              },
              {
                name: 'Deleted Users',
                url: '/#/deletedusers',
                icon: 'BlockContact',
                title: '',
                key: 'deletedUsers',
              },
            ],
            key: 'userManagement',
          },
          {
            name: 'Groups',
            url: '',
            icon: 'HomeGroup',
            expandAriaLabel: 'Expand Products section',
            collapseAriaLabel: 'Collapse Products section',
            isExpanded:
              pathname.includes('/deletedgroups') ||
              pathname.includes('/activegroups'),
            title: '',
            shouldShowWhenCollapsed: false,
            links: [
              {
                name: 'Active Groups',
                url: '/#/activegroups',
                icon: 'Group',
                title: '',
                key: 'activeGroups',
              },
              {
                name: 'Deleted Groups',
                url: '/#/deletedgroups',
                icon: 'SecurityGroup',
                title: '',
                key: 'deletedGroups',
              },
            ],
            key: 'group',
          },
          {
            name: 'Documentation Portal',
            url: '/#/products?status=PUBLISHED&view=read',
            icon: 'Documentation',
            expandAriaLabel: 'Expand Products section',
            collapseAriaLabel: 'Collapse Products section',
            isExpanded: !!search.split('status=')[1],
            title: '',
            shouldShowWhenCollapsed: false,
            links: [
              {
                name: 'Published Documents',
                url: '/#/products?status=PUBLISHED',
                icon: 'Documentation',
                title: '',
                key: 'publishedDocuments',
              },
              {
                name: 'Edited Documents',
                url: '/#/products?status=PENDING',
                icon: 'ExploreContent',
                title: '',
                key: 'editedDocuments',
              },
              {
                name: 'Pending Approval',
                url: '/#/products?status=PENDING_APPROVAL',
                icon: 'TextDocumentShared',
                title: '',
                key: 'pendingApproval',
              },
            ],
            key: 'documentationPortal',
          },
          {
            name: 'Documentation Portal',
            url: '/#/products?status=PUBLISHED',
            icon: 'Documentation',
            expandAriaLabel: 'Expand Products section',
            collapseAriaLabel: 'Collapse Products section',
            title: '',
            isExpanded: false,
            key: 'documentationPortalOther',
          },

          {
            name: 'Support Portal',
            url: '/#/supportPortal',
            icon: 'People',
            isExpanded:
              pathname.includes('/Ticket') ||
              pathname.startsWith('/contracts') ||
              pathname.startsWith('/ticketRule'),
            key: 'supportPortal',
            title: '',
            links: [
              {
                name: 'Tickets',
                url: '/#/Ticket',
                icon: 'Ticket',
                title: '',
                key: 'ticket',
              },
              {
                name: 'Contract Information',
                url: '/#/contracts',
                icon: 'CustomList',
                title: '',
                key: 'contractInformation',
              },
            ],
          },
          {
            name: 'Support Portal',
            url: '/#/supportPortal',
            icon: 'People',
            isExpanded:
              pathname.includes('/Ticket') ||
              pathname.startsWith('/contracts') ||
              pathname.startsWith('/ticketRule'),
            key: 'supportPortalOther',
            title: '',
            links: [
              {
                name: 'Tickets',
                url: '/#/Ticket',
                icon: 'Ticket',
                title: '',
                key: 'ticket',
              },
              {
                name: 'Contract Information',
                url: '/#/contracts',
                icon: 'CustomList',
                title: '',
                key: 'contractInformation',
              },
              {
                name: 'Settings',
                url: '/#/ticketRule',
                icon: 'Settings',
                title: '',
                key: 'ticketRule',
              },
            ],
          },
          {
            name: 'Lead Registration',
            // url: '/#/leadManagement',
            url: '/#/leadRegistration',
            icon: 'Commitments',
            isExpanded: pathname.includes('/leads'),
            key: 'leadRegistration',
            title: '',
            links: [
              {
                name: 'Leads',
                url: '/#/leads',
                icon: 'People',
                title: '',
                key: 'leads',
              },
            ],
          },
          {
            name: 'Currency & Exchange rate',
            url: '/#/currency',
            icon: 'Money',
            isExpanded: false,
            key: 'currency',
            title: '',
          },
          {
            name: 'Tenants',
            url: '',
            icon: 'Org',
            expandAriaLabel: 'Expand Tenants section',
            collapseAriaLabel: 'Collapse Tenants section',
            isExpanded:
              pathname.includes('/activetenants') ||
              pathname.includes('/deletedtenants'),
            title: '',
            shouldShowWhenCollapsed: false,
            links: [
              {
                name: 'Active Tenants',
                url: `/#/activetenants`,
                icon: 'Accept',
                title: '',
                key: 'activetenants',
              },
              {
                name: 'Deleted Tenants',
                url: `/#/deletedtenants`,
                icon: 'Delete',
                title: '',
                key: 'deletedTenants',
              },
            ],
            key: 'tenants',
          },
        ],
      },
    ];

    return navLinks;
  }, [currentUserDetails?.organizationId, pathname, search]);

  const navLinkGroupsRes = useMemo(() => {
    if (currentUserDetails) {
      let navLinkGroups = internalUserNavLinks[0].links;

      let notApprovedLinks: string[] = [];

      const superAdminNavLinks = ['userManagement', 'group'];

      const userTask =
        hasSomeCreatorAccess ||
        hasSomeApproverAccess ||
        hasSomeSupportLeadAccess ||
        hasSomeSupportEngineerAccess ||
        isLeadManagementSalesTeamOrDirector
          ? ''
          : 'userTask';

      if (isSuperAdmin) {
        notApprovedLinks = [
          'userManagement',
          'group',
          'publishedDocuments',
          'editedDocuments',
          'superAdminTask',
          'userTask',
          'pendingApproval',
          'supportPortal',
          'ticketRule',
          'documentationPortal',
          'documentationPortalOther',
          'supportPortalOther',
          'currency',
          'product-management',
          'product-sub-categories',
          'product-versions',
          'product-metric',
          'contract-management',
          'contact-management',
          'organization-management',
          'solutions',
          'issue-categories',
          'case-severities',
          'closure-reasons',
          'roles',
          'industries',
          'countries',
          'salesforce',
        ];
      } else if (isTenantAdmin) {
        notApprovedLinks = [
          'userTask',
          'publishedDocuments',
          'editedDocuments',
          'pendingApproval',
          'documentationPortal',
          'supportPortal',
          'tenants',
        ];
      } else if (hasSomeApproverAccess && hasSomeCreatorAccess) {
        notApprovedLinks = [
          ...superAdminNavLinks,
          'superAdminTask',
          'documentationPortalOther',
          'supportPortalOther',
          hasSomeSupportPortalAccess ? '' : 'supportPortal',
          hasSomeLeadManagementPortalAccess ? '' : 'leadRegistration',
          userTask,
          'ticketRule',
          'currency',
          'tenants',
          'product-management',
          'product-sub-categories',
          'product-versions',
          'product-metric',
          'contract-management',
          'contact-management',
          'organization-management',
          'solutions',
          'issue-categories',
          'case-severities',
          'closure-reasons',
          'roles',
          'industries',
          'countries',
          'salesforce',
        ];
      } else if (hasSomeApproverAccess) {
        notApprovedLinks = [
          ...superAdminNavLinks,
          'publishedDocuments',
          'editedDocuments',
          'documentationPortal',
          'superAdminTask',
          'pendingApproval',
          'supportPortalOther',
          hasSomeSupportPortalAccess ? '' : 'supportPortal',
          hasSomeLeadManagementPortalAccess ? '' : 'leadRegistration',
          userTask,
          'ticketRule',
          'currency',
          'tenants',
          'product-management',
          'product-sub-categories',
          'product-versions',
          'product-metric',
          'contract-management',
          'contact-management',
          'organization-management',
          'solutions',
          'issue-categories',
          'case-severities',
          'closure-reasons',
          'roles',
          'industries',
          'countries',
          'salesforce',
        ];
      } else if (hasSomeCreatorAccess) {
        notApprovedLinks = [
          ...superAdminNavLinks,
          'documentationPortalOther',
          'supportPortalOther',
          'superAdminTask',
          hasSomeSupportPortalAccess ? '' : 'supportPortal',
          hasSomeLeadManagementPortalAccess ? '' : 'leadRegistration',
          userTask,
          'ticketRule',
          'currency',
          'tenants',
          'product-management',
          'product-sub-categories',
          'product-versions',
          'product-metric',
          'contract-management',
          'contact-management',
          'organization-management',
          'solutions',
          'issue-categories',
          'case-severities',
          'closure-reasons',
          'roles',
          'industries',
          'countries',
          'salesforce',
        ];
      } else if (hasSomeReaderAccess) {
        notApprovedLinks = [
          ...superAdminNavLinks,
          'publishedDocuments',
          'editedDocuments',
          'superAdminTask',
          'documentationPortal',
          'pendingApproval',
          'supportPortalOther',
          hasSomeSupportPortalAccess ? '' : 'supportPortal',
          hasSomeLeadManagementPortalAccess ? '' : 'leadRegistration',
          userTask,
          'ticketRule',
          'currency',
          'tenants',
          'product-management',
          'product-sub-categories',
          'product-versions',
          'product-metric',
          'contract-management',
          'contact-management',
          'organization-management',
          'solutions',
          'issue-categories',
          'case-severities',
          'closure-reasons',
          'roles',
          'industries',
          'countries',
          'salesforce',
        ];
      } else if (hasSomeSupportPortalAccess) {
        notApprovedLinks = [
          ...superAdminNavLinks,
          'publishedDocuments',
          'editedDocuments',
          'superAdminTask',
          'documentationPortal',
          'documentationPortalOther',
          'supportPortalOther',
          'pendingApproval',
          userTask,
          'ticketRule',
          hasSomeLeadManagementPortalAccess ? '' : 'leadRegistration',
          'currency',
          'tenants',
          'product-management',
          'product-sub-categories',
          'product-versions',
          'product-metric',
          'contract-management',
          'contact-management',
          'organization-management',
          'solutions',
          'issue-categories',
          'case-severities',
          'closure-reasons',
          'roles',
          'industries',
          'countries',
          'salesforce',
        ];
      } else if (hasSomeLeadManagementPortalAccess) {
        notApprovedLinks = [
          ...superAdminNavLinks,
          'publishedDocuments',
          'editedDocuments',
          'superAdminTask',
          'documentationPortal',
          'documentationPortalOther',
          'supportPortalOther',
          'pendingApproval',
          userTask,
          'ticketRule',
          'supportPortal',
          'currency',
          'tenants',
          'product-management',
          'product-sub-categories',
          'product-versions',
          'product-metric',
          'contract-management',
          'contact-management',
          'organization-management',
          'solutions',
          'issue-categories',
          'case-severities',
          'closure-reasons',
          'roles',
          'industries',
          'countries',
          'salesforce',
        ];
      } else {
        notApprovedLinks = [
          'userManagement',
          'group',
          'publishedDocuments',
          'editedDocuments',
          'superAdminTask',
          'userTask',
          'ticketRule',
          'pendingApproval',
          'supportPortal',
          'documentationPortal',
          'documentationPortalOther',
          'supportPortalOther',
          'leadRegistration',
          'currency',
          'tenants',
          'product-management',
          'product-sub-categories',
          'product-versions',
          'product-metric',
          'contract-management',
          'contact-management',
          'organization-management',
          'solutions',
          'issue-categories',
          'case-severities',
          'closure-reasons',
          'roles',
          'industries',
          'countries',
          'salesforce',
        ];
      }

      const filteredData = navLinkGroups.filter(
        (link) => link.key && !notApprovedLinks.includes(link.key),
      );

      if (notApprovedLinks.includes('ticketRule')) {
        const supportPortalNavGroup = filteredData.find(
          (x) => x.key === 'supportPortal',
        );

        let newFilteredData: INavLink[] = [];

        if (supportPortalNavGroup) {
          const allSupportPortalNavItems = supportPortalNavGroup.links ?? [];

          const finalSupportPortalNavItems = allSupportPortalNavItems.filter(
            (x) => x.key !== 'ticketRule',
          );

          supportPortalNavGroup.links = finalSupportPortalNavItems;

          const remainingNavGroups = filteredData.filter(
            (x) => x.key !== 'supportPortal',
          );

          newFilteredData = [...remainingNavGroups, supportPortalNavGroup];
        }
      }

      navLinkGroups = filteredData;

      return navLinkGroups;
    }

    return [];
  }, [
    currentUserDetails,
    hasSomeApproverAccess,
    hasSomeCreatorAccess,
    hasSomeLeadManagementPortalAccess,
    hasSomeReaderAccess,
    hasSomeSupportEngineerAccess,
    hasSomeSupportLeadAccess,
    hasSomeSupportPortalAccess,
    internalUserNavLinks,
    isLeadManagementSalesTeamOrDirector,
    isSuperAdmin,
    isTenantAdmin,
  ]);

  useEffect(() => {
    setNavLinkGroups([{ links: navLinkGroupsRes }]);
  }, [navLinkGroupsRes]);

  useEffect(() => {
    if (pathname === '/') {
      setKey('home');
    }

    if (currentUserDetails) {
      if (isContentReader) {
        if (pathname.startsWith('/products')) {
          setKey('documentationPortalOther');
        }
      } else if (hasSomeCreatorAccess || hasSomeApproverAccess) {
        if (pathname.startsWith('/task')) {
          setKey('userTask');
        }

        if (hasSomeCreatorAccess && pathname.startsWith('/products')) {
          if (status === StatusType.PENDING) {
            setKey('editedDocuments');
          } else if (status === StatusType.PENDING_APPROVAL) {
            setKey('pendingApproval');
          } else {
            setKey('publishedDocuments');
          }
        }
        if (isSuperAdmin && pathname.startsWith('/products')) {
          setKey('documentationPortalOther');
        }
        if (hasSomeApproverAccess && pathname.startsWith('/products')) {
          if (status === StatusType.PUBLISHED) {
            setKey('documentationPortal');
          }

          if (status === StatusType.PENDING_APPROVAL) {
            setKey('userTask');
          }
        }
        if (isSuperAdmin && pathname.startsWith('/currency')) {
          setKey('currency');
        }
      }
      if (isSuperAdmin && pathname.startsWith('/Ticket')) {
        if (pathname.startsWith('/Ticket')) {
          setKey('ticket');
        } else if (pathname.startsWith('/contracts')) {
          setKey('contractInformation');
        } else if (pathname.startsWith('/supportPortal')) {
          setKey('supportPortalOther');
        } else if (pathname.startsWith('/ticketRule')) {
          setKey('ticketRule');
        }
      }

      if (hasSomeSupportPortalAccess) {
        if (pathname.startsWith('/Ticket')) {
          setKey('ticket');
        } else if (pathname.startsWith('/contracts')) {
          setKey('contractInformation');
        } else if (pathname.startsWith('/supportPortal')) {
          setKey('supportPortal');
        } else if (pathname.startsWith('/ticketRule')) {
          setKey('ticketRule');
        }
      }

      if (hasSomeLeadManagementPortalAccess) {
        if (pathname.startsWith('/leadRegistration')) {
          setKey('leadRegistration');
        } else if (pathname.startsWith('/leads')) {
          setKey('leads');
        }
      }
    }
  }, [
    pathname,
    currentUserDetails,
    status,
    isContentReader,
    hasSomeApproverAccess,
    hasSomeCreatorAccess,
    isSuperAdmin,
    hasSomeSupportPortalAccess,
    hasSomeLeadManagementPortalAccess,
  ]);

  useEffect(() => {
    if (pathname.includes('/deletedusers')) {
      setKey('deletedUsers');
    } else if (pathname.includes('/applicationroleassignment')) {
      setKey('applicationRoleAssignment');
    } else if (pathname.includes('/activeusers')) {
      setKey('activeUsers');
    } else if (pathname.includes('/activegroups')) {
      setKey('activeGroups');
    } else if (pathname.includes('/deletedgroups')) {
      setKey('deletedGroups');
    } else if (pathname.includes('/Ticket')) {
      setKey('ticket');
    } else if (pathname.includes('/contracts')) {
      setKey('contractInformation');
    } else if (pathname.includes('/supportPortal')) {
      setKey('supportPortal');
    } else if (pathname.includes('/ticketRule')) {
      setKey('ticketRule');
    } else if (pathname.includes('/leadRegistration')) {
      setKey('leadRegistration');
    } else if (pathname.includes('/leads')) {
      setKey('leads');
    } else if (pathname.includes('/currency')) {
      setKey('currency');
    } else if (pathname.includes('/activetenants')) {
      setKey('activetenants');
    } else if (pathname.includes('/deletedtenants')) {
      setKey('deletedtenants');
    } else if (pathname.includes('/product-management')) {
      setKey('product-management');
    } else if (pathname.includes('/product-sub-categories')) {
      setKey('product-sub-categories');
    } else if (pathname.includes('/product-versions')) {
      setKey('product-versions');
    } else if (pathname.includes('/product-metric')) {
      setKey('product-metric');
    } else if (pathname.includes('/contract-line')) {
      setKey('contract-line');
    } else if (pathname.includes('/contract-management')) {
      setKey('contract-management');
    } else if (pathname.includes('/contact-management')) {
      setKey('contact-management');
    } else if (pathname.includes('/organization-management')) {
      setKey('organization-management');
    } else if (pathname.includes('/solutions')) {
      setKey('solutions');
    } else if (pathname.includes('/issue-categories')) {
      setKey('issue-categories');
    } else if (pathname.includes('/case-severities')) {
      setKey('case-severities');
    } else if (pathname.includes('/closure-reasons')) {
      setKey('closure-reasons');
    } else if (pathname.includes('/roles')) {
      setKey('roles');
    } else if (pathname.includes('/industries')) {
      setKey('industries');
    } else if (pathname.includes('/countries')) {
      setKey('countries');
    }
  }, [pathname]);

  const onLinkClick = useCallback(
    (_ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
      setKey(item?.key);
    },
    [],
  );

  const flattenedNavItems: INavLink[] | undefined = useMemo(
    () => navLinkGroups1?.[0].links,
    [navLinkGroups1],
  );

  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen(true);
  }, [setSidebarOpen]);

  const handleSidebarClose = useCallback(() => {
    setSidebarOpen(false);
  }, [setSidebarOpen]);

  if (!isSidebarOpen) {
    return (
      <CollapsedSideBar
        handleSidebarOpen={handleSidebarOpen}
        flattenedNavItems={flattenedNavItems}
      />
    );
  }

  return (
    <MainSideBar
      selectedKey={key}
      onLinkClick={onLinkClick}
      handleSidebarClose={handleSidebarClose}
      navLinkGroups1={navLinkGroups1}
    />
  );
};

export default Sidebar;
