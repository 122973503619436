import { useLocation } from 'react-router-dom';

import type { DocItem } from 'API';
import { StatusType } from 'API';

import type { CustomStatusType } from 'types/documents';
import type { DocItemsMapType } from 'types/Pages/Products';

import useConstructStatus from 'hooks/useConstructStatus';

import sortDocuments from 'helpers/utils/sortDocuments';

type UseTransformDocItemsType = {
  docItems: Partial<DocItem>[];
};

const filterDocItems = ({
  docItems,
  documentsMap,
  status,
}: {
  docItems: Partial<DocItem>[];
  documentsMap: DocItemsMapType;
  status: CustomStatusType;
}) => {
  return docItems.filter((c) => {
    if (status === StatusType.PUBLISHED) {
      const shouldKeep = c.status === StatusType.PUBLISHED;

      return shouldKeep;
    }

    if (
      status === StatusType.PENDING ||
      status === StatusType.PENDING_APPROVAL
    ) {
      if (c.id && documentsMap[c.id]) {
        const tempItems = [...(documentsMap[c.id] ?? [])];

        if (c.status === StatusType.PUBLISHED) {
          // If there is a pending version for an already existing published version, we show the pending version instead of the published version.
          // Otherwise, we show the published version directly.
          const shouldKeep = !tempItems.some(
            (item) => item.status === StatusType.PENDING,
          );

          return shouldKeep;
        }

        if (
          c.status === StatusType.PENDING ||
          c.status === StatusType.PENDING_APPROVAL
        ) {
          return true;
        }

        return false;
      }

      return false;
    }

    return false;
  });
};

const useTransformDocItems = ({ docItems }: UseTransformDocItemsType) => {
  const search = useLocation().search;
  const { status } = useConstructStatus({ search });

  const documentsMap: DocItemsMapType = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const document of docItems) {
    if (document.id) {
      if (documentsMap[document.id]) {
        if (
          document.status &&
          [
            StatusType.PUBLISHED,
            StatusType.PENDING,
            StatusType.PENDING_APPROVAL,
          ].includes(document.status as StatusType)
        ) {
          const currentDocumentsMapItems = documentsMap[document.id] ?? [];

          if (
            !currentDocumentsMapItems.some(
              (item) => item.status === document.status,
            )
          ) {
            documentsMap[document.id] = [
              ...(documentsMap[document.id] ?? []),
              document,
            ];
          }
        } else {
          documentsMap[document.id] = [
            ...(documentsMap[document.id] ?? []),
            document,
          ];
        }
      } else {
        documentsMap[document.id] = [document];
      }
    }
  }

  const modifiedDocItems = filterDocItems({ docItems, documentsMap, status });

  const sortedFilteredDocItems = sortDocuments({
    documents: [...modifiedDocItems],
    documentsMap,
  });

  return {
    filteredDocItems: sortedFilteredDocItems,
    documentsMap,
  };
};

export default useTransformDocItems;
