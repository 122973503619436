import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { User } from 'API';

import {
  currentUserDetailsAtom,
  currentUserOrganizationAtom,
} from 'atoms/modals';

const useSetUserDetails = ({
  userDetails,
}: {
  userDetails: Partial<User> | null;
}) => {
  const setCurrentUserDetails = useSetAtom(currentUserDetailsAtom);
  const setCurrentUserOrganization = useSetAtom(currentUserOrganizationAtom);

  useEffect(() => {
    if (userDetails) {
      setCurrentUserDetails(userDetails);

      if (userDetails.organizationId) {
        setCurrentUserOrganization(userDetails.organizationId);

        // For testing as a user belonging to different orgs
        // setCurrentUserOrganization('SGPE1001'); // Employee
        // setCurrentUserOrganization('SGPC1001'); // Customer
        // setCurrentUserOrganization('INDP1005'); // Partner
        // setCurrentUserOrganization('MYSD1001'); // Distributor
        // setCurrentUserOrganization('MYSD1002'); // Distributor
        // setCurrentUserOrganization('SGPD1005'); // Distributor
      }
    }
  }, [userDetails, setCurrentUserDetails, setCurrentUserOrganization]);
};

export default useSetUserDetails;
