import type { GraphQLQuery } from '@aws-amplify/api';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useAtomValue } from 'jotai';
import { useMemo, useEffect } from 'react';

import type {
  CustomListSFSolutionsQuery,
  ModelSFSolutionFilterInput,
  SFSolution,
} from 'API';

import { tenantAtom } from 'atoms/modals';

import { customListSFSolutions } from 'graphql/custom-queries';

import getNonNullableList from 'helpers/utils/getNonNullableList';
import toMilliseconds from 'helpers/utils/toMilliseconds';

type IUseSFSolutionsSourceType =
  | ({
      filter?: ModelSFSolutionFilterInput | null;
    } & UseInfiniteQueryOptions)
  | null
  | void;

export const useSFSolutionsSource = (props: IUseSFSolutionsSourceType = {}) => {
  const filter = props?.filter ?? null;

  const queryKey = props?.queryKey ?? [];

  const enabled = props?.enabled ?? true;

  return useInfiniteQuery({
    queryKey: ['sfSolutions', filter, ...queryKey],
    queryFn: (params) => {
      const pageParam: unknown = params.pageParam;

      return API.graphql<GraphQLQuery<CustomListSFSolutionsQuery>>({
        query: customListSFSolutions,
        variables: filter
          ? { filter, limit: 1_000_000, nextToken: pageParam }
          : { limit: 1_000_000, nextToken: pageParam },
      });
    },
    staleTime: toMilliseconds({ hours: 1, minutes: 0, seconds: 0 }), // 1hr
    enabled,
    getNextPageParam: (lastPage) => {
      const nextToken = lastPage.data?.listSFSolutions?.nextToken;

      return nextToken ?? undefined; // Return undefined if no nextToken present: https://react-query.tanstack.com/guides/infinite-queries
    },
  });
};

const useSFSolutions = (props: IUseSFSolutionsSourceType = {}) => {
  const tenantAtomValue = useAtomValue(tenantAtom);

  const tenantId = tenantAtomValue?.id ?? null;

  const filter = props?.filter ?? null;

  const enabled = (props?.enabled ?? true) && !!tenantId;

  const defaultFilter: ModelSFSolutionFilterInput | null = tenantId
    ? {
        tenantId: {
          eq: tenantId,
        },
      }
    : null;

  const sourceFilter: ModelSFSolutionFilterInput | null = tenantId
    ? filter
      ? { ...filter, ...defaultFilter }
      : { ...defaultFilter }
    : null;

  const result = useSFSolutionsSource({
    ...props,
    filter: sourceFilter,
    enabled,
  });

  const sfSolutionsSource = useMemo(() => {
    const pages = result.data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.listSFSolutions?.items),
    );

    return tempItems as Partial<SFSolution>[];
  }, [result.data?.pages]);

  const sfSolutions = useMemo(
    () =>
      sfSolutionsSource.flatMap((sfSolution) =>
        sfSolution.id && sfSolution.name
          ? [
              {
                id: sfSolution.id,
                name: sfSolution.name,
              },
            ]
          : [],
      ),
    [sfSolutionsSource],
  );

  return {
    sfSolutionsSource,
    sfSolutions,
    ...result,
  };
};

export const useInfiniteSFSolutions = (
  props: IUseSFSolutionsSourceType = {},
) => {
  const result = useSFSolutions(props);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = result;

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      try {
        void fetchNextPage();
      } catch (error) {
        console.error(error);
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return result;
};

export default useSFSolutions;
