import { useEffect, useState } from 'react';

import Flex from 'components/Flex';

import { StatusType } from 'API';

import { DocItemPageType } from 'types/documents';

import { DIFF_BG_CLASSNAME } from 'consts/diffConstants';

import useIsContentReader from 'hooks/useIsContentReader';
import useS3FileUrl from 'hooks/useS3FileUrl';

import DocItemModifier from './DocItemModifier';
import { Panel, PanelType } from '@fluentui/react';

const ImageBlock = ({
  doc,
  docItem,
  documentsMap,
  rootChapter,
  allRootChapterVersions = [],
  isAnyAncestorDeleted,
  setMessage,
}: DocItemPageType) => {
  const [isHoveringDocItem, setIsHoveringDocItem] = useState(false);

  const { isContentReader } = useIsContentReader();

  const fileUrl = useS3FileUrl(docItem);

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [clickedImageSrc, setClickedImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const imgElement = document.getElementById(docItem.id);
    const onImageClick = () => {
      setClickedImageSrc(fileUrl);
      setIsPanelOpen(true);
    };
    imgElement && imgElement.addEventListener('click', onImageClick);

    return () => {
      imgElement && imgElement.removeEventListener('click', onImageClick);
    };
  }, [docItem.id, fileUrl]);

  let bgColor = '';

  if (
    !isContentReader &&
    (docItem.status === StatusType.PENDING || isAnyAncestorDeleted)
  ) {
    bgColor = DIFF_BG_CLASSNAME;
  }

  return (
    <div
      id={docItem.id}
      className="flex flex-row justify-start w-auto max-w-2xl z-0 "
      onMouseEnter={() => {
        setIsHoveringDocItem(true);
      }}
      onMouseLeave={() => {
        setIsHoveringDocItem(false);
      }}
    >
      <Flex
        rounded="rounded-md"
        padding="py-4 "
        margin="my-3"
        className={bgColor}
      >
        <Flex
          alignItems="center"
          className="lg:justify-start md:justify-start sm: justify-between"
        >
          {fileUrl && (
            <img src={fileUrl} alt="fetchedPic" className="w-auto h-auto" />
          )}
        </Flex>
        {isHoveringDocItem && (
          <DocItemModifier
            doc={doc}
            docItem={docItem}
            documentsMap={documentsMap}
            rootChapter={rootChapter}
            allRootChapterVersions={allRootChapterVersions}
            isAnyAncestorDeleted={isAnyAncestorDeleted}
            setMessage={setMessage}
          />
        )}
      </Flex>
      <Panel
        isOpen={isPanelOpen}
        onDismiss={() => setIsPanelOpen(false)}
        type={PanelType.custom}
        customWidth="100vw"
        styles={{
          main: { height: '100vh' },
          content: { height: '100%' },
          scrollableContent: {
            height: '100%',
            overflowY: 'hidden',
          },
        }}
      >
        {clickedImageSrc && (
          <img
            src={clickedImageSrc}
            alt="Clicked"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              display: 'block',
            }}
          />
        )}
      </Panel>
    </div>
  );
};

export default ImageBlock;
