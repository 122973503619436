/* eslint-disable unicorn/prefer-set-has */
/* eslint-disable react/forbid-dom-props */
import { Icon } from '@fluentui/react';
import { Button } from '@fluentui/react-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Flex from 'components/Flex';
import HomeLoader from 'components/Loader/HomeLoader';
// import MaintenanceNotification from 'components/MaintenanceNotification';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import Text from 'components/Text';

import useTenant from 'hooks/useTenant';

import type { ChildrenProps } from 'types';

import ProfileCard from './component/ProfileCard';
import Footer from './Footer';
import ProfilePictureCard from './Footer/ProfileCard';
import useAppLayout from './hooks';

const AppLayout = ({ children }: ChildrenProps) => {
  const {
    userDetails,
    isUserDisabled,
    setSidebarOpen,
    isSidebarOpen,
    setIsShowProfileCard,
    isShowProfileCard,
    currentUserAccessDetails,
    pathname,
    hasSomeSupportPortalAccess,
  } = useAppLayout();

  const { tenantLogoURL } = useTenant();

  if (!userDetails) {
    return <Flex direction="col" className="w-0" />;
  }

  if (isUserDisabled) {
    return (
      <Flex width="lg:w-full md:w-full sm: w-full" direction="col">
        <div className="min-h-screen h-auto flex w-full justify-center items-center">
          <Text className="bg-white p-4 rounded-sm m-1 text-base">
            You account has been disabled. Please contact your Admininstrator.
          </Text>
        </div>
      </Flex>
    );
  }

  return (
    <Flex direction="col" className="w-0">
      <Navbar variant="secondary">
        <Flex
          width="w-full"
          margin="mx-auto"
          justify="between"
          alignItems="center"
        >
          <Flex width="w-1/2" alignItems="center">
            <Icon
              iconName="Waffle"
              className="ml-3 mr-2 text-3xl text-white cursor-pointer"
              onClick={() => {
                setSidebarOpen(!isSidebarOpen);
              }}
            />
            <Link to="/">
              <img
                src={tenantLogoURL}
                className="sm:w-60 xl:w-60 lg:w-60 md:w-60"
                alt="logo"
              />
            </Link>
          </Flex>

          <Flex width="w-1/2" justify="end" className="items-center">
            <Flex
              width="w-8"
              height="h-8"
              rounded="rounded-full"
              border="border-2 border-white"
              direction="col"
              className="lg:mt-0 md:mt-0 sm:mt-0.5 mx-4 cursor-pointer z-30"
              position="relative"
              onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                  setIsShowProfileCard(false);
                }
              }}
            >
              <ProfilePictureCard
                isShowProfileCard={isShowProfileCard}
                setIsShowProfileCard={setIsShowProfileCard}
                userDetails={userDetails}
              />

              {isShowProfileCard && (
                <ProfileCard setIsShowProfileCard={setIsShowProfileCard} />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Navbar>
      {currentUserAccessDetails ? (
        <Flex direction="row" margin="mt-12">
          <Flex
            width={isSidebarOpen ? ' lg:w-3/12 xl:w-60 2xl:w-60' : 'lg:w-16'}
          >
            <Sidebar
              isSidebarOpen={isSidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </Flex>
          <Flex
            width={
              isSidebarOpen
                ? pathname === '/' && !hasSomeSupportPortalAccess
                  ? 'w-full lg:w-9/12 xl:w-full'
                  : 'w-full lg:w-9/12 xl:w-10/12 2xl:w-10/12'
                : 'w-full'
            }
            direction="col"
            backgroundColor="bg-white"
          >
            <div
              className={classNames(
                'min-h-screen h-auto flex flex-col w-full mx-auto lg:ml-0',
              )}
              style={{
                backgroundColor: '#fff',
              }}
            >
              {/* <MaintenanceNotification /> */}
              {children}
            </div>
          </Flex>
        </Flex>
      ) : (
        <HomeLoader />
      )}

      <Footer />
    </Flex>
  );
};

export default AppLayout;
