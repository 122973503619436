import { useMutation } from '@tanstack/react-query';
import gql from 'graphql-tag';

import {
  CreateChapterInput,
  CustomCreateChapterMutation,
  CustomUpdateChapterMutation,
  CustomDeleteChapterMutation,
  UpdateChapterInput,
} from 'API';

import {
  customCreateChapter,
  customUpdateChapter,
  customDeleteChapter,
} from 'graphql/custom-mutations';

import client from 'helpers/utils/client';

export type ChapterParams = {
  name: string;
  description: string;
  file: any;
  parentProductId: string;
  rootChapterId?: string | null;
  version?: string | null;
  parentChapterId: string;
};

export type EditChapterParams = {
  id?: string;
  name: string;
  file?: any;
  description: string;
  parentProductId: string;
  parentChapterId: string;
};

export const createChapterRequest = (payload: CreateChapterInput) => {
  const input: CreateChapterInput = {
    version: payload.version,
    status: payload.status,
    createdByUserId: payload.createdByUserId,
    lastModifiedByUserId: payload.lastModifiedByUserId,
    name: payload.name,
    description: payload.description,
    parentProductDocId: payload.parentProductDocId,
    rootChapterId: payload.rootChapterId,
    parentChapterId: payload.parentChapterId,
    file: payload.file,
    orderFloat: payload.orderFloat,
    tenantId: payload.tenantId,
  };

  if (payload.id) {
    input.id = payload.id;
  }

  if (payload.shouldBeDeleted) {
    input.shouldBeDeleted = payload.shouldBeDeleted;
  }

  return client.mutate<CustomCreateChapterMutation>({
    mutation: gql`
      ${customCreateChapter}
    `,
    variables: {
      input,
    },
  });
};

export const updateChapterRequest = (payload: UpdateChapterInput) => {
  const input: UpdateChapterInput = {
    id: payload.id,
    version: payload.version,
    status: payload.status,
    lastModifiedByUserId: payload.lastModifiedByUserId,
    name: payload.name,
    description: payload.description,
    parentProductDocId: payload.parentProductDocId,
    parentChapterId: payload.parentChapterId,
    file: payload.file,
    orderFloat: payload.orderFloat,
  };

  if (payload.currentTaskId) {
    input.currentTaskId = payload.currentTaskId;
  }

  if (payload.isRejected !== null) {
    input.isRejected = payload.isRejected;
  }

  return client.mutate<CustomUpdateChapterMutation>({
    mutation: gql`
      ${customUpdateChapter}
    `,
    variables: {
      input,
    },
  });
};

export const deleteChapterRequest = ({
  id,
  version,
}: {
  id: string;
  version: string;
}) => {
  return client.mutate<CustomDeleteChapterMutation>({
    mutation: gql`
      ${customDeleteChapter}
    `,
    variables: {
      input: { id, version },
    },
  });
};

export const useCreateChapter = () =>
  useMutation({
    mutationFn: createChapterRequest,
  });

export const useUpdateChapter = () =>
  useMutation({ mutationFn: updateChapterRequest });

export const useDeleteChapter = () =>
  useMutation({ mutationFn: deleteChapterRequest });
