import { useNavigate, useLocation } from 'react-router-dom';

const useHistoryLocation = () => {
  const navigate = useNavigate();

  const location = useLocation();

  return [navigate, location] as const;
};

export default useHistoryLocation;
