import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

import { Lead } from 'API';

import { currentUserAccessDetailsAtom, isTenantAdminAtom } from 'atoms/modals';

import falainaTenantId from 'consts/tenant';

import { useInfiniteLeadsList } from 'queries/leads.query';

import getNonNullableList from 'helpers/utils/getNonNullableList';

const useGetAllLeads = () => {
  const currentUserAccessDetails = useAtomValue(currentUserAccessDetailsAtom);
  const isTenantAdmin = useAtomValue(isTenantAdminAtom);

  const hasSomeLeadManagementPortalAccess =
    !!currentUserAccessDetails?.hasSomeLeadManagementPortalAccess;

  const {
    data,
    isInitialLoading,
    isError,
    isLoadingError,
    isFetching,
    isFetched,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteLeadsList({
    enabled: isTenantAdmin || hasSomeLeadManagementPortalAccess,
    filter: { tenantId: { eq: falainaTenantId } },
  });

  const leads = useMemo(() => {
    const pages = data?.pages ?? [];

    const tempItems = pages.flatMap((page) =>
      getNonNullableList(page.data?.listLeads?.items),
    );

    return tempItems as Partial<Lead>[];
  }, [data?.pages]);

  return {
    leads,
    isInitialLoading,
    isError,
    isLoadingError,
    isFetching,
    isFetched,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  };
};

export default useGetAllLeads;
